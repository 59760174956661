import {FieldState, FormState} from "formstate-x/lib";
import moment from "moment-timezone";
import {CampaignStore, IGroupCampaignStore, INotificationStore, ITourStore} from "../../store";
import {Icons} from "../../components/Icon";
import {ITicket} from "../ticket-templates/models";
import {PageTemplateTypes} from "./style-step/models";

export interface ICampaignStore {
    campaignStore: CampaignStore
}

export interface ICampaignState {
    steps: IStep[];
    loadingData: boolean;
}

export enum StatusStep {
    inProgress = 'in Progress',
    done = 'Done',
    not = 'Not done yet',
}

export enum TitleStep {
    DEFINE_OBJECTIVE = 'DEFINE OBJECTIVES',
    DEFINE_PRODUCTS = 'DEFINE PRODUCTS',
    DEFINE_STYLE = 'DEFINE STYLE',
}

export interface IStep {
    title: TitleStep;
    status: StatusStep;
    icon: Icons,
    active: boolean;
}

export type ICampaignForm = {
    title: FieldState<string>;
    brand: FieldState<string>;
    brand_logo: FieldState<any>;
    ticket_template: FieldState<string>;
    page_template: FieldState<number | null>;
    accuracy: FieldState<ICampaign['accuracy']>;
    track_codes: FieldState<string[]>;
    approve: FieldState<ICampaign['approve']>;
    budget: FieldState<string>;
    start_date: FieldState<moment.Moment | null>;
    end_date: FieldState<moment.Moment | null>;
    slug: FieldState<string>;
    sender_name: FieldState<string>;
    custom_subject: FieldState<string>;
    bulk_approval: FieldState<boolean>;
}
export type FileDTO = File & { original_url: string }

export type FileImage = { [key: string] : FileDTO }

export interface ITrackCode {
    id: number;
    campaign_id: number;
    created_at: string;
    name: string;
    updated_at: string;
}

export interface ICampaign {
    id: number;
    title: string;
    brand: string;
    brand_logo: any;
    accuracy?: number;
    cashbacks: boolean;
    track_codes: ITrackCode[];
    approve: number;
    bulk_approval: boolean;
    ticket_template: ITicket;
    page_template: {
        id: number;
    } | null;
    status: CampaignStatuses;
    budget: string;
    start_date: moment.Moment;
    end_date: moment.Moment;
    company: object; // TODO - заменить на интерфейс
    products: IProductDTO[];
    pages: object[]; // TODO - заменить на интерфейс
    slug: string;
    sender_name: string;
    custom_subject: string;
}

export interface ICashback {
    id: number;
    email?: string;
    accuracy: string;
    cashback_number: string;
    name: string;
    payment_method: string;
    product: string;
    campaign: Partial<ICampaign>;
    invoice: {
        id: number;
    }
    sum: number;
    status: number;
}

export enum CashbackStatuses {
    'Accepted'= 1,
    'Review',
    'Rejected',
    'Paid'
}

export enum CampaignStatuses {
    'Active' = 1,
    'Completed' = 2,
    'Draft' = 3,
    'Archive' = 4,
}

export interface IBrandLogo {
    custom_properties: []
    extension: string;
    file_name: string;
    name: string;
    order: number;
    original_url: string;
    preview_url: string;
    size: number;
    uuid: string;
}

export enum Currency {
    EUR = "EUR",
    USD = "USD",
}

export interface IProduct {
    id?: number;
    name: string;
    cashback: string;
    ticketNames: string[];
    image: any;
    value: string;
    max_value?: string;
    currency: Currency | null;
}

export interface IProductDTO {
    id?: number;
    name: string;
    cashback: string;
    ticketNames: ITicketName[];
    image: any;
    value: string;
    max_value?: string;
    currency: Currency | null;
}

export interface ITicketName {
    id: string;
    name: string;
}

export interface IDetail {
    budget: string;
    cashbacks_count: {
        review: number;
        paid: number;
        accepted: ICounts;
        processed: ICounts;
        rejected: ICounts;
    };
    id: number;
    spent: string;
    title: string;
}

export interface ICounts {
    total: number;
    automatically: number;
    manually: number;
}

export interface ICounter {
    icon: Icons;
    title: string;
    filterName?: string;
    value: number;
    manual?: number;
    auto?: number;
}

export type IProductForm = {
    id: FieldState<IProduct['id']>;
    name: FieldState<IProduct['name']>;
    value: FieldState<IProduct['value']>;
    max_value: FieldState<IProduct['max_value']>;
    ticketNames: FieldState<IProduct['ticketNames']>;
    cashback: FieldState<IProduct['cashback']>;
    image: FieldState<IProduct['image']>;
    currency: FieldState<IProduct['currency']>;
}

export interface IProductProps {
    product: FormState<any>;
    image: string;
    index: number;

    onRemove(): void;
}

export interface IPage {
    id: number;
    template: string;
    options: {
        general: string;
        main: string;
        h1: string;
        h2: string;
        data: string;
    }
    products: IProduct[];
}

export type Page = Pick<IPage, 'id'|'template'>

export interface ICashbackInfo {
    campaign: number;
    onSearch(value: string): void;
}

export interface ITemplate {
    id: number;
    isCurrent: boolean;
    dataTour?: string;
    options: {
        name: string;
        [PageTemplateTypes.welcome]: ITemplateOption;
        [PageTemplateTypes.popup_confirm]?: ITemplateOption;
        [PageTemplateTypes.form]?: ITemplateOption;
        [PageTemplateTypes.upload]?: ITemplateOption;
        [PageTemplateTypes.thank_you]?: ITemplateOption;
    }
}

export interface ITemplateOption {
    general: string;
    main: string;
    h1: string;
    h2: string;
    data: string;
}

export interface IInfo {
    active: number | null;
    total: number | null;
    reach: number | null;
    statistic: number | null;
}

export interface ICampaignItemProps {
    isSubItem?: boolean;
    isGroupItem?: boolean;
    loading?: boolean;
    isReports?: boolean;
    dataTour?: string;
}

export type CampaignItemProps = ICampaign & ICampaignItemProps & ICampaignStore & INotificationStore & IGroupCampaignStore & ITourStore;