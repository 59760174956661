import {createContext} from "react";
import {isNil} from 'lodash';
import {action, makeObservable, observable} from "mobx";
import {FieldState, FormState} from "formstate-x/lib";

import {PermissionName, User} from "../shared/models";
import {repository} from "../shared/repositories";
import {ILoginForm} from "../pages/Login/models";

export class AuthStore {
    @observable loginForm: FormState<ILoginForm>;
    @observable token!: string;
    @observable user!: User;

    constructor() {
        this.loginForm = new FormState({
            email: new FieldState(""),
            password: new FieldState(""),
        });

        makeObservable(this)

        const localToken = localStorage.getItem('token');
        if (localToken) {
            this.setToken(localToken);

            const localUser = localStorage.getItem('user');
            if (localUser) {
                this.setUser(JSON.parse(localUser));
            }
        }

    }

    get userIsSuperAdmin(): boolean {
        const permissions = (this.user?.permissions || []).find(p => p.name === PermissionName.super_admin);

        return !isNil(permissions);
    }

    @action
    setToken(token: string) {
        this.token = token;
        localStorage.setItem('token', token);
    }

    @action
    setUser(user: User) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(user));
    }

    @action
    logout() {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        window.location.href = "/login";
    }

    @action
    async login() {
        const response = await repository.auth.login({
            email: this.loginForm.$.email._value,
            password: this.loginForm.$.password._value
        });

        this.setToken(response.data.access_token);
        this.setUser(response.data.user);
    }
}

export const AuthContext = createContext<AuthStore>(new AuthStore())
