import {FC} from "react";
import {Icon, Icons} from '../../components/Icon';
import './CompanySettingsOption.scss';

export interface ICompanySettingsOptionProps {
    urlToDocument: string | null;
    title: string;
    value: string;
    actionName: string;
    action(): void;
}

export const CompanySettingsOption: FC<ICompanySettingsOptionProps> = (props) => {
    return (
        <div className="settings-option">
            <div className="settings-option__info">
                <div className="settings-option__info-title">
                    {props.title}
                </div>
                <div className="settings-option__info-value">
                    {props.value}
                </div>
            </div>
            {props.urlToDocument && <div className="settings-option__action settings-option__action--first">
                <a target="_blank" className="settings-option__action-name" href={props.urlToDocument}>
                    view
                </a>
            </div>}
            <div className="settings-option__action" onClick={props.action}>
                <Icon icon={Icons.edit} width={12} height={12} />
                <div className="settings-option__action-name">
                    {props.actionName}
                </div>
            </div>
        </div>
    )
}

