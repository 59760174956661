import React, {FC, useState} from "react";
import {stylePageStoreEnhancer, IStylePageStore} from "../../../store";
import {Icon, Icons} from "../../../components/Icon";

import './styles.scss';

export const Preview = stylePageStoreEnhancer((props: IStylePageStore) => {
    const [display, setDisplay] = useState('desktop');
    const { loading, template, campaign_id } = props.stylePageStore;

    return (
        <div className="style-page__preview" data-tour="preview">
            <ToggleDisplay value={display} onChange={setDisplay}  />
            <div className="style-page__title">Preview</div>
            <iframe
                src={loading ? '' : `/preview-template/${campaign_id}/${template ? template : 'none'}`}
                className={`style-page__result ${display === 'phone' ? 'style-page__result--mobile' : ''}`}
            />
        </div>
    );
});

export const ToggleDisplay:FC<{ value: string; onChange(v: string): void }> = ({ value, onChange }) => {
    return (
        <div className="toggle-display">
            <input type="radio" name="display" value="desktop" id="desktop" checked={value === 'desktop'} onChange={(e) => { onChange(e.target.value)}} />
            <label htmlFor="desktop" className="toggle-display__desktop">
                <Icon icon={Icons.icon_monitor_new} width={16} height={16} />
            </label>
            <input type="radio" name="display" value="phone" id="phone" checked={value === 'phone'} onChange={(e) => { onChange(e.target.value)}} />
            <label htmlFor="phone" className="toggle-display__phone" >
                <Icon icon={Icons.icon_smartphone} width={16} height={16} />
            </label>
        </div>
    );
}