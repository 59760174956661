import React, {useCallback, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';

import {Icon, Icons} from "../../../components/Icon";
import {Accordion} from "../../../components/accordeon";
import {Field, Select} from "../../../components/Field";
import {Tabs} from "../../../components/tabs";
import {Button, ButtonType} from '../../../components/Button';
import {ModalForm} from "../../../components/modals/modal-form";
import {stylePageStoreEnhancer, IStylePageStore, INotificationStore, ITourStore} from "../../../store";
import {ICampaignStore, IProduct, ITemplate} from "../models";
import {Droppables, IBlock, ISubBlock, PageTemplateTypes} from "./models";
import {BLOCK_ITEMS, PAGES} from './constants';
import {Columns} from "./columns";
import {Settings} from "./Settings";
import {Preview} from './Preview';
import {GeneralSettings, TextSettings} from './general';
import './styles.scss';
import {Library} from "../../../components/library";
import {TemplateItem} from "./template-item";
import {v4 as uuidv4} from "uuid";
import {StatusNotification} from "../../../components/Notifications";
import {repository} from "../../../shared/repositories";

export const StyleStep = stylePageStoreEnhancer((props: {id: number, products: IProduct[], companyId: number} & IStylePageStore & INotificationStore & ICampaignStore & ITourStore) => {
    const {
        setCurrentStep,
    } = props.tourStore;
    const {
        list,
        pageTemplateId,
        accordions,
        template,
        createNewAccordion,
        removeAccordion,
        cloneAccordion,
        dragDropAccordion,
        toggleAccordions,
        openBlock,
        closeBlock,
        changeTemplate,
        formSave,
        loading,
        savePageTemplate,
        deletePageTemplate,
        selectPageTemplate,
        updatePageTemplate,
        templateList,
        setTemplateList,
        disabledPaste,
        copyPage,
        pastePage,
        disabledCopy,
    } = props.stylePageStore;
    const {
        page_template
    } = props.campaignStore.form.$;
    const {
        saveCampaign,
    } = props.campaignStore;
    const [openSettings, setOpenSettings] = useState(false);
    const [visible, setVisible] = useState(false);
    const [openLibrary, setOpen] = useState(false);

    useEffect(() => {
        setTemplateList(props.companyId);
        repository.campaign.getScreenshot(props.id).then(response => {
            console.log(response);
        })

    }, [])

    const blockFilter = (block: IBlock) => {
        return template && (block.templates.includes(PageTemplateTypes.all) || block.templates.includes(template));
    }

    const listFilter = (subBlock: ISubBlock) => {
        return template && (subBlock.templates.includes(PageTemplateTypes.all) || subBlock.templates.includes(template));
    }

    const handleSaveTemplate = async () => {
        const result = await formSave.validate();

        if (result.hasError) {
            return;
        }

        savePageTemplate(formSave.$.name.value, props.companyId).then(page_template_id => {
            setVisible(false);
            if (page_template_id) {
                setTemplateList(props.companyId);
                page_template.onChange(page_template_id);
                setTimeout(() => {
                    saveCampaign()
                        .then(({ type, message }) => {
                            if (type === StatusNotification.success) {
                                props.notificationStore.success(message);
                            }
                        })
                        .catch(() => {})
                },1000);
                props.notificationStore.success('template is saved...', 'Success');
            } else {
                props.notificationStore.error('something went wrong');
            }
        });
    }

    const handleSelectItem = (data: ITemplate) => {
        props.notificationStore.confirm('Are you sure you want to apply this template? Your data will be overriden', () => {
            selectPageTemplate(data);
            setCurrentStep(3);
            setOpen(false);
            page_template.onChange(data.id);
            setTimeout(() => {
                saveCampaign()
                    .then(({ type, message }) => {
                        if (type === StatusNotification.success) {
                            props.notificationStore.success(message);
                        }
                    })
                    .catch(() => {})
            }, 1000);
        }, 'Apply template')
    }

    const handleUpdate = () => {
        props.notificationStore.confirm(
            'Are you sure? you can rewrite important template?',
            () => {
                updatePageTemplate().then(success => {
                    if (success) {
                        setTemplateList(props.companyId);
                        props.notificationStore.success('template is updated...', 'Success');
                    } else {
                        props.notificationStore.error('something went wrong');
                    }
                });            }
        );

    }

    const handleDeleteItem = (data: ITemplate) => {
        props.notificationStore.confirm(
            'Are you sure you want to delete this template?',
            () => {
                deletePageTemplate(data.id).then(success => {
                    if (success) {
                        setTemplateList(props.companyId);
                        props.notificationStore.success('template is deleted...', 'Success');
                        if (page_template.value && page_template.value === data.id) {
                            page_template.onChange(null);
                            setTimeout(saveCampaign, 0);
                        }
                    } else {
                        props.notificationStore.error('something went wrong');
                    }
                });
            }
        );

    }

    const handleCopyPage = () => {
        copyPage();
        props.notificationStore.success('page copied');
    }

    const handlePastePage = () => {
        props.notificationStore.confirm('page will be replaced, changes cannot be reverted, are you sure?', () => {
            pastePage();
        })

    }

    const ondragEnd = (result: DropResult) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId !== destination.droppableId && destination.droppableId === Droppables.page) {
            createNewAccordion(result.draggableId, destination.index)
        }

        if (source.droppableId === destination.droppableId && destination.droppableId === Droppables.page) {
            dragDropAccordion(source.index, destination.index);
        }
    }

    const renderItems = () => {
        if (list.items) {
            return (
                <>
                    <div className="style-page__title style-page__title--btn" onClick={closeBlock}>
                        <Icon icon={Icons.arrow_left_big} />
                        <span>{list.title}</span>
                    </div>
                    {list.items.filter(listFilter).map((block, index) => {
                        return (
                            <Draggable draggableId={block.id} index={index} key={block.id}>
                                {(provided, snapshot) => (
                                    <>
                                        <div
                                            className={`style-page__item ${snapshot.isDragging ? 'dragging' : ''}`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <Icon icon={block.icon} width={18} height={18} />
                                            {block.title}
                                        </div>
                                        {snapshot.isDragging && (<div className="style-page__item clone">
                                            <Icon icon={block.icon} width={18} height={18} />
                                            {block.title}
                                        </div>)}
                                    </>
                                )}
                            </Draggable>
                        );
                    })}
                </>
            );
        }

        return (
            <>
                <div className="style-page__title">Blocks</div>
                {template !== undefined ? BLOCK_ITEMS.filter(blockFilter).map((block) => {
                    return (
                        <div
                            key={block.id}
                            className="style-page__item style-page__item--btn style-page__item--with-arrow"
                            onClick={() => openBlock({ title: block.title, items: block.items || [] })}
                        >
                            <Icon icon={block.icon} width={18} height={18} />
                            {block.title}
                        </div>
                    );
                }) : (<div>first select template</div>)}
            </>
        );
    }

    const renderSettings = () => {
        return (
            <Accordion
                title="Settings"
                isOpen={openSettings}
                isGrey={true}
                onToggle={() => setOpenSettings(!openSettings)}
            >
                <Tabs tabs={[
                    { title: 'GENERAL', content: <GeneralSettings /> },
                    { title: 'TEXT STYLES', content: <TextSettings /> },
                ]} />
            </Accordion>
        );
    }

    const renderTemplatesList = useCallback(() => {
        return (
            templateList.map((item: ITemplate) => {
                const key = uuidv4();

                return (
                    <TemplateItem
                        {...item}
                        dataTour={item.options.name === 'data-tour template' || item.id === pageTemplateId ? 'template' : undefined}
                        key={key}
                        isCurrent={item.id === pageTemplateId}
                        onSelect={() => handleSelectItem(item)}
                        onDelete={() => handleDeleteItem(item)}
                    />
                );
            })
        )
    }, [templateList]);

    const renderButtonUpdate = () => {
        if (!pageTemplateId) {
            return null
        }

        return (
            <Button
                text="Update template"
                type={ButtonType.edit}
                isOrange
                full
                onClick={handleUpdate}
            />
        );
    }

    return(
        <div className="style-page">
            <ModalForm
                isVisible={visible}
                title="Save as template"
                formConfig={{
                    cancelText: 'Close',
                    submitText: 'Save',
                    onSubmit: handleSaveTemplate
                }}
                onClose={() => setVisible(false)}
            >
                <Field
                    needFocus={visible}
                    label='template name'
                    placeholder='Enter template name...'
                    name='name'
                    noAutocomplete
                    value={formSave.$.name._value}
                    errorMessage={formSave.$.name.error}
                    onChange={(value: any) => {formSave.$.name.onChange(value)}}
                />
            </ModalForm>
            <Library
                title="Campaign templates"
                open={openLibrary}
                onClose={() => {
                    setOpen(false);
                    setCurrentStep(3);
                }}
            >
                {renderTemplatesList()}
            </Library>
            <DragDropContext onDragEnd={ondragEnd} >
                <Droppable droppableId={Droppables.blocks} isDropDisabled={true}>
                    {(provider, snapshot) => (
                        <div className="style-page__blocks" ref={provider.innerRef}>
                            <div className="style-page__title">Your style</div>
                            <Button
                                text="Save as template"
                                type={ButtonType.save}
                                isSuccess
                                full
                                onClick={() => {
                                    formSave.reset();
                                    setVisible(true)
                                }}
                            />
                            <div data-tour="Select template">
                                <Button
                                    text="Select template"
                                    type={ButtonType.new}
                                    full={true}
                                    onClick={() => {
                                        setOpen(true);
                                        setCurrentStep(2); // вызов библиотеки и переход на сл. тур
                                    }}
                                />
                            </div>
                            {renderButtonUpdate()}
                            {renderItems()}
                        </div>
                    )}
                </Droppable>
                <div className="style-page__page" >
                    <div className="mb-24">
                        <Select<string>
                            dataTour={'select page template'}
                            dataTourOpen={'options page template'}
                            updateCurrentTour={(open) => {
                                setCurrentStep(open ? 4 : 5)
                            }}
                            list={PAGES}
                            placeholder="select page template"
                            onChange={changeTemplate}
                            value={template}
                        />
                        <div className="style-page__actions">
                            <div className={`action-button${disabledCopy ? ' disabled': ''}`} onClick={handleCopyPage}>Copy page</div>
                            <div className={`action-button${disabledPaste ? ' disabled': ''}`} onClick={handlePastePage}>Paste</div>
                        </div>
                    </div>

                    {template !== PageTemplateTypes.popup_confirm && renderSettings()}
                    <Droppable droppableId={Droppables.page}>
                        {(provided, snapshot) => (
                            <div className={`style-page__list ${snapshot.isDraggingOver ? 'style-page__list--dragging' : ''}`} ref={provided.innerRef}>
                                {accordions.length > 0 ? accordions.map((item, index) => (
                                    <Draggable draggableId={item.id} key={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <Accordion
                                                dataTour={index === 1 ? 'content-1' : undefined}
                                                updateDataTour={(open) => {

                                                }}
                                                title={item.title}
                                                icon={item.icon}
                                                provided={provided}
                                                isOpen={item.isOpen}
                                                onToggle={() => {
                                                    toggleAccordions(item.id);
                                                    if (index === 1) {
                                                        setCurrentStep(6);
                                                    }
                                                }}
                                                onCopy={() => cloneAccordion(index)}
                                                onRemove={() => removeAccordion(index)}
                                                isDragging={snapshot.isDragging}
                                            >
                                                <Tabs
                                                    dataTour={index === 1 ? 'content-2' : undefined}
                                                    tabs={[
                                                        { title: 'columns', content: <Columns items={item.content} parent_id={item.id} /> },
                                                        { title: 'row settings', content: (<Settings {...item.rowParams} name={item.title} parent_id={item.id} />) },
                                                    ]}
                                                    reload={loading}
                                                />
                                            </Accordion>
                                        )}
                                    </Draggable>
                                )) : !provided.placeholder && (
                                    <div>
                                        Drop items here
                                    </div>
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>

            </DragDropContext>
            <Preview />
        </div>
    );
});

