import {action, makeObservable, observable} from "mobx";
import {IDocumentStore, INotificationsProps, StatusNotification} from "../components/Notifications";
import {generateRandom} from "../utils/utils";

export class NotificationStore {
    @observable list: INotificationsProps[];
    @observable visible: boolean;
    @observable messageConfirm: string;
    @observable buttonConfirm: string;
    @observable callback: () => void;
    @observable document: IDocumentStore;

    constructor() {
        this.list = [];
        this.visible = false;
        this.messageConfirm = '';
        this.buttonConfirm = 'Confirm';
        this.callback = () => {};
        this.document = {} as IDocumentStore;

        makeObservable(this);
    }

    @action
    addNotification = (params: INotificationsProps) => {
        this.list.push({
            ...params,
        });

        setTimeout(() => {
            this.removeNotification(params.id);
        }, 2500);
    }

    @action
    success = (description: string, title?: string) => {
        this.addNotification({
            id: generateRandom().toString(),
            status: StatusNotification.success,
            title: title || 'Success',
            description,
        })
    }

    @action
    info = (description: string, title?: string) => {
        this.addNotification({
            id: generateRandom().toString(),
            status: StatusNotification.info,
            title: title || 'Info',
            description,
        })
    }

    @action
    error = (description: string, title?: string) => {
        this.addNotification({
            id: generateRandom().toString(),
            status: StatusNotification.error,
            title: title || 'Error',
            description,
        })
    }

    @action
    removeNotification = (id: string) => {
        const index = this.list.findIndex(item => item.id === id);

        if (this.list[index]) {
            this.list[index].status = StatusNotification.closed;
            setTimeout(() => {
                this.list.splice(index, 1);
            }, 500);
        }
    }

    @action
    confirm = (message: string, callback: () => void, buttonConfirm?: string) => {
        this.messageConfirm = message;
        if (buttonConfirm) {
            this.buttonConfirm = buttonConfirm;
        }
        this.visible = true;
        this.callback = callback;
    }

    @action
    closeModal = () => {
        this.visible = false;
    }

    @action
    onConfirm = () => {
        this.callback();
        this.visible = false;
    }

    @action
    viewDocument = (id: string, category: string) => {
        this.document = {
            open: true,
            id: id,
            title: category,
        }
    }

    @action
    closeDocument = () => {
        this.document = {
            open: false,
            id: undefined,
            title: '',
        }
    }
}
