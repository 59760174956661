import React, {useEffect, useState} from "react";
import {OnSelectCallbackParam} from "react-daterange-picker";

import {Field, FileUpload, Select} from "../../components/Field";
import {DateRangePickerComponent, IDateFieldProps} from "../../components/date-range-picker";
import {Button, ButtonType} from "../../components/Button";
import {ICompanyStore, INotificationStore, ITourStore, oneStepStoreEnhancer} from "../../store";

import {ICampaignStore} from "./models";
import {repository} from "../../shared/repositories";
import {TList} from "./style-step/models";
import {DOCUMENT_CATEGORY} from "../Documents/constants";
import {copyToClipboard, getEnumKeyByEnumValue} from "../../utils/utils";
import {ACCURACY_LEVEL, APPROVAL_TYPES} from "./constants";
import {MultiCreator} from "../../components/Field/SelectMultiCreater";
import './campaigns.scss';
import {isEmpty, isNil} from "lodash";
import {StatusNotification} from "../../components/Notifications";

export const CampaignOneStep = oneStepStoreEnhancer(({ campaignStore, notificationStore, companyStore, tourStore }: ICampaignStore & INotificationStore & ICompanyStore & ITourStore) => {
    const [reset, setReset] = useState(false);
    const startDateProps: IDateFieldProps = {
        label: 'Start date',
        placeholder: 'select date',
        value: campaignStore.form?.$.start_date?._value
    }
    const endDateProps: IDateFieldProps = {
        label: 'End date',
        placeholder: 'select date',
        value: campaignStore.form?.$.end_date?._value
    }
    const handleChangeDates = (dates: OnSelectCallbackParam) => {
        campaignStore?.form?.$.start_date.onChange(dates.start);
        campaignStore?.form?.$.end_date.onChange(dates.end);
    }
    const [list, setList] = useState<TList<string>>([])

    useEffect(() => {
        repository.ticket.getTicketTemplates().then((response) => {
            setList(response.data.map(item => ({ title: item.name, value: `${item.id}` })));
            campaignStore.form?.$.ticket_template.onChange(`${response.data[0].id}`);
        })
    },[]);

    useEffect(() => {
         setReset(true);
         setTimeout(() => { setReset(false) }, 0)
    }, [campaignStore.campaign_id]);

    const saveCampaign = async (): Promise<boolean> => {
        const result = await campaignStore.form.validate();

        if (result.hasError) {
            notificationStore.error('Form is not valid...')
            return Promise.resolve(false);

        }

        return campaignStore.saveCampaign().then(({ type, message }) => {
            if (type === StatusNotification.success) {
                notificationStore.success(message);
                return Promise.resolve(true);
            }

            notificationStore.error(message);

            return Promise.resolve(false);
        });
    }

    const getURlToDocument =  (category: DOCUMENT_CATEGORY): string => {
        const document = companyStore.company?.documents.find(document => document.category === category);
        const key = getEnumKeyByEnumValue(DOCUMENT_CATEGORY, category);

        return document && document.id ? `${document.id}` : key;
    }

    const openDocument = () => {
        companyStore.fetchCompany(campaignStore.company_id).then(() => {
            notificationStore.viewDocument(getURlToDocument(DOCUMENT_CATEGORY.TermsAndConditions), DOCUMENT_CATEGORY.TermsAndConditions)
        });
    }

    const copy = (trackCodes: string[]) => {
        const url = window.location.pathname || '';
        const domainName = (window.location.href || '').replace(url, '');
        const urls = (trackCodes || []).map(track => {
            return `${domainName}/${campaignStore.campaign_id}?track_code=${track}`;
        })

        if (isEmpty(urls)) {
            notificationStore.error('track codes is empty')
            return;
        }

        if (copyToClipboard(urls.join(', '))) {
            notificationStore.success('all URLs are copied to the clipboard')
        } else {
            notificationStore.error('Something went wrong')
        }
    }

    const copyUrls = () => {
        const trackCodes = campaignStore.form.$.track_codes._value;

        if (isNil(campaignStore.campaign_id)) {
            saveCampaign().then((isGreat) => {
                if (isGreat) {
                    copy(trackCodes);
                }
            })
        } else {
            copy(trackCodes);
        }
    }

    const keyPressEnter = (target: any) => {
        if (target.code === 'Tab' && tourStore.isOpen) {
            tourStore.setCurrentStep(tourStore.currentStep + 1);
        }
    }

    return (
        <div className="page__wrap page__wrap--campaign">
            <div className="page__main">
                <h1 className="section__title section__title_center">Your objective</h1>
                <p className="section__title_center">Please fill in your objective details and proceed to the next step. </p>
                <div className="campaign__form">
                    <div className="sign__form">
                        <FileUpload
                            label="Logo"
                            onChange={(file) => campaignStore.form?.$.brand_logo.onChange(file)}
                            centered
                            image={campaignStore.image_logo}
                            reset={reset}
                            getImage={(image) => { campaignStore.setImage(image)}}
                            errorMessage={campaignStore.form?.$.brand_logo.error}
                        />
                    </div>
                    <Field
                        dataTour={'Campaign name'}
                        label="Campaign name"
                        name="title"
                        value={campaignStore.form?.$.title._value}
                        onKeyPress={keyPressEnter}
                        onChange={(val) => campaignStore.form?.$.title.onChange(val)}
                        placeholder="Enter campaign name"
                        errorMessage={campaignStore.form?.$.title.error}
                    />
                    <Field
                        dataTour={'Brand'}
                        label="Brand"
                        name="brand"
                        onKeyPress={keyPressEnter}
                        value={campaignStore.form?.$.brand._value}
                        onChange={(val) => campaignStore.form?.$.brand.onChange(val)}
                        placeholder="Enter brand"
                        errorMessage={campaignStore.form?.$.brand.error}
                    />
                    <Field
                        dataTour={'Budget'}
                        label="Budget"
                        name="budget"
                        type="number"
                        onKeyPress={keyPressEnter}
                        min={0}
                        noAutocomplete
                        value={campaignStore.form?.$.budget._value}
                        onChange={(val) => { campaignStore.form?.$.budget.onChange(val)
                        }}
                        placeholder="Enter budget"
                        errorMessage={campaignStore.form?.$.budget.error}
                    />
                    <Select
                        label="Ticket template"
                        disabled
                        hidden
                        value={campaignStore.form?.$.ticket_template._value}
                        list={list}
                        onChange={(value) => campaignStore.form?.$.ticket_template.onChange(value)}
                        errorMessage={campaignStore.form?.$.ticket_template.error}
                    />
                    <Select
                        label="Ticket template"
                        disabled
                        hidden
                        value={campaignStore.form?.$.ticket_template._value}
                        list={list}
                        onChange={(value) => campaignStore.form?.$.ticket_template.onChange(value)}
                        errorMessage={campaignStore.form?.$.ticket_template.error}
                    />
                    <Select
                        dataTour={'Accuracy'}
                        dataTourOpen={'Accuracy-open'}
                        updateCurrentTour={(open) => {
                            if (tourStore.isOpen) {
                                tourStore.setCurrentStep(open ? 7 : 8)
                            }
                        }}
                        label="Accuracy"
                        description="(Automatically proccess tikets with accuracy higher than %)"
                        name="accuracy"
                        value={campaignStore.form?.$.accuracy._value}
                        onChange={(value) => campaignStore.form?.$.accuracy.onChange(value)}
                        placeholder="Enter accuracy"
                        errorMessage={campaignStore.form?.$.accuracy.error}
                        list={ACCURACY_LEVEL}
                    />
                    <div className="container-link">
                        <div className="buffer-link" onClick={copyUrls}>copy all urls</div>
                    </div>
                    <MultiCreator
                        dataTour={'Trackcode'}
                        label="Trackcode"
                        onKeyPress={keyPressEnter}
                        value={campaignStore.form.$.track_codes._value}
                        onChange={(value) => { campaignStore.form.$.track_codes.onChange(value) }}
                    />
                    <Select
                        dataTour={'Cashbacks approval'}
                        dataTourOpen={'Cashbacks approval open'}
                        label="Cashbacks approval"
                        updateCurrentTour={(open) => {
                            tourStore.setCurrentStep(open ? 10 : 11)
                        }}
                        list={APPROVAL_TYPES}
                        value={campaignStore.form?.$.approve._value}
                        onChange={(value) => campaignStore.form?.$.approve.onChange(value)}
                        errorMessage={campaignStore.form?.$.approve.error}
                    />
                    <DateRangePickerComponent
                        dataTour={'period'}
                        start={startDateProps}
                        end={endDateProps}
                        onChange={handleChangeDates}
                    />
                    <Field
                        label="Brand name to URL"
                        name="slug"
                        noAutocomplete
                        value={campaignStore.form?.$.slug._value}
                        onChange={(val) => { campaignStore.form?.$.slug.onChange(val) }}
                        placeholder="Add brand name to URL"
                        errorMessage={campaignStore.form?.$.slug.error}
                    />
                    <Field
                        label="Email sender name"
                        name="sender_name"
                        noAutocomplete
                        value={campaignStore.form?.$.sender_name._value}
                        onChange={(val) => { campaignStore.form?.$.sender_name.onChange(val) }}
                        placeholder="Enter email sender name"
                        errorMessage={campaignStore.form?.$.sender_name.error}
                    />
                    <Field
                        label="Email subject"
                        name="custom_subject"
                        type={'textarea'}
                        noAutocomplete
                        value={campaignStore.form?.$.custom_subject._value}
                        onChange={(val) => { campaignStore.form?.$.custom_subject.onChange(val) }}
                        placeholder="Enter email subject"
                        errorMessage={campaignStore.form?.$.custom_subject.error}
                    />
                    <div className="mt-2 mb-14" data-tour={'term'}>
                        <Button text="Edit terms and conditions" onClick={openDocument} full type={ButtonType.edit} />
                    </div>

                    <div className="campaign__form-description">
                        Disclaimer text about the terms and conditions. <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sollicitudin sed eros vitae convallis.
                    </div>
                </div>
            </div>
        </div>
    );
});