import React, {Component} from "react";
import {inject, observer} from "mobx-react";
import {AccountStore, IAccountStore, INotificationStore, NotificationStore} from "../../store";

import Header from "../../components/Header/Header";
import {Field, FileUpload} from "../../components/Field";
import {Button} from "../../components/Button";

import './Account.scss';
import {getErrorMessage} from "../../utils/utils";

@inject('accountStore', 'notificationStore')
@observer
export default class Account extends Component<IAccountStore & INotificationStore> {
    accountStore: AccountStore;
    notificationStore: NotificationStore;

    constructor(props: Readonly<IAccountStore & INotificationStore>) {
        super(props);
        this.accountStore = props.accountStore;
        this.notificationStore = props.notificationStore;
    }

    componentDidMount() {
        this.accountStore.initForm();
    }

    handleLoadFile = (file: File)=> {
        this.accountStore.form.$.file.onChange(file);
    }

    save = () => {
        this.accountStore.save().then((response) => {
            if ([200, 201].includes(response.status)) {
                this.notificationStore.success('Account settings is saved!')
            } else {
                this.notificationStore.error(getErrorMessage(response))
            }
        });
    }

    render() {
        return (
            <div>
                <Header/>
                <main className="page__main account">
                    <div className="page__container">
                        <div className="page__header page__header_center">
                            <h1 className="account__title">Account settings</h1>
                        </div>
                        <div className="account__form">
                            <div  className="account__logo">
                                <FileUpload
                                    centered
                                    image={this.accountStore.currentAvatar}
                                    onChange={this.handleLoadFile}
                                />
                            </div>
                                <div className="field-container">
                                    <div className="field-container__row">
                                        <div className="col-lg-6">
                                            <Field
                                                label="First Name"
                                                value={this.accountStore.form.$.firstName._value}
                                                placeholder="Enter your first name"
                                                onChange={(e) => this.accountStore.form.$.firstName.onChange(e)}
                                                name="firstName"
                                                errorMessage={this.accountStore.form.$.firstName.error}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <Field
                                                label="Last Name"
                                                value={this.accountStore.form.$.lastName._value}
                                                onChange={(e) => this.accountStore.form.$.lastName.onChange(e)}
                                                name="lastName"
                                                placeholder="Enter your last name"
                                                errorMessage={this.accountStore.form.$.lastName.error}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Field
                                    label="Email"
                                    value={this.accountStore.form.$.email._value}
                                    placeholder="Enter your email"
                                    onChange={(e) => this.accountStore.form.$.email.onChange(e)}
                                    name="email"
                                    errorMessage={this.accountStore.form.$.email.error}
                                />
                                <Field
                                    label="Phone number"
                                    value={this.accountStore.form.$.phone._value}
                                    type="phone"
                                    placeholder="Enter your phone number"
                                    onChange={(e) => this.accountStore.form.$.phone.onChange(e)}
                                    errorMessage={this.accountStore.form.$.phone.error}
                                />
                                <div className="mt-2">
                                    <Button
                                        text="SAVE"
                                        full
                                        disabled={!!this.accountStore.form.error}
                                        onClick={this.save}
                                    />
                                </div>
                                <div className="account__form-divider" />
                                <div className="field-container">
                                    <div className="field-container__row">
                                        <div className="col-lg-6">
                                            <Field
                                                label="Password"
                                                value={this.accountStore.passwordForm.$.password._value}
                                                type="password"
                                                placeholder="Enter password"
                                                onChange={(e) => this.accountStore.passwordForm.$.password.onChange(e)}
                                                name="new-password"
                                                noAutocomplete
                                                errorMessage={this.accountStore.passwordForm.$.password.error}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <Field
                                                label="Confirmed password"
                                                type="password"
                                                value={this.accountStore.passwordForm.$.confirmedPassword._value}
                                                onChange={(e) => this.accountStore.passwordForm.$.confirmedPassword.onChange(e)}
                                                name="confirmPassword"
                                                placeholder="repeat password"
                                                noAutocomplete
                                                errorMessage={this.accountStore.passwordForm.$.confirmedPassword.error}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <Button
                                        text="Change password"
                                        full
                                        disabled={!!this.accountStore.passwordForm.error}
                                        onClick={() => {
                                            this.accountStore.changePassword()
                                        }}
                                    />
                                </div>
                            </div>
                    </div>
                </main>
            </div>);
    }
}
