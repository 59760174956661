import {TList} from "../../pages/Campaigns/style-step/models";

export interface ISelectFilterProps {
    status: IStatus;
    onChange(status: IStatus): void;
}

export interface IStatus {
    title: string;
    value: number;
}

export const StatusesCampaign: IStatus[] = [
    {
        title: 'All',
        value: 0,
    },
    {
        title: 'Active',
        value: 1,
    },
    {
        title: 'Completed',
        value: 2,
    },
    {
        title: 'Draft',
        value: 3,
    },
    {
        title: 'Archive',
        value: 4,
    },
];

export interface ISelectFilterMiniProps {
    label: string;
    value: unknown;
    list: TList<unknown>;
    labelOnTop?: boolean;
    onChange(value: unknown): void;
}