import React, {useMemo} from "react";
import {IRowParams, ISettingsProps} from "./models";
import {Field, FileUpload, Select} from "../../../components/Field";
import {required} from "../../../utils/validators/required";
import {FormState, FieldState} from "formstate-x/lib";
import { ColorPicker } from "../../../components/Field/Color";
import { Button } from "../../../components/Button";
import {MultiSelect} from "../../../components/Field/SelectMulti";
import {AtImage, BgPosition, Position, Repeat, ROW_OPTIONS, SizeImage} from "./constants";
import {stylePageStoreEnhancer} from "../../../store";
import {IStylePageStore} from "../../../store/models";
import {ImageLoad} from "../../../components/Field/Image-load";

export const Settings = stylePageStoreEnhancer((props: ISettingsProps & IStylePageStore) => {
    const row = useMemo(() => new FormState({
        name: new FieldState(props.name).validators(required),
        backgroundColor: new FieldState(props.backgroundColor),
        color: new FieldState(props.color),
        backgroundImage: new FieldState(props.backgroundImage),
        backgroundRepeat: new FieldState(props.backgroundRepeat),
        backgroundPosition: new FieldState(props.backgroundPosition),
        backgroundAttachment: new FieldState(props.backgroundAttachment),
        backgroundSize: new FieldState(props.backgroundSize),
        container: new FieldState(props.container),
        options: new FieldState(props.options),
        minHeight: new FieldState(props.minHeight),
        width: new FieldState(props.width),
        maxWidth: new FieldState(props.maxWidth),
        marginTop: new FieldState(props.marginTop),
        marginRight: new FieldState(props.marginRight),
        marginLeft: new FieldState(props.marginLeft),
        marginBottom: new FieldState(props.marginBottom),
        paddingTop: new FieldState(props.paddingTop),
        paddingRight: new FieldState(props.paddingRight),
        paddingLeft: new FieldState(props.paddingLeft),
        paddingBottom: new FieldState(props.paddingBottom),

    }), []);
    const { stylePageStore } = props;

    const handleSaveRow = () => {
        const rowParams: IRowParams = {
            backgroundColor: row.$.backgroundColor._value,
            color: row.$.color._value,
            backgroundImage: row.$.backgroundImage._value,
            backgroundSize: row.$.backgroundSize._value,
            backgroundRepeat: row.$.backgroundRepeat._value,
            backgroundPosition: row.$.backgroundPosition._value,
            backgroundAttachment: row.$.backgroundAttachment._value,
            container: row.$.container._value,
            options: row.$.options._value,
            minHeight: row.$.minHeight._value,
            width: row.$.width._value,
            maxWidth: row.$.maxWidth._value,
            marginTop: row.$.marginTop._value,
            marginRight: row.$.marginRight._value,
            marginLeft: row.$.marginLeft._value,
            marginBottom: row.$.marginBottom._value,
            paddingTop: row.$.paddingTop._value,
            paddingRight: row.$.paddingRight._value,
            paddingLeft: row.$.paddingLeft._value,
            paddingBottom: row.$.paddingBottom._value,
        }
        stylePageStore.changeAccordionRowParam(props.parent_id, rowParams, row.$.name._value);
    }

    return (
        <div className="tab-settings">
            <Field label="Name" onChange={(e) => row.$.name.onChange(e)} value={row.$.name._value} />
            <ImageLoad
                label="Background image"
                image={row.$.backgroundImage._value}
                getImage={(image) => row.$.backgroundImage.onChange(image)}
            />
            <div className="grid">
                <div className="grid__col">
                    <ColorPicker
                        label="Background color"
                        onChange={(color) => row.$.backgroundColor.onChange(color?.hex ? color?.hex : '')}
                        value={row.$.backgroundColor._value}
                    />
                    <Select<string>
                        label="Background size"
                        placeholder="Select value"
                        value={row.$.backgroundSize._value}
                        onChange={(value) => row.$.backgroundSize.onChange(value)}
                        list={SizeImage}
                    />
                    <Select<string>
                        label="Background attachment"
                        list={AtImage}
                        value={row.$.backgroundAttachment._value}
                        onChange={(value) => row.$.backgroundAttachment.onChange(value)}
                        placeholder="Select value"
                    />
                </div>
                <div className="grid__col">
                    <ColorPicker
                        label="Color"
                        onChange={(color) => row.$.color.onChange(color?.hex ? color?.hex : '')}
                        value={row.$.color._value}
                    />
                    <Select<string>
                        label="Background repeat"
                        list={Repeat}
                        value={row.$.backgroundRepeat._value}
                        onChange={(value) => row.$.backgroundRepeat.onChange(value)}
                        placeholder="Select value"
                    />
                    <Select<string>
                        label="Background position"
                        list={BgPosition}
                        value={row.$.backgroundPosition._value}
                        onChange={(size) => row.$.backgroundPosition.onChange(size)}
                        placeholder="Select value"
                    />

                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Field label="Padding" type="number" onChange={(e) => row.$.paddingTop.onChange(e)} value={row.$.paddingTop._value} placeholder="Top" prefix="px" />
                    <Field type="number" onChange={(e) => row.$.paddingBottom.onChange(e)} value={row.$.paddingBottom._value} placeholder="Bottom" prefix="px" />

                </div>
                <div className="grid__col">
                    <Field label="&nbsp;" type="number" onChange={(e) => row.$.paddingRight.onChange(e)} value={row.$.paddingRight._value} placeholder="Right" prefix="px" />
                    <Field type="number" onChange={(e) => row.$.paddingLeft.onChange(e)} value={row.$.paddingLeft._value} placeholder="Left" prefix="px" />
                </div>

                <div className="grid__col">
                    <Field label="Margin" type="number" onChange={(e) => row.$.marginTop.onChange(e)} value={row.$.marginTop._value} placeholder="Top" prefix="px" />
                    <Field  type="number" onChange={(e) => row.$.marginBottom.onChange(e)} value={row.$.marginBottom._value} placeholder="Bottom" prefix="px" />

                </div>
                <div className="grid__col">
                    <Field  type="number" label="&nbsp;" onChange={(e) => row.$.marginRight.onChange(e)} value={row.$.marginRight._value} placeholder="Right" prefix="px" />
                    <Field  type="number" onChange={(e) => row.$.marginLeft.onChange(e)} value={row.$.marginLeft._value} placeholder="Left" prefix="px" />
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <MultiSelect<string>
                        label="Row options"
                        placeholder="Optional styling"
                        value={row.$.options._value}
                        onChange={(value) => row.$.options.onChange(value)}
                        list={ROW_OPTIONS}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Field
                        label="Width"
                        onChange={(e) => row.$.width.onChange(e)}
                        value={row.$.width._value}
                        placeholder="default 100%"
                        prefix="%"
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="Maximum width"
                        onChange={(e) => row.$.maxWidth.onChange(e)}
                        value={row.$.maxWidth._value}
                        placeholder="max width"
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="Minimum height"
                        onChange={(e) => row.$.minHeight.onChange(e)}
                        value={row.$.minHeight._value}
                        placeholder="min height"
                        prefix="px"
                    />
                </div>
            </div>
            <div className="footer">
                <Button text="Save & Apply changes" onClick={handleSaveRow} />
            </div>
        </div>

    );
});