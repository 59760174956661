import {FieldState} from "formstate-x/lib";
import {IChartRepository} from "components/charts/models";

export interface Avatar {
    extension: string;
    original_url: string;
    file_name: string;
    uuid: string;
}

export interface Meta {
    /** Нужно для пагинации - номер текущей страницы */
    current_page: number;
    /** всего документов */
    total: number;
    /** индекс первого документа на странице */
    from: number;
    /** индекс последнего документа на странице */
    to: number;
    /**  номер последней страницы */
    last_page: number;
    /** количество документов на странице */
    per_page: number;
}

export interface Response<T> {
    data: T;
    meta: Meta;
}

export enum LoadingState {
    loading,
    success,
    failed
}

export enum UserStatus {
    _,
    active,
    completed
}

export enum PermissionName {
    super_admin = 'super_admin',
}

export interface IPermission {
    id: number;
    name: PermissionName;
    guard_name: string;
    created_at: string;
    updated_at: string;
}

export interface User {
    first_name: string;
    last_name: string;
    avatar: string;
    email: string;
    status: UserStatus;
    company_id: number;
    roles: string[];
    phone: string;
    id: number;
    permissions: IPermission[];
}

export interface CompanyDocument {
    title: string;
    category: string;
    file?: File;
    order?: string;
    id?: number;
}

export interface ICompanyDetail {
    active_campaigns_count: number | null;
    total_campaigns_count: number | null;
    total_reach_count: number | null;
    statistic_count: number | null;
}

export interface Company {
    id: number;
    title: string,
    vat: string,
    street: string,
    city: string,
    postal_code: string,
    documents: CompanyDocument[];
}

export type CompanySettingsForm = {
    title: FieldState<string>,
    vat: FieldState<string>,
    street: FieldState<string>,
    city: FieldState<string>,
    postal_code: FieldState<string>,
}

export interface DocumentIndexes {
    [key: string]: number
}

export interface ICampaignsApi extends IChartRepository {
    createNewCampaign(company_id: number, data: FormData): Promise<unknown>
}

export enum statuses {
    accepted = 'accepted',
    paid     = 'paid',
    rejected = 'rejected',
    review   = 'review',
    reviewed = 'reviewed',
}

export interface ILineChartDTO {
    accepted: ILine[];
    paid: ILine[];
    rejected: ILine[];
    review: ILine[];
    reviewed: ILine[];
}

export interface ILine {
    day: string;
    count: number;
}