import './CompanySettings.scss';
import * as H from 'history';
import {inject, observer} from "mobx-react";
import React, {Component} from "react";
import {AuthStore, CompanyStore, NotificationStore} from "../../store";
import {CompanySettingsOption, ICompanySettingsOptionProps} from "./CompanySettingsOption";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading";
import {LoadingState} from "../../shared/models";
import {Button} from '../../components/Button';
import {Field} from "../../components/Field";
import {DOCUMENT_CATEGORY, DOCUMENT_LINKS} from "../Documents/constants";
import {getEnumKeyByEnumValue} from "../../utils/utils";

export interface ICompanySettingsProps {
    companyStore: CompanyStore;
    authStore: AuthStore;
    notificationStore: NotificationStore;
    history: H.History;
}

@inject('companyStore', 'authStore', 'notificationStore')
@observer
class CompanySettings extends Component<ICompanySettingsProps> {
    private options: ICompanySettingsOptionProps[];
    private companyStore: CompanyStore;
    private notificationStore: NotificationStore;
    private company_id: number;

    constructor(props: ICompanySettingsProps) {
        super(props);
        this.companyStore = props.companyStore;
        this.notificationStore = props.notificationStore;

        this.company_id = props.authStore.user.company_id;
        this.options = [
            {
                title: 'Current plan',
                urlToDocument: null,
                value: 'Premium',
                actionName: 'Change',
                action: () => {
                    this.props.history.push('/company-settings/pricing');
                }
            },
            {
                title: 'Payment method',
                urlToDocument: null,
                value: 'Visa',
                actionName: 'Change',
                action: () => {}
            },
            {
                title: DOCUMENT_CATEGORY.TermsAndConditions,
                urlToDocument: `/company/${this.company_id}/${DOCUMENT_LINKS.TermsAndConditions}`,
                value: 'Update TaC',
                actionName: 'Change',
                action: async () => {
                    const result = await this.companyStore.save();
                    if (result) {
                        this.notificationStore.viewDocument(this.getURlToDocument(DOCUMENT_CATEGORY.TermsAndConditions), DOCUMENT_CATEGORY.TermsAndConditions);
                    }
                }
            },
            {
                title: DOCUMENT_CATEGORY.TermsOfServices,
                urlToDocument: `/company/${this.company_id}/${DOCUMENT_LINKS.TermsOfServices}`,
                value: 'Update ToS',
                actionName: 'Change',
                action: async () => {
                    const result = await this.companyStore.save();
                    if (result) {
                        this.notificationStore.viewDocument(this.getURlToDocument(DOCUMENT_CATEGORY.TermsOfServices), DOCUMENT_CATEGORY.TermsOfServices);
                    }
                }
            },
            {
                title: DOCUMENT_CATEGORY.PrivacyPolicy,
                urlToDocument: `/company/${this.company_id}/${DOCUMENT_LINKS.PrivacyPolicy}`,
                value: 'Update Privacy Policy',
                actionName: 'Change',
                action: async () => {
                    const result = await this.companyStore.save();
                    if (result) {
                        this.notificationStore.viewDocument(this.getURlToDocument(DOCUMENT_CATEGORY.PrivacyPolicy), DOCUMENT_CATEGORY.PrivacyPolicy);
                    }
                }
            },
        ]
    }

    componentDidMount() {
        this.companyStore.fetchCompany(this.company_id);
    }

    getURlToDocument = (category: DOCUMENT_CATEGORY): string => {
        const document = this.companyStore.company?.documents.find(document => document.category === category);
        const key = getEnumKeyByEnumValue(DOCUMENT_CATEGORY, category);

        return document && document.id ? `${document.id}` : key;
    }

    render() {
        return (
            <>
                <Header />
                <Loading active={this.companyStore.loading === LoadingState.loading}/>
                <main className="page__main">
                    <div className="page__container page__container_lg">
                        <div className="company-settings__container">
                            <div className="company-settings__header">
                                <div className="company-settings__header-title">Company settings</div>
                                <div className="company-settings__header-subtitle">Please enter your company details.
                                </div>
                            </div>
                            <div className="company-settings__form">
                                <form>
                                    <Field
                                        label="Company"
                                        placeholder="Enter company name"
                                        value={this.companyStore.form?.$.title._value}
                                        onChange={(e) => this.companyStore.form?.$.title.onChange(e)}
                                        errorMessage={this.companyStore.form?.$.title.error}
                                    />
                                    <Field
                                        label="VAT"
                                        placeholder="Enter company vat"
                                        value={this.companyStore.form?.$.street._value}
                                        onChange={(e) => this.companyStore.form?.$.street.onChange(e)}
                                        errorMessage={this.companyStore.form?.$.street.error}
                                    />
                                    <Field
                                        label="Street"
                                        placeholder="Enter company street"
                                        value={this.companyStore.form?.$.title._value}
                                        onChange={(e) => this.companyStore.form?.$.title.onChange(e)}
                                        errorMessage={this.companyStore.form?.$.title.error}
                                    />
                                    <div className="field-container">
                                        <div className="field-container__row">
                                            <div className="col-lg-7">
                                                <Field
                                                    label="City"
                                                    placeholder="Enter city name"
                                                    value={this.companyStore.form?.$.city._value}
                                                    onChange={(e) => this.companyStore.form?.$.city.onChange(e)}
                                                    errorMessage={this.companyStore.form?.$.city.error}
                                                />
                                            </div>
                                            <div className="col-lg-5">
                                                <Field
                                                    label="Postal code"
                                                    placeholder="Enter postal code"
                                                    value={this.companyStore.form?.$.postal_code._value}
                                                    onChange={(e) => this.companyStore.form?.$.postal_code.onChange(e)}
                                                    errorMessage={this.companyStore.form?.$.postal_code.error}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="company-settings__form-divider"/>
                                <div className="company-settings__options">
                                    {this.options.map(option => (
                                        <div  className="company-settings__options-item" key={option.title}>
                                            <CompanySettingsOption {...option} />
                                        </div>
                                    ))}
                                </div>
                                <div className="company-settings__action">
                                    <Button
                                        full
                                        text="SAVE"
                                        disabled={this.companyStore.loading === LoadingState.loading || !!this.companyStore.form?.error}
                                        onClick={() => {this.companyStore.save()}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }

}

export default CompanySettings;
