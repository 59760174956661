import {Component} from 'react';
import {RouteComponentProps} from "react-router-dom";
import {AxiosResponse} from "axios";
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";

import Loading from "../../components/Loading";
import {Field} from "../../components/Field";
import { Button } from '../../components/Button';
import {LayoutPage} from "./Layout";
import {FieldState, FormState} from "formstate-x/lib";
import {IRegisterForm} from "./models";
import {email, required} from "../../utils/validators/required";
import {repository} from "../../shared/repositories";
import {getErrorMessage, getQueryParams} from "../../utils/utils";
import {INotificationStore} from "../../store";
import './styles.scss'

type Props = RouteComponentProps<{}> & INotificationStore;

@inject('notificationStore')
@observer
export default class Register extends Component<Props, { loading: boolean }> {
    @observable form: FormState<IRegisterForm>

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false
        }

        const { search } = this.props.location;
        const { token } = getQueryParams(search);

        this.form = new FormState({
            email: new FieldState("").validators(email),
            token: new FieldState(token),
            password: new FieldState("").validators(required),
            confirmPassword: new FieldState("").validators(required),
        });

        makeObservable(this);
    }

    submit = async (e: any) => {
        e.preventDefault();

        const res = await this.form.validate();

        if (res.hasError) {
            return;
        }

        this.setState({ loading: true })

        const formData = new FormData();
        formData.append('email', this.form.$.email.value);
        formData.append('password', this.form.$.password.value);
        formData.append('password_confirmation', this.form.$.confirmPassword.value);
        formData.append('invite_token', this.form.$.token.value);

        const result: AxiosResponse<any> = await repository.auth.register(formData);

        this.setState({ loading: false })

        if ([200, 201].includes(result.status)) {
            this.props.history.push('/login');
            this.props.notificationStore.success('You are registered!')
        } else {
            this.props.notificationStore.error(getErrorMessage(result))
        }

    }

    getErrorConfirm = () => {
        if (this.form.$.confirmPassword.dirty && this.form.$.password._value !== this.form.$.confirmPassword._value) {
            return "password is not valid"
        }

        return null;
    }

    render() {
        return (
            <>
                <Loading active={this.state.loading} />
                <LayoutPage signIn={true} title="Sign up" >
                    <form onSubmit={this.submit} autoComplete="off">
                        <Field
                            label="E-mail address"
                            noAutocomplete
                            placeholder="Enter your email"
                            value={this.form.$.email._value}
                            onChange={(e) => {this.form.$.email.onChange(e)}}
                            errorMessage={this.form.$.email.error}
                        />
                        <Field
                            label="Password"
                            type="password"
                            noAutocomplete
                            name="new-password"
                            placeholder="Enter your password"
                            value={this.form.$.password._value}
                            onChange={(e) => this.form.$.password.onChange(e)}
                            errorMessage={this.form.$.password.error}
                        />
                        <Field
                            label="Confirm password"
                            type="password"
                            noAutocomplete
                            placeholder="Enter your password"
                            value={this.form.$.confirmPassword._value}
                            onChange={(e) => this.form.$.confirmPassword.onChange(e)}
                            errorMessage={this.form.$.confirmPassword.error || this.getErrorConfirm()}
                        />
                        <div className="login__form-footer">
                            <Button text="SIGN UP" isSubmit full />
                        </div>
                    </form>
                </LayoutPage>
            </>
        )
    }
}
