import styled from "styled-components";
import {Action, IAllSettings, IColParams, IRowParams, IValParams} from "../pages/Campaigns/style-step/models";
import {customPercentage} from "../pages/Campaigns/style-step/constants";

export const getGeneralBody = (settings: IAllSettings) => {
    const {general} = settings;

    return styled.div`
        ${general.bgColor ? `background-color: ${general.bgColor};` : ''}
        ${general.bgImage ? `background-image: url(${general.bgImage});` : ''}
        ${general.imageSize ? `background-size: ${general.imageSize};` : ''}
        ${general.imagePosition ? `background-position: ${general.imagePosition};` : ''}
        ${general.imageRepeat ? `background-repeat: ${general.imageRepeat};` : ''}
        ${general.imageAttachment ? `background-attachment: ${general.imageAttachment};` : ''}
    `;
}

export const getGeneralPage = (settings: IAllSettings) => {
    const { general, text } = settings;
    const isPagging = general.paddingTop || general.paddingBottom || general.paddingLeft || general.paddingRight;

    return styled.div`
        min-height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        ${general.maxWidth ? `max-width: ${general.maxWidth}px;` : ''}
        ${general.container && general.container !== 'full' && `
            margin: 0 auto;
            align-items: center;
        `}
        ${isPagging && `padding: ${general.paddingTop || 0}px ${general.paddingRight || 0}px ${general.paddingBottom || 0}px ${general.paddingLeft || 0}px;`}
        ${general.marginTop && `margin-top: ${general.marginTop}px;`}
        ${general.marginBottom && `margin-bottom: ${general.marginBottom}px;`}
        ${general.marginLeft && `margin-left: ${general.marginLeft}px;`}
        ${general.marginRight && `margin-right: ${general.marginRight}px;`}
        * {
            ${text.main.fontFamily ? `font-family: ${text.main.fontFamily};` : ''}
            ${text.main.color ? `color: ${text.main.color} !important;` : ''}
            ${text.main.size ? `font-size: ${text.main.size}px;` : ''}
            ${text.main.weight ? `font-weight: ${text.main.weight};` : ''}
            ${text.main.style ? `font-style: ${text.main.style};` : ''}
            ${text.main.lineHeight ? `line-height: ${text.main.lineHeight}px;` : ''}
            ${text.main.letterSpacing ? `letter-spacing: ${text.main.letterSpacing}px;` : ''}
        }
        h1 {
            ${text.h1.fontFamily ? `font-family: ${text.h1.fontFamily};` : ''}
            ${text.h1.color ? `color: ${text.h1.color} !important;` : ''}
            ${text.h1.size ? `font-size: ${text.h1.size}px;` : ''}
            ${text.h1.weight ? `font-weight: ${text.h1.weight};` : ''}
            ${text.h1.style ? `font-style: ${text.h1.style};` : ''}
            ${text.h1.lineHeight ? `line-height: ${text.h1.lineHeight}px;` : ''}
            ${text.h1.letterSpacing ? `letter-spacing: ${text.h1.letterSpacing}px;` : ''}
        }
        h2 {
            ${text.h2.fontFamily ? `font-family: ${text.h2.fontFamily};` : ''}
            ${text.h2.color ? `color: ${text.h2.color} !important;` : ''}
            ${text.h2.size ? `font-size: ${text.h2.size}px;` : ''}
            ${text.h2.weight ? `font-weight: ${text.h2.weight};` : ''}
            ${text.h2.style ? `font-style: ${text.h2.style};` : ''}
            ${text.h2.lineHeight ? `line-height: ${text.h2.lineHeight}px;` : ''}
            ${text.h2.letterSpacing ? `letter-spacing: ${text.h2.letterSpacing}px;` : ''}
        }
    `
}

export const getParentRow = (rowParams: IRowParams) => {
    return styled.div`
            display: flex;
            ${`width: ${rowParams.width || '100'}%;`}
            flex-wrap: wrap;
            padding: ${rowParams.paddingTop || 0}px ${rowParams.paddingRight || 0}px ${rowParams.paddingBottom || 0}px ${rowParams.paddingLeft || 0}px;
            margin: ${rowParams.marginTop || 0}px ${rowParams.marginRight || 0}px ${rowParams.marginBottom || 0}px ${rowParams.marginLeft || 0}px;
            ${rowParams.backgroundColor ? `background-color: ${rowParams.backgroundColor};` : ''}
            ${rowParams.color ? `
                * {
                    color: ${rowParams.color} !important;
                }
            ` : ''}
            ${rowParams.minHeight ? `min-height: ${rowParams.minHeight}px;` : ''}
            ${rowParams.maxWidth ? `max-width: ${rowParams.maxWidth}px;` : ''}
            ${rowParams.backgroundImage ? `background-image: url(${rowParams.backgroundImage});` : ''}
            ${rowParams.backgroundRepeat ? `background-repeat: ${rowParams.backgroundRepeat};` : ''}
            ${rowParams.backgroundPosition ? `background-position: ${rowParams.backgroundPosition};` : ''}
            ${rowParams.backgroundAttachment ? `background-attachment: ${rowParams.backgroundAttachment};` : ''}
            ${rowParams.backgroundSize ? `background-size: ${rowParams.backgroundSize};` : ''}
            ${rowParams.options.includes('center') && `
                justify-content: center;
            `}
            ${rowParams.options.includes('between') && `
                justify-content: space-between;
            `}
            ${rowParams.options.includes('around') && `
                justify-content: space-around;
            `}
            ${rowParams.options.includes('right') && `
                justify-content: flex-end;
            `}
            ${rowParams.options.includes('left') && `
                justify-content: flex-start;
            `}
            ${rowParams.options.includes('hideMobile') && `
                @media (max-width: 767px) {
                    display: none !important;
                }
            `}
            ${rowParams.options.includes('mobileOnly') && `
                @media (min-width: 767px) {
                    display: none !important;
                }
            `}
        `
}

export const getContent = (colParams: IColParams) => {
    return styled.div`
            ${(colParams.colOptions.includes('center') || colParams.colOptions.includes('left') || colParams.colOptions.includes('right')) && `
                display: flex;
                flex-wrap: wrap; 
            `}
            ${colParams.colOptions.includes('center') && `
                justify-content: center;
            `}
            ${colParams.colOptions.includes('between') && `
                justify-content: space-between;
            `}
            ${colParams.colOptions.includes('around') && `
                justify-content: space-around;
            `}
            ${colParams.colOptions.includes('left') && `
                justify-content: flex-start;
            `}
            ${colParams.colOptions.includes('right') && `
                justify-content: flex-end;
            `}
            ${colParams.colOptions.includes('hideMobile') && `
                @media (max-width: 500px) {
                    display: none !important;
                }
            `}
            ${colParams.colOptions.includes('mobileOnly') && `
                @media (min-width: 500px) {
                    display: none !important;
                }
            `}
            padding: ${colParams.paddingTop || 0}px ${colParams.paddingRight || 0}px ${colParams.paddingBottom || 0}px ${colParams.paddingLeft || 0}px;
            margin: ${colParams.marginTop || 0}px ${colParams.marginRight || 0}px ${colParams.marginBottom || 0}px ${colParams.marginLeft || 0}px;
            ${colParams.maxHeight ? `max-height: ${colParams.maxHeight}px;` : ''}
            ${colParams.maxWidth ? `max-width: ${colParams.maxWidth}px;` : ''}
            @media (min-width: 768px) {
                width: ${colParams.mediumWidth === customPercentage ? `${colParams.customMediumWidth}%` : colParams.mediumWidth};
            }
            @media (min-width: 1200px) {
                width: ${colParams.largeWidth === customPercentage ? `${colParams.customLargeWidth}%` : colParams.largeWidth};
            }
            
            @media (max-width: 767px) {
                width: ${colParams.smallWidth === customPercentage ? `${colParams.customSmallWidth}%` : colParams.smallWidth};
            }
            @media (max-width: 575px) {
                width: ${colParams.mobileWidth === customPercentage ? `${colParams.customMobileWidth}%` : colParams.mobileWidth};
            }
        `;
}

export const getButton = (valueParams?: IValParams) => {
    if (!valueParams) {
        return styled.button``;
    }

    const ButtonParent = valueParams.action === Action.link
        ? styled.div``
        : valueParams.action === Action.moveToPage
            ? styled.button``
            : valueParams.action === Action.cancel
                ? styled.button.attrs({ type: 'reset' })``
                : styled.button.attrs({ type: 'submit' })``;


    const Button = styled(ButtonParent)`
                ${valueParams?.bgColor ? `
                    background-color: ${valueParams.bgColor};
                    border: 1px solid ${valueParams.bgColor};
                ` : ''}
                ${valueParams?.color ? `color: ${valueParams.color};` : ''}
                box-shadow: 0 6px 32px rgb(67 138 251 / 20%);
                height: 40px;
                line-height: 40px;
                width: 100%;
                text-align: center;
                padding: 0 20px;
                cursor: pointer;
                * {
                    ${valueParams?.color ? `color: ${valueParams.color};` : ''}
                }
            `;
    const Button1 = styled(Button)`
                border-radius: 30px;
            `;
    const Button2 = styled(Button)`
                border-radius: 3px;
            `;
    const Button3 = styled(Button)`
                transform: skewX(-5deg);
            `;

    if (valueParams?.type === 'button2') {
        return Button2;
    }

    if (valueParams?.type === 'button3') {
        return Button3;
    }

    return Button1;
}