import {FC, useRef, useState} from "react";

import {ISelectSize, ISize} from "./models";
import './styles.scss';

export const SelectSizes: FC<ISelectSize> = (props) => {
    const [open, setOpen] = useState(false);
    const select = useRef<HTMLHeadingElement>(null);
    const classes = [
        'select2',
        'select2-container',
        'select2-container--simple',
        'select2-container--inline',
        'select2-container--below',
        'select2-container--focus',
        open ? 'select2-container--open' : ''
    ];
    const onBlur = () => {
        setOpen(false);
        select && select.current && select.current.blur && select?.current.blur();
    }
    const changeSize = (size: ISize) => {
        if (size === props.currentSize) {
            onBlur();
            return;
        }

        props.onChange(size);
        onBlur();
    }

    return (
        <div className={classes.join(' ')} onFocus={() => setOpen(true)} onBlur={() => setOpen(false)} tabIndex={0} ref={select}>
            <div className="select2-selection select2-selection--single">
                <div className="select2-selection__rendered">
                    {props.currentSize.value}
                </div>
                <div className="select2-selection__arrow" />
            </div>
            <div className="select2-dropdown select2-dropdown--below">
                <div className="select2-results">
                    <ul className="select2-results__options">
                        {props.sizeList.map(size => {
                            return(
                                <li key={size.value + '-li'} className="select2-results__option" onClick={() => changeSize(size)}>
                                    <div className="select-option select-option_hover">{size.title}</div>
                                    <div className="select-option__cat" />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}