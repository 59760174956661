// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0; }
  .loader * {
    margin: auto; }

.loader_overlay {
  background: rgba(255, 0, 0, 0.5); }

.loader_content {
  color: #333; }
  .loader_content svg circle {
    stroke: #5D5FEF !important; }
`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,aAAa;EACb,MAAM;EACN,OAAO,EAAA;EAPT;IASI,YAAY,EAAA;;AAIhB;EACE,gCAAgC,EAAA;;AAGlC;EACE,WAAW,EAAA;EADb;IAKM,0BAA0B,EAAA","sourcesContent":[".loader {\n  position: absolute;\n  z-index: 1000;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  top: 0;\n  left: 0;\n  * {\n    margin: auto;\n  }\n}\n\n.loader_overlay {\n  background: rgba(255, 0, 0, 0.5);\n}\n\n.loader_content {\n  color: #333;\n\n  svg {\n    circle {\n      stroke: #5D5FEF !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
