import {sortBy} from 'lodash';
import moment from "moment-timezone";

import {Period} from "pages/constants";
import {ISelectOption} from "components/Field";
import {IDataLineChart, IStatistic, IStatisticDTO} from "components/charts/models";
import {ILineChartDTO} from "shared/models";

export const generateRandom = () => Math.floor(new Date().valueOf() * Math.random());

export interface IEnum {
    [key: string]: string
}

export const getEnumKeyByEnumValue = (myEnum: IEnum, enumValue: string): string => {
    return Object.keys(myEnum).find(x => myEnum[x] === enumValue) || '';
}

export const getEnumValueByEnumKey = (myEnum: IEnum, enumKey: string) => myEnum[enumKey];

export const SelectMap = <Value>(values: Value[], options: ISelectOption<Value>[]): ISelectOption<Value>[] => {
    if (!values) {
        return [];
    }

    return values.map((value) => {
        return options.find(o => o.value === value) || { title: '', value };
    });
}

export const getAnyQueryParams = (query: string): { [key: string]: string } => {
    const validQuery = query.replace('?', '');
    const params = validQuery.split('&');
    let result: { [key: string]: string } = {};
    params.forEach((param:string) => {
        const [key, value] = param.split('=');

        result[key] = value;
    });

    return result;
}

export const getQueryParams = (query: string): { pageNumber: number, size: number, token: string, email: string } => {
    const validQuery = query.replace('?', '');
    const params = validQuery.split('&');
    let size = 10;
    let pageNumber = 1;
    let token = '';
    let email = '';

    params.forEach((param: string) => {
        const [key, value] = param.split('=');

        if (key === 'size') {
            size = Number(value);
        }

        if (key === 'pageNumber') {
            pageNumber = Number(value);
        }

        if (key === 'token') {
            token = value;
        }

        if (key === 'email') {
            email = value;
        }
    })

    return { pageNumber, size, token, email };
}

export const getErrorMessage = (response: any) => {
    const { message }: { message: string, errors: {} } = response.request.response ? JSON.parse(response.request.response) : { message: 'Something went wrong' };

    return message;
}

export const getFilterPeriod = (period: Period, format?: string) => {
    const formatDate = format || 'YYYY-MM-DD';
    switch (period) {
        case Period.fourteenDays: return moment().subtract(14, 'days').format(formatDate);
        case Period.quarter: return moment().subtract(1, 'quarter').format(formatDate);
        case Period.sevenDays: return moment().subtract(7, 'days').format(formatDate);
        case Period.thirtyDays: return moment().subtract(30, 'days').format(formatDate);
        case Period.today: return moment().format(formatDate);
        case Period.twentyFourHours: return moment().subtract(24, 'hours').format(formatDate);
        case Period.week: return moment().subtract(1, 'week').format(formatDate);
        case Period.year: return moment().subtract(1, 'year').format(formatDate);
        case Period.yesterday: return moment().subtract(1, 'day').format(formatDate);
        default: return null;
    }
}

export const formatStatistic = (data: IStatisticDTO): IStatistic => {
    return {
        accepted: data.accepted.count,
        paid: data.paid.count,
        rejected: data.rejected.count,
        review: data.review.count,
        reviewed: data.reviewed.count,
    }
}

export const formatLineChart = (data: ILineChartDTO): IDataLineChart[] => {
    const acceptedDays = data.accepted.map((i => (i.day)));
    const rejectedDays = data.rejected.map((i => (i.day)));
    const reviewDays = data.review.map((i => (i.day)));
    const reviewedDays = data.reviewed.map((i => (i.day)));
    const paidDays = data.paid.map((i => (i.day)));

    const allDays = Array.from(new Set([...acceptedDays, ...rejectedDays, ...reviewDays, ...reviewedDays, ...paidDays ]));
    const sortedDays = sortBy(allDays, function(date) {
        return new Date(date);
    })

    return sortedDays.map(day => {
        let Accepted = 0;
        let Approved = 0;
        let AwaitingReview = 0;
        let Paid = 0;
        let Rejected = 0;

        data.accepted.forEach((line => {
            if (line.day === day) {
                Accepted = line.count;
            }
        }));
        data.reviewed.forEach((line => {
            if (line.day === day) {
                Approved = line.count;
            }
        }));
        data.review.forEach((line => {
            if (line.day === day) {
                AwaitingReview = line.count;
            }
        }));
        data.paid.forEach((line => {
            if (line.day === day) {
                Paid = line.count;
            }
        }));
        data.rejected.forEach((line => {
            if (line.day === day) {
                Rejected = line.count;
            }
        }));


        return {
            date: moment(day).format('DD.MM'),
            Accepted,
            Approved,
            AwaitingReview,
            Paid,
            Rejected
        }
    });
}

export const copyToClipboard = (text: string): boolean => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);

        return true;
    }

    return false;

}