import React, {useEffect, useState} from "react";
import {ModalCreateGroup} from "./modal-create";
import Header from "components/Header/Header";
import {List} from "components/List";
import {CampaignItem} from "pages/Campaigns/CampaignItem";

import {repository} from "shared/repositories";
import {isEmpty} from "lodash";
import {RouteComponentProps} from "react-router-dom";
import {GroupTotalInfo} from "./total-info";
import Charts from "components/charts";
import {Period} from "../constants";
import {useCharts} from "hooks";
import {Button} from "components/Button";

const GroupDetails = (props: RouteComponentProps<{ id: string }>) => {
    const [campaigns, setCampaigns] = useState([]);
    const { id } = props.match.params;
    const config = {
        sizes: ['5%', '25%', '20%', '20%', '10%', '10%'],
        withSort: true,
        headTitles: ['Id', 'Campaign', 'Brand', 'Duration', 'Status'],
        first60: true,
    }

    const data = useCharts(Number(id), repository.groupCampaign);

    useEffect(() => {
        repository.groupCampaign.getListCampaigns(Number(id)).then(data => {
            if (!isEmpty(data)) {
                setCampaigns(data);
            }
        })
    }, []);

    const handleChangePeriod = (value: Period) => {
        data.setPeriod(value);
    }

    return (
        <>
            <ModalCreateGroup />
            <Header />
            <GroupTotalInfo id={Number(id)} />
            <Charts
                period={data.period}
                dataLineChart={data.dataLineChart}
                dataBarChart1={data.dataBarChart1}
                dataBarChart2={data.dataBarChart2}
                statistic={data.statistic}
                onChangePeriod={handleChangePeriod}
            />
            <div className="v-container">
                <section className="section pb-8">
                    <div className="page__flex flex-row-reverse">
                        <div className="section__tools-col">
                            <Button text="Back to details" onClick={() => {}}  />
                        </div>
                    </div>
                    <List
                        config={config}
                        data={campaigns}
                        component={CampaignItem}
                        componentProps={{ isGroupItem: true }}
                    />
                </section>
            </div>
        </>
    );

}

export default GroupDetails;