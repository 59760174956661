import {FC} from "react";
import {Icons, IIconProps} from "./models";

export const Icon: FC<IIconProps> = (props) => {
    return (
        <svg className={`icon__${props.icon}`} width={`${props.width || 12}px`} height={`${props.height || 12}px`}>
            <use xlinkHref={`#${props.icon}`} />
        </svg>
    );
}

export const AllIcons = () => {
    return (
        <div className="container">
            <div className="row">
                {Object.keys(Icons).map((icon) => (
                    <div className="col col-lg-2 mb-2">
                        <div>{icon}</div>
                        {/* @ts-ignore */}
                        <Icon icon={Icons[icon]} width={50} height={50} />
                    </div>
                ))}
            </div>
        </div>
    );
}