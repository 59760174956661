import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {Icon, Icons} from "../../components/Icon";
import {authEnhancer, IAuthStore} from "../../store";
import {SelectFilterMini} from "../../components/select-filter/SelectFilterMini";
import {repository} from "../../shared/repositories";
import {LIST_PERIOD} from "../constants";
import {IInfo} from "./models";
import './campaigns.scss';

export const Dashboard = authEnhancer(({ authStore, companyId }: IAuthStore & { companyId: number }) => {
    const [info, setInfo] = useState<IInfo>({
        active: null,
        total: null,
        reach: null,
        statistic: null,
    });
    useEffect(() => {
        repository.company.getDetailsForCompany(companyId).then(response => {
            const { data } = response?.data || { data: {active_campaigns_count: 0, total_campaigns_count: 0, total_reach_count: 0, statistic_count: 0} };
            setInfo({
                active: data.active_campaigns_count,
                total: data.total_campaigns_count,
                reach: data.total_reach_count,
                statistic: data.statistic_count
            })
        })
    }, []);

    const counters = [
        {
            icon: Icons.cash_success,
            title: 'Active campaigns',
            value: info.active || '0',
        },
        {
            icon: Icons.total_campaign,
            title: 'Total campaigns',
            value: info.total || '0',
        },
        {
            icon: Icons.total_reach,
            title: 'Total reach',
            value: info.reach,
        },
        {
            icon: Icons.statistics,
            title: 'Statistics',
            value: info.statistic,
        },
    ];

    const renderCounters = () => {
        return (
            <div className="board__counters">
                {counters.map(counter => {
                    const key = uuidv4();

                    if (!counter.value) {
                        return null;
                    }

                    return (
                        <div className="board__wrapper board__wrapper--flex" key={key}>
                            <div className="board__counter">
                                <div className="board__counter-icon">
                                    <Icon icon={counter.icon} width={48} height={48} />
                                </div>
                                <div className="board__counter-title">{counter.title}</div>
                                <div className="board__counter-value">{counter.value}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    const { user } = authStore;

    const [current, setCurrent] = useState(LIST_PERIOD[0].value)

    return (
        <div className="board">
            <div className="v-container">
                <div className="board__welcome">
                    <div className="board__title">Hi, <span className="board__username">{user.first_name}!</span></div>
                    <div className="board__filter">
                        <SelectFilterMini
                            label="Your overview for"
                            value={current}
                            list={LIST_PERIOD}
                            onChange={setCurrent}
                        />
                    </div>
                </div>
            </div>
            <div className="v-container">
                {renderCounters()}
            </div>

        </div>
    );
});