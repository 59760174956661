import {IDataBarChart, ILineChartProps} from "./models";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

export const BarChartComponent = (props: ILineChartProps<IDataBarChart>) => {
    const { data } = props;
    const renderColorfulLegendText = (value: string) => (<span style={{ color: '#60789D', fontSize: 12, marginRight: 10 }}>{value}</span>);

    return (
        <ResponsiveContainer>
            <BarChart
                data={data}
                layout='vertical'
                barSize={10}
                margin={{
                    left: 50
                }}
            >
                <CartesianGrid />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" style={{ fontSize: 12 }} />
                <Tooltip />
                <Legend
                    type="circle"
                    align='left'
                    iconSize={10}
                    iconType="circle"
                    verticalAlign="bottom"
                    layout="horizontal"
                    wrapperStyle={{ bottom: -10 }}
                    formatter={renderColorfulLegendText}
                />
                <Bar dataKey="AwaitingReview"   fill="#F6A161" name="Awaiting review" />
                <Bar dataKey="Rejected"         fill="#FF5A64" />
                <Bar dataKey="Accepted"         fill="#5D5FEF" />
                <Bar dataKey="Approved"         fill="#53C351" />
                <Bar dataKey="Paid"             fill="#8BA0C0" />
            </BarChart>
        </ResponsiveContainer>
    );
}