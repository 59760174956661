import {IListProps} from "./models";
import {FC} from "react";
import {get, isEmpty} from 'lodash';
import classNames from "classnames";

export const List: FC<IListProps<any>> = (props) => {
    const { config, data, component: ItemList, componentProps } = props;
    const hideActionsColumn = get(componentProps, 'isSuperUser', false);

    const getIndexDataTour = () => {
        if (!props.dataTour) {
            return 0;
        }

        return data.findIndex((item) => item.cashbacks);
    }




    return (
        <div className="table-scroll" data-tour={props.dataTour}>
            <table className="table table_separated">
                <colgroup>
                    {config.sizes.map((item, index) => (<col key={item + index} width={item} />))}
                </colgroup>
                <thead>
                    <tr className="table__header">
                        {config.headTitles.map((item, index) => {
                            const classTh = classNames({
                                'table__th': true,
                                'pt-60': index === 0 && config.first60,
                            })
                            return (
                                <th className={classTh} key={item + index}>
                                    {!isEmpty(item) && (<div className="table__sort">
                                        {item}
                                        <div className="table__sort-icon">
                                            <svg
                                                className="icon__arrow-drop-down"
                                                width="6.59px"
                                                height="3.79px"
                                            >
                                                <use xlinkHref="#arrow-drop-down" />
                                            </svg>
                                        </div>
                                    </div>)}
                                </th>
                            )
                        })}
                        {!hideActionsColumn && (<th className="table__th table__th_actions" />)}
                </tr>
                </thead>
                <tbody>
                    {(data || []).map((item, index) => (
                        <ItemList
                            key={`list-item-${index}`}
                            dataTour={0 === index ? 'campaign dropdown': undefined}
                            {...item}
                            {...componentProps}
                            {...{ hideActionsColumn: hideActionsColumn }}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};
