import {useContext, useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import {observer} from "mobx-react";

import {AuthContext} from "../../store";
import {pages, superPages} from "../../shared/constants";
import {Icon, Icons} from "../Icon";
import './Header.scss';

const Header = observer(() => {
    const authStore = useContext(AuthContext);
    const [openProfile, setOpenProfile] = useState<boolean>(false);
    const location = useLocation();

    const logout = (e: any) => {
        e.preventDefault();
        authStore.logout();
    }

    return (
        <div className="page__header">
            <header className="header">
                <div className="header__container">
                    <div className="header__row">
                        <div className="header__col header__col_left">
                            <Link key="logo" to="/" className="header__name">ValYu</Link>
                        </div>
                        <div className="header__col header__col_left">
                            {(authStore.userIsSuperAdmin ? superPages : pages).map(page => {
                                const className = location.pathname === page.link ? "header__link active" : "header__link"
                                return (
                                    <Link key={page.link} className={className} to={page.link}>{page.title}</Link>
                                );
                            })}
                        </div>
                        <div className="header__col">
                            {!authStore.userIsSuperAdmin && (<div className="header__notification">
                                <button className="notification-btn">
                                  <span className="notification-btn__icon">
                                      <Icon icon={Icons.notic} width={16.5} height={20} />
                                  </span>
                                </button>
                            </div>)}
                        </div>
                        <div className="header__separator d-none d-md-block"/>
                        <div className="header__col d-none d-md-block">
                            <div className="header__profile" tabIndex={0} onBlur={() => setOpenProfile(false)}>
                                <div className={`profile-btn${openProfile ? ' active' : ''}`} onClick={() => setOpenProfile(true)}>
                                    {/*<div className="profile-btn__text">*/}
                                    {/*    {authStore.user?.first_name + ' ' + authStore.user?.last_name}*/}
                                    {/*</div>*/}
                                    <div className="profile-btn__img">
                                        <div
                                            className="avatar avatar_md"
                                            style={{backgroundImage: 'url("' + authStore.user?.avatar + '")'}}
                                        />
                                    </div>
                                    <div className="profile-btn__arrow">
                                        <Icon icon={Icons.arrow_drop_down} width={6.59} height={3.79} />
                                    </div>
                                </div>
                                <div className={`dropdown header__profile-dropdown${openProfile ? ' active' : ''}`}>
                                    <ul className="dropdown__list">
                                        <li className="dropdown__list-item dropdown__list-item_line-bottom">
                                            <Link className="dropdown__list-link" to={"/profile"}>
                                                <div className="dropdown__list-text">My profile</div>
                                            </Link>
                                        </li>
                                        {/*{!authStore.userIsSuperAdmin && (<li className="dropdown__list-item dropdown__list-item_line-bottom">*/}
                                        {/*    <Link className={"dropdown__list-link"} to={"/company-settings"}>*/}
                                        {/*        <div className="dropdown__list-text">Company settings</div>*/}
                                        {/*    </Link>*/}
                                        {/*</li>)}*/}
                                        <li className="dropdown__list-item">
                                            <a href="#" onClick={logout} className="dropdown__list-link dropdown__list-text">Log out</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
});

export default Header;
