import React, {FC, FormEvent} from "react";
import classNames from 'classnames';
import Loading from "components/Loading";
import {Button} from "components/Button";

import {IPopup, IPopupWizard} from "./models";
import './modalForm.scss'

export const ModalForm: FC<IPopup> = (props) => {
    const {title, subTitle, isVisible, formConfig, loading, onClose} = props;

    const handleConfirm = (e: FormEvent) => {
        e.preventDefault();
        formConfig?.onSubmit && formConfig.onSubmit();
    }

    const popupClass = classNames({
        'popup': true,
        'fadeIn' : isVisible,
        'fadeOut': !isVisible,
    });

    return (
        <div className={popupClass}>
            <div className="popup__overlay" onClick={onClose} />
            {typeof loading === 'boolean' && (<Loading active={loading} />)}
            <form className="popup__body" onSubmit={handleConfirm}>
                <div className="popup__close" onClick={onClose}>
                    <svg className="icon__close" width="11" height="11">
                        <use xlinkHref="#close" />
                    </svg>
                </div>
                <h2 className="popup__title">{title}</h2>
                {subTitle && (<div className="popup__text">{subTitle.map(text => (<div key={text}>{text}</div>))}</div>)}
                <div className="popup__form">
                    {props.children}
                </div>
                {formConfig && (<div className="popup__footer">
                    <Button text={formConfig.cancelText} isWhite popup onClick={onClose}  />
                    <Button text={formConfig.submitText} isSubmit popup disabled={formConfig.formSubmitDisabled} />
                </div>)}
            </form>
        </div>
    );
}

export const ModalFormWizard: FC<IPopupWizard> = (props) => {
    const {isVisible, onSubmit, onClose, preview} = props;

    const handleConfirm = (e: FormEvent) => {
        e.preventDefault();
        onSubmit && onSubmit(e);
    }

    const popupClass = classNames({
        'popup': !preview,
        'fadeIn' : isVisible,
        'fadeOut': !isVisible,
    });

    return (
        <div className={popupClass}>
            <div className="popup__overlay" onClick={onClose} />
            <form className="popup__body" onSubmit={handleConfirm} onReset={onClose}>
                <div className="popup__close" onClick={onClose}>
                    <svg className="icon__close" width="11" height="11">
                        <use xlinkHref="#close" />
                    </svg>
                </div>
                {props.children}
            </form>
        </div>
    );
}