import React, {PureComponent} from "react";
import classNames from 'classnames';
import {pricingList} from "./constants";
import {IPrice, IPricingState} from "./models";
import Header from "../../components/Header/Header";
import './pricing.scss';
import { Button } from "../../components/Button";
import {updatePricing} from "../../shared/mappers";
import {repository} from "../../shared/repositories";
import Loading from "../../components/Loading";

export class Pricing extends PureComponent<{ }, IPricingState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            pricingList: pricingList.map((pricing, index) => ({ ...pricing, active: index === 0 })),
            loading: false,
        };
    }

    componentDidMount() {
        updatePricing(this.state.pricingList).then(response => {
            this.setState({ pricingList: response });
        })
    }

    handleSelection = (i: number) => {
        this.setState((prevState) => ({
            pricingList: prevState.pricingList.map((pricing, index) => ({ ...pricing, active: index === i })),
        }))
    }

    handleStart = (price: IPrice) => {
        if (price.priceKey) {
            this.setState({ loading: true })
            repository.payment.getPaymentPage(price.priceKey).then(response => {
                this.setState({ loading: false })
                window.location.assign(response.data.url);
            })
        }

    }

    renderRadioPeriod = () => {
        const { pricingList } = this.state;

        return (
            <div className="pricing__periods">
                {pricingList.map((pricing, index) => {
                    const buttonClass = classNames({
                        'pricing__period': true,
                        'active': pricing.active,
                    });

                    return (
                        <div className={buttonClass} key={pricing.period} onClick={() => this.handleSelection(index)}>
                            {`${pricing.period} month`}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderPriceBoxes = () => {
        const { pricingList } = this.state;
        const activePrices = pricingList.find(pricing => pricing.active)?.prices || [];


        return (
            <div className="pricing__list">
                {activePrices.map((price, index) => {
                    const buttonText = price.buttonText ? price.buttonText.toUpperCase() : price.canStarted ? 'GET STARTED' : '';

                    return (
                        <div className={`price-box price-box--${price.title.toLowerCase()}`} key={`${price.title} - ${price.price}`}>
                            <div className="price-box__title">{price.title}</div>
                            {this.renderPrice(price)}
                            <Button
                                text={buttonText}
                                onClick={() => this.handleStart(price)}
                                full
                                isDark={!price.price}
                                isOrange={price.title === 'Advanced'}
                            />
                            <div className="price-box__info">
                                {price.options.map(option => (<p key={option} dangerouslySetInnerHTML={{ __html: option }} />))}
                            </div>
                            {price.freeTrial && (<div className="price-box__free">
                                <b>{`${price.freeTrial} days free trial`}</b> entry point then
                            </div>)}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderPrice = (price: IPrice) => {
        if (!price.price) {
            return (<div className="price-box__enterprise" />);
        }
        const priceInfoClass = classNames({
            'price-box__price-info': true,
            'price-box__price-info--without-sales': !price.sales,
        })

        return (
            <>
                <div className="price-box__price">{price.price}</div>
                <div className={priceInfoClass}>
                    {price.sales && (<div className="price-box__sales">{`${price.sales}% saving`}</div>)}
                    <div className="price-box__description">PER MEMBER PER MONTH</div>
                </div>
            </>
        );
    }

    render() {

        return (
            <>
                <Header />
                <Loading active={this.state.loading} />
                <div className="pricing">
                    <h1 className="pricing__title">Choose your plan</h1>
                    <p className="pricing__description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur pellentesque eget lorem tristique. Amet purus habitant.</p>
                    {this.renderRadioPeriod()}
                    {this.renderPriceBoxes()}
                </div>
            </>
        );
    }
}