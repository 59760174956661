import React, {useRef, useState} from "react";
import {IMultiCreatorProps} from "./models";
import './styles.scss';

export const MultiCreator = (props: IMultiCreatorProps) => {
    const { label , placeholder, value = [], onChange, onKeyPress } = props;
    const input = useRef<HTMLInputElement>(null);
    const selectIsEmpty = value.length === 0;
    const [newValue, setNewValue] = useState('');

    const onBlur = () => {

    }

    const handleFocus = () => {
        input && input.current && input.current?.focus()
    }

    const clear = () => {
        onChange([]);
    }

    const handleDelete = (event: any, index: number) => {
        event.preventDefault();
        event.stopPropagation();
        let newValues = Array.from(value);
        newValues.splice(index, 1);
        onChange(newValues);
    }

    const handleAddNewValue = (key: any) => {
        if (key.charCode === 13) {
            onChange([...value, newValue]);
            setNewValue('');
        }
    }
    const handleChange = (e: any) => {
        setNewValue(e.target.value);
    }

    return (
        <div className="field" data-tour={props.dataTour}>
            {label && (<p className="field__title">{label}</p>)}
            <div
                className={`select-full select2-container select2-container--default select2-container--below select2-container--focus`}
            >
                <div className="selection">
                    <span className={`empty select2-selection select2-selection--multiple`}>
                        <div className="select2-selection__rendered"
                            onBlur={onBlur}
                            onClick={handleFocus}
                            tabIndex={1}

                        >
                            <span className={`select2-selection__clear`} onMouseDown={clear}>×</span>
                            {value.map((item: string, index) => (
                                <li className="select2-selection__choice" key={index + item}>
                                    <span className="select2-selection__choice__remove" onMouseDown={(e) => handleDelete(e, index)}>×</span>
                                    <div className="">{item}</div>
                                </li>
                            ))}
                            <li className="select2-search select2-search--inline" style={{ maxWidth: 5 + (newValue.length*10) }}>
                                <input
                                    className="select2-search__field"
                                    type="search"
                                    tabIndex={0}
                                    autoComplete="off"
                                    ref={input}

                                    autoCorrect="off"
                                    autoCapitalize="none"
                                    placeholder={selectIsEmpty ? placeholder : ''}
                                    value={newValue}
                                    onKeyDown={onKeyPress}
                                    onKeyPress={handleAddNewValue}
                                    onChange={handleChange}
                                />
                            </li>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    );
}