import React, {FC, useEffect, useState} from "react";
import {SelectSizes} from "./SelectSize";
import {IPaginationProps, ISize} from "./models";

const sizeList: ISize[] = [
    { value: 5, title: '5' },
    { value: 10, title: '10' },
    { value: 20, title: '20' },
    { value: 50, title: '50' },
    { value: 100, title: '100' }
];

export const Paginations: FC<IPaginationProps> = (props) => {
    const [ currentSize, setSize ] = useState<ISize>(sizeList[1]);
    const { per_page, last_page, current_page } = props.meta;

    useEffect(() => {
        const size = sizeList.find(s => Number(s.value) === Number(per_page)) || sizeList[1];
        setSize(size);
    }, [per_page])

    if (!last_page || !current_page) {
        return null;
    }

    const onChangeSize = (size: ISize) => {
        setSize(size);
        props.onChange(size.value, 1);
    }

    const renderPrevButton = () => {
        if (current_page === 1) {
            return null;
        }
        return (
            <li className="pagination-list__item" onClick={() => props.onChange(currentSize.value, current_page - 1)}>
                <a href='#' className="pagination-list__link prev">previous</a>
            </li>
        );
    }

    const renderNextButton = () => {
        if (current_page === last_page) {
            return null;
        }
        return (
            <li className="pagination-list__item_active pagination-list__item" onClick={() => props.onChange(currentSize.value,current_page + 1)}>
                <a href='#' className="pagination-list__link next">next</a>
            </li>
        );
    }

    const renderList = () => {
        if (last_page === 1) {
            return (
                <li className="pagination-list__item_active pagination-list__item">
                    <span className="pagination-list__item-current">1</span>
                </li>
            );
        }
        const pages = Array.from({length: last_page}, (v, k) => k + 1)

        return pages.map(page => {
            if (page === current_page) {
                return (
                    <li key={page + '-page'} className="pagination-list__item_active pagination-list__item">
                        <span className="pagination-list__item-current">{page}</span>
                    </li>
                );
            }

            return (
                <li key={page + '-page'} className="pagination-list__item_active pagination-list__item" onClick={() => props.onChange(currentSize.value, page)}>
                    <a href='#' className="pagination-list__link">{page}</a>
                </li>
            );
        })
    }

    return (
        <div className="section__tools">
            <div className="section__tools-row">
                <div className="section__tools-col section__tools-col_left">
                    <SelectSizes currentSize={currentSize} sizeList={sizeList} onChange={onChangeSize} />
                </div>
                <div className="section__tools-col section__tools-col_right">
                    <div className="light-theme simple-pagination">
                        <ul className="pagination-list">
                            {renderPrevButton()}
                            {renderList()}
                            {renderNextButton()}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}