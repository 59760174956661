import {ISelectOption} from "../../components/Field";

export const ACCURACY_LEVEL: ISelectOption<number>[] = [
    { title: 'None', value: 0 },
    { title: 'Low', value: 50 },
    { title: 'Mid', value: 70 },
    { title: 'Strong', value: 90 },
    { title: 'Full', value: 100 },
];

export const APPROVAL_TYPES: ISelectOption<number>[] = [
    { title: 'Automatically', value: 1 },
    { title: 'Manually', value: 2 },
]