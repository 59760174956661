import {action, computed, makeObservable, observable} from "mobx";
import {
    FormGeneralSettings,
    FormText,
    IAccordionBlock,
    IAllSettings,
    IColParams,
    IContent,
    IGeneralSettings,
    IRowParams,
    IStyleList,
    ISubBlock,
    ITextSettings, IValParams, PageTemplateTypes,
} from "../pages/Campaigns/style-step/models";
import {v4 as uuidv4} from "uuid";
import {FieldState, FormState} from "formstate-x/lib";
import {repository} from "../shared/repositories";
import {IPage, IProduct, ITemplate} from "../pages/Campaigns/models";
import {DEFAULT_TEXT_SETTINGS} from "../pages/Campaigns/style-step/constants";
import {required} from "../utils/validators/required";
import {updatePagesIntoTemplate} from "../shared/mappers";
import {isEmpty, isNil} from "lodash";

export class StylePageStore {
    @observable list: IStyleList;
    @observable campaign_id: number;
    @observable currentPageId: number;
    @observable currentPage: IPage;
    @observable copiedOptions: IPage['options'];
    @observable pageTemplateId: number | null;
    @observable products: IProduct[];
    @observable accordions: IAccordionBlock[];
    @observable template: PageTemplateTypes | undefined;
    @observable loading: number | undefined;
    @observable settings: {
        general: FormState<FormGeneralSettings>;
        text: {
            main: FormState<FormText>;
            h1: FormState<FormText>;
            h2: FormState<FormText>;
        }
    }
    @observable pages: IPage[];
    @observable formSave : FormState<{ name: FieldState<string>}>
    @observable templateList: ITemplate[];

    constructor() {
        /** список кнопок выбранной категории */
        this.list = {} as IStyleList;
        /** список созданных аккордеонов для выбранной страницы (template) */
        this.accordions = [];
        /** список всех созданных страниц */
        this.pages = [];
        /** список продуктов */
        this.products = [];
        /** идентификатор связывающий шаблон с текущей кампанией */
        this.pageTemplateId = null;
        /** идентификатор кампании */
        this.campaign_id = 0;
        /** идентификатор выбранной страницы для изменения / сохраненения */
        this.currentPageId = 0;
        this.currentPage = {} as IPage;
        this.copiedOptions = {} as IPage['options'];
        /** выводит лоадер */
        this.loading = undefined;
        /** страница, выбранная в текущий момент, ее видно в селекте */
        this.template = undefined;
        /** список всех шаблонов, полученных с бека */
        this.templateList = [];
        /** глобальные настройки каждой страницы */
        this.settings = this.initialSettings();
        /** форма, куда вводим имя при создании шаблона */
        this.formSave = new FormState<{name: FieldState<string>}>({
            name: new FieldState('').validators(required),
        });

        makeObservable(this);
    }

    initialSettings() {
        return {
            general: this.createGeneralSettings({}),
            text: {
                main: this.createTextSettings(DEFAULT_TEXT_SETTINGS),
                h1: this.createTextSettings(DEFAULT_TEXT_SETTINGS),
                h2: this.createTextSettings(DEFAULT_TEXT_SETTINGS),
            }
        }
    }

    initialCampaign = (campaign_id: number, products: IProduct[], page_template: number | null) => {
        this.campaign_id = campaign_id;
        this.accordions = [];
        this.list = {} as IStyleList;
        this.template = undefined;
        this.pageTemplateId = page_template;
        this.products = products;
        this.currentPageId = 0;
        repository.campaign.getCampaignPages(campaign_id).then((response) => {
            this.pages = response;
        });
    }

    getPageTemplateName = (id: number | null) => {
        if (!id) {
            return '';
        }

        const template = this.templateList.find(item => item.id === id);

        return template?.options?.name || '';
    }

    createGeneralSettings = (params: IGeneralSettings): FormState<FormGeneralSettings> => {
        return new FormState({
            maxWidth:        new FieldState(params.maxWidth),
            container:       new FieldState(params.container),
            bgColor:         new FieldState(params.bgColor),
            bgImage:         new FieldState(params.bgImage),
            imageSize:       new FieldState(params.imageSize),
            imageAttachment: new FieldState(params.imageAttachment),
            imagePosition:   new FieldState(params.imagePosition),
            imageRepeat:     new FieldState(params.imageRepeat),
            paddingTop:     new FieldState(params.paddingTop),
            paddingLeft:     new FieldState(params.paddingLeft),
            paddingRight:     new FieldState(params.paddingRight),
            paddingBottom:     new FieldState(params.paddingBottom),
            marginTop:     new FieldState(params.marginTop),
            marginLeft:     new FieldState(params.marginLeft),
            marginRight:     new FieldState(params.marginRight),
            marginBottom:     new FieldState(params.marginBottom),
        })
    }

    createTextSettings = (params: ITextSettings): FormState<FormText> => {
        return new FormState({
            fontFamily:    new FieldState(params.fontFamily),
            color:         new FieldState(params.color),
            size:          new FieldState(params.size),
            weight:        new FieldState(params.weight),
            style:         new FieldState(params.style),
            lineHeight:    new FieldState(params.lineHeight),
            letterSpacing: new FieldState(params.letterSpacing),
        })
    }

    getOptions = () => {
        const data = new FormData();
        this.pages.forEach((page) => {
            data.append(`options[${page.template}][general]`,  page.options.general);
            data.append(`options[${page.template}][main]`,     page.options.main);
            data.append(`options[${page.template}][h1]`,       page.options.h1);
            data.append(`options[${page.template}][h2]`,       page.options.h2);
            data.append(`options[${page.template}][data]`,     page.options.data);
        })

        return data;
    }

    updatePage = () => {
        const data = new FormData();
        this.loading = 1;
        data.append('template', `${this.template}`);

        this.products.forEach((product) => {
            data.append(`products[]`, `${product.id}`);
        })

        data.append('options[general]', JSON.stringify(this.settings.general.value));
        data.append('options[main]', JSON.stringify(this.settings.text.main.value));
        data.append('options[h1]', JSON.stringify(this.settings.text.h1.value));
        data.append('options[h2]', JSON.stringify(this.settings.text.h2.value));
        data.append('options[data]', JSON.stringify(this.accordions));

        repository.campaign.updatePageForCampaign(this.currentPageId, data).then((response) => {
            const updatedPages = [ ...this.pages ];
            this.pages = updatedPages.map(page => {
                if (page.template === this.template) {
                    return {
                        ...response.data.data
                    }
                }

                return page
            });
            this.loading = undefined
        });
    }

    @action
    setTemplateList = (companyId: number) => {
        repository.campaign.getListPageTemplates(companyId).then(response => {
            this.templateList = response?.data?.data;
            // на случай сломанных данных
            // const a = response.data.data;
            // a.forEach((i: ITemplate) => {
            //     this.deletePageTemplate(i.id);
            // })
        })
    }

    @computed
    get getSettings(): IAllSettings {
        return {
            general: this.settings.general.value,
            text: {
                main: this.settings.text.main.value,
                h1: this.settings.text.h1.value,
                h2: this.settings.text.h2.value,
            }
        }
    }

    /** работа с редактором -----------------> */

    @action // при выборе шаблона данные подтягиваются из выбранного шаблона для изменения
    changeTemplate = (value: PageTemplateTypes) => {
        const currentPage: IPage = this.pages.find(page => page.template === value) || {} as IPage;

        this.template = value;
        this.currentPage = currentPage;

        // когда мы выбираем шаблон, мы актуализируем copiedOptions, чтобы потом работать только с ним
        const general = localStorage.getItem('general') || '';
        const main = localStorage.getItem('main') || '';
        const h1 = localStorage.getItem('h1') || '';
        const h2 = localStorage.getItem('h2') || '';
        const data = localStorage.getItem('data') || '';
        this.copiedOptions = {
            general,
            main,
            h1,
            h2,
            data,
        }

        if (currentPage.id) {
            this.loadPage(currentPage); // если уже есть страница в этой кампании - загружаем последние данные
        }
        else {
            this.createNewPage();
        }
    }

    @action
    openBlock = (list: IStyleList) => {
        this.list = list;
    }

    @action
    closeBlock = () => {
        this.list = {} as IStyleList;
    }

    @action
    createNewAccordion = (draggableId: string, index: number) => {
        const dragItem = this.list.items.find(i => i.id === draggableId) || {} as ISubBlock;
        const newAccordions: IAccordionBlock[] = Array.from(this.accordions);
        const newItemContent = dragItem.content.map(c => {
            return { ...c, id: uuidv4() }
        })
        newAccordions.splice(index, 0 , {...dragItem, content: newItemContent, id: uuidv4(), isOpen: false});

        this.accordions = newAccordions;
        this.updatePage();
    }

    @action
    dragDropAccordion = (indexSource: number, indexDestination: number) => {
        const sourceList: IAccordionBlock[] = Array.from(this.accordions);
        const [removed] = sourceList.splice(indexSource, 1);
        sourceList.splice(indexDestination, 0, removed);

        this.accordions = sourceList;
        this.updatePage();
    }

    @action
    toggleAccordions = (id: string) => {
        this.accordions = this.accordions.map(item => {
            if (item.id === id) {
                return {...item, isOpen: !item.isOpen}
            }

            return {...item, isOpen: false}
        });
    }

    @action
    removeAccordion = (index: number) => {
        const sourceList: IAccordionBlock[] = Array.from(this.accordions);
        sourceList.splice(index, 1);

        this.accordions = sourceList;
        this.updatePage();
    }

    @action
    cloneAccordion = (index: number) => {
        const sourceList: IAccordionBlock[] = Array.from(this.accordions);
        sourceList.splice(index, 0, { ...this.accordions[index], id: uuidv4(), isOpen: false});

        this.accordions = sourceList;
        this.updatePage();
    }

    @action
    changeAccordionContent = (id: string, newContent: IContent[]) => {
        const list = Array.from(this.accordions).map(item => {
            if (item.id === id) {
                return {...item, content: newContent}
            }

            return item
        });

        this.accordions = list;
        this.updatePage();
    }

    @action
    saveModalData = (parent_id: string, content_id: string, contentValue: string, colParams: IColParams, valParams?: IValParams) => {
        const list = this.accordions.map(item => {
            if (item.id === parent_id) {
                const newContent = item.content.map(c => {
                    if (c.id === content_id) {
                        return { ...c, value: contentValue, colParams, valueParams: valParams}
                    }

                    return c;
                });

                return {...item, content: newContent}
            }

            return item
        });
        this.accordions = list;
        setTimeout(() => {
            this.updatePage();
        }, 0);
    }

    @action
    changeAccordionRowParam = (id: string, rowParams: IRowParams, title: string) => {
        const list = this.accordions.map(item => {
            if (item.id === id) {
                return {...item, rowParams, title }
            }

            return item
        });

        this.accordions = list;
        this.updatePage();
    }

    @action
    loadPage = (page: IPage) => {
        this.currentPageId = page.id;
        this.loadData(page.options);
        this.updatePage();
    }

    @action
    loadData = (options: IPage['options'], otherData?: IAccordionBlock[]) => {
        const data = options.data ? JSON.parse(options.data) : [];
        const general = options.general ? JSON.parse(options.general) : {};
        const main = options.main ? JSON.parse(options.main) : DEFAULT_TEXT_SETTINGS;
        const h1 = options.h1 ? JSON.parse(options.h1) : DEFAULT_TEXT_SETTINGS;
        const h2 = options.h2 ? JSON.parse(options.h2) : DEFAULT_TEXT_SETTINGS;
        this.settings = {
            general: this.createGeneralSettings(general),
            text: {
                main: this.createTextSettings(main),
                h1: this.createTextSettings(h1),
                h2: this.createTextSettings(h2),
            }
        }
        this.accordions = otherData || data;
        this.list = {} as IStyleList;
    }

    @action
    createNewPage = () => {
        const data = new FormData();
        data.append('template', `${this.template}`);

        this.products.forEach((product) => {
            data.append(`products[]`, `${product.id}`);
        })

        data.append('options[general]', JSON.stringify({}));
        data.append('options[main]', JSON.stringify(DEFAULT_TEXT_SETTINGS));
        data.append('options[h1]', JSON.stringify(DEFAULT_TEXT_SETTINGS));
        data.append('options[h2]', JSON.stringify(DEFAULT_TEXT_SETTINGS));
        data.append('options[data]', JSON.stringify([]));

        repository.campaign.createNewPageForCampaign(this.campaign_id, data).then((response) => {
            const { id, options } = response.data.data
            this.currentPageId = id;
            this.pages.push(response.data.data);
            this.currentPage = response.data.data;
            this.loadData(options);
        });
    }

    /** работа с шаблонами */
    @action
    savePageTemplate = (name: string, companyId: number) => {
        const data = this.getOptions();
        data.append('options[name]', name);

        return repository.campaign.createPageTemplate(companyId, data)
            .then(response => {
                if (response.data.data.id) {
                    this.pageTemplateId = response.data.data.id;
                    return response.data.data.id;
                } else {
                    return false;
                }

            });
    }

    @action
    selectPageTemplate = (template: ITemplate) => {
        this.pageTemplateId = template.id;
        const pages = [...this.pages];
        updatePagesIntoTemplate(this.campaign_id, template, pages, this.products).then(() => {
            this.template = undefined;
            this.list = {} as IStyleList;
            this.accordions = [];
            repository.campaign.getCampaignPages(this.campaign_id).then((response) => {
                this.pages = response;
            });
        });
    }

    @action
    deletePageTemplate = (id: number) => {
        if (id === this.pageTemplateId) {
            this.pageTemplateId = null;
        }

        return repository.campaign.deletePageTemplate(id).then(response => {
            return [200, 201].includes(response.status);
        })
    }

    updatePageTemplate = () => {
        if (!this.pageTemplateId) {
            return Promise.resolve(false);
        }

        const data = this.getOptions();
        data.append('options[name]', this.getPageTemplateName(this.pageTemplateId));

        return repository.campaign.updatePageTemplate(this.pageTemplateId, data).then(response => {
            return [200, 201].includes(response.status);
        });
    }

    @action
    copyPage = () => {
        this.copiedOptions = this.currentPage.options;
        localStorage.setItem('general', this.copiedOptions.general);
        localStorage.setItem('main', this.copiedOptions.main);
        localStorage.setItem('h1', this.copiedOptions.h1);
        localStorage.setItem('h2', this.copiedOptions.h2);
        localStorage.setItem('data', this.copiedOptions.data);
    }

    @action
    pastePage = () => {
        const copiedOptions: IPage['options'] = this.copiedOptions;
        const dataForCopy = (JSON.parse(this.copiedOptions.data) as IAccordionBlock[]).filter(el => {
            return this.template && el.templates.includes(this.template);
        });
        this.loadData(copiedOptions, dataForCopy);
        this.updatePage();
    }

    get disabledCopy() {
        return isNil(this.template) || this.template === PageTemplateTypes.popup_confirm;
    }
    get disabledPaste() {
        return isNil(this.template) || this.template === PageTemplateTypes.popup_confirm || isEmpty(this.copiedOptions.data);
    }
}
