import React, {useState} from "react";
import styled from "styled-components";
import {campaignStoreEnhancer} from "store";
import {ModalForm} from "components/modals/modal-form";
import {Button} from "components/Button";
import {repository} from "shared/repositories";
import {ICampaignStore} from "pages/Campaigns/models";

export const ModalReview = campaignStoreEnhancer((props: ICampaignStore) => {
    const { modalReview, hideModalReview, campaign_id, fetchCashbacks } = props.campaignStore;
    const {visible, image, status, subtitle} = modalReview;
    const [big, setBig] = useState(false);

    const ImageWrap = styled.div`
        margin: 0 auto 20px;
        width: 100%;
        max-width: 152px;
        background-image: url(${image});
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 0.3s ease-out;
        transform: scale(${big ? '5' : '1'});
        position: relative;
        z-index: 20; 
        &:before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%;
        }
    `;

    const handleClose = () => {
        setBig(false);
        hideModalReview();
    }

    const handleAccept = () => {
        if (campaign_id) {
            repository.campaign.checkAccept(campaign_id, modalReview.id_cash).then(response => {
                handleClose();
                fetchCashbacks(campaign_id, 10, 1);
            })
        }
    }

    const handleDecline = () => {
        if (campaign_id) {
            repository.campaign.checkDecline(campaign_id, modalReview.id_cash).then(response => {
                handleClose();
                fetchCashbacks(campaign_id, 10, 1);
            })
        }
    }

    return (
        <ModalForm
            isVisible={visible}
            title="Review"
            subTitle={[subtitle]}
            onClose={handleClose}
        >
            <ImageWrap onClick={() => {setBig(!big)}} />
            <div className="popup__footer popup__footer--custom">
                {status === 2 ? (
                    <>
                        <Button text="Accept" isSuccess popup onClick={handleAccept}  />
                        <Button text="Decline" isAlert popup onClick={handleDecline} />
                    </>
                ) : (
                    <Button text="Close" isWhite full onClick={handleClose}  />
                )}

            </div>
        </ModalForm>
    );
})