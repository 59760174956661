import React, {FC, useState} from "react";
import {ICheckboxProps} from "./models";

export const Checkbox: FC<ICheckboxProps> = (props) => {
    const { label, name, required, children } = props;
    const [checked, setChecked] = useState(props.checked);
    const handleChange = () => {
        setChecked(!checked);
    }

    return (
        <div className="checkbox field">
            <input className="checkCheckboxAccount" required={required} id={name} type="checkbox" checked={checked} name={name} onChange={handleChange} />
            <label className="checkbox__label" htmlFor={name}>{label || children}</label>
        </div>
    );
}