import {FC} from "react";

const FaqPage: FC<{}> = () => {
    return (
        <div className="page__container v-container">
            <section className={"section"}>
                <div className="login__logo" />
                <div className="login__title">FAQ - PAGE</div>
                <h2 className="section__title">1. What is a cashback?</h2>
                <p className="mb-24">
                    In a cashback promotion campaign, you get part of your money back. For this to happen,
                    you buy first the specified product in promotion in the store.
                    Don’t forget to keep your receipt (you will need to upload it into our platform).
                    And at last: follow the few instructions on the platform to receive your cashback!
                </p>
                <h2 className="section__title">2. How do I participate in a Valyu cashback promotion?</h2>
                <p className="mb-24">
                    <ol className="faq__list">
                        <li>Click on the link of the ad.</li>
                        <li>Fill in your email address.</li>
                        <li>Check your email for a unique code.</li>
                        <li>Enter the code on the ValYu platform.</li>
                        <li>Upload your kassa ticket (make sure it is a jpg or png format).</li>
                        <li>Submit.</li>
                        <li>Check your inbox for the confirmation email.</li>
                    </ol>
                </p>
                <h2 className="section__title">3. What should I do if my receipt does not want to be uploaded?</h2>
                <p className="mb-24">
                    Make sure the quality of the photo or image is good and not blurry.
                    Also make sure that the receipt is from a participating retailer.
                    (then mention the retailers here eg. ‘This promotion is valid at Albert Heijn, Colruyt,
                    Carrefour Express, Carrefour Hyper, Carrefour Market, Ok, Spar, Lidl, ALDI’ ) .
                </p>
                <h2 className="section__title">4. What is the duration of this cashback campaign?</h2>
                <p>
                    From xx/xx/xxxx until xx/xx/xxxx.
                </p>
                <p className="mb-24">
                    Any receipts uploaded to the platform after the closing date will not be accepted anymore.
                </p>
                <h2 className="section__title">5. How long does it take before I receive my cashback?</h2>
                <p className="mb-24">
                    The cashback will normally take <span className="faq__bold">4 - 6 weeks</span> to be processed and paid into your bank account.
                    We will send you a confirmation email once it has been processed.
                </p>
                <h2 className="section__title">6. In which regions is this cashback campaign available?</h2>
                <p className="mb-24">
                    <span className="faq__bold">Belgium only</span> and only bank accounts from this region will be considered.
                </p>
                <h2 className="section__title">7. Which bank accounts are eligible for this campaign?</h2>
                <p className="mb-24">
                    <ol className="faq__list">
                        <li>BNP Paribas Fortis</li>
                        <li>KBC Bank</li>
                        <li>ING Belgium</li>
                        <li>Belfius Bank</li>
                        <li>Argenta</li>
                        <li>AXA Bank Belgium</li>
                        <li>Beobank</li>
                    </ol>
                </p>
                <h2 className="section__title">8. Will ValYu share my data that I filled in on the platform?</h2>
                <p className="mb-24">
                    No, ValYu is GDPR compliant and will not share your data with any 3rd parties.
                </p>
                <h2 className="section__title">9. I have not received email updates regarding my claim, how can I track the progress of it?</h2>
                <p className="mb-24">
                    An automated confirmation e-mail is sent when your claim is submitted and another email when
                    your claim is accepted. Should we need any further information, a separate email is sent to you.
                    Please make sure to check your spam/junk folder of your mailbox.
                </p>
                <h2 className="section__title">10. What are the contact details of ValYu if I have further questions or concerns</h2>
                <p>
                    Email: *we will check for a general email address*
                </p>
                <p className="mb-24">
                    Telephone: *we will check for a general phone number*
                </p>
            </section>
        </div>
    );
}

export default FaqPage;