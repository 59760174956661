// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-picker {
  width: 282px !important;
  box-sizing: border-box !important;
  box-shadow: none !important; }

.dropdown-colorpicker {
  top: calc(100% + 10px); }
`, "",{"version":3,"sources":["webpack://./src/components/Field/color.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iCAAiC;EACjC,2BAA2B,EAAA;;AAG7B;EACE,sBAAsB,EAAA","sourcesContent":[".color-picker {\r\n  width: 282px !important;\r\n  box-sizing: border-box !important;\r\n  box-shadow: none !important;\r\n}\r\n\r\n.dropdown-colorpicker {\r\n  top: calc(100% + 10px);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
