import {Component, FormEvent} from 'react';
import {inject, observer} from "mobx-react";
import {Link, Redirect} from "react-router-dom";

import {AuthStore} from "../../store";
import Loading from "../../components/Loading";
import {Field} from "../../components/Field";
import { Button } from '../../components/Button';

import {LayoutPage} from "./Layout";
import {ILoginProps, ILoginState, LoginFlow} from "./models";
import './styles.scss'


@inject('authStore')
@observer
export default class Login extends Component<ILoginProps, ILoginState> {

    authStore: AuthStore;

    constructor(props: ILoginProps) {
        super(props);
        this.authStore = props.authStore;
        this.state = { loginFlow: LoginFlow.init }
    }

    submit = async (e: FormEvent) => {
        e.preventDefault();
        this.setState({loginFlow: LoginFlow.loading});
        try {
            await this.authStore.login()
            this.setState({loginFlow: LoginFlow.login});
        } catch (e) {
            this.setState({loginFlow: LoginFlow.incorrectLogin});
        }
    }


    render() {
        return (
            <>
                <LayoutPage signIn={false} title="Sign in" >
                    <Loading active={this.state.loginFlow === LoginFlow.loading} />
                    <form onSubmit={this.submit}>
                        <Field
                            label="E-mail address"
                            name="email"
                            placeholder="Enter your email"
                            value={this.authStore.loginForm.$.email._value}
                            onChange={(e) => {this.authStore.loginForm.$.email.onChange(e)}}
                        />
                        <Field
                            label="Password"
                            name="password"
                            type="password"
                            placeholder="Enter your password"
                            value={this.authStore.loginForm.$.password._value}
                            onChange={(e) => this.authStore.loginForm.$.password.onChange(e)}
                            errorMessage={this.state.loginFlow === LoginFlow.incorrectLogin ? 'Incorrect email or password' : null}
                        />
                        <div className="login__form-footer">
                            <Button text="SIGN IN" isSubmit full />
                            <Link className="login__link" to="/forgot-password" >Forgot your password?</Link>
                        </div>

                    </form>
                </LayoutPage>
                {this.state.loginFlow === LoginFlow.login && <Redirect to='/'/>}
            </>
        )
    }
}
