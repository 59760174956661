import {useEffect, useState} from "react";

import {repository} from "../../shared/repositories";
import {IGroup} from "../../store";

export const GroupTotalInfo = (props: { id: number }) => {

    const [info, setInfo] = useState({ title: '', spent: 0, budget: 0 });

    useEffect(() => {
        repository.groupCampaign.details(props.id).then(response => {
            if (response?.data?.data?.name) {
                setInfo({
                    title: response.data.data.name,
                    budget: response.data.data.budget,
                    spent: response.data.data.spent || 0,
                })
            }
        })
    }, []);

    return (
        <div className="board">
            <div className="v-container">
                <div className="board__header">
                    <div>
                        <div className="board__title">{info.title}</div>
                    </div>
                    <div className="board__total">
                        <div className="board__total-title">cashback</div>
                        <div className="board__total-value board__total-value--EUR">
                            <div className="board__total-reminder">{info.spent}</div>
                            <div className="board__total-amount">{info.budget}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}