import {action, makeObservable, observable} from "mobx";
import {FieldState, FormState} from "formstate-x/lib";
import {email, phone, required} from "../utils/validators/required";
import {AuthStore} from "./AuthStore";
import {repository} from "../shared/repositories";

export type IAccountStoreForm = {
    firstName: FieldState<string>;
    lastName: FieldState<string>;
    email: FieldState<string>;
    phone: FieldState<string>;
    file: FieldState<File | null>,
}
export type IAccountStorePasswordForm = {
    password: FieldState<string>;
    confirmedPassword: FieldState<string>;
}

export class AccountStore {
    @observable form: FormState<IAccountStoreForm>;
    @observable passwordForm: FormState<IAccountStorePasswordForm>;
    @observable public currentAvatar: string = "";
    public authStore: AuthStore;

    constructor(authStore: AuthStore) {
        this.authStore = authStore;
        this.form = new FormState({
            firstName: new FieldState(this.authStore.user?.first_name).validators(required),
            lastName: new FieldState(this.authStore.user?.last_name).validators(required),
            email: new FieldState(this.authStore.user?.email).validators(required, email),
            phone: new FieldState(this.authStore.user?.phone).validators(required, phone),
            file: new FieldState(null),
        });
        this.passwordForm = new FormState<IAccountStorePasswordForm>({
            password: new FieldState("").validators(required),
            confirmedPassword: new FieldState( "").validators(required),
        })

        makeObservable(this)
    }

    @action
    async save() {
        const formData = new FormData();
        formData.append('first_name', this.form.$.firstName.value);
        formData.append('last_name', this.form.$.lastName.value);
        formData.append('email', this.form.$.email.value);
        formData.append('phone', this.form.$.phone.value);
        formData.append('_method', 'PUT');
        if (this.form.$.file.value !== null) {
            formData.append('avatar', this.form.$.file.value);
        }
        const response = await repository.user.updateUser(this.authStore.user.id, formData);
        this.authStore.setUser(response.data.data);

        return response;
    }

    @action
    changePassword() {
        // TODO - нужен метод для смены пароля, он должен быть авторизован и получать email, password, password_confirm
    }

    @action
    initForm() {
        this.form.$.firstName.onChange(this.authStore.user?.first_name);
        this.form.$.lastName.onChange(this.authStore.user?.last_name);
        this.form.$.email.onChange(this.authStore.user?.email);
        this.form.$.phone.onChange(this.authStore.user?.phone);
        this.currentAvatar = this.authStore.user?.avatar;
    }

}
