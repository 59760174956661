import React from "react";
import {IColumnsProps, IContent, IModalProps} from "./models";
import {Icon, Icons} from "../../../components/Icon";
import {modalEnhancer, IModalStore, ITourStore} from "../../../store";
import {DEFAULT_COL_PARAMS, DEFAULT_VAL_PARAMS} from "./constants";

export const Columns = modalEnhancer((props: IColumnsProps & IModalStore & ITourStore) => {
    const { modalStore, tourStore, items } = props;

    const getModalParams = (index: number): IModalProps => {
        return {
            content: items[index].value,
            type: items[index].type,
            colParams: items[index].colParams || DEFAULT_COL_PARAMS,
            valParams: items[index].valueParams || DEFAULT_VAL_PARAMS,
            id: items[index].id,
            parent_id: props.parent_id,
        }
    }

    const renderItem = (i: IContent, index: number) => {
        return (
            <div className="col p-8" key={i.type + index} >
                <div className="column">
                    <div className="column__settings">
                        <span className="column__type">{i.type}</span>
                        <Icon icon={Icons.icon_settings_v2} width={12} height={12} />
                    </div>
                    <div className="column__edit" onClick={() => {
                        modalStore.openModal(getModalParams(index));
                        if (tourStore.isOpen) {
                            setTimeout(() => {
                                tourStore.setCurrentStep(7)
                            }, 500);
                        }
                    }}>
                        <Icon icon={Icons.icon_edit} width={12} height={12} />
                        <span>Edit content</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="column__list">
            <div className="row">
                {items.map(renderItem)}
            </div>
        </div>
    );
});