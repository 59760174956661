import {action, makeObservable, observable} from "mobx";

export class TourStore {
    @observable isOpen: boolean;
    @observable currentStep: number;
    @observable showNavigation: boolean;
    @observable isTwoCycle: number;

    constructor() {
        this.isOpen = false;
        this.currentStep = 0;
        this.showNavigation = false;
        this.isTwoCycle = 0;

        makeObservable(this);
    }

    @action
    setCurrentStep = (step: number) => {
        this.currentStep = step;
    }

    @action
    nextStep = () => {
        this.currentStep += 1;
    }

    @action
    nextCycle = (cycle: number) => {
        this.isTwoCycle = cycle;
    }


    @action
    toggleOpen = (open: boolean) => {
        this.isOpen = open;
    }

    @action
    toggleNav = (show: boolean) => {
        this.showNavigation = show;
    }

}
