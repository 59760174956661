import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {ITabsProps} from "./models";

export const Tabs: FC<ITabsProps> = observer((props) => {
    const [tabs, setTabs] = useState(props.tabs.map((i, index) => ({...i, active: index === 0})))

    useEffect(() => {
        if (props.reload !== undefined) {
            setTabs(props.tabs.map((t, i) => ({...t, active: i === 0})))
        }
    }, [props.reload])

    const handleActive = (index: number) => {
        setTabs(props.tabs.map((t, i) => ({...t, active: index === i})))
    }

    return (
        <div className="posts-tabs">
            <div className="posts-tabs__controls">
                {tabs.map((tab, index) => (
                    <div
                        key={`tab-${index}`}
                        className={`posts-tabs__control ${tab.active ? 'posts-tabs__control_active' : ''}`}
                        onClick={() => handleActive(index)}
                    >{tab.title}</div>
                ))}
            </div>
            <div className="posts-tabs__content" data-tour={props.dataTour}>
                {tabs.map((tab, index) => (
                    <div className="posts-tabs__item" key={`tab-content-${index}`} style={tab.active ? {display: 'block'} : {}}>
                        {tab.content}
                    </div>
                ))}
            </div>
        </div>
    );
});