import {Icons} from "../../../components/Icon";
import {Action, IBlock, IColParams, IRowParams, IValParams, TList, PageTemplateTypes, TypeContent} from "./models";
import {FieldState} from "formstate-x/lib";

export const DEFAULT_ROW_PARAMS: IRowParams = {
    container: 'contain',
    options: [],
}

export const DEFAULT_COL_PARAMS: IColParams = {
    mobileWidth: '100%',
    smallWidth: '100%',
    mediumWidth: '100%',
    largeWidth: '100%',
    customMobileWidth: undefined,
    customSmallWidth: undefined,
    customMediumWidth: undefined,
    customLargeWidth: undefined,
    paddingTop: undefined,
    paddingLeft: undefined,
    paddingRight: undefined,
    paddingBottom: undefined,
    marginTop: undefined,
    marginLeft: undefined,
    marginRight: undefined,
    marginBottom: undefined,
    colOptions: []
}

export const DEFAULT_TEXT_SETTINGS = { }

export const DEFAULT_VAL_PARAMS: IValParams = {
    type: '',
    action: Action.link,
    link: '',
    bgColor: '',
    color: '',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    IBAN: 'IBAN',
    bic: 'Bic',
}

export const BLOCK_ITEMS: IBlock[] = [
    {
        id: "1",
        title: 'Texts',
        icon: Icons.icon_text_new,
        items: [
            {
                id: '11',
                title: 'popup title',
                icon: Icons.icon_text_new,
                content: [
                    {
                        id: '111',
                        type: TypeContent.popupTitle,
                        value: 'title',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.popup_confirm],
            },
            {
                id: '12',
                title: 'popup subtitle',
                icon: Icons.icon_text_new,
                content: [
                    {
                        id: '121',
                        type: TypeContent.popupSubTitle,
                        value: 'subtitle',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.popup_confirm],
            },
            {
                id: '13',
                title: 'Heading 1',
                icon: Icons.icon_text_new,
                content: [
                    {
                        id: '131',
                        type: TypeContent.text,
                        value: '<h1>Heading 1</h1>',
                        colParams: DEFAULT_COL_PARAMS,
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '14',
                title: 'Heading 2',
                icon: Icons.icon_text_new,
                content: [
                    {
                        id: '141',
                        type: TypeContent.text,
                        value: '<h2>Heading 2</h2>',
                        colParams: DEFAULT_COL_PARAMS,
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '15',
                title: 'text',
                icon: Icons.icon_text_new,
                content: [
                    {
                        id: '151',
                        type: TypeContent.text,
                        value: '<p>Lorem ipsum dolor sit amet, consectetur adipisicing.</p>',
                        colParams: DEFAULT_COL_PARAMS,
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '16',
                title: 'Heading + text',
                icon: Icons.icon_text_new,
                content: [
                    {
                        id: '161',
                        type: TypeContent.text,
                        value: '<h1>Heading 1</h1>',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '162',
                        type: TypeContent.text,
                        value: '<p>Lorem ipsum dolor sit amet.</p>',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '17',
                title: 'text + text',
                icon: Icons.icon_text_new,
                content: [
                    {
                        id: '171',
                        type: TypeContent.text,
                        value: '<p>Lorem ipsum dolor sit amet.</p>',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '172',
                        type: TypeContent.text,
                        value: '<p>Lorem ipsum dolor sit amet.</p>',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '18',
                title: 'text + text + text',
                icon: Icons.icon_text_new,
                content: [
                    {
                        id: '181',
                        type: TypeContent.text,
                        value: '<p>Lorem ipsum dolor sit amet.</p>',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '182',
                        type: TypeContent.text,
                        value: '<p>Lorem ipsum dolor sit amet.</p>',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '183',
                        type: TypeContent.text,
                        value: '<p>Lorem ipsum dolor sit amet.</p>',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
        ],
        templates: [PageTemplateTypes.all],
    },
    {
        id: "2",
        title: 'Media',
        icon: Icons.icon_image,
        items: [
            {
                id: '21',
                title: 'Image',
                icon: Icons.icon_image,
                content: [
                    {
                        id: '211',
                        type: TypeContent.media,
                        value: '',
                        colParams: DEFAULT_COL_PARAMS,
                    }],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '22',
                title: 'image with text',
                icon: Icons.icon_image,
                content: [
                    {
                        id: '221',
                        type: TypeContent.media,
                        value: '',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '222',
                        type: TypeContent.text,
                        value: '<p>Lorem ipsum dolor sit amet.</p>',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '23',
                title: 'text with image',
                icon: Icons.icon_image,
                content: [
                    {
                        id: '231',
                        type: TypeContent.text,
                        value: '<p>Lorem ipsum dolor sit amet.</p>',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '232',
                        type: TypeContent.media,
                        value: '',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
        ],
        templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
    },
    {
        id: "3",
        title: 'Buttons',
        icon: Icons.icon_button,
        items: [
            {
                id: '31',
                title: '1 Button',
                icon: Icons.icon_button,
                content: [
                    {
                        id: '311',
                        type: TypeContent.button,
                        value: 'Button',
                        valueParams: {
                            type: 'button1',
                            bgColor: '',
                            color: '',
                            action: Action.submit,
                        },
                        colParams: DEFAULT_COL_PARAMS,
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '32',
                title: '2 Button',
                icon: Icons.icon_button,
                content: [
                    {
                        id: '321',
                        type: TypeContent.button,
                        value: 'Button 1',
                        valueParams: {
                            type: 'button1',
                            bgColor: '',
                            color: '',
                            action: Action.moveToPage,
                        },
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '322',
                        type: TypeContent.button,
                        value: 'Button 2',
                        valueParams: {
                            type: 'button1',
                            bgColor: '',
                            color: '',
                            action: Action.submit,
                        },
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '33',
                title: '3 Button',
                icon: Icons.icon_button,
                content: [
                    {
                        id: '331',
                        type: TypeContent.button,
                        value: 'button1',
                        valueParams: {
                            type: 'button1',
                            bgColor: '',
                            color: '',
                            action: Action.moveToPage,
                        },
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '332',
                        type: TypeContent.button,
                        value: 'button2',
                        valueParams: {
                            type: 'button1',
                            bgColor: '',
                            color: '',
                            action: Action.submit,
                        },
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '333',
                        type: TypeContent.button,
                        value: 'button3',
                        valueParams: {
                            type: 'button1',
                            bgColor: '',
                            color: '',
                            action: Action.moveToPage,
                        },
                        colParams: DEFAULT_COL_PARAMS,
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.welcome, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
            },
            {
                id: '34',
                title: '2 Button for popup',
                icon: Icons.icon_button,
                content: [
                    {
                        id: '341',
                        type: TypeContent.button,
                        value: 'Cancel',
                        valueParams: {
                            type: 'button1',
                            bgColor: '',
                            color: '',
                            action: Action.cancel,
                        },
                        colParams: DEFAULT_COL_PARAMS,
                    },
                    {
                        id: '332',
                        type: TypeContent.button,
                        value: 'Confirm',
                        valueParams: {
                            type: 'button1',
                            bgColor: '',
                            color: '',
                            action: Action.submit,
                        },
                        colParams: DEFAULT_COL_PARAMS,
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.popup_confirm],
            },
        ],
        templates: [PageTemplateTypes.welcome, PageTemplateTypes.popup_confirm, PageTemplateTypes.form, PageTemplateTypes.upload, PageTemplateTypes.thank_you],
    },
    {
        id: "4",
        title: 'Forms',
        icon: Icons.icon_form,
        items: [
            {
                id: '41',
                title: 'form with email',
                icon: Icons.icon_form,
                content: [
                    {
                        id: '411',
                        type: TypeContent.confirm_email,
                        value: 'Email',
                        colParams: DEFAULT_COL_PARAMS,
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.popup_confirm],
            },
            {
                id: '42',
                title: 'form with phone',
                icon: Icons.icon_form,
                content: [
                    {
                        id: '421',
                        type: TypeContent.confirm_phone,
                        value: 'Phone',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.popup_confirm],
            },
            {
                id: '43',
                title: 'upload a receipt',
                icon: Icons.icon_form,
                content: [
                    {
                        id: '431',
                        type: TypeContent.formCheck,
                        value: 'Check',
                        colParams: DEFAULT_COL_PARAMS,
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.upload],
            },
            {
                id: '44',
                title: 'form',
                icon: Icons.icon_form,
                content: [
                    {
                        id: '431',
                        type: TypeContent.form,
                        value: '<p>I accept the general <a href="http://google.com" target="_blank" rel="noopener">terms and conditions</a></p>',
                        colParams: DEFAULT_COL_PARAMS,
                        valueParams: {
                            firstName: 'First name',
                            lastName: 'Last name',
                            email: 'Email',
                            IBAN: 'IBAN',
                            bic: 'Bic'
                        }
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.form],
            },
            {
                id: '45',
                title: 'Checkbox',
                icon: Icons.icon_form,
                content: [
                    {
                        id: '451',
                        type: TypeContent.checkbox,
                        value: '<span>Checkbox label</span>',
                        valueParams: {
                            type: '',
                            bgColor: '',
                            color: '',
                        },
                        colParams: DEFAULT_COL_PARAMS,
                    }
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.form],
            },
        ],
        templates: [PageTemplateTypes.popup_confirm, PageTemplateTypes.form, PageTemplateTypes.upload],
    },
    {
        id: "5",
        title: 'Products',
        icon: Icons.icon_package_new,
        items: [
            {
                id: '51',
                title: 'products list',
                icon: Icons.icon_package_new,
                content: [
                    {
                        id: '421',
                        type: TypeContent.products,
                        value: '',
                        colParams: DEFAULT_COL_PARAMS,
                    },
                ],
                rowParams: DEFAULT_ROW_PARAMS,
                templates: [PageTemplateTypes.form, PageTemplateTypes.upload],
            },
        ],
        templates: [PageTemplateTypes.form, PageTemplateTypes.upload]
    },
]

export const PAGES: TList<PageTemplateTypes> = [
    {
        title: 'Welcome page',
        value: PageTemplateTypes.welcome,
    },
    {
        title: 'Popup confirm',
        value: PageTemplateTypes.popup_confirm,
    },
    {
        title: 'Form page',
        value: PageTemplateTypes.form,
    },
    {
        title: 'Upload page',
        value: PageTemplateTypes.upload,
    },
    {
        title: 'Thank you page',
        value: PageTemplateTypes.thank_you,
    },
];

export const ROW_OPTIONS: TList<string> = [
    { title: 'Center content',      value: 'center'     },
    { title: 'Between content',     value: 'between'    },
    { title: 'Around content',      value: 'around'     },
    { title: 'Left content',        value: 'left'       },
    { title: 'Right content',       value: 'right'      },
    { title: 'Hide on mobile',      value: 'hideMobile' },
    { title: 'Show on mobile only', value: 'mobileOnly' },
];

export const COL_OPTIONS: TList<string> = [
    { title: 'Center content',      value: 'center'     },
    { title: 'Left content',        value: 'left'       },
    { title: 'Right content',       value: 'right'      },
    { title: 'Hide on mobile',      value: 'hideMobile' },
    { title: 'Show on mobile only', value: 'mobileOnly' },
];

export const Container = [
    { title: 'full', value: 'full'},
    { title: 'contain', value: 'contain'},
]

export const customPercentage = 'custom';

export const WIDTH_LIST = [
    { title: 'none'  , value: ''   },
    { title: '8%'  , value: '8%'   },
    { title: '16%' , value: '16%'  },
    { title: '25%' , value: '25%'  },
    { title: '33%' , value: '33%'  },
    { title: '40%' , value: '40%'  },
    { title: '50%' , value: '50%'  },
    { title: '66%' , value: '66%'  },
    { title: '83%' , value: '83%'  },
    { title: '100%', value: '100%' },
    { title: customPercentage, value: customPercentage },
];

export const SizeImage = [
    { title: 'cover',   value: 'cover'},
    { title: 'contain', value: 'contain'},
    { title: 'auto',    value: 'auto'},
    { title: '50%',    value: '50%'},
    { title: '100%',    value: '100%'},
]

export const AtImage = [
    { title: 'fixed',   value: 'fixed'},
    { title: 'initial', value: 'initial'},
    { title: 'scroll',  value: 'scroll'},
]

export const Position = [
    { title: 'center',   value: 'center'},
    { title: 'space between',   value: 'between'},
    { title: 'space around',   value: 'around'},
    { title: 'top',     value: 'top'},
    { title: 'left',    value: 'left'},
    { title: 'right',   value: 'right'},
    { title: 'bottom',  value: 'bottom'},
];

export const BgPosition = [
    { title: 'center',   value: 'center'},
    { title: 'inherit',   value: 'inherit'},
    { title: 'initial',   value: 'initial'},
    { title: 'top',     value: 'top'},
    { title: 'left',    value: 'left'},
    { title: 'right',   value: 'right'},
    { title: 'bottom',  value: 'bottom'},
    { title: 'unset',  value: 'unset'},
    { title: 'revert',  value: 'revert'},
];

export const Repeat = [
    { title: 'no-repeat',   value: 'no-repeat'},
    { title: 'repeat',     value: 'repeat'},
    { title: 'horizontal', value: 'repeat-x'},
    { title: 'vertical',   value: 'repeat-y'},
];

export const Weight = [
    { title: 'default',  value: '' },
    { title: 'normal',  value: 'normal' },
    { title: '300',     value: '300'    },
    { title: '400',     value: '400'    },
    { title: '500',     value: '500'    },
    { title: 'bold',    value: 'bold'   },
];

export const Style = [
    { title: 'default',  value: '' },
    { title: 'normal',  value: 'normal' },
    { title: 'italic',  value: 'italic' },
];

export const FontFamilies = [
    { title: 'initial',  value: '\"Poppins\", \"sans-serif\"' },
    { title: 'Arial',  value: '\"Arial\"' },
    { title: 'Sans-serif',  value: '\"Sans-serif\"' },
]

export const BUTTON_TYPES: TList<string> = [
    { title: 'button #1', value: 'button1' },
    { title: 'button #2', value: 'button2' },
    { title: 'button #3', value: 'button3' },
]
export const BUTTON_ACTIONS: TList<Action> = [
    { title: 'Link', value: Action.link },
    { title: 'move to page', value: Action.moveToPage },
    { title: 'submit form', value: Action.submit },
    { title: 'reset form', value: Action.cancel },
]