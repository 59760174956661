export enum DOCUMENT_CATEGORY {
    TermsAndConditions = 'Terms and conditions',
    TermsOfServices = 'Terms of Services',
    PrivacyPolicy = 'Privacy Policy',
}

export const DOCUMENT_CATEGORIES: { [key: string]: string } = {
    TermsAndConditions: 'Terms and conditions',
    TermsOfServices: 'Terms of Services',
    PrivacyPolicy: 'Privacy Policy',
}


export enum DOCUMENT_LINKS {
    TermsAndConditions = 'terms-and-conditions',
    TermsOfServices = 'terms-of-services',
    PrivacyPolicy = 'privacy-policy',
}

export const DOC_TYPES = [
    DOCUMENT_LINKS.TermsAndConditions,
    DOCUMENT_LINKS.TermsOfServices,
    DOCUMENT_LINKS.PrivacyPolicy,
]