import React from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import "./Loading.scss";

const Loading: React.FC<{ active: boolean }> = (props) => {
    if (!props.active) {
        return null;
    }

    return (
        <div className={"loader"}>
            <LoadingOverlay
                active={props.active}
                spinner
                classNamePrefix="loader_"
            />
        </div>
    );
}

export default Loading;