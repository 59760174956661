export const Roles: { [key: string]: string } = {
    'admin': 'crown.svg',
    'manager': 'user.svg',
}

export const statuses: { [key: number]: string } = {
    1: 'Active',
    2: 'Completed',
    3: 'Draft',
    4: 'Archive',
}