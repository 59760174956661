import {ReactourStep} from "reactour";
import {Button, ButtonType} from "../Button";
import {ITourStore, tourEnhancer} from "../../store";
import {useHistory} from "react-router-dom";

/** первый кейс */
export const FirstSteps: ReactourStep[] = [
    {
        selector: "[data-tour='create']",
        content: 'Click here to create your campaign. You can create a single campaign or a grouped campaign.',
    },
    {
        selector: '[data-tour="create-menu"]',
        content: () => (
            <>
                <h3>1. Create group campaign </h3>
                <div>
                    This simply creates a group, and later, you have to move any single campaign into this group.
                    For example, in case you want to have campaigns in different languages, but group them under one campaign.
                </div>
                <h3>2. Create single campaign</h3>
                <div>
                    Create a single campaign.
                </div>
            </>
        ),
    },
];
export const FirstSteps2: ReactourStep[] = [
    {
        selector: '[data-tour="list campaigns"]',
        content: ({...args}) => {
            console.log(args);
            return (
                <>
                    <h3>Campaign overview</h3>
                    <div>
                        This is a list of all your Cashback campaigns.

                    </div>
                </>
            )
        },
    },
    {
        selector: '[data-tour="campaign dropdown"]',
        content: ({...args}) => {
            console.log(args);
            return (
                <>
                    <div>By mouse enter on the 3 dots and choosing useful options for your Campaign:</div>
                </>
            )
        },
    },
    {
        selector: '[data-tour="campaign dropdown menu"]',
        content: ({...args}) => {
            console.log(args);
            return (
                <>
                    <h3>View details</h3>
                    <h3>Edit</h3>
                    <h3>Duplicate</h3>
                    <h3>Move to group</h3>
                    <h3>Archive</h3>
                </>
            )
        },
    },
    {
        selector: '[data-tour="campaign dropdown line"]',
        content: ({...args}) => {
            console.log(args);
            return (
                <>
                    <h3>Share the landing page</h3>
                    <div>
                        Next, you need to share the link to the landing page with your customers.
                        <br style={{ marginBottom: 10 }}/>
                        Click on the Campaign to go to its details page.
                    </div>
                </>
            )
        },
    },
];
export const FirstSteps3: ReactourStep[] = [
    {
        selector: '[data-tour="campaign dropdown line"]',
        content: () => (
            <>
                <h3>You have processed your first cashback</h3>
                <div>
                    Great job!< br />
                    Some customers have requested a cashback.
                    <br style={{ marginBottom: 10 }}/>
                    To see the status of all the processed cashback, click on the campaign to go to its dashboard overview.
                </div>
            </>
        ),
    },
];
export const CashbackSteps: ReactourStep[] = [
    {
        selector: "[data-tour='public']",
        content: () => (
            <>
                <h3>
                    Landing page
                </h3>
                <div>
                    Click on "Public" to open the landing page.
                    <br style={{ marginBottom: 10 }}/>
                    You can then share the URL to the landing page with your customers.
                    <br style={{ marginBottom: 10 }}/>
                    Note: This is the landing page where the customers can get a cashback.
                </div>
                <h3>if you have new campaign's cashback</h3>
                <ButtonNextCycle />
            </>
        ),
    },
];
export const CashbackSteps2: ReactourStep[] = [
    {
        selector: "[data-tour='cashbacks list']",
        content: () => (
            <>
                <h3>
                    Processed cashbacks
                </h3>
                <div>
                    These are all the processed cashbacks.
                    <br style={{ marginBottom: 10 }}/>
                    You can see the status of the cashback, but also the details of each cashback.
                </div>
            </>
        ),
    },
    {
        selector: "[data-tour='status']",
        content: () => (
            <>
                <h3>
                    IMPORTANT
                </h3>
                <div>
                    Some cashbacks may require manual review.
                    <br style={{ marginBottom: 10 }}/>
                    Some images of the receipt may be too blurry for the system to approve automatically.
                    <br style={{ marginBottom: 10 }}/>
                    Therefore, any cashback with the status  "Awaiting review" will have to be manually reviewed by you.
                </div>
            </>
        ),
    },
    {
        selector: "[data-tour='view details']",
        content: () => (
            <>
                <h3>
                    View the receipt from the customer
                </h3>
                <div>
                    Click on the "View details" to see the receipt uploaded by the customer.
                    <br style={{ marginBottom: 10 }}/>
                    In case you need to manually review and accept the requested cashback, you can view and verify the receipt here.
                    <br style={{ marginBottom: 10 }}/>
                    Once accepted, the customer will receive a cashback.
                </div>
            </>
        ),
    },
];
export const objectsSteps: ReactourStep[] = [
    {
        selector: '.header__tabs',
        content: () => (
            <>
                <h3>There are 3 steps when creating a campaign: </h3>
                <div>
                    1. Define the objective of the campaign
                </div>
                <div>
                    2. Define the products for which you want to give a Cashback
                </div>
                <div>
                    3. Define the design of the landing page of the campaign
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="step-1"]',
        content: () => (
            <>
                <h3>Step 1: Define the objectives</h3>
                <div>
                    Here, we'll fill in the basic information about our campaign.
                    Such as the name of our campaign, so we can find it on our dashboard later on.
                    The total budget that we want to use for all the cashbacks.
                    The duration of our campaign, and so on.
                </div>
            </>
        )
    },
    {
        selector: '.image-upload',
        content: () => (
            <>
                <h3>upload the campaign's logo image</h3>
                <div>
                    this is important, you can't go to step two without logo
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="Campaign name"]',
        content: () => (
            <>
                <h3>Campaign name</h3>
                <div>
                    You can name this campaign anything you want.
                    This campaign will show up on the dashboard overview.
                    And a good name will make it easier to find it later on,
                    especially when you have many campaigns later on.
                </div>
                <h4>press tab for go to next field</h4>
            </>
        )
    },
    {
        selector: '[data-tour="Brand"]',
        content: () => (
            <>
                <h3>Brand</h3>
                <div>
                     Fill in the brand for which you're running the campaign.
                </div>
                <h4>press tab for go to next field</h4>
            </>
        )
    },
    {
        selector: '[data-tour="Budget"]',
        content: () => (
            <>
                <h3>Budget</h3>
                <div>
                     Fill the total budget for all the cashback.
                    This is the total sum that you'd like to pay out in form of cachbacks to all the customers
                    that have purchased one of the eligible products.
                </div>
                <h4>press tab for go to next field</h4>
            </>
        )
    },
    {
        selector: '[data-tour="Accuracy"]',
        content: () => (
            <>
                <h3>Accuracy</h3>
                <div>
                    The customers will have to upload their receipt to be eligible for a cashback.
                    Our AI will then review the uploaded receipt.
                    The higher the accuracy, the more strict the AI will be during the review.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="Accuracy-open"]',
        content: () => (
            <>
                <h3>Accuracy</h3>
                <div>
                    The options for accuracy are as follows:
                    <ul>
                        <li>None: There will be no review and any uploaded receipt will be approved automatically.</li>
                        <li>Low: AI will review, but will be very mild with the review and accept most receipts.</li>
                        <li>Normal: AI will be strict, but if the image is a bit blurry, it may still approve the receipt.</li>
                        <li>High: AI is very strict, and a high quality picture of the receipt is expected.</li>
                        <li>Full: AI will not allow a single mistake on the receipt. High quality picture is expected.</li>
                    </ul>

                    <b>NOTE:</b>
                    The higher you set the accuracy, the more receipts you will have to review manually. Since strict rules,
                    means the receipt has a lower chance to pass the approval from AI.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="Trackcode"]',
        content: () => (
            <>
                <h3>Trackcode</h3>
                <div>
                     Having different trackcodes, will generate unique URL's that you can share.
                    And per URL, you'll be able to get statistics per a custom category. See trackcode as a tracking category.
                    For example, you could have a trackingcode per region, e.g. Vlaanderen, Wallonië, Brussel.
                    Or if you want to bundle the different shops throughout the country based on your custom categorizations.
                </div>
                <h4>press enter for add track code</h4>
                <h3>copy all urls</h3>
                <div>
                    This will copy all the URL's with your trackingcodes. You can then paste them into Excel and share with
                    the people responsible for distributing the URL's to different shops (to generate a QR, for example).
                </div>
                <h4>press tab for go to next field</h4>
            </>
        )
    },
    {
        selector: '[data-tour="Cashbacks approval"]',
        content: () => (
            <>
                <h3>Cashbacks approval</h3>
                <div>


                </div>
            </>
        )
    },
    {
        selector: '[data-tour="Cashbacks approval open"]',
        content: () => (
            <>
                <h3>automatically</h3>
                <div>
                    the system will process the receipt automatically.  Meaning it will automatically approve or decline based on our AI algorithms
                </div>
                <h3>manual</h3>
                <div>
                    you will have to approve or decline each receipt manually   let’s keep automatically
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="period"]',
        content: () => (
            <>
                <h3>Start and End date</h3>
                <div>
                     Set a duration for your campaign, during which it must be active.
                    Once the campaign is expired,
                    no more cashback will be given by this campaign.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="term"]',
        content: () => (
            <>
                <h3>Edit terms and conditions</h3>
                <div>
                    Each campaign has their own terms and conditions that the customers will have to accept, before they can claim a cashback.
                    These are usually the terms and conditions from your client / brand, for which you are running the cashback campaign.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="next-button"]',
        content: () => (
            <>
                <h3>Next</h3>
                <div>
                    Now that step 1 "Define your objective" has been completed, we can go to step 2 and define our products for this campaign,
                    by clicking on the "NEXT" button.   Note: You can always go back to step 1, by clicking on the tab above "DEFINE OBJECTIVE"
                </div>
            </>
        )
    },
];
export const productSteps: ReactourStep[] = [
    {
        selector: '[data-tour="step-2"]',
        content: () => (
            <>
                <h3>Step 2: Define products</h3>
                <div>
                    The idea of the cashback campaign, is that we want to give cashback for some of the products that the customer will purchase.
                    <br style={{ marginBottom: 10 }} />
                    In step 2, we will define for which products we want to give a cashback.
                    <br style={{ marginBottom: 10 }} />
                    We also define here how much cashback we want to give for the purchased product.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="add-product-form"]',
        content: () => (
            <>
                <h3>Add your first product</h3>
                <div>
                    Fill in the product name and press ENTER or click on the  + Add product button.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="new-product"]',
        content: () => (
            <>
                <h3>Fill in the remaining info</h3>
                <div>
                    To complete our first product, we must fill in the remaining fields. We shall go through them one by one.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="Display name"]',
        content: () => (
            <>
                <h3>Display product name</h3>
                <div>
                    The first field that we've already filled in, is how our product will be displayed on the landing page
                    that the customers will visit for their cashback.
                    <br style={{ marginBottom: 10 }}/>
                    So that we can inform the client for which product they'll get a cashback.

                </div>
            </>
        )
    },
    {
        selector: '[data-tour="Ticket names"]',
        content: () => (
            <>
                <h3>Receipt product name</h3>
                <div>
                    On the receipt, the product name might be written differently. It could be an abbreviation or a shortened version.
                    <br style={{ marginBottom: 10 }}/>
                    It is important that we fill in the product name here as it is listed on the receipt,
                    because our system will look for this naming on the receipt to validate it.
                    <br style={{ marginBottom: 10 }}/>
                    <p>Note:</p>
                    It is also possible that different shops will use a slightly different naming for the product on their receipt.
                    You can add as many variations as needed, separated by a comma. For example: "Cookies, Cookie, Koeken, Koekjes"

                </div>
            </>
        )
    },
    {
        selector: '[data-tour="Min value"]',
        content: () => (
            <>
                <h3>Product price</h3>
                <div>
                    Fill in the product price. Our system uses this information to validate the receipt and check if the customer is eligible for a cashback.
                    <br style={{ marginBottom: 10 }}/>
                    Since the product price might be different in different shops, you can simply fill in the minimum price of the product and the maximum price.
                    <br style={{ marginBottom: 10 }}/>
                    In case the price is the same in all the shops, you can fill in the same price for the minimum and the maximum.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="Cashback"]',
        content: () => (
            <>
                <h3>Cashback</h3>
                <div>
                    You can define how much cashback you want to give for the purchased product.
                    <br style={{ marginBottom: 10 }}/>
                    Note that there is a small dropdown on the right side of the input field. In this dropdown, you can choose whether the given cashback should be a certain percentage of the price or an absolute price.
                    <br style={{ marginBottom: 10 }}/>
                    For example, you can choose to give back 80% of the product price. Or 0,70 EUR instead of a percentage.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="add-product-form"]',
        content: () => (
            <>
                <h3>More products</h3>
                <div>
                    You can add more products to this campaign if you are giving cashback for more than one type of product.
                    <br style={{ marginBottom: 10 }}/>
                    As usual, you start off by typing the product name and hitting ENTER or clicking on the + Add product button.
                </div>
            </>
        )
    },
    {
        selector: '[data-tour="next-button"]',
        content: () => (
            <>
                <h3>Next</h3>
                <div>
                    Now that step 2 "Define your products" has been completed, we can go to step 3 and define how our
                    landing page is going to look like, where your customers will land to claim their cashback.
                    <br style={{ marginBottom: 10 }}/>
                    Note: You can always go back to the previous steps by clicking on the tabs of the corresponding step.
                </div>
            </>
        )
    },
];
export const stylesSteps: ReactourStep[] = [
    {
        selector: '[data-tour="step-3"]',
        content: () => (
            <>
                <h3>Step 3: Define your landing page</h3>
                <div>
                    To give a cashback to your customers, we first need to redirect them to a landing page.
                    <br style={{ marginBottom: 10 }}/>
                    The landing page is where they can upload their receipt, as proof of their purchase and fill in the necessary
                    information, such as their IBAN, so that we can give them a cashback that they're eligible for.
                    <br style={{ marginBottom: 10 }}/>
                    In this step, we can create a custom landing page that fits the brand and product of this campaign.
                </div>
            </>
        )
    }, // 0
    {
        selector: '[data-tour="Select template"]',
        content: () => (
            <>
                <h3>Choose from our available templates</h3>
                <div>
                    Fortunately, you do not have to design the landing page from scratch.
                    <br style={{ marginBottom: 10 }}/>
                    You can choose from all of our available landing page templates.
                </div>
            </>
        )
    }, // 1
    {
        selector: '[data-tour="template"]',
        content: () => (
            <>
                <h3>Choose from our available templates</h3>
                <div>
                     Fortunately, you do not have to design the landing page from scratch.
                    <br style={{ marginBottom: 10 }}/>
                    You can choose from our available landing page templates to get started.
                    <br style={{ marginBottom: 10 }}/>
                    Extra:  You can learn from the templates how they're build in case you're interested in building
                    your own from scratch. Note this requires basic web development knowledge.
                </div>
            </>
        )
    }, // 2
    {
        selector: '[data-tour="select page template"]',
        content: () => (
            <>
                <h3>Choose the page you want to edit</h3>
                <div>
                     The landing page consists of several steps. So we must select the page that we want to edit first.
                </div>
            </>
        )
    }, // 3
    {
        selector: '[data-tour="options page template"]',
        content: () => (
            <>
                <h3>Welcome page:</h3>
                <div>
                    This is the first page that the customer will see once they land on the landing page.
                    Here, we want to give a brief explanation to the customer of how they can get a cashback.
                </div>
                <h3>Popup form:</h3>
                <div>
                    To prevent abuse, the customer will have to validate their phone number or email,
                    before they're eligible for a cashback. Which can be done through this popup.
                </div>
                <h3>Form page:</h3>
                <div>
                    On this page, the customer can provide the necessary information, such as their IBAN,
                    so that the cashback can be paid out.
                </div>
                <h3>Upload page:</h3>
                <div>
                    This is where the customer can upload their receipt as proof of their purchase.
                </div>
                <h3>Thank you page:</h3>
                <div>
                    The final page where we thank the customer for the purchase and inform them that they'll be notified
                    once the cashback has been processed.
                </div>
            </>
        )
    }, // 4
    {
        selector: '[data-tour="content-1"]',
        content: () => (
            <>
                <h3>Adjust the content in the template (1/3)</h3>
                <div>
                     The templates come with predefined content.
                    <br style={{ marginBottom: 10 }}/>
                    But you can always change any content, whether it's text, image, buttons, etc.
                    <br style={{ marginBottom: 10 }}/>
                    To do so, click on this block to open the EDIT options.
                </div>
            </>
        )
    }, // 5
    {
        selector: '[data-tour="content-2"]',
        content: () => (
            <>
                <h3>Adjust the content in the template (2/3)</h3>
                <div>
                     Click now on Edit content to open the editor.
                </div>
            </>
        )
    }, // 6
    {
        selector: '[data-tour="content-3"]',
        content: () => (
            <>
                <h3>Adjust the content in the template (3/3)</h3>
                <div>
                     Once you've adjusted the content, click on  Save & Apply changes button.
                </div>
            </>
        )
    }, // 7
    {
        selector: '[data-tour="preview"]',
        content: () => (
            <>
                <h3>Preview</h3>
                <div>
                    Your changes to the landing page will always be visible in the preview window.
                </div>
            </>
        )
    }, // 8
    {
        selector: '[data-tour="Select template"]',
        content: () => (
            <>
                <h3>Quick summary (1/3)</h3>
                <div>
                    Let's do a quick summary.
                    <br style={{ marginBottom: 10 }}/>
                    Step 1: Select a template.
                    <ButtonNextStep />
                </div>
            </>
        )
    }, // 9
    {
        selector: '[data-tour="select page template"]',
        content: () => (
            <>
                <h3>Quick summary (2/3)</h3>
                <div>
                    Step 2: Select the page you want to edit first.
                    <ButtonNextStep />
                </div>
            </>
        )
    }, // 10
    {
        selector: '[data-tour="content-1"]',
        content: () => (
            <>
                <h3>Quick summary (3/3)</h3>
                <div>
                    Step 3: Click to open the edit options, then click on Edit content.
                    <ButtonNextStep />
                </div>
            </>
        )
    }, // 11
    {
        selector: '[data-tour="next-button"]',
        content: () => (
            <>
                <h3>Save</h3>
                <div>

                    Once all 5 pages of the landing page are ready, click on SAVE button to publish the campaign.
                </div>
            </>
        )
    }, // 12

];


export const finalStep: ReactourStep[] = [
    {
        selector: '[data-tour="help-button"]',
        content: () => (
            <>
                <h3>How to re-activate the guide</h3>
                <div>
                    You can re-activate the guide at any time, by clicking on the "Help?" button.
                </div>
            </>
        )
    },
];

export const ButtonNextCycle = tourEnhancer((props: ITourStore) => {
    const history = useHistory();
    const handleClick = () => {
        props.tourStore.nextCycle(3);
        history.push('/');
    }

    return (
        <Button text={'Go to show it'} onClick={handleClick} />
    );
})

export const ButtonNextStep = tourEnhancer((props: ITourStore) => {
    const handleClick = () => {
        props.tourStore.nextStep();
    }

    return (
        <Button text={'Next'} type={ButtonType.next} isSmall isWhite onClick={handleClick} />
    );
})