import {notificationEnhancer, INotificationStore} from "../../store";
import {Icon, Icons} from "../Icon";
import {StatusNotification} from "./models";
import './styles.scss'

export const getNotificationComponent = () => notificationEnhancer(({ notificationStore }: INotificationStore) => {

    const iconType: { [key: string]: Icons } = {
        [StatusNotification.success]: Icons.icon_check_circle,
        [StatusNotification.error]: Icons.icon_x_circle,
        [StatusNotification.info]: Icons.icon_info,
    };

    return (
        <div className="notification__list">
            {notificationStore.list.map((item) => {
                const {id, status, title, description} = item;

                return (
                    <div className={`notification__item open ${status}`} key={id}>
                        <div className="notification__icon">
                            <Icon icon={iconType[status]} width={25} height={25}/>
                        </div>
                        <div className="notification__title">
                            {title}
                        </div>
                        {description && (<div className="notification__description">
                            {description}
                        </div>)}
                        <div className="notification__close" onClick={() => notificationStore.removeNotification(id)}>close</div>
                    </div>
                );
            })}
        </div>
    );
});