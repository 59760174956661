// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-filter__row {
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: 16px; }

.dashboard-filter__col {
  padding: 0 12px;
  margin-bottom: 12px; }
`, "",{"version":3,"sources":["webpack://./src/pages/super-dashboard/styles.scss"],"names":[],"mappings":"AACE;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB,EAAA;;AAErB;EACE,eAAe;EACf,mBAAmB,EAAA","sourcesContent":[".dashboard-filter {\r\n  &__row {\r\n    display: flex;\r\n    margin-left: -12px;\r\n    margin-right: -12px;\r\n    margin-bottom: 16px;\r\n  }\r\n  &__col {\r\n    padding: 0 12px;\r\n    margin-bottom: 12px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
