import {ICampaignStore} from "../Campaigns/models";
import {RouteComponentProps} from "react-router-dom";
import {ITourStore} from "../../store";

export type CashbackProps = ICampaignStore & ITourStore & RouteComponentProps<{ id: string }>

export interface ITab {
    name: string;
    active: boolean;
}

export interface IBulk {
    budget: string;
    cashbacks_count: number;
    created_at: string;
    id: number;
    status: BulkStatus;
    updated_at: string;
}

export enum BulkStatus {
    Created = 1,
    Accepted = 2,
    Declined = 3,
}

export const BULK_STATUS = {
    [BulkStatus.Created]: 'Created',
    [BulkStatus.Accepted]: 'Accepted',
    [BulkStatus.Declined]: 'Declined',
}