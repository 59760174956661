// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save {
  margin-top: 15px;
  text-align: center; }

.not-page {
  text-align: center;
  font-size: 7rem;
  letter-spacing: 3px;
  text-transform: uppercase; }
`, "",{"version":3,"sources":["webpack://./src/pages/Documents/Documents.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB,EAAA;;AAGpB;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,yBAAyB,EAAA","sourcesContent":[".save {\n  margin-top: 15px;\n  text-align: center;\n}\n\n.not-page {\n  text-align: center;\n  font-size: 7rem;\n  letter-spacing: 3px;\n  text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
