import {FC} from "react";
import {capitalizeFirstLetter} from "../../utils/capitalizeFirstLetter";
import {Roles, statuses} from "./constants";
import {User} from "../../shared/models";
import './styles.scss';

export const UserItem: FC<User> = (props) => {
    const handleDelete = async () => {

    }

    return (
        <>
            <tr className="table__row">
                <td className={"table__td"}>{props.id}</td>
                <td className={"table__td"}>{props.email}</td>
                <td className="table__td">
                    <div className="person">
                        <div className="person__container">
                            <div className="person__main">
                                <div className="person__title">
                                    <a href="#">
                                        {props.first_name + ' ' + props.last_name}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td className="table__td">
                    {props.roles.map((role) => (
                        <div key={role.toString()} className={role === 'admin' ? 'campaigns-block__category_red' : 'campaigns-block__category_gray'}>
                            <img className="campaigns-block__icon" src={"/img/assets/" + Roles[role]} />
                            <span>{capitalizeFirstLetter(role)}</span>
                        </div>
                    ))}
                </td>
                <td className="table__td">
                    <div className={`campaigns-block__category campaigns-block__category_${props.status === 1 ? 'blue' : 'gray'}`}>{statuses[props.status]}</div>
                </td>
                <td className="table__td table__td_actions" />
            </tr>
            <tr className="table__spacer"/>
        </>
    );
}