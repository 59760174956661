import React, {PureComponent} from "react";
import Header from "../../components/Header/Header";
import {inject, observer} from "mobx-react";
import {TicketsStore} from "../../store";
import {IListProps, List} from "../../components/List";
import {Meta, User} from "../../shared/models";
import {Button, ButtonType} from "../../components/Button";
import {Paginations} from "../../components/Paginations";
import {TicketItem} from "./TicketItem";
import {TicketEditForm} from "./EditForm";

interface ITicketStore {
    ticketsStore: TicketsStore;
}

@inject('ticketsStore')
@observer
export default class TicketSettings extends PureComponent<ITicketStore, { formVisible: boolean }> {
    private ticketsStore: TicketsStore;
    private readonly config: IListProps<User>['config'];

    constructor(props: Readonly<{ ticketsStore: TicketsStore }>) {
        super(props);
        this.ticketsStore = props.ticketsStore
        this.config = {
            sizes: ['20%', '20%', '30%', '30%', ],
            withSort: true,
            headTitles: ['Id', 'Logo', 'Name', 'Date format'],
        }
        this.state = {
            formVisible: false,

        }
    }

    componentDidMount() {
        this.ticketsStore.fetchTickets(10, 1);
    }

    modalClose = () => {
        this.ticketsStore.fetchTickets(10,1);
        this.ticketsStore.hideForm();
    }

    render() {
        return (
            <>
                <Header />
                <main className="page__main">
                    <div className="page__container v-container">
                        <section className="section">
                            <div className="section__tools">
                                <div className="section__tools-row flex-wrap">
                                    <div className="section__tools-col col">
                                        <h2 className="section__title">Ticket templates</h2>
                                    </div>
                                    <div className="section__tools-col col-12 col-lg-auto">
                                        <Button type={ButtonType.add} text="Add new ticket template" onClick={() => this.ticketsStore.showForm()}  />
                                        <TicketEditForm
                                            isVisible={this.ticketsStore.visibleForm}
                                            onClose={this.modalClose}
                                            ticket={this.ticketsStore.currentTicket}
                                        />
                                    </div>
                                </div>
                            </div>
                            <List config={this.config} data={this.ticketsStore.tickets} component={TicketItem} />
                        </section>
                        <Paginations
                            meta={this.ticketsStore.meta || {} as Meta}
                            onChange={(size: number, page: number) => this.ticketsStore.fetchTickets(size, page)}
                        />
                    </div>
                </main>
            </>
        );
    }

}