import {action, makeObservable, observable} from "mobx";

import {repository} from "../shared/repositories";
import {Meta} from "../shared/models";
import {ITicket, Ticket} from "../pages/ticket-templates/models";

export class TicketsStore {
    @observable tickets: ITicket[];
    @observable visibleForm: boolean;
    @observable currentTicket: Ticket;
    @observable meta?: Meta;

    constructor() {
        this.tickets = [];
        this.currentTicket = {} as Ticket;
        this.visibleForm = false;

        makeObservable(this);
    }


    @action
    fetchTickets = async (size: number, page: number) => {
        const response = await repository.ticket.getTicketTemplates(size, page);

        this.tickets = response?.data;
        this.meta = response.meta;
    }

    deleteTicket = (id: number) => {
        return repository.ticket.deleteTicketTemplate(id);
    }

    @action
    showForm = (id?: number) => {
        if (!id) {
            this.currentTicket = {
                logo: null,
                image: '',
                name: '',
                date_format: '',
            }
        } else {
            const ticket = this.tickets.find(ticket => ticket.id === id) || {} as ITicket
            this.currentTicket = { ...ticket, image: ticket.logo }
        }
        setTimeout(() => {
            this.visibleForm = true;
        }, 0);

    }

    @action
    hideForm = () => {
        this.visibleForm = false;
    }
}
