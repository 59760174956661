import {action, makeObservable, observable} from "mobx";
import {FieldState, FormState} from "formstate-x/lib";
import {IGroup, ModalCreateGroupFields, ModalMode, ModalMoveGroupFields} from "./models";
import {required} from "../utils/validators/required";
import {ISelectOption} from "../components/Field";
import {repository} from "../shared/repositories";

export class GroupCampaignStore {
    @observable visibleModal: boolean;
    @observable mode: ModalMode;
    @observable formCreate: FormState<ModalCreateGroupFields>;
    @observable formMove: FormState<ModalMoveGroupFields>;
    @observable groupList: ISelectOption<number>[];
    @observable campaignId: number;
    @observable groupId: undefined | number;

    constructor() {
        this.groupList = [];
        this.mode = ModalMode.create;
        this.visibleModal = false;
        this.formCreate = this.initialFormCreate({ name: '', budget: ''});
        this.formMove = this.initialFormMove();
        this.campaignId = 0;
        this.groupId = undefined;

        makeObservable(this);
    }

    initialFormCreate = (params: IGroup): FormState<ModalCreateGroupFields> => {
        return new FormState({
            name: new FieldState<string>(params.name).validators(required),
            budget: new FieldState<string>(params.budget).validators(required),
        })
    }

    initialFormMove = (): FormState<ModalMoveGroupFields> => {
        return new FormState({
            group: new FieldState<null | number>(null).validators(required),
        })
    }

    @action
    showModalCreate = () => {
        this.visibleModal = true;
        this.mode = ModalMode.create;
        this.formCreate = this.initialFormCreate({ name: '', budget: ''});
    }
    @action
    showModalUpdate = (group: IGroup) => {
        this.visibleModal = true;
        this.mode = ModalMode.update;
        this.formCreate = this.initialFormCreate({ name: group.name, budget: group.budget });
        this.groupId = group.id;
    }

    @action
    showModalMove = (id: number) => {
        this.mode = ModalMode.move;
        this.visibleModal = true;
        this.formMove = this.initialFormMove();
        this.campaignId = id;
    }

    @action
    hideModal = () => {
        this.visibleModal = false;
    }

    @action
    loadGroupList = () => {
        repository.groupCampaign.getGroups().then(response => {
            this.groupList = response.data.data.map((item: IGroup) => ({
                title: item.name,
                value: item.id,
            }))
        })
    }
}