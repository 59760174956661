import {ICampaignState, StatusStep} from "./models";

interface ICampaignPresenter {
    nextStep(indexActiveStep: number, prevSteps: ICampaignState["steps"]): ICampaignState["steps"];
    prevStep(indexActiveStep: number, prevSteps: ICampaignState["steps"]): ICampaignState["steps"];
}

class _CampaignPresenter implements ICampaignPresenter {
    nextStep(indexActiveStep: number, prevSteps: ICampaignState["steps"]): ICampaignState["steps"] {
        return prevSteps.map((step, i) => {
            let status = step.status;
            let active = step.active

            if (i === indexActiveStep) {
                status = StatusStep.done;
                active = false;
            }

            if (i === indexActiveStep + 1) {
                status = StatusStep.inProgress;
                active = true;
            }

            return {
                ...step,
                status,
                active,
            }
        })
    };

    prevStep(indexActiveStep: number, prevSteps: ICampaignState["steps"]): ICampaignState["steps"] {
        return prevSteps.map((step, i) => {
            let status = step.status;
            let active = step.active

            if (i === indexActiveStep - 1) {
                status = StatusStep.inProgress;
                active = true;
            }

            if (i === indexActiveStep) {
                status = StatusStep.inProgress;
                active = false;
            }

            return {
                ...step,
                status,
                active,
            }
        })
    }
}

export const CampaignPresenter = new _CampaignPresenter();