import {IPage, IProduct, ITemplate} from "../pages/Campaigns/models";
import {PageTemplateTypes} from "../pages/Campaigns/style-step/models";
import {repository} from "./repositories";
import {IPriceDto, IPricingForState} from "../pages/CompanySettings/models";

export const mapTemplatePageToWelcomePage = (template: ITemplate): IPage => {
    const { welcome } = template.options;
    return {
        template: PageTemplateTypes.welcome,
        options: {
            general: welcome.general,
            data: welcome.data,
            h1: welcome.h1,
            h2: welcome.h2,
            main: welcome.h2,
        }
    } as IPage;
}

export const updatePagesIntoTemplate = async (campaignId: number, template: ITemplate, pages: IPage[], products: IProduct[]): Promise<IPage[]> => {
    return Promise.all(
        Object.keys(template.options).map((key) => {
            switch (key) {
                case (PageTemplateTypes.welcome):
                case (PageTemplateTypes.popup_confirm):
                case (PageTemplateTypes.form):
                case (PageTemplateTypes.upload):
                case (PageTemplateTypes.thank_you): {
                    if (template.options[key]) {
                        const prevPage = pages.find(p => p.template === key);

                        const page: IPage  = {
                            id: 0,
                            template: key,
                            options: {
                                general:    template.options[key]?.general || '',
                                main:       template.options[key]?.main || '',
                                h1:         template.options[key]?.h1 || '',
                                h2:         template.options[key]?.h2 || '',
                                data:       template.options[key]?.data || '',
                            },
                            products
                        };

                        if (prevPage?.id) {
                            page.id = prevPage.id;

                            return updatePage(page).then(() => page);

                        }
                        else {
                            return createPage(campaignId, page).then(response => response.data.data);
                        }
                    }

                    break;
                }

                default: {
                    return Promise.resolve();
                }
              }
        })
    );
}

export const getData = (page: IPage): FormData => {
    const data = new FormData();
    data.append('template', `${page.template}`);

    page.products.forEach((product, index) => {
        data.append(`products[]`, `${product.id}`);
    })

    data.append('options[general]', page.options.general);
    data.append('options[main]', page.options.main);
    data.append('options[h1]', page.options.h1);
    data.append('options[h2]', page.options.h2);
    data.append('options[data]', page.options.data);

    return data;
}

export const updatePage = (page: IPage) => {
    return repository.campaign.updatePageForCampaign(page.id, getData(page))
}

export const createPage = (campaign_id: number, page: IPage) => {
    return repository.campaign.createNewPageForCampaign(campaign_id, getData(page))
}

export const updatePricing = (pricingList: IPricingForState[]): Promise<IPricingForState[]> => {
    return repository.payment.getPricing().then(response => {
        if (response.status === 200) {
            const prices: IPriceDto[] = [];

            (response.data || []).forEach((dto) => {
                Object.keys(dto.prices).forEach(item => {
                    prices.push(dto.prices[item]);
                })
            });

            return pricingList.map(pricing =>
                ({
                    ...pricing,
                    prices: pricing.prices.map(price => {
                        return {
                            ...price,
                            priceKey: prices.find(p => p.amount === price.price)?.id,
                        }
                    }),
                }));
        }

        return pricingList;
    })
}