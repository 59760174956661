import React, {FormEvent, useState} from "react";
import {observer} from "mobx-react";

import {Button, ButtonType} from "../../components/Button";
import { Field, FileUpload } from "../../components/Field";

import {Currency, ICampaignStore, IProductProps} from "./models";
import './campaigns.scss';
import {Icon, Icons} from "../../components/Icon";
import {campaignStoreEnhancer, ITourStore} from "../../store";
import {v4 as uuidv4} from "uuid";
import {MultiCreator} from "../../components/Field/SelectMultiCreater";

export const CampaignTwoStep = campaignStoreEnhancer((props: ICampaignStore & ITourStore) => {
    const [value, setValue] = useState("");
    const [error, setError] = useState("");

    const submitForm =  async (e: FormEvent) => {
        e.preventDefault();
        if (value) {
            setError("");
            props.campaignStore.createNewProduct(value).then(() => {
                setValue('');
                props.tourStore.nextStep();
            });
        } else {
            setError('Value is required');
        }
    }

    return (
        <div className="campaign-product">
            <h1 className="section__title section__title_center">Your products</h1>
            <div className="campaign-product__list">
                {props.campaignStore.products.map((product, index) => {
                    const key = uuidv4();

                    return (
                        <Product
                            product={product.form}
                            image={product.image}
                            index={index}
                            onRemove={() => { props.campaignStore.removeProduct(index) }}
                            key={key}
                        />);
                })}
                <form className="campaign-product__form" data-tour={'add-product-form'} onSubmit={submitForm}>
                    <div className="campaign-product__form-field">
                        <Field
                            name="name"
                            type="text"
                            placeholder="Product name"
                            noAutocomplete
                            value={value}
                            onChange={(v) => {
                                setValue(v);
                                if (v && v.length > 0) {
                                    setError("")
                                }
                            }}
                            errorMessage={error}
                        />
                    </div>
                    <Button text="Add product" type={ButtonType.add} isSubmit />
                </form>
            </div>

        </div>
    );
});

export const Product = observer(({ product, image,  index, onRemove }: IProductProps) => {
    const dataTour = index === 0 ? 'new-product': null;

    return (<div className="campaign-product__item" key={index + 'product'} data-tour={dataTour}>
        <div className="campaign-product__close" onClick={onRemove}>
            <Icon icon={Icons.close_circle} width={16} height={16} />
        </div>
        <div className="campaign-product__image">
            <FileUpload
                label='Product image'
                image={image}
                onChange={(file: File) => { product.$.image.onChange(file) }}
                hideRemoveBtn
            />
        </div>

        <div className="campaign-product__body">
            <div className="campaign-product__field-line">
                <div className="col p-8">
                    <Field
                        dataTour={dataTour ? 'Display name' : ''}
                        label="Display name"
                        name="productName"
                        noAutocomplete
                        value={product.$.name._value}
                        placeholder="Enter display name"
                        onChange={(value) => { product.$.name.onChange(value) }}
                        errorMessage={product.$.name.error}
                    />
                </div>
            </div>
            <div className="campaign-product__field-line">
                <div className="col p-8">
                    <MultiCreator
                        dataTour={dataTour ? 'Ticket names': ''}
                        label="Ticket names"
                        value={product.$.ticketNames._value}
                        onChange={(value) => { product.$.ticketNames.onChange(value) }}
                    />
                </div>

            </div>
            <div className="campaign-product__field-line">
                <div className="col-4 p-8">
                    <Field
                        dataTour={dataTour ? 'Min value': ''}
                        label="Min value"
                        type="number"
                        noAutocomplete
                        min={0}
                        max={product.$.max_value._value}
                        name="min-value"
                        value={product.$.value._value}
                        placeholder="Enter value"
                        onChange={(value) => { product.$.value.onChange(value) }}
                        errorMessage={product.$.value.error}
                    />
                </div>
                <div className="col-4 p-8">
                    <Field
                        label="Max value"
                        type="number"
                        noAutocomplete
                        min={product.$.value._value}
                        name="max-value"
                        value={product.$.max_value._value}
                        placeholder="Enter value"
                        onChange={(value) => { product.$.max_value.onChange(value) }}
                        errorMessage={product.$.max_value.error}
                    />
                </div>
                <div className="col-4 p-8">
                    <Field
                        dataTour={dataTour ? 'Cashback': ''}
                        label="Cashback"
                        type="number"
                        noAutocomplete
                        min={0}
                        value={product.$.cashback._value}
                        onChange={(value) => { product.$.cashback.onChange(value) }}
                        currency={product.$.currency._value}
                        errorMessage={product.$.cashback.error}
                        onChangeCurrency={(currency: Currency) => { product.$.currency.onChange(currency) }}
                    />
                </div>
            </div>
        </div>
    </div>);
})