import React, {FormEvent} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import styled from "styled-components";

import {
    getButton,
    getContent,
    getGeneralBody,
    getGeneralPage,
    getParentRow,
} from "components/styledComponents";
import {ModalFormWizard} from "components/modals/modal-form";
import {Checkbox} from "components/Field";
import {repository} from "shared/repositories";
import {Presenter} from "../../preview-page/presenter";

import {IPage, IProduct} from "../models";
import {IContent, ISubBlock, PageTemplateTypes, TypeContent} from "./models";
import {EmailField, FormCheck, FormFields, PhoneField, Products} from "./formFields";

interface IState {
    pages: IPage[];
    products: IProduct[];
}

export class PreviewTemplate extends React.PureComponent<RouteComponentProps<{ id: string, template: PageTemplateTypes }>, IState> {
    constructor(props: RouteComponentProps<{ id: string, template: PageTemplateTypes }>) {
        super(props);

        this.state = {
            pages: [],
            products: [],
        }
    }

    componentDidMount() {
        repository.campaign.getCampaignPages(Number(this.props.match.params.id)).then((response) => {
            const formPage = response.find(page => page.template === PageTemplateTypes.form);
            const pageWithProducts = response.find(page => page?.products && page.products.length > 0) || { products: [] };
            const products = formPage?.products ? formPage.products : pageWithProducts.products;

            this.setState({
                pages: response,
                products,
            });
        })
    }


    renderBlock = (block: ISubBlock) => {
        const key = uuidv4();
        const { content, rowParams } = block;

        const Parent = getParentRow(rowParams);

        return (
            <Parent key={key}>
                {content.map(this.renderContent)}
            </Parent>
        )
    }

    renderContent = (content: IContent) => {
        const keyContent = uuidv4();
        const { value, colParams, type, valueParams } = content;

        const Content = getContent(colParams);

        const StyleButton = getButton(valueParams);

        switch (type) {
            case TypeContent.text: {
                return (
                    <Content key={keyContent} dangerouslySetInnerHTML={{ __html: value }} />
                );
            }

            case TypeContent.media: {
                const Img = styled.img`
                width: 100%;
                object-fit: cover;
            `;

                return (
                    <Content key={keyContent}>
                        <Img src={value} />
                    </Content>
                );
            }

            case TypeContent.button: {
                return (
                    <Content key={keyContent}>
                        <StyleButton>{value}</StyleButton>
                    </Content>
                );
            }

            case TypeContent.confirm_phone: {
                return (
                    <Content key={keyContent}>
                        <PhoneField label={value} />
                    </Content>
                );
            }

            case TypeContent.confirm_email: {
                return (
                    <Content key={keyContent}>
                        <EmailField label={value} />
                    </Content>
                );
            }

            case TypeContent.popupTitle: {
                return (
                    <Content key={keyContent}>
                        <h2 className="popup__title p-0">{value}</h2>
                    </Content>
                );
            }

            case TypeContent.popupSubTitle: {
                return (
                    <Content key={keyContent}>
                        <p className="popup__text p-0">{value}</p>
                    </Content>
                );
            }

            case TypeContent.form: {
                return (
                    <Content key={keyContent}>
                        <FormFields
                            acceptLabel={value}
                            labels={{
                                firstName: valueParams?.firstName,
                                lastName: valueParams?.lastName,
                                email: valueParams?.email,
                                IBAN: valueParams?.IBAN,
                                bic: valueParams?.bic,
                            }}
                        />
                    </Content>
                );
            }

            case TypeContent.checkbox: {
                return (
                    <Content key={keyContent}>
                        <Checkbox name={valueParams?.type || keyContent} required >
                            <p dangerouslySetInnerHTML={{ __html: value }} />
                        </Checkbox>
                    </Content>
                );
            }

            case TypeContent.formCheck: {
                return (
                    <Content key={keyContent}>
                        <FormCheck label={value} />
                    </Content>
                );
            }

            case TypeContent.products: {
                return (
                    <Content key={keyContent}>
                        <Products products={this.state.products} data={{}} />
                    </Content>
                );
            }

            default: {
                return <div>what wrong?</div>
            }
        }
    }

    renderPage = (template: PageTemplateTypes) => {
        if (template === PageTemplateTypes.none) {
            return null;
        }

        const DTO: IPage | undefined = this.state.pages.find(page => page.template === template);
        if (!DTO) {
            return null;
        }

        const key = uuidv4();
        const pageInfo = Presenter.getPage(DTO);
        const GeneralPage = getGeneralPage(pageInfo.settings);
        const GeneralBody = getGeneralBody(pageInfo.settings);

        if (template === PageTemplateTypes.popup_confirm) {
            const buttons = pageInfo.data.filter((item: ISubBlock) => item.title === "2 Button for popup");
            const allWithoutButtons = pageInfo.data.filter((item: ISubBlock) => item.title !== "2 Button for popup");

            return (
                <ModalFormWizard
                    isVisible={true}
                    onClose={() => {}}
                    onSubmit={(e) => { e.preventDefault() }}
                >
                    <div className="popup__form">
                        {allWithoutButtons.map(this.renderBlock)}
                    </div>
                    <div className="popup__footer">
                        {buttons.map(this.renderBlock)}
                    </div>
                </ModalFormWizard>
            );
        }

        if (template === PageTemplateTypes.form) {
            return (
                <GeneralBody key={key + 'body'}>
                    <GeneralPage key={key} as={'form'} onSubmit={(e: FormEvent) => { e.preventDefault() }}>
                        {pageInfo.data.map(this.renderBlock)}
                    </GeneralPage>
                </GeneralBody>

            );
        }

        if (template === PageTemplateTypes.upload) {
            return (
                <GeneralBody key={key + 'body'}>
                    <GeneralPage key={key} as={'form'} onSubmit={(e: FormEvent) => { e.preventDefault() }}>
                        {pageInfo.data.map(this.renderBlock)}
                    </GeneralPage>
                </GeneralBody>
            );
        }

        return (
            <GeneralBody key={key + 'body'}>
                <GeneralPage key={key}>
                    {pageInfo.data.map(this.renderBlock)}
                </GeneralPage>
            </GeneralBody>
        );
    }

    render() {
        return (
            <div className="page">
                {this.renderPage(this.props.match.params.template)}
            </div>
        );
    }
}

export const PreviewPage = withRouter(PreviewTemplate);
