import {inject, observer} from "mobx-react";
import {IStoresWithTypes} from "./models";

export * from './NotificationStore';
export * from './models';
export * from './AccountStore';
export * from './AuthStore';
export * from './CampaignsStore';
export * from './CompanyStore';
export * from './TicketsStore';
export * from './RootStore';
export * from './UserStore';
export * from './tourStore';

export const withObserverAndInject = <TStores extends Partial<IStoresWithTypes>>(
    ...stores: Array<Partial<keyof IStoresWithTypes>>
) => {
    return <TProps extends {}>(Wrapped: React.ComponentType<TProps>) => {
        return inject(...stores)(observer(Wrapped)) as unknown as React.ComponentType<Omit<TProps, keyof TStores>>;
    };
};

export const campaignStoreEnhancer = withObserverAndInject('notificationStore', 'campaignStore', 'groupCampaignStore', 'authStore', 'tourStore');
export const oneStepStoreEnhancer = withObserverAndInject('notificationStore', 'campaignStore', 'companyStore', 'tourStore');
export const stylePageStoreEnhancer = withObserverAndInject('notificationStore', 'campaignStore', 'stylePageStore', 'tourStore');
export const modalEnhancer = withObserverAndInject('notificationStore', 'modalStore', 'stylePageStore', 'tourStore');
export const ticketEnhancer = withObserverAndInject('notificationStore', 'ticketsStore');
export const notificationEnhancer = withObserverAndInject('notificationStore');
export const authEnhancer = withObserverAndInject('authStore');
export const groupCampaignEnhancer = withObserverAndInject('groupCampaignStore', 'tourStore');
export const tourEnhancer = withObserverAndInject('tourStore');
