import React, {FC, useState} from "react";
import {SketchPicker} from 'react-color';
import {IFieldProps} from "./models";
import {Button} from "../Button";
import './color.scss';

type Color = Omit<IFieldProps, 'noAutocomplete' | 'errorMessage' | 'needFocus' | 'currency' | 'onChangeCurrency' | 'value'> & { value?: string }

export const ColorPicker: FC<Color> = (props) => {
    const { label} = props;
    const [open, setOpen] = useState(false);

    const handleRemove = () => {
        props.onChange && props.onChange("");
        setOpen(false);

    }
    const handleChange = () => {
        setOpen(false);
    }

    return (
        <div className='field'>
            {label && (<p className="field__title">{label}</p>)}
            <div className="field__input">
                <input
                    className="field__input-text input-colorpicker"
                    placeholder="Select a color"
                    onClick={() => setOpen(!open)}
                    readOnly={true}
                    value={props.value}
                />
            </div>
            <div className="dropdown dropdown-colorpicker" style={{ display: open ? 'block' : 'none'}}>
                <SketchPicker
                    color={props.value}
                    disableAlpha={true}
                    onChangeComplete={props.onChange}
                    className="color-picker"
                />
                <div className="dropdown__footer dropdown__footer_between">
                    <Button text="Set color" popup onClick={handleChange} />
                    <Button text="Remove" isWhite popup onClick={handleRemove} />
                </div>
            </div>
        </div>
    );
}