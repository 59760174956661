export const pages: { title: string, link: string }[] = [
    {
        title: 'Campaigns',
        link: '/',
    },
    {
        title: 'Reports',
        link: '/reports',
    },
    {
        title: 'Users',
        link: '/users',
    },
    {
        title: 'Сompany settings',
        link: '/company-settings',
    }
];
export const superPages: { title: string, link: string }[] = [
    {
        title: 'Dashboard',
        link: '/',
    },
];
export const DATE_VIEW_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_TIME_WITH_SECONDS_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_VIEW_FORMAT = 'DD.MM.YYYY, HH:mm';
export const DATE_TIME_FOR_NAME = 'DD/MM/YYYY-HH/mm';
export const DATE_TIME_FOR_DTO = 'YYYY-MM-DDTHH:mm:ss.000000Z';

console.log(process.env.REACT_APP_API_URL);

export const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL || 'https://api.staging.valyu.app', //'https://api.valyu.app',
    headers: {
        'Content-Type': 'application/json',
    },
};

