import React, {FC, useEffect, useState} from "react";
import {DateRange} from 'moment-range';
import moment from 'moment-timezone';
import DateRangePicker, {OnSelectCallbackParam} from "react-daterange-picker";
import 'react-daterange-picker/dist/css/react-calendar.css'

import {Icon, Icons} from "../Icon";

import {IDateFieldProps, IDateRangePickerProps} from "./models";
import './styles.scss'

export const DateRangePickerComponent: FC<IDateRangePickerProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<any>(null);
    useEffect(() => {
        const start = props.start.value;
        const end = props.end.value;
        start && end && setValue({ start, end })
    }, [])

    const onSelect = (param: OnSelectCallbackParam) => {
        setValue(param);
        setOpen(false);
        props.onChange && props.onChange(param);
    }

    const handleClose = () => {
        setOpen(false);
        props.onChange && props.onChange(value);
    }

    const renderField = ({label, value, placeholder}: IDateFieldProps) => {
        return (
            <div className="grid__col">
                <div className="field">
                    <p className="field__title">{label}</p>
                    <input
                        className="field__input-text input-text"
                        type="text"
                        readOnly={true}
                        placeholder={placeholder}
                        onFocus={() => setOpen(true)}
                        autoComplete="off"
                        value={value? moment(value).format('DD/MM/yyyy') : "" }
                    />
                    <Icon icon={Icons.calendar} width={19} height={19} />
                </div>
            </div>
        );
    }

    return (
        <div className="grid date-range" data-tour={props.dataTour}>
            {renderField({...props.start})}
            {renderField({...props.end})}

            <div className={`ui-datepicker__overlay`} style={{ display : open ? "block" : "none"}} onClick={handleClose} />
            <div className={`ui-datepicker-multi ui-datepicker ${open ? 'fade-in' : 'fade-out' }`}>
                <DateRangePicker
                    onSelect={onSelect}
                    firstOfWeek={1}
                    value={value && new DateRange([value.start, value.end])}
                    numberOfCalendars={2}
                />
            </div>

        </div>
    );
}