import {campaignStoreEnhancer, IAuthStore, IGroupCampaignStore, INotificationStore, ModalMode} from "../../store";
import React, {useEffect} from "react";
import {ModalForm} from "../../components/modals/modal-form";

import {Field, Select} from "../../components/Field";
import {repository} from "../../shared/repositories";
import {ICampaignStore} from "../Campaigns/models";

export const ModalCreateGroup = campaignStoreEnhancer(( { groupCampaignStore, campaignStore, authStore, notificationStore }: IGroupCampaignStore & ICampaignStore & IAuthStore & INotificationStore) => {
    const { visibleModal, formCreate, formMove, hideModal, mode, groupList, loadGroupList, campaignId, groupId } = groupCampaignStore;
    const { company_id, fetchCampaigns, meta: { current_page, per_page}, statusFilter } = campaignStore;
    const { user } = authStore;
    const { success } = notificationStore;

    useEffect(() => {
        loadGroupList();

    }, []);

    const handleClose = () => {
        hideModal();
    }

    const handleSubmit = async () => {
        switch (mode) {
            case ModalMode.create: {
                const formValidate = await formCreate.validate();

                if (formValidate.hasError) {
                    return;
                }

                const formData = new FormData();
                formData.append('name', formCreate.$.name._value);
                formData.append('budget', formCreate.$.budget._value);
                formData.append('company_id', `${company_id}`);
                formData.append('user_id', `${user.id}`);

                repository.groupCampaign.createNewGroup(formData).then(response => {
                    if (response.data) {
                        fetchCampaigns(per_page, current_page, statusFilter.value);
                        loadGroupList();
                        hideModal();
                    }
                });

                break;
            }
            case ModalMode.update: {
                const formValidate = await formCreate.validate();

                if (formValidate.hasError) {
                    return;
                }

                const formData = new FormData();
                formData.append('name', formCreate.$.name._value);
                formData.append('budget', formCreate.$.budget._value);
                formData.append('company_id', `${company_id}`);
                formData.append('user_id', `${user.id}`);

                if (groupId) {
                    repository.groupCampaign.updateGroup(groupId, formData).then(response => {
                        if (response.data) {
                            fetchCampaigns(per_page, current_page, statusFilter.value);
                            loadGroupList();
                            hideModal();
                        }
                    });
                }

                break;
            }
            case ModalMode.move: {
                const formValidate = await formMove.validate();

                if (formValidate.hasError) {
                    return;
                }

                const groupId = formMove.$.group._value || 0;
                repository.groupCampaign.moveToGroup(campaignId, groupId).then(response => {
                    if (response.data) {
                        fetchCampaigns(per_page, current_page, statusFilter.value);
                        success('Campaign moved to group');
                        loadGroupList();
                        hideModal();
                    }
                });
                break;
            }
        }

    }

    const renderContent = () => {
        if (mode === ModalMode.move) {
            return (
                <Select
                    placeholder='Select'
                    label="Campaign group"
                    value={formMove.$.group._value}
                    list={groupList}
                    onChange={(value) => formMove.$.group.onChange(value)}
                    errorMessage={formMove.$.group.error}
                />
            );
        }

        return (
            <>
                <Field
                    needFocus={visibleModal}
                    label='Group name'
                    placeholder='Enter name'
                    name='name'
                    noAutocomplete
                    value={formCreate.$.name._value}
                    errorMessage={formCreate.$.name.error}
                    onChange={(value: any) => {formCreate.$.name.onChange(value)}}
                />
                <Field
                    label="Budget"
                    name="budget"
                    type="number"
                    min={0}
                    noAutocomplete
                    value={formCreate.$.budget._value}
                    onChange={(val) => {formCreate.$.budget.onChange(val)
                    }}
                    placeholder="Enter budget"
                    errorMessage={formCreate.$.budget.error}
                    prefix="€"
                />
            </>
        );
    }

    const getTitle = () => {
        if (mode === ModalMode.create) {
            return 'Create new group'
        }

        if (mode === ModalMode.move) {
            return 'Move to a group'
        }

        return 'Update group'
    }


    return (
        <ModalForm
            isVisible={visibleModal}
            title={getTitle()}
            onClose={handleClose}
            formConfig={{
                cancelText: 'Close',
                submitText: 'Apply',
                onSubmit: handleSubmit,
            }}
        >
            {renderContent()}
        </ModalForm>
    );
})