import * as React from 'react';
import {inject, observer} from "mobx-react";
import {Campaigns} from "../Campaigns/Campaigns";
import {SuperDashboard} from "./super-dashboard";
import {HomeProps} from "./models";

@inject('authStore')
@observer
export class Home extends React.PureComponent<HomeProps> {
    private userIsSuperAdmin: boolean;

    constructor(props: HomeProps) {
        super(props);
        this.userIsSuperAdmin = props.authStore.userIsSuperAdmin;
    }

    render() {
        if (this.userIsSuperAdmin) {
            return (
                <SuperDashboard />
            );
        }

        return (
            <Campaigns />
        );
    }
}