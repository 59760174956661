import React, { FC } from "react";
import classNames from "classnames";
import {Icon, Icons} from "../Icon";
import {ILibrary} from "./models";
import './styles.scss';

export const Library: FC<ILibrary> = (props) => {
    const classLibrary = classNames({
        'library': true,
        'open': props.open,
    });

    return (
        <div className={classLibrary}>
            <header className="header">
                <div className="header__container">
                    <div className="header__row">
                        <div className="header__col header__col_first">
                            <div className="header__close" onClick={props.onClose}>
                                <Icon icon={Icons.close} width={11} height={11} />
                            </div>
                        </div>
                        <div className="header__separator header__separator_sm header__separator_right" />
                        <div className="header__col header__col_left">
                            <h1 className="header__name header__name--grey">{props.title}</h1>
                        </div>
                    </div>
                </div>
            </header>
            <main className="page__two-cols">
                <div className="layout layout_demographics">
                    <div className="layout__main">
                        <div className="simplebar-wrapper">
                            <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer" />
                            </div>
                            <div className="simplebar-mask library__body">
                                <div className="simplebar-offset">
                                    <div className="simplebar-content">
                                        <section className="section">
                                            <div className="section__grid">
                                                {props.children}
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className="simplebar-placeholder" />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}