import React, {FC} from "react";
import {IPage, ITemplate} from "../models";
import './styles.scss';
import {IContent, ISubBlock, TypeContent} from "./models";
import {v4 as uuidv4} from "uuid";
import {Presenter} from "../../preview-page/presenter";
import {getContent, getGeneralPage, getParentRow} from "../../../components/styledComponents";
import {Button} from "../../../components/Button";
import {mapTemplatePageToWelcomePage} from "../../../shared/mappers";
import {Icon, Icons} from "../../../components/Icon";

export const TemplateItem: FC<ITemplate & {onSelect(): void; onDelete(): void}> = (props) => {
    const DTO: IPage | undefined = mapTemplatePageToWelcomePage(props);
    console.log(DTO);
    console.log(props.options.name);
    const classTemplate = props.isCurrent
        ? 'template template--active'
        : 'template'

    if (!DTO.options) {
        return (
            <div className="grid__item js-grid-item col-12 col-lg-4 col-xl-3 col-xxl-2dot4">
                <div className={classTemplate}>
                    <div className="template__page" />
                    <div className="template__title">{props.options.name}</div>
                    <div className="template__overlay">
                        <Button text="Select template" isWhite onClick={props.onSelect} />
                    </div>
                </div>
            </div>
        );
    }



    const renderBlock = (block: ISubBlock) => {
        const key = uuidv4();
        const { content, rowParams } = block;

        const Parent = getParentRow(rowParams);

        return (
            <Parent key={key}>
                {content.map(renderContent)}
            </Parent>
        )
    }

    const renderContent = (content: IContent) => {
        const keyContent = uuidv4();
        const { value, colParams, type } = content;

        const Content = getContent(colParams);

        switch (type) {
            case TypeContent.text: {
                return (
                    <Content key={keyContent} dangerouslySetInnerHTML={{ __html: value }} />
                );
            }

            default: {
                return null;
            }
        }
    }

    const renderWelcomePage = () => {
        if (!DTO?.options) {
            return null;
        }

        const pageInfo = Presenter.getPage(DTO);
        const GeneralPage = getGeneralPage(pageInfo.settings);

        return (
            <GeneralPage>
                {pageInfo.data.map(renderBlock)}
            </GeneralPage>
        );
    }

    return (
        <div className="grid__item js-grid-item col-12 col-lg-4 col-xl-3 col-xxl-2dot4">
            <div className={classTemplate} data-tour={props.dataTour}>
                <div className="template__page">
                    {renderWelcomePage()}
                </div>
                <div className="template__title">{props.options.name}</div>
                <div className="template__overlay">
                    <div className="template__clear" onClick={props.onDelete}>
                        <Icon icon={Icons.close_circle} width={18} height={18} />
                    </div>
                    <Button text="Select template" isWhite onClick={props.onSelect} />
                </div>
            </div>
        </div>
    );
}