import {IPricing} from "./models";

export const pricingList: IPricing[] = [
    {
        period: 1,
        prices: [
            { title: 'Basic', price: 159, canStarted: true, options: ['1-10 users', '20 campaigns maximum', '20+ colour combinations', 'Online chat support', 'Basic reporting'], freeTrial: 14 },
            { title: 'Advanced', price: 249, canStarted: true, options: ['1-20 users', '<b>Unlimited</b> campaigns', 'Presets & templates', '24h ticket support', 'Advanced reporting'] },
            { title: 'Enterprise', buttonText: 'Contact Sales', price: undefined, canStarted: false, options: ['<b>Unlimited</b> users', '<b>Unlimited</b> campaigns', 'Presets & templates', 'Direct chat', '<b>Consultancy</b> support', 'Advanced reporting'] },
        ],
    },
    {
        period: 3,
        prices: [
            { title: 'Basic', price: 449, sales: 5, canStarted: true, options: ['1-10 users', '20 campaigns maximum', '20+ colour combinations', 'Online chat support', 'Basic reporting'] },
            { title: 'Advanced', price: 710, sales: 5, canStarted: true, options: ['1-20 users', '<b>Unlimited</b> campaigns', 'Presets & templates', '24h ticket support', 'Advanced reporting'] },
            { title: 'Enterprise', buttonText: 'Request Quote', price: undefined, canStarted: false, options: ['<b>Unlimited</b> users', '<b>Unlimited</b> campaigns', 'Presets & templates', 'Direct chat', '<b>Consultancy</b> support', 'Advanced reporting'] },
        ],
    },
    {
        period: 12,
        prices: [
            { title: 'Basic', price: 1717, sales: 10, canStarted: true, options: ['1-10 users', '20 campaigns maximum', '20+ colour combinations', 'Online chat support', 'Basic reporting'] },
            { title: 'Advanced', price: 2689, sales: 10, canStarted: true, options: ['1-20 users', '<b>Unlimited</b> campaigns', 'Presets & templates', '24h ticket support', 'Advanced reporting'] },
            { title: 'Enterprise', buttonText: 'Contact us', price: undefined, canStarted: false, options: ['<b>Unlimited</b> users', '<b>Unlimited</b> campaigns', 'Presets & templates', 'Direct chat', '<b>Consultancy</b> support', 'Advanced reporting'] },
        ],
    },
];
