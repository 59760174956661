import {RouteComponentProps} from "react-router-dom";
import React from "react";
import Header from "../../components/Header/Header";
import {repository} from "../../shared/repositories";
import '../campaign-groups/styles.scss';
import {useCharts} from "../../hooks";
import Charts from "../../components/charts";
import {Period} from "../constants";

export const CampaignReport = (props: RouteComponentProps<{ id: string }>) => {
    const { id } = props.match.params;
    const data = useCharts(Number(id), repository.campaign);

    const handleChangePeriod = (value: Period) => {
        data.setPeriod(value);
    }

    return (
        <>
            <Header />
            <main className="page__main">
                <Charts
                    period={data.period}
                    dataLineChart={data.dataLineChart}
                    dataBarChart1={data.dataBarChart1}
                    dataBarChart2={data.dataBarChart2}
                    statistic={data.statistic}
                    onChangePeriod={handleChangePeriod}
                />
            </main>
        </>
    );
}