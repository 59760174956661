import {action, makeObservable, observable} from "mobx";

import {repository} from "../shared/repositories";
import {Meta, User} from "../shared/models";

export class UserStore {
    @observable users: User[] = [];
    @observable meta?: Meta;

    constructor() {
        makeObservable(this);
    }


    @action
    async fetchUsers(size: number, page: number) {
        const response = await repository.user.getUsers(size, page);

        this.users = response?.data?.data;
        this.meta = response?.data?.meta;
    }
}
