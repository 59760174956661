export enum ButtonType {
    add,
    next,
    edit,
    save,
    new,
}

export interface IButtonProps {
    text: string;
    /** Использовать как ссылку */
    to?: string;
    targetBlank?: boolean;
    /** во всю ширину */
    full?: boolean;
    /** белая */
    isWhite?: boolean;
    isGray?: boolean;
    isDark?: boolean;
    isOrange?: boolean;
    isSmall?: boolean;
    isSuccess?: boolean;
    isAlert?: boolean;
    /** отрабатывает отправку формы */
    isSubmit?: boolean;
    isCancel?: boolean;
    /** блокирована */
    disabled?: boolean;
    /** внутри попапа */
    popup?: boolean;
    /** для вывода иконки */
    type?: ButtonType;
    dataTour?: string;
    onClick?(): void;
}
