import {action, makeObservable, observable} from "mobx";
import {FieldState, FormState} from "formstate-x/lib";
import {
    Action,
    ColParams,
    IColParams,
    IModalProps,
    IValParams,
    PageTemplateTypes,
    TypeContent,
    ValParams
} from "../pages/Campaigns/style-step/models";
import {DEFAULT_COL_PARAMS, DEFAULT_VAL_PARAMS} from "../pages/Campaigns/style-step/constants";
import {StylePageStore} from "./StylePageStore";

export class ModalStore {
    @observable open: boolean;
    @observable loading: boolean;
    @observable content_id: string;
    @observable accordion_id: string;
    @observable type: TypeContent;
    @observable content: string;
    @observable colParams: FormState<ColParams>;
    @observable valParams: FormState<ValParams>;
    @observable styleStore: StylePageStore;


    constructor(styleStore: StylePageStore) {
        this.styleStore = styleStore;
        this.open = false;
        this.loading = false;
        this.content_id = '';
        this.accordion_id = ''
        this.valParams = this.initialValParams(DEFAULT_VAL_PARAMS);
        this.type = TypeContent.text;
        this.content = '';
        this.colParams = this.initialColParams(DEFAULT_COL_PARAMS);

        makeObservable(this);
    }

    initialValParams = (params: IValParams) => {
        return new FormState({
            type: new FieldState<string | undefined>(params.type),
            action: new FieldState<Action | undefined>(params.action),
            link: new FieldState<string | undefined>(params.link),
            page: new FieldState<PageTemplateTypes | undefined>(params.page),
            bgColor: new FieldState<string | undefined>(params.bgColor),
            color: new FieldState<string | undefined>(params.color),
            firstName: new FieldState<string | undefined>(params.firstName),
            lastName: new FieldState<string | undefined>(params.lastName),
            email: new FieldState<string | undefined>(params.email),
            IBAN: new FieldState<string | undefined>(params.IBAN),
            bic: new FieldState<string | undefined>(params.bic),
        })
    }

    initialColParams = (params: IColParams) => {
        return new FormState({
            mobileWidth: new FieldState<string>(params.mobileWidth),
            smallWidth: new FieldState<string>(params.smallWidth),
            mediumWidth: new FieldState<string>(params.mediumWidth),
            largeWidth: new FieldState<string>(params.largeWidth),
            customMobileWidth: new FieldState<number | undefined>(params.customMobileWidth),
            customSmallWidth: new FieldState<number | undefined>(params.customSmallWidth),
            customMediumWidth: new FieldState<number | undefined>(params.customMediumWidth),
            customLargeWidth: new FieldState<number | undefined>(params.customLargeWidth),
            maxWidth: new FieldState<number | undefined>(params.maxWidth),
            maxHeight: new FieldState<number | undefined>(params.maxHeight),
            paddingTop: new FieldState<number | undefined>(params.paddingTop),
            paddingLeft: new FieldState<number | undefined>(params.paddingLeft),
            paddingRight: new FieldState<number | undefined>(params.paddingRight),
            paddingBottom: new FieldState<number | undefined>(params.paddingBottom),
            marginTop: new FieldState<number | undefined>(params.marginTop),
            marginLeft: new FieldState<number | undefined>(params.marginLeft),
            marginRight: new FieldState<number | undefined>(params.marginRight),
            marginBottom: new FieldState<number | undefined>(params.marginBottom),
            colOptions: new FieldState<string[]>(params.colOptions),
        })
    }

    @action
    openModal = (params: IModalProps) => {
        this.loading = true;
        this.content = params.content;
        this.content_id = params.id;
        this.type = params.type;
        this.accordion_id = params.parent_id;
        this.colParams = this.initialColParams(params.colParams);
        this.valParams = this.initialValParams(params.valParams);
        this.open = true;
        setTimeout(() => {
            this.loading = false;
        }, 100);
    }

    @action
    changeContent = (content: string) => {
        this.content = content;
    }

    @action
    closeModal = () => {
        this.open = false;
    }

    @action
    clearData = () => {
        this.content = '';
        this.colParams  = this.initialColParams(DEFAULT_COL_PARAMS);
    }

    @action
    saveData = () => {
        this.styleStore.saveModalData(
            this.accordion_id,
            this.content_id,
            this.content,
            this.colParams.value,
            [
                TypeContent.button,
                TypeContent.checkbox,
                TypeContent.form,
            ].includes(this.type) ? this.valParams.value : undefined
        );
    }
}
