import {Component} from "react";
import {inject, observer} from "mobx-react";

import Header from "../../components/Header/Header";
import {UserStore} from "../../store";
import {Paginations} from "../../components/Paginations";
import {Meta, User} from "../../shared/models";
import {List, IListProps} from "../../components/List";

import {UserItem} from "./UserItem";
import {AddInvite} from "./AddInvite";
import './styles.scss';

@inject('userStore')
@observer
export default class Users extends Component<{ userStore: UserStore }> {
    private userStore: UserStore;
    private readonly config: IListProps<User>['config'];

    constructor(props: Readonly<{ userStore: UserStore }>) {
        super(props);
        this.userStore = props.userStore
        this.config = {
            sizes: ['5%', '30%', '30%', '20%', '5%'],
            withSort: true,
            headTitles: ['id', 'Email', 'Name', 'Role', 'Status'],
        }

        this.state = {
            size: 5,
            currentPage: 1,
            pagesCount: 1
        }
    }

    componentDidMount() {
        this.userStore.fetchUsers(10, 1);
    }

    render() {
        return (
            <>
                <Header/>
                <main className="page__main">
                    <div className="page__container v-container">
                        <section className="section">
                            <div className="section__tools">
                                <div className="section__tools-row flex-wrap">
                                    <div className="section__tools-col col">
                                        <h2 className="section__title">User management</h2>
                                    </div>
                                    <div className="section__tools-col col-12 col-lg-auto">
                                        <AddInvite />
                                    </div>
                                </div>
                            </div>
                            <List config={this.config} data={this.userStore.users} component={UserItem} />
                        </section>
                        <Paginations
                            meta={this.userStore.meta || {} as Meta}
                            onChange={(size: number, page: number) => this.userStore.fetchUsers(size, page)}
                        />
                    </div>
                </main>
            </>
        );
    }

}
