import httpClient from "../utils/http/httpClient";
import {AxiosResponse} from "axios";
import {Company, CompanyDocument, ICampaignsApi, ICompanyDetail, ILineChartDTO, Response, User} from "./models";
import {CampaignStatuses, ICampaign, ICashback, IDetail, IPage, IProductDTO} from "../pages/Campaigns/models";
import {ITicket} from "../pages/ticket-templates/models";
import {TList} from "../pages/Campaigns/style-step/models";
import {IFilterParams} from "../pages/super-dashboard/models";
import {IPaymentDTO} from "../pages/CompanySettings/models";
import {IChartRepository, IDetailsByCampaigns, IDetailsByTrack} from "../components/charts/models";

class AuthApi {
    api = {
        BASE: () => '/api/auth',
        LOGIN: () => `${this.api.BASE()}/login`,
        REFRESH: () => `${this.api.BASE()}/refresh`,
        ME: () => `${this.api.BASE()}/me`,
        LOGOUT: () => `${this.api.BASE()}/logout`,
        REGISTER: () => `${this.api.BASE()}/register`,
        UPDATE: () => `${this.api.BASE()}/update?_method=PUT`,
        RESET_PASSWORD_BASE: () => '/api/reset-password',
        FORGOT_PASSWORD: (email: string) => `${this.api.RESET_PASSWORD_BASE()}/send-link?email=${email}`,
        CHECK_EMAIL: (token: string, email: string) => `${this.api.RESET_PASSWORD_BASE()}/check?token=${token}&email=${email}`,
        RESET_PASSWORD: () => `${this.api.RESET_PASSWORD_BASE()}/reset`,
    }

    login(data: { email: string; password: string }) {
        return httpClient.post(this.api.LOGIN(), data);
    }

    forgotPassword(email: string) {
        return httpClient.post(this.api.FORGOT_PASSWORD(email));
    }

    checkEmail(token: string, email: string) {
        return httpClient.post(this.api.CHECK_EMAIL(token, email));
    }

    resetPassword(data: FormData) {
        return httpClient.post(this.api.RESET_PASSWORD(), data);
    }

    register(data: FormData) {
        return httpClient.post(this.api.REGISTER(), data, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}

class UsersApi {
    api = {
        BASE: () => '/api/users',
        GET_LIST: (size?: number, page?: number) => `${this.api.BASE()}?per_page=${size ? size : 10}&page=${page ? page : 1}`,
        USER: (id: number) => `${this.api.BASE()}/${id}`,
        TOGGLE_ACTIVATED: (userId: number) => `${this.api.BASE()}/change-status/${userId}`,
        INVITE: () => `${this.api.BASE()}/invite`,

        CASHBACKS: (page: number, size: number, filterParams: IFilterParams) => {
            const company = filterParams.company ? `&company=${filterParams.company}` : '';
            const campaign = filterParams.campaign ? `&campaign=${filterParams.campaign}` : '';
            const period = filterParams.period ? `&from=${filterParams.period}` : '';
            const status = filterParams.status ? `&status=${filterParams.status}` : '';

            return `/api/cashbacks?per_page=${size}&page=${page}${company}${campaign}${period}${status}`;
        },
        EXPORT: (filterParams: IFilterParams) => {
            const company = filterParams.company ? `?company=${filterParams.company}` : '';

            const prefixCampaign = filterParams.company ? '&' : '?';
            const campaign = filterParams.campaign ? `${prefixCampaign}campaign=${filterParams.campaign}` : '';

            const prefixPeriod = filterParams.company || filterParams.campaign ? '&' : '?';
            const period = filterParams.period ? `${prefixPeriod}from=${filterParams.period}` : '';

            const prefixStatus = filterParams.company || filterParams.campaign || filterParams.period ? '&' : '?';
            const status = filterParams.status ? `${prefixStatus}status=${filterParams.status}` : '';

            return `/api/cashbacks/sepa${company}${campaign}${period}${status}`;
        }
    }

    getUsers(size?: number, page?: number) {
        return httpClient.get<Response<User[]>>(this.api.GET_LIST(size, page))
    }

    updateUser(id: number, data: FormData) {
        return httpClient.post(this.api.USER(id), data, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    deleteUser(id: number) {
        return httpClient.delete(this.api.USER(id));
    }

    invite(email: string): Promise<AxiosResponse<{}>> {
        return httpClient.post(this.api.INVITE(), {email});
    }

    toggleActivate(id: number) {
        return httpClient.post(this.api.TOGGLE_ACTIVATED(id));
    }

    getListCashbacks(page: number, size: number, filterParams: IFilterParams) {
        return httpClient.get(this.api.CASHBACKS(page, size, filterParams));
    }

    export(filterParams: IFilterParams) {
        return httpClient.post(this.api.EXPORT(filterParams));
    }
}

class CampaignsApi implements ICampaignsApi {
    api = {
        BASE: () => '/api/campaigns',
        CAMPAIGN: (id: number) => `${this.api.BASE()}/${id}`,
        PAGE_CREATE: (campaign_id: number) => `${this.api.CAMPAIGN(campaign_id)}/pages`,
        PAGE_EDIT: (page_id: number) => `/api/pages/${page_id}?_method=PUT`,
        PRODUCTS: (camapaign_id: number) => `${this.api.CAMPAIGN(camapaign_id)}/products`,
        GET_LIST: (idCompany: number, size?: number, page?: number, status?: number) => `${this.api.BASE()}/${idCompany}?per_page=${size ? size : 10}&page=${page ? page : 1}${status && status > 0 ? '&status=' + status : ''}`,
        GET_ITEM: (idCampaign: number) => `${this.api.BASE()}/${idCampaign}/show`,
        CHANGE_STATUS: (id: number, status: CampaignStatuses) => `${this.api.BASE()}/change-status/${id}?status=${status}`,

        INFO: (id: number) => `${this.api.CAMPAIGN(id)}/details`,
        CASHBACK: (campaignId: number) => `${this.api.BASE()}/${campaignId}/cashbacks`,
        CASHBACK_EXPORT: (campaignId: number) => `${this.api.CASHBACK(campaignId)}/5/export`,
        CASHBACK_LIST: (campaignId: number, size?: number, page?: number, search?: string) => {
            if (search) {
                return `${this.api.CASHBACK(campaignId)}?per_page=${size ? size : 10}&page=${page ? page : 1}&search=${search}`;
            }

            return `${this.api.CASHBACK(campaignId)}?per_page=${size ? size : 10}&page=${page ? page : 1}`;
        },
        CASHBACK_ACCEPT: (campaignId: number, cashbackId: number) => `${this.api.CASHBACK(campaignId)}/${cashbackId}/accept`,
        CASHBACK_DECLINE: (campaignId: number, cashbackId: number) => `${this.api.CASHBACK(campaignId)}/${cashbackId}/decline`,

        PAGE_TEMPLATE: (companyId: number) => `/api/companies/${companyId}/page-templates`,
        LIST_PAGE_TEMPLATES: (companyId: number, size?: number, page?: number) => `${this.api.PAGE_TEMPLATE(companyId)}?per_page=${size ? size : 10}&page=${page ? page : 1}`,
        DELETE_PAGE_TEMPLATE: (templateId: number) => `/api/page-templates/${templateId}`,
        UPDATE_PAGE_TEMPLATE: (templateId: number) => `/api/page-templates/${templateId}?_method=PUT`,

        CONFIRM_EMAIL: () => '/api/confirm/email',
        UPDATE_EMAIL: (email: string) => `${this.api.CONFIRM_EMAIL()}/${email}?_method=PUT`,

        CONFIRM_PHONE: () => '/api/confirm/phone',
        UPDATE_PHONE: (phone: string) => `${this.api.CONFIRM_PHONE()}/${phone}?_method=PUT`,

        LINE_CHART: (id: number, date_from: string, date_to: string) => `${this.api.BASE()}/${id}/statistic/by-days?start_at=${date_from}&end_at=${date_to}`,
        BAR_CHART_1: (id: number, date_from: string, date_to: string) => `${this.api.BASE()}/${id}/statistic/by-campaigns?start_at=${date_from}&end_at=${date_to}`,
        BAR_CHART_2: (id: number, date_from: string, date_to: string) => `${this.api.BASE()}/${id}/statistic/by-track-code?start_at=${date_from}&end_at=${date_to}`,
        STATISTIC: (id: number, date_from: string, date_to: string) => `${this.api.BASE()}/${id}/statistic/details?start_at=${date_from}&end_at=${date_to}`,
        COPY: (id: number) => `${this.api.BASE()}/${id}/copy`,
        SCREEN: (id: number) => `${this.api.BASE()}/${id}/screenshot`,
    }

    getCampaignsList(id: number, size: number, page: number, status?: number) {
        return httpClient.get<Response<ICampaign[]>>(this.api.GET_LIST(id, size, page, status))
    }

    getCampaignItem(id: number) {
        return httpClient.get(this.api.GET_ITEM(id));
    }

    getCashbackList(campaignId: number, size: number, page: number, search?: string) {
        return httpClient.get<Response<ICashback[]>>(this.api.CASHBACK_LIST(campaignId, size, page, search))
    }

    exportCashback(campaignId: number) {
        return httpClient.get(this.api.CASHBACK_EXPORT(campaignId), {responseType: 'blob'});
    }

    getScreenshot(campaignId: number) {
        return httpClient.get(this.api.SCREEN(campaignId));
    }

    createNewCampaign(company_id: number, data: FormData): Promise<AxiosResponse<{data: ICampaign}>> {
        return httpClient.post(this.api.CAMPAIGN(company_id), data, {
            'headers': {
                'Content-Type': 'application/json'
            }
        })

    }

    updateCampaign(campaignId: number, data: FormData) {
        return httpClient.post<Response<ICampaign>>(`${this.api.CAMPAIGN(campaignId)}?_method=PUT`, data, {
            'headers': {
                'Content-Type': 'application/json'
            }
        })
    }

    deleteCampaign(campaignId: number): Promise<Response<{ status: boolean }>> {
        return httpClient.delete(this.api.CAMPAIGN(campaignId));
    }

    changeStatusCampaignAsDraft(id: number) {
        return httpClient.post(this.api.CHANGE_STATUS(id, CampaignStatuses.Draft));
    }

    changeStatusCampaignAsActive(id: number) {
        return httpClient.post(this.api.CHANGE_STATUS(id, CampaignStatuses.Active));
    }

    changeStatusCampaignAsArchive(id: number) {
        return httpClient.post(this.api.CHANGE_STATUS(id, CampaignStatuses.Archive));
    }

    getCampaignPages(campaignId: number): Promise<IPage[]> {
        return httpClient.get(this.api.PAGE_CREATE(campaignId)).then(response => (response.data.data));
    }

    createNewPageForCampaign(campaignId: number, data: FormData) {
        return httpClient.post(this.api.PAGE_CREATE(campaignId), data);
    }

    updatePageForCampaign(pageId: number, data: FormData) {
        return httpClient.post(this.api.PAGE_EDIT(pageId), data);
    }

    createCheck(data: FormData, track_code?: string) {
        const trackCode = track_code ? `?track_code=${track_code}` : '';
        return httpClient.post(`/api/check${trackCode}`, data, {
            'headers': {
                'Content-Type': 'application/json'
            }
        });
    }

    showCheck(id: number) {
        return httpClient.get(`/api/check/${id}`);
    }

    getCampaignDetails = (id: number): Promise<AxiosResponse<{data: IDetail}>> => {
        return httpClient.get(this.api.INFO(id));
    }

    checkAccept(campaignId: number, cashbackId: number) {
        return httpClient.post(this.api.CASHBACK_ACCEPT(campaignId, cashbackId));
    }

    checkDecline(campaignId: number, cashbackId: number) {
        return httpClient.post(this.api.CASHBACK_DECLINE(campaignId, cashbackId));
    }

    updateProducts(id: number, data: FormData): Promise<AxiosResponse<{data: IProductDTO[]}>> {
        return httpClient.post(this.api.PRODUCTS(id), data, {
            'headers': {
                'Content-Type': 'application/json'
            }
        })
    }

    createPageTemplate(companyId: number, data: FormData) {
        return httpClient.post(this.api.PAGE_TEMPLATE(companyId), data);
    }

    getListPageTemplates(companyId: number, size?: number, page?: number) {
        return httpClient.get(this.api.LIST_PAGE_TEMPLATES(companyId, size, page));
    }

    updatePageTemplate(templateId: number, data: FormData) {
        return httpClient.post(this.api.UPDATE_PAGE_TEMPLATE(templateId), data);
    }

    deletePageTemplate(templateId: number) {
        return httpClient.delete(this.api.DELETE_PAGE_TEMPLATE(templateId));
    }

    createConfirmEmail(email: string) {
        const data = new FormData();
        data.append('email', email);
        return httpClient.post(this.api.CONFIRM_EMAIL(), data);
    }

    updateConfirmEmail(email: string, code: string) {
        const data = new FormData();
        data.append('token', code);
        return httpClient.post(this.api.UPDATE_EMAIL(email), data);
    }

    createConfirmPhone(phone: string) {
        const data = new FormData();
        data.append('phone', phone);
        return httpClient.post(this.api.CONFIRM_PHONE(), data);
    }

    updateConfirmPhone(phone: string, code: string) {
        const data = new FormData();
        data.append('token', code);
        return httpClient.post(this.api.UPDATE_PHONE(phone), data);
    }

    getLineChart(id_group: number, date_from: string, date_to: string): Promise<ILineChartDTO> {
        return httpClient.get(this.api.LINE_CHART(id_group, date_from, date_to)).then(response => (response?.data?.data));
    }

    getBarChartByCampaigns(id_group: number, date_from: string, date_to: string): Promise<IDetailsByCampaigns[]> {
        return httpClient.get(this.api.BAR_CHART_1(id_group, date_from, date_to)).then(response => (response?.data?.data));
    }

    getBarChartByTrek(id_group: number, date_from: string, date_to: string): Promise<IDetailsByTrack[]> {
        return httpClient.get(this.api.BAR_CHART_2(id_group, date_from, date_to)).then(response => (response?.data?.data));
    }

    getStatistics(id_group: number, date_from: string, date_to: string) {
        return httpClient.get(this.api.STATISTIC(id_group, date_from, date_to)).then(response => (response?.data?.data));
    }

    createDuplicate(id: number) {
        return httpClient.get(this.api.COPY(id));
    }
}

class CompanyApi {
    api = {
        BASE: () => '/api/companies',
        BASE_DOC: () => `${this.api.BASE()}/document`,
        COMPANY: (id: number) => `${this.api.BASE()}/${id}`,
        DOCUMENT: (id: number) => `${this.api.BASE_DOC()}/${id}?_method=PUT`,
        DETAILS: (id: number) => `${this.api.COMPANY(id)}/details`,

    }

    getListCompanies() {
        return httpClient.get(this.api.BASE());
    }

    getCurrentCompany(id: number) {
        return httpClient.get<Response<Company>>(this.api.COMPANY(id));
    }

    updateCurrentCompany(id: number, data: any) {
        return httpClient.put<Response<Company>>(this.api.COMPANY(id), data)
    }

    getDocument(id: number) {
        return httpClient.get<Response<CompanyDocument>>(this.api.DOCUMENT(id));
    }

    updateDocument(id: number, data: FormData) {
        return httpClient.post<Response<CompanyDocument>>(this.api.DOCUMENT(id), data, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    createDocument(data: FormData) {
        return httpClient.post<Response<CompanyDocument>>(this.api.BASE_DOC(), data, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getDetailsForCompany(companyId: number) {
        return httpClient.get<Response<ICompanyDetail>>(this.api.DETAILS(companyId));
    }
}

class TicketsApi {
    api = {
        BASE: () => '/api/tickets',
        GET_LIST: (size?: number, page?: number) => `${this.api.BASE()}?per_page=${size ? size : 10}&page=${page ? page : 1}`,
        UPDATE: (id: number) => `${this.api.BASE()}/${id}?_method=PUT`,
        TOGGLE_ACTIVATED: (id: number) => `${this.api.BASE()}/change-status/${id}`,
        // ---- ticket templates
        TICKET_TEPLATES: () => `/api/ticket-templates`,
        DELETE_TICKET_TEPLATE: (id: number) => `${this.api.TICKET_TEPLATES()}/${id}`,
        GET_TICKET_TEPLATES_LIST: (size?: number, page?: number) => `${this.api.TICKET_TEPLATES()}?per_page=${size ? size : 10}&page=${page ? page : 1}`,
        GET_FORMATS: () => `/api/ticket-templates/date-formats`,
    }

    getTickets(size?: number, page?: number) {
        return httpClient.get<Response<ITicket[]>>(this.api.GET_LIST(size, page))
    }

    createTicket(data: any) {
        return httpClient.post<Response<any>>(this.api.BASE(), data)
    }

    updateTicket(id: number, data: any) {
        return httpClient.post<Response<any>>(this.api.UPDATE(id), data)
    }

    toggleActiveTicket(id: number) {
        return httpClient.post<Response<any>>(this.api.TOGGLE_ACTIVATED(id))
    }

    getTicketTemplates(size?: number, page?: number): Promise<Response<ITicket[]>> {
        return Promise.all([
            httpClient.get<Response<ITicket[]>>(this.api.GET_TICKET_TEPLATES_LIST(size, page)),
            httpClient.get(this.api.GET_FORMATS())
        ]).then(([templates, response]) => {
            const formats = response.data;

            return {
                data: (templates.data?.data || []).map(t => ({...t, date_format_value: formats[t.date_format]})),
                meta: templates.data?.meta,
            }
        });
    }

    createNewTicketTemplate(data: FormData) {
        return httpClient.post(this.api.TICKET_TEPLATES(), data, {
            'headers': {
                'Content-Type': 'application/json'
            }
        })
    }

    deleteTicketTemplate(id: number) {
        return httpClient.delete(this.api.DELETE_TICKET_TEPLATE(id))
    }

    getFormats(): Promise<TList<string>> {
        return httpClient.get(this.api.GET_FORMATS()).then(response => {
            const formats = response.data;
            const keys = Object.keys(formats);
            const list: TList<string> = [];

            keys.forEach(key => {
                list.push({ title: formats[key], value: key });
            });

            return list
        });
    }
}

class ComponentsApi {
    api = {
        BASE: () => `/api/components`,
        BACKGROUND: () => `${this.api.BASE()}/backgrounds`
    }

    getListLoadedImages() {
        return httpClient.get<Response<any>>(this.api.BACKGROUND());
    }

    loadNewImage(data: FormData) {
        return httpClient.post<Response<any>>(this.api.BACKGROUND(), data, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}

class PaymentApi {
    api = {
        BASE: () => '/api/subscription',
    }

    getPricing() {
        return httpClient.get<IPaymentDTO[]>(this.api.BASE());
    }

    getPaymentPage(price: string) {
        return httpClient.post(this.api.BASE(), { price });
    }
}

class GroupCampaignApi implements IChartRepository {
    api = {
        BASE: () => '/api/campaign-groups',
        STORE: () => `${this.api.BASE()}/store`,
        SHOW: (id: number) => `${this.api.BASE()}/show/${id}`,
        UPDATE: (id: number) => `${this.api.BASE()}/update/${id}?_method=PUT`,
        CAMPAIGNS: (id: number) => `${this.api.BASE()}/${id}/campaigns`,
        LINE_CHART: (id: number, date_from: string, date_to: string) => `${this.api.BASE()}/${id}/statistic/by-days?start_at=${date_from}&end_at=${date_to}`,
        BAR_CHART_1: (id: number, date_from: string, date_to: string) => `${this.api.BASE()}/${id}/statistic/by-campaigns?start_at=${date_from}&end_at=${date_to}`,
        BAR_CHART_2: (id: number, date_from: string, date_to: string) => `${this.api.BASE()}/${id}/statistic/by-track-code?start_at=${date_from}&end_at=${date_to}`,
        STATISTIC: (id: number, date_from: string, date_to: string) => `${this.api.BASE()}/${id}/statistic/details?start_at=${date_from}&end_at=${date_to}`,

        MOVE_TO_GROUP: (campaign_id: number, group_id: number) => `/api/campaigns/${campaign_id}/group/${group_id}`,
        REMOVE_INTO_GROUP: (campaign_id: number) => `/api/campaigns/${campaign_id}/ungroup`,
    }

    getGroups() {
        return httpClient.get(this.api.BASE());
    }

    createNewGroup(data: FormData) {
        return httpClient.post(this.api.STORE(), data);
    }

    updateGroup(id: number, data: FormData) {
        return httpClient.post(this.api.UPDATE(id), data);
    }

    getListCampaigns(id_group: number) {
        return httpClient.get(this.api.CAMPAIGNS(id_group)).then(response => (response?.data?.data || []));
    }

    details(id_group: number) {
        return httpClient.get(this.api.SHOW(id_group));
    }

    getLineChart(id_group: number, date_from: string, date_to: string): Promise<ILineChartDTO> {
        return httpClient.get(this.api.LINE_CHART(id_group, date_from, date_to)).then(response => (response?.data?.data));
    }

    getBarChartByCampaigns(id_group: number, date_from: string, date_to: string): Promise<IDetailsByCampaigns[]> {
        return httpClient.get(this.api.BAR_CHART_1(id_group, date_from, date_to)).then(response => (response?.data?.data));
    }

    getBarChartByTrek(id_group: number, date_from: string, date_to: string): Promise<IDetailsByTrack[]> {
        return httpClient.get(this.api.BAR_CHART_2(id_group, date_from, date_to)).then(response => (response?.data?.data));
    }

    getStatistics(id_group: number, date_from: string, date_to: string) {
        return httpClient.get(this.api.STATISTIC(id_group, date_from, date_to)).then(response => (response?.data?.data));
    }

    moveToGroup(campaign_id: number, group_id: number) {
        return httpClient.post(this.api.MOVE_TO_GROUP(campaign_id, group_id));
    }

    removeIntoGroup(campaign_id: number) {
        return httpClient.post(this.api.REMOVE_INTO_GROUP(campaign_id));
    }
}

class BulkApi {
    api = {
        BASE: (campaignId: number) => `/api/campaigns/${campaignId}/bulks`,
        GET_LIST: (campaignId: number, size?: number, page?: number) => `${this.api.BASE(campaignId)}?per_page=${size ? size : 10}&page=${page ? page : 1}`,
        PREVIEW: (campaignId: number) => `${this.api.BASE(campaignId)}/preview`,
        ACCEPT: (campaignId: number, bulkId: number) => `${this.api.BASE(campaignId)}/${bulkId}/accept`,
        DECLINE: (campaignId: number, bulkId: number) => `${this.api.BASE(campaignId)}/${bulkId}/decline`,
        EXPORT: (campaignId: number, bulkId: number) => `${this.api.BASE(campaignId)}/${bulkId}/export`,
    }

    getList(campaignId: number, size: number, page: number) {
        return httpClient.get(this.api.GET_LIST(campaignId, size, page));
    }

    preview(campaignId: number) {
        return httpClient.get(this.api.PREVIEW(campaignId)).then(response => (response?.data?.data));
    }

    create(campaignId: number) {
        return httpClient.post(this.api.BASE(campaignId)).then(response => (response?.data?.data));
    }

    accept(campaignId: number, bulkId: number) {
        return httpClient.post(this.api.ACCEPT(campaignId, bulkId));
    }

    decline(campaignId: number, bulkId: number) {
        return httpClient.post(this.api.DECLINE(campaignId, bulkId));
    }

    export(campaignId: number, bulkId: number) {
        return httpClient.get(this.api.EXPORT(campaignId, bulkId), {responseType: 'blob'});
    }
}

class ValidateApi {
    api = {
        IBAN: () => '/api/iban',
        BIC: () => '/api/bic',
    }

    validateIban(data: FormData) {
        return httpClient.post(this.api.IBAN(), data)
    }

    validateBic(data: FormData) {
        return httpClient.post(this.api.BIC(), data)
    }
}

export const repository = {
    auth: new AuthApi(),
    user: new UsersApi(),
    campaign: new CampaignsApi(),
    company: new CompanyApi(),
    ticket: new TicketsApi(),
    components: new ComponentsApi(),
    payment: new PaymentApi(),
    groupCampaign: new GroupCampaignApi(),
    bulk: new BulkApi(),
    validate: new ValidateApi(),
}


