import {IPage} from "../Campaigns/models";
import {
    IAllSettings, IBlock,
    ISubBlock,
} from "../Campaigns/style-step/models";
import React from "react";
import {FieldState} from "formstate-x/lib";

export interface IPageInfo {
    settings: IAllSettings;
    data: ISubBlock[];
}

export type Confirm = {
    email: FieldState<string>;
    phone: FieldState<string>;
}

export interface IPreviewPresenter {
    getPage(page: IPage): IPageInfo;
}


class PreviewPresenter implements IPreviewPresenter {
    getPage = (page: IPage) => {
        const { options } = page;
        return {
            settings: {
                general: JSON.parse(options.general),
                text: {
                    main: JSON.parse(options.main),
                    h1: JSON.parse(options.h1),
                    h2: JSON.parse(options.h2),
                }
            },
            data: JSON.parse(options.data) as ISubBlock[],
        }
    }
}

export const Presenter = new PreviewPresenter();