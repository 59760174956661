import React, {useMemo} from "react";
import {observer} from "mobx-react";
import {FieldState, FormState} from "formstate-x/lib";

import {Checkbox, Field, FileUpload, RadioButtons} from "../../../components/Field";
import {IProduct} from "../models";
import {code, email, ibanValid, phone, required} from "../../../utils/validators/required";
import {IDataAll, IDataForm, IFieldForm} from "./models";
import './styles.scss'

export const FormFields = observer((props: IDataForm) => {
    const form = useMemo(() => new FormState({
        firstName: new FieldState(props.name || '').validators(required),
        lastName: new FieldState(props.lastName || '').validators(required),
        email: new FieldState(props.email || '').validators(required, email),
        IBAN: new FieldState(props.payment_method || '').validators(required, ibanValid),
        bic: new FieldState(props.bic || '').validators(required),
    }), []);

    return (
        <>
            <Field
                label={props.labels?.firstName || 'First name'}
                name="name"
                value={form.$.firstName._value}
                onChange={(e) => form.$.firstName.onChange(e)}
                required
                errorMessage={form.$.firstName.error}
            />
            <input type="hidden" name="firstNameError" value={form.$.firstName.error} />
            <Field
                label={props.labels?.lastName || 'Last name'}
                name="lastName"
                value={form.$.lastName._value}
                onChange={(e) => form.$.lastName.onChange(e)}
                required
                errorMessage={form.$.lastName.error}
            />
            <input type="hidden" name="lastNameError" value={form.$.lastName.error} />
            <Field
                label={props.labels?.email || 'Email'}
                name="email"
                value={form.$.email._value}
                onChange={(e) => form.$.email.onChange(e)}
                required
                errorMessage={form.$.email.error}
            />
            <input type="hidden" name="emailError" value={form.$.email.error} />
            <Field
                label={props.labels?.IBAN || 'IBAN'}
                name="payment_method"
                value={form.$.IBAN._value}
                onChange={(e) => form.$.IBAN.onChange(e)}
                required
                errorMessage={form.$.IBAN.error}
            />
            <input type="hidden" name="ibanError" value={form.$.IBAN.error} />
            <Field
                label={props.labels?.bic || 'Bic'}
                name="bic"
                value={form.$.bic._value}
                onChange={(e) => form.$.bic.onChange(e)}
                required
                errorMessage={form.$.bic.error}
            />

            <input type="hidden" name="bicError" value={form.$.bic.error} />
            <Checkbox name="accept" required >
                <p dangerouslySetInnerHTML={{ __html: props.acceptLabel }} />
            </Checkbox>
        </>
    );
});

export const EmailField = observer((props: IFieldForm) => {
    const form = useMemo(() => new FormState({
        email: new FieldState(props.email || '').validators(required, email),
    }), []);

    return (
        <>
            <Field
                label={props.label || 'Email'}
                name="email"
                required
                value={form.$.email._value}
                onChange={(e) => form.$.email.onChange(e)}
                errorMessage={form.$.email.error}
            />
            <input type="hidden" value={form.$.email.error} name="emailError" />
        </>
    );
});

export const PhoneField = observer((props: IFieldForm) => {
    const form = useMemo(() => new FormState({
        phone: new FieldState(props.phone || '').validators(required, phone),
    }), []);

    return (
        <>
            <Field
                label={props.label || 'Phone'}
                type="phone"
                name="phone"
                required
                value={form.$.phone._value}
                onChange={(e) => {
                    form.$.phone.onChange(e);
                }}
                errorMessage={form.$.phone.error}
            />
            <input type="hidden" value={form.$.phone.error} name="phoneError" />
        </>
    );
});

export const CodeField = observer(({ errorMessage }: { errorMessage?: string } ) => {
    const form = useMemo(() => new FormState({
        code: new FieldState('').validators(required, code),
    }), []);

    return (
        <>
            <Field
                label="Code"
                name="code"
                required
                value={form.$.code._value}
                onChange={(e) => form.$.code.onChange(e)}
                errorMessage={form.$.code.error || errorMessage}
            />
            <input type="hidden" value={form.$.code.error} name="codeError" />
        </>
    );
});

export const FormCheck = observer((props: { label: string }) => {
    const form = useMemo(() => new FormState({
        check: new FieldState<File | null>(null),
    }), []);

    return (
        <>
            <div className="center">
                <FileUpload
                    label={props.label || "Check"}
                    name="check"
                    centered
                    onChange={(file) => form.$.check.onChange(file) }
                />
            </div>
        </>
    );
});

export const Products = observer((props: { products: IProduct[], data: IDataAll}) => {
    const form = useMemo(() => new FormState({
        product: new FieldState<string>(props.data.product || ''),
    }), []);

    if (props.products.length === 1) {
        return <input type="hidden" name="product" value={props.products[0].id} />;
    }

    return(
        <RadioButtons
            label="Product"
            name='product'
            list={
                props.products.map(product => {
                    return { title: product.name, value: `${product.id}` }
                })
            }
            value={form.$.product._value}
            onChange={(value) => form.$.product.onChange(value)}
        />
    );
});
