import React, {FC, useState} from "react";
import {observer} from "mobx-react";
import {FormState} from "formstate-x/lib";

import {stylePageStoreEnhancer, IStylePageStore} from "../../../store";
import {Field, ColorPicker, Select, ImageLoad} from "../../../components/Field";
import {Button} from "../../../components/Button";
import {BgPosition, Container, FontFamilies, Repeat, SizeImage, Style, Weight} from "./constants";
import {Accordion} from "../../../components/accordeon";

import {FormText} from "./models";

// основные настройки каждой страницы
export const GeneralSettings = stylePageStoreEnhancer((props: IStylePageStore) => {
    const {settings: { general }, updatePage} = props.stylePageStore;

    return (
        <div>
            <ImageLoad
                label="Background image"
                getImage={(image) => { general.$.bgImage.onChange(image) }}
                image={general.$.bgImage._value}
            />
            <div className="grid">
                <div className="grid__col">
                    <Field
                        label="Maximum width"
                        type="number"
                        min={0}
                        value={general.$.maxWidth._value}
                        onChange={(e) => general.$.maxWidth.onChange(e)}
                        placeholder="default 1140px"
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Select<string>
                        label="Container"
                        list={Container}
                        value={general.$.container._value}
                        onChange={(size) => general.$.container.onChange(size)}
                        placeholder="Select value"
                    />
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Select<string>
                        label="Background size"
                        list={SizeImage}
                        value={general.$.imageSize._value}
                        onChange={(size) => general.$.imageSize.onChange(size)}
                        placeholder="Select value"
                    />
                    <ColorPicker
                        label="Background color"
                        placeholder="select color"
                        onChange={(color) => general.$.bgColor.onChange(color?.hex ? color?.hex : '')}
                        value={general.$.bgColor._value}
                    />
                </div>
                <div className="grid__col">
                    <Select<string>
                        label="Background position"
                        list={BgPosition}
                        value={general.$.imagePosition._value}
                        onChange={(size) => general.$.imagePosition.onChange(size)}
                        placeholder="Select value"
                    />
                    <Select<string>
                        label="Background repeat"
                        list={Repeat}
                        value={general.$.imageRepeat._value}
                        onChange={(value) => general.$.imageRepeat.onChange(value)}
                        placeholder="Select value"
                    />
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Field
                        label="Padding"
                        type="number"
                        onChange={(e) => general.$.paddingTop.onChange(e)}
                        value={general.$.paddingTop._value}
                        placeholder="Top"
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        type="number"
                        onChange={(e) => general.$.paddingLeft.onChange(e)}
                        value={general.$.paddingLeft._value}
                        placeholder="Left"
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        type="number"
                        onChange={(e) => general.$.paddingBottom.onChange(e)}
                        value={general.$.paddingBottom._value}
                        placeholder="Bottom"
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        type="number"
                        onChange={(e) => general.$.paddingRight.onChange(e)}
                        value={general.$.paddingRight._value}
                        placeholder="Right"
                        prefix="px"
                    />
                </div>

            </div>
            <div className="grid">
                <div className="grid__col">
                    <Field
                        label="Margin"
                        type="number"
                        onChange={(e) => general.$.marginTop.onChange(e)}
                        value={general.$.marginTop._value}
                        placeholder="Top"
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        type="number"
                        onChange={(e) => general.$.marginLeft.onChange(e)}
                        value={general.$.marginLeft._value}
                        placeholder="Left"
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        type="number"
                        onChange={(e) => general.$.marginBottom.onChange(e)}
                        value={general.$.marginBottom._value}
                        placeholder="Bottom"
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        type="number"
                        onChange={(e) => general.$.marginRight.onChange(e)}
                        value={general.$.marginRight._value}
                        placeholder="Right"
                        prefix="px"
                    />
                </div>

            </div>
            <div className="footer">
                <Button text="Save & Apply changes" onClick={() => updatePage()} />
            </div>
        </div>
    );
});

export const TextSettings = stylePageStoreEnhancer((props: IStylePageStore) => {
    const {settings: {text}, updatePage} = props.stylePageStore;
    const [open, setOpen] = useState<string>('undefined');

    return (
        <div>
            <Accordion key="Main style" title="Main" isOpen={open === 'main'} onToggle={() => setOpen(open === 'main' ? 'undefined' : 'main')} >
                <TextSetting form={text.main} onUpdate={updatePage} />
            </Accordion>
            <Accordion key="h1" title="Heading 1" isOpen={open === 'h1'} onToggle={() => setOpen(open === 'h1' ?  'undefined' : 'h1')} >
                <TextSetting form={text.h1} onUpdate={updatePage} />
            </Accordion>
            <Accordion key="h2" title="Heading 2" isOpen={open === 'h2'} onToggle={() => setOpen(open === 'h2' ?  'undefined' : 'h2')} >
                <TextSetting form={text.h2} onUpdate={updatePage} />
            </Accordion>

        </div>
    );
});

export const TextSetting: FC<{ form : FormState<FormText>, onUpdate(): void }> = observer(({form, onUpdate}) => {
    const { fontFamily, color, size, weight, style, letterSpacing, lineHeight} = form.$;

    return (
        <div>
            <div className="grid">
                <div className="grid__col">
                    <Select<string>
                        list={FontFamilies}
                        label="Font family"
                        placeholder="font-family"
                        value={fontFamily._value}
                        onChange={(e) => fontFamily.onChange(e)}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Select<string>
                        list={Style}
                        label="Font style"
                        placeholder="font-style"
                        value={style._value}
                        onChange={(e) => style.onChange(e)}
                    />
                </div>
                <div className="grid__col">
                    <ColorPicker
                        label="Color"
                        value={color._value}
                        onChange={(c) => color.onChange(c?.hex ? c.hex : '')}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Field
                        label="Font size"
                        placeholder="font-size"
                        type="number"
                        min={0}
                        value={size._value}
                        onChange={(e) => size.onChange(e)}
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Select<string>
                        list={Weight}
                        label="Font weight"
                        placeholder="font-weight"
                        value={weight._value}
                        onChange={(e) => weight.onChange(e)}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Field
                        label="Line height"
                        placeholder="line-height"
                        type="number"
                        min={0}
                        value={lineHeight._value}
                        onChange={(e) => lineHeight.onChange(e)}
                        prefix="px"
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="letter spacing"
                        placeholder="letter-spacing"
                        type="number"
                        min={0}
                        value={letterSpacing._value}
                        onChange={(e) => letterSpacing.onChange(e)}
                        prefix="px"
                    />
                </div>
            </div>
            <div className="footer">
                <Button text="Save & Apply changes" onClick={() => onUpdate()} />
            </div>

        </div>
    );
});