import {AccountStore} from "./AccountStore";
import {AuthContext, AuthStore} from "./AuthStore";
import {useContext} from "react";
import {UserStore} from "./UserStore";
import {CompanyStore} from "./CompanyStore";
import {CampaignStore} from "./CampaignsStore";
import {NotificationStore} from "./NotificationStore";
import {TicketsStore} from "./TicketsStore";
import {ModalStore} from "./ModalStore";
import {StylePageStore} from "./StylePageStore";
import {GroupCampaignStore} from "./GroupCampaignsStore";
import {TourStore} from "./tourStore";

export class RootStore {
    public accountStore: AccountStore;
    public authContext: AuthStore;
    public userStore: UserStore;
    public companyStore: CompanyStore;
    public campaignStore: CampaignStore;
    public notificationStore: NotificationStore;
    public ticketsStore: TicketsStore;
    public modalStore: ModalStore;
    public stylePageStore: StylePageStore;
    public groupCampaignStore: GroupCampaignStore;
    public tourStore: TourStore;

    constructor() {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        this.authContext = useContext(AuthContext);
        this.accountStore = new AccountStore(this.authContext);
        this.userStore = new UserStore();
        this.companyStore = new CompanyStore();
        this.campaignStore = new CampaignStore();
        this.notificationStore = new NotificationStore();
        this.ticketsStore = new TicketsStore();
        this.stylePageStore = new StylePageStore();
        this.modalStore = new ModalStore(this.stylePageStore);
        this.groupCampaignStore = new GroupCampaignStore();
        this.tourStore = new TourStore();
    }
}