import {Editor} from "@tinymce/tinymce-react";
import {EventHandler} from "@tinymce/tinymce-react/lib/es2015/main/ts/Events";
import {Editor as TinyMCEEditor} from "tinymce";

export default function TinyEditorComponent(props: {  onEditorChange?: (a: string, editor: TinyMCEEditor) => void,value?: string, initialValue?: string, onChange?: EventHandler<unknown>; }) {
    // note that skin and content_css is disabled to avoid the normal
    // loading process and is instead loaded as a string via content_style
    return (
        <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce/tinymce.min.js'}
            init={{
                height: 400,
                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  code print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                contextmenu: 'link image imagetools table configurepermanentpen',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt 52pt 54pt 56pt 64pt 72pt 100pt 150pt 200pt',
                menubar: 'file edit view insert format tools table help',
                image_advtab: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 span blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
            }}
            {...props}
        />
    );
}