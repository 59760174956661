import {TList} from "./Campaigns/style-step/models";
import {CashbackStatuses} from "./Campaigns/models";

export enum Period {
    thirtyDays,
    twentyFourHours,
    sevenDays,
    fourteenDays,
    quarter,
    year,
    yesterday,
    today,
    week,
}

export const LIST_PERIOD: TList<Period> = [
    {
        title: 'Last 30 days',
        value: Period.thirtyDays,
    },
    {
        title: 'Last 24 hours',
        value: Period.twentyFourHours,
    },
    {
        title: 'Last 7 days',
        value: Period.sevenDays,
    },
    {
        title: 'Last 14 days',
        value: Period.fourteenDays,
    },
    {
        title: 'Last quarter',
        value: Period.quarter,
    },
    {
        title: 'Last year',
        value: Period.year,
    },
    {
        title: 'Yesterday',
        value: Period.yesterday,
    },
    {
        title: 'Today',
        value: Period.today,
    },
    {
        title: 'This week',
        value: Period.week,
    },
];

export const DEFAULT_LIST: TList<string> = [{ title: 'All', value: 'all' }];

export const LIST_STATUS: TList<CashbackStatuses | string> = [
    { title: 'Accepted', value: CashbackStatuses.Accepted },
    { title: 'In review', value: CashbackStatuses.Review },
    { title: 'Paid', value: CashbackStatuses.Paid },
    { title: 'Rejected', value: CashbackStatuses.Rejected },
];


