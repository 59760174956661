import React, {FC} from "react";
import {IRadioButtons} from "./models";
import './styles.scss';

export const RadioButtons: FC<IRadioButtons> = (props) => {
    const { label, list, value, name, onChange } = props;
    return (
        <div className={`field`}>
            {label && (<p className="field__title">{label}</p>)}
            {list.map((item) => {
                return (
                    <div className="field__radio" key={`radio-${item.value}}`}>
                        <input
                            type="radio"
                            name={name}
                            value={item.value}
                            checked={value === item.value}
                            id={item.value}
                            onChange={
                                (e) => {
                                    onChange(e.target.value.toString())
                                }
                            }
                        />
                        <label htmlFor={item.value}>{item.title}</label>
                    </div>
                );
            })}
        </div>
    );
}