
export const required = (val:string | number | undefined | null | File ) => !val && 'Value is required';
export const requiredForSelect = (val: string) => {
    return val === 'undefined' && 'Value is required';
}
export const email = (value: string) => {
    if (required(value)) return required(value);

    value = value.trim();

    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.exec(value)) {
        return "Please enter a valid email address";
    }

    return null;
}
export const phone = (value: string) => {
    if (required(value)) return required(value);

    value = (value || "").trim();

    if (value.length < 11) {
        return "Please enter a valid phone";
    }

    return null;
}
export const code = (value: string) => {
    if (required(value)) return required(value);

    value = value.trim();

    if (value.includes('_')) {
        return "Please enter a valid code";
    }

    return null;
}
export const confirmPassword = (password: string, confirm: string) => confirm === password ? null : "Invalid password";
export const password = (password: string) => password.length < 6 && "Invalid password";
export const ibanValid = (iban: string) => !isValidIBANNumber(iban) && 'Invalid IBAN';
export const isValidIBANNumber = (input: string) => {
    const CODE_LENGTHS: { [key: string]: number } = {
        AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
        CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
        FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
        HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
        LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
        MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
        RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
        AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
        SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };
    const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''),
          code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
    const replaceCode = (letter: string): string => `${letter.charCodeAt(0) - 55}`;
    let digits;

    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }

    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, replaceCode);

    return mod97(digits);
}

export const mod97 = (str: string) => {
    let checksum: number = parseInt(str.slice(0, 2)), fragment;

    for (let offset = 2; offset < str.length; offset += 7) {
        fragment = String(checksum) + str.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }

    return checksum;
}