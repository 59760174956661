import {FC} from "react";
import {Link} from "react-router-dom";
import './styles.scss'

interface ILayoutProps {
    signIn: boolean;
    title: string;
}
export const LayoutPage: FC<ILayoutProps> = ({ signIn, title, ...props}) => {
    return (
        <main className="page__main">
            <div className="row m-0">
                <div className="col-lg-6 login">
                    <div className="login__container login__container--full">
                        <div className="login__logo" />
                        <div className="login__title">{title}</div>
                        <div className="login__form">
                            {props.children}
                        </div>
                    </div>
                    <div className="login__footer">
                        <div className="login__container">
                            <div>
                                <span>{signIn ? "Alredy have an account?" : "Don't have an account?"}</span>
                                <Link className="login__link" to={signIn ? "/login" : "/register"} >{signIn ? "Sign in" : "Sign up"}</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 hidden-md p-0">
                    <div className="login__bg" />
                </div>
            </div>
        </main>
    )
}