import {FC, useState} from "react";
import classNames from 'classnames';
import {ISelectFilterMiniProps} from "./models";
import './styles.scss';

export const SelectFilterMini: FC<ISelectFilterMiniProps> = ({ labelOnTop, label, value, onChange, list}) => {
    const [open, setOpen] = useState(false);

    const getTitle = () => {
        return (list.find(i => i.value === value) || {}).title;
    }

    const handleChange = (newValue: unknown) => {
        if (value !== newValue) {
            onChange(newValue);
        }

        setOpen(false);
    }

    const containerClasses = classNames({
        'filter': true,
        'filter--full': labelOnTop,
    })

    const filterClasses = classNames({
        'select-filter': true,
        'select-filter--mini': true,
        'open': open,
    })

    return (
        <div className={containerClasses}>
            <div className="filter__label">{label}</div>
            <div className={filterClasses} tabIndex={0} onBlur={() => setOpen(false)}>
                <div className="select-filter__value" onClick={() => { setOpen(!open)}}>
                    {getTitle()}
                </div>
                <div className="select-filter__dropdown">
                    <div className="select-filter__list">
                        {list.map((item) => {
                            return (
                                <div
                                    className={`select-filter__item ${value === item.value ? 'select-filter__item--active': ''}`}
                                    key={`${item.title}-${item.value}`}
                                    onClick={() => handleChange(item.value)}
                                >
                                    {item.title}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>

    );
}