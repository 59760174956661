import {useRef, useState} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {Button, ButtonType} from "../../../components/Button";
import {groupCampaignEnhancer, IGroupCampaignStore, ITourStore} from "../../../store";
import './styles.scss';

export const ButtonAddCampaigns = groupCampaignEnhancer(({ groupCampaignStore, tourStore }: IGroupCampaignStore & ITourStore) => {
    const [open, setOpen] = useState(false);

    const select = useRef<HTMLHeadingElement>(null);
    const classes = classNames({
        'select-container': true,
        'select-container--open': open,
    });



    const onBlur = () => {
        setOpen(false);
        select && select.current && select.current.blur && select?.current.blur();
        tourStore?.setCurrentStep(0);
    }

    const onFocus = () => {
        setOpen(true);
        if (tourStore?.isOpen) {
            tourStore?.setCurrentStep(1);
        }

    }

    const openModalCreate = () => {
        groupCampaignStore.showModalCreate();
    }

    return (
        <div className={classes} data-tour={'create'} onFocus={onFocus} onBlur={onBlur} tabIndex={0} ref={select}>
            <Button type={ButtonType.add} text="Add new campaign" />
            <div className="select-dropdown" data-tour={'create-menu'}>
                <ul className="select-dropdown__options">
                    <span className="option" onClick={openModalCreate}>Create group campaign</span>
                    <Link className="option" to="/campaigns/new">Create single campaign</Link>
                </ul>
            </div>
        </div>
    );
});