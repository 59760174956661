import React, {useCallback, useEffect, useRef} from "react";
import {withRouter} from "react-router-dom";

import {campaignStoreEnhancer} from "store";
import {getQueryParams} from "utils/utils";
import {List} from "components/List";
import Header from "components/Header/Header";
import {Paginations} from "components/Paginations";
import {SelectFilter} from "components/select-filter/SelectFilter";
import {IStatus} from "components/select-filter/models";

import {HomeProps} from "pages/super-dashboard/models";
import {ButtonAddCampaigns} from "pages/campaign-groups/button-create/add-new-campaigns";
import {ModalCreateGroup} from "pages/campaign-groups/modal-create";

import {CampaignItem} from "./CampaignItem";
import {Dashboard} from "./dashboard";
import ReactTour from "../../components/react-tour";
import {FirstSteps, FirstSteps2, FirstSteps3} from "../../components/react-tour/steps";

export const CampaignsList = campaignStoreEnhancer((props: HomeProps) => {
    const config = {
        sizes: ['5%', '25%', '20%', '20%', '10%', '10%'],
        withSort: true,
        headTitles: ['Id', 'Campaign', 'Brand', 'Duration', 'Status'],
        first60: true,
    }
    const {campaignStore, authStore, tourStore } = props;
    const {search, pathname} = props.location;
    const {pageNumber, size} = getQueryParams(search);

    useEffect(() => {
        console.log(authStore.user?.company_id);
        if (authStore.user?.company_id) {
            campaignStore.setCompanyId(authStore.user?.company_id as number);
        }

        campaignStore.fetchCampaigns(size, pageNumber, campaignStore.statusFilter.value);
    }, [authStore.user?.company_id]);

    useEffect(() => {
        if (tourStore.isTwoCycle === 0) {
            tourStore.toggleNav(false);
        } else {
            tourStore.toggleNav(true);
        }
    }, [tourStore.isTwoCycle]);

    const handleFilter = (status: IStatus) => {
        const size = campaignStore.meta.per_page || 10;
        const page = campaignStore.meta.current_page || 1;
        campaignStore.changeStatus(status);
        campaignStore.fetchCampaigns(size, page, status?.value);
    }

    const getSteps = useCallback(() => {
        if (tourStore.isTwoCycle === 3) {
            return {
                steps: FirstSteps3,
                stepName: 'firstSteps3'
            };
        }

        if (tourStore.isTwoCycle === 2) {
            return {
                steps: FirstSteps2,
                stepName: 'firstSteps2'
            };
        }

        return {
            steps: FirstSteps,
            stepName: 'firstSteps'
        }

    }, [campaignStore.campaigns, tourStore.isTwoCycle]);

    return (
        <>
            <ReactTour
                {...getSteps()}
                withDropDown
            />
            <ModalCreateGroup/>
            <Header/>
            <Dashboard companyId={authStore.user?.company_id}/>
            <main className="page__main page__main--grey page__main--with-head">
                <div className="page__container v-container">
                    <section className={"section"}>
                        <div className="section__tools">
                            <div className="section__tools-row flex-wrap">
                                <div className="section__tools-col col">
                                    <SelectFilter onChange={handleFilter} status={campaignStore.statusFilter}/>
                                </div>
                                <div className="section__tools-col col-12 col-lg-auto">
                                    <ButtonAddCampaigns/>
                                </div>
                            </div>
                        </div>
                        <List
                            dataTour={'list campaigns'}
                            config={config}
                            data={campaignStore.campaigns}
                            component={CampaignItem}
                            componentProps={{isReports: pathname === '/reports'}}
                        />
                    </section>
                    {campaignStore.meta && (<Paginations
                        meta={campaignStore.meta}
                        onChange={(size: number, page: number) => {
                            campaignStore.fetchCampaigns(size, page, campaignStore.statusFilter?.value)
                            props.history.push(`${pathname}?pageNumber=${page}&size=${size}`);
                        }}
                    />)}
                </div>
            </main>
        </>
    );
});

export const Campaigns = withRouter(CampaignsList) as unknown as React.ComponentType<{}>
