// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.header__name {
  color: #5D5FEF; }
  .header__name--grey {
    color: #60789D; }

.header__link {
  margin-right: 35px;
  color: #60789D;
  display: inline-block;
  line-height: 62px; }
  .header__link.active {
    color: #5D5FEF;
    border-bottom: 2px solid #5D5FEF; }
  .header__link:hover {
    color: #5D5FEF; }
`, "",{"version":3,"sources":["webpack://./src/shared/styles/vars.scss","webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AA6CA;EACE;IACE,UAAU,EAAA;EAEZ;IACE,UAAU,EAAA,EAAA;;AChDd;EACE,cDHc,EAAA;ECId;IACE,cAAc,EAAA;;AAGlB;EACE,kBAAkB;EAClB,cDTY;ECUZ,qBAAqB;EACrB,iBAAiB,EAAA;EAJnB;IAOI,cDfY;ICgBZ,gCDhBY,EAAA;ECQhB;IAWI,cDnBY,EAAA","sourcesContent":["$purple: #5D5FEF;\r\n$grey: #60789D;\r\n$grey_active: #233456;\r\n$color-black: #233456;\r\n$color-gray-400: #233456;\r\n$color-white: #ffffff;\r\n$page-background-color: #E5E5E5;\r\n$color-iris-100: #5D5FEF;\r\n// Colors\r\n$white: #ffffff;\r\n$gray-100: #a9c2d1;\r\n$gray-200: #8ba0c0;\r\n$gray-300: #60789d;\r\n$gray-400: #233456;\r\n\r\n\r\n$blue: #5D5FEF;\r\n$blue-light: #5D5FEF;\r\n$black: #233456;\r\n$navy-blue: #233456;\r\n$red: #f75a65;\r\n$green: #4aeb83;\r\n$yellow: #fbb48b;\r\n$orange: #F6A161;\r\n\r\n// Theme colors\r\n$color-brand: $blue;\r\n$color-brand: $blue;\r\n$color-brand-light: $blue-light;\r\n$color-primary: $navy-blue;\r\n$color-secondary: $gray-300;\r\n$color-tertiary: $gray-100;\r\n$color-base: #60789D;\r\n$color-success: $green;\r\n$color-danger: $red;\r\n$color-layout: #f8fafb;\r\n$color-completed: #1ad46a;\r\n$color-light: #8BA0C0;\r\n$color-base-2: #60789D;\r\n$color-black: #2E2F33;\r\n\r\n$success: #53C351;\r\n$error: #FF5A64;\r\n$info: #7879F1;\r\n\r\n@keyframes fadeIn {\r\n  0% {\r\n    opacity: 0;\r\n  }\r\n  100% {\r\n    opacity: 1;\r\n  }\r\n}\r\n\r\n","@import \"../../shared/styles/mixins\";\n\n.header__name {\n  color: $purple;\n  &--grey {\n    color: #60789D;\n  }\n}\n.header__link {\n  margin-right: 35px;\n  color: $grey;\n  display: inline-block;\n  line-height: 62px;\n\n  &.active {\n    color: $purple;\n    border-bottom: 2px solid $purple;\n  }\n  &:hover {\n    color: $purple;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
