import React, {PureComponent} from "react";
import {inject, observer} from "mobx-react";
import TinyEditorComponent from "../../components/TinyEditor/TinyEditor";
import Loading from "../../components/Loading";
import {CompanyStore} from "../../store";
import {LoadingState} from "../../shared/models";

import './Documents.scss';
import {DOCUMENT_CATEGORY} from "./constants";
import {getEnumValueByEnumKey} from "../../utils/utils";
import {DocumentProps, IDocumentProps} from "./models";



@inject('companyStore')
@observer
export class _Documents extends PureComponent<DocumentProps> {
    private companyStore: CompanyStore

    constructor(props: DocumentProps) {
        super(props);

        this.companyStore = props.companyStore;
    }

    componentDidMount() {
        const categoryKeys: string[] = Object.keys(DOCUMENT_CATEGORY);
        const { id } = this.props;

        if (id && categoryKeys.includes(id)) {
            const category = getEnumValueByEnumKey(DOCUMENT_CATEGORY, id) as DOCUMENT_CATEGORY;

            this.companyStore.newDocument(category);
        } else {
            this.companyStore.loadDocument(Number(id));
        }
    }

    handleChange = (value: string) => {
        if (this.props.companyStore?.document) {
            this.props.companyStore.document.title = value;
        }
    }

    save = () => {
        this.props.companyStore.saveDocument().then(() => {
            this.props.onSave && this.props.onSave();
        });
    }

    render() {
        return (
            <main className="page__main">
                <Loading active={this.props.companyStore.loading === LoadingState.loading}/>
                <div className="page__container page__container_lg">
                    <div className="company-settings__container">
                        <div className="row">
                            <div className="col">
                                <TinyEditorComponent
                                    onEditorChange={(e) => {this.handleChange(e)}}
                                    value={this.props.companyStore.document?.title}
                                />
                            </div>
                        </div>
                        <div className={"row save justify-content-center"}>
                            <div className={"col-4"}>
                                <button onClick={this.save} className={"button button_primary"}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export const Documents = _Documents as unknown as React.ComponentType<IDocumentProps>;