import React, {useState} from "react";
import {campaignStoreEnhancer} from "store";
import {ModalForm} from "components/modals/modal-form";
import {Button} from "components/Button";
import {ICampaignStore} from "pages/Campaigns/models";
import { isNil } from "lodash";
import {repository} from "../../../shared/repositories";
import moment from "moment-timezone";
import {DATE_TIME_FOR_NAME} from "../../../shared/constants";
import FileSaver from "file-saver";

export const ModalApprove = campaignStoreEnhancer((props: ICampaignStore) => {
    const { modalApprove, hideModalApprove, campaign_id, fetchBulks, bulkMeta: {per_page, current_page}, toggleTab } = props.campaignStore;
    const { visible, budget, counter, id } = modalApprove;


    const handleClose = () => {
        hideModalApprove();
    }

    const handleApprove = () => {
        // перейти в список апрувов
        repository.bulk.create(Number(campaign_id)).then((response) => {
            fetchBulks(per_page, current_page);
            toggleTab();
            hideModalApprove();
        })
    }

    const handleExport = () => {
        repository.bulk.create(Number(campaign_id)).then((response) => {
            const { id } = response.data.data;
            repository.bulk.export(Number(campaign_id), id).then((response) => {
                const data = new Blob([response.data], {type: 'xml'});
                FileSaver.saveAs(data, `export-bulk-${id}.xml`);
                fetchBulks(per_page, current_page);
                toggleTab();
                hideModalApprove();
            })
        })
    }

    return (
        <ModalForm
            isVisible={visible}
            title="Approve payout"
            onClose={handleClose}
        >
            <div className="popup__text">
                Total budget will be approved: <span className="strong">{budget || 0} EUR</span>
            </div>
            <div className="popup__text pb-3">
                Total number of accepted cashbacks: <span className="strong">{counter || 0}</span>
            </div>

            <div className="popup__footer popup__footer--custom">
                <Button text="Cancel" isWhite onClick={handleClose}  />
                <Button text="Export excel" isSuccess onClick={handleExport} disabled={isNil(budget)} />
                <Button text="Approve" onClick={handleApprove} disabled={isNil(budget)} />
            </div>
        </ModalForm>
    );
})