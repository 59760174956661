import {action, computed, makeObservable, observable} from "mobx";
import {isNil, isEmpty} from 'lodash'
import moment from "moment-timezone";
import {repository} from "../shared/repositories";
import {FieldState, FormState} from "formstate-x/lib";
import {
    CampaignStatuses,
    Currency,
    ICampaign,
    ICampaignForm,
    ICashback,
    IProduct,
    IProductForm
} from "../pages/Campaigns/models";
import {Meta} from "../shared/models";
import {required, requiredForSelect} from "../utils/validators/required";
import {DATE_TIME_FOR_DTO} from "../shared/constants";
import {StatusNotification} from "../components/Notifications";
import {ITicket} from "../pages/ticket-templates/models";
import {getErrorMessage} from "../utils/utils";
import {IStatus, StatusesCampaign} from "../components/select-filter/models";
import {ITab} from "../pages/cashback/models";

type CampaignForm = Pick<ICampaign, 'title'|'brand'|'budget'|'accuracy'|'brand_logo'|'ticket_template'|'page_template'|'start_date'|'end_date'|'approve'|'track_codes'|'slug'|'sender_name'|'custom_subject'|'bulk_approval'>;

const EMPTY_FORM: CampaignForm = {
    title: '',
    brand: '',
    budget: '',
    accuracy: 0.9,
    brand_logo: null,
    ticket_template: {} as ITicket,
    approve: 1,
    track_codes: [],
    page_template: null,
    start_date: moment(new Date()).add(1, 'days'),
    end_date: moment(new Date()).add(2, 'days'),
    slug: '',
    sender_name: '',
    custom_subject: '',
    bulk_approval: false,
}

export class CampaignStore {
    @observable campaigns: ICampaign[] = [];
    @observable campaignsFetched: boolean = false;
    @observable statusFilter: IStatus;
    @observable meta: Meta;

    @observable cashbacks: ICashback[] = [];
    @observable cashbackMeta: Meta = {} as Meta;
    @observable cashBacksTabs: ITab[];

    @observable bulks: any[] = [];
    @observable bulkMeta: Meta = {} as Meta;

    @observable campaign_id: number | null;
    @observable slug: string | null;
    @observable company_id: number;
    @observable campaign_status: CampaignStatuses | null;
    @observable isCashbacks: boolean;
    @observable form: FormState<ICampaignForm>;
    @observable image_logo: any;
    @observable products: { form: FormState<IProductForm>; image: string }[];
    @observable modalReview: {
        visible: boolean,
        id_cash: number,
        id_check: number,
        image: string,
        status: number,
        subtitle: string,
    };
    @observable modalApprove: {
        visible: boolean,
        id: number,
        budget: number,
        counter: number,
    }

    constructor() {
        this.form = this.initialForm(EMPTY_FORM);
        this.campaign_id = null;
        this.slug = null;
        this.campaign_status = null;
        this.statusFilter = StatusesCampaign[0];
        this.company_id = 0;
        this.image_logo = '';
        this.campaigns = [];
        this.isCashbacks = false;
        this.meta = { current_page: 1, per_page: 10 } as Meta;
        this.products = [];
        this.cashbacks = [];
        this.cashBacksTabs = [
            {
                name: 'Cashbacks',
                active: true,
            },
            {
                name: 'Bulk approval',
                active: false,
            },
        ];
        this.bulks = [];
        this.bulkMeta = { current_page: 1, per_page: 10 } as Meta;
        this.modalReview = {
            visible: false,
            image: '',
            id_cash: 0,
            id_check: 0,
            status: 0,
            subtitle: '',
        };
        this.modalApprove = {
            visible: false,
            id: 0,
            budget: 0,
            counter: 0,
        }

        makeObservable(this);
    }

    getProductForm = (product: IProduct): FormState<IProductForm> => {
        return new FormState({
            id: new FieldState<IProduct['id']>(product.id),
            name: new FieldState<IProduct['name']>(product.name).validators(required),
            ticketNames: new FieldState<IProduct['ticketNames']>(product.ticketNames || []),
            value: new FieldState<IProduct['value']>(product.value).validators(required),
            max_value: new FieldState<IProduct['max_value']>(product.max_value),
            cashback: new FieldState<IProduct['cashback']>(product.cashback).validators(required),
            image: new FieldState<IProduct["image"]>(null),
            currency: new FieldState<IProduct["currency"]>(product.currency),
        })
    }

    saveCampaign = (): Promise<{ type: string; message: string; id?: number; }> => {
        const {
            title,
            brand,
            start_date,
            end_date,
            budget,
            brand_logo,
            ticket_template,
            page_template,
            accuracy,
            approve,
            track_codes,
            slug,
            sender_name,
            custom_subject,
            bulk_approval,
        } = this.form.value;

        if (!this.form.dirty) {
            return Promise.reject(); // перейти на сл. шаг, если данные не изменял
        }

        const formData = new FormData();
        formData.append('title', title);
        if (start_date) {
            formData.append('start_date', moment(start_date).format(DATE_TIME_FOR_DTO));
        }
        if (end_date) {
            formData.append('end_date', moment(end_date).format(DATE_TIME_FOR_DTO));

        }

        formData.append('budget', budget);
        formData.append('slug', slug);
        formData.append('sender_name', sender_name);
        formData.append('custom_subject', custom_subject);
        formData.append('bulk_approval', `${bulk_approval ? 1 : 0}`);
        formData.append('brand', brand);
        formData.append('approve', `${approve}`);
        if (accuracy) {
            formData.append('accuracy', `${accuracy / 100}`);
        }
        if (brand_logo) {
            formData.append('brand_logo', brand_logo);
        }
        formData.append('ticket_template_id', ticket_template);
        if (page_template) {
            formData.append('page_template_id', `${page_template}`);
        }

        if (!isEmpty(track_codes)) {
            track_codes.forEach((track, i) => {
                formData.append(`track_codes[${i+1}]`, track);
            });
        }

        if (isNil(this.campaign_id)) {
            return repository.campaign.createNewCampaign(this.company_id, formData).then(response => {
                if ([200, 201].includes(response.status)) {
                    this.campaign_id = response.data?.data.id;
                    this.campaign_status = response.data?.data.status;
                    this.isCashbacks = response.data?.data.cashbacks;

                    return { type: StatusNotification.success, message: 'campaign is create', id: response.data?.data.id };
                }

                return { type: StatusNotification.error, message: getErrorMessage(response) };

            }).catch((err) => {
                return { type: StatusNotification.error, message: getErrorMessage(err) }
            });
        } else {
            return repository.campaign.updateCampaign(this.campaign_id, formData).then((response) => {
                if ([200, 201].includes(response.status)) {
                    return { type: StatusNotification.success, message: 'campaign is update'}
                }

                return { type: StatusNotification.error, message: getErrorMessage(response) };
            });
        }
    }

    deleteCampaign = (campaign_id: number) => {
        return repository.campaign.deleteCampaign(campaign_id);
    }

    getSlug = async (campaign_id: number) => {
        const response = await repository.campaign.getCampaignItem(campaign_id);
        const campaign: ICampaign = response.data.data;

        this.slug = campaign.slug;
    }

    @action
    toggleTab = () => {
        const prevTabs = this.cashBacksTabs;
        this.cashBacksTabs = prevTabs.map(t => ({ ...t, active: !t.active }));
    }

    @action
    setCompanyId = (id: number) => {
        this.company_id = id;
    }

    @action
    setImage = (image: string) => {
        this.image_logo = image;
    }

    @action
    changeStatus = (statusFiter: IStatus) => {
        this.statusFilter = statusFiter;
    }

    @action
    fetchCampaigns = async (size: number, page: number, status?: number) => {
        const response = await repository.campaign.getCampaignsList(this.company_id, size, page, status);

        this.campaigns = response?.data?.data;
        this.campaignsFetched = true;
        this.meta = response?.data?.meta;
    }

    @action
    fetchCashbacks = async (campaignId: number, size: number, page: number, search?: string) => {
        this.campaign_id = campaignId;
        const validSearch = (search || '').toLowerCase();
        const response = await repository.campaign.getCashbackList(campaignId, size, page, validSearch);
        this.cashbacks = response?.data?.data;
        this.cashbackMeta = response.data.meta;
    }

    initialForm = (params: CampaignForm) => {
        const ticket_template: string = params.ticket_template ? `${params.ticket_template?.id}` : ''
        const page_template = params?.page_template?.id || null;
        return new FormState({
            title: new FieldState<string>(params.title).validators(required),
            brand: new FieldState<string>(params.brand).validators(required),
            budget: new FieldState<string>(params.budget).validators(required),
            accuracy: new FieldState<number>(Number(params.accuracy || 0) * 100),
            brand_logo: new FieldState(null),
            approve: new FieldState(params.approve),
            track_codes: new FieldState(params.track_codes.map(code => (code.name))),
            ticket_template: new FieldState<string>(ticket_template).validators(requiredForSelect),
            page_template: new FieldState<number | null>(page_template),
            start_date: new FieldState<moment.Moment | null>(params.start_date),
            end_date: new FieldState<moment.Moment | null>(params.end_date),
            slug: new FieldState<string>(params.slug),
            sender_name: new FieldState<string>(params.sender_name),
            custom_subject: new FieldState<string>(params.custom_subject),
            bulk_approval: new FieldState<boolean>(params.bulk_approval),
        })
    }

    @action
    initialCampaign = async (id?: number) => {
        if (id) {
            const response = await repository.campaign.getCampaignItem(id)
            const current: ICampaign = response.data.data;

            if (current) {
                this.campaign_id = id;
                this.campaign_status = current.status;
                this.isCashbacks = current.cashbacks;
                this.form = this.initialForm(current);
                this.products = current.products.map(product => {
                    const productForForm: IProduct = {
                        ...product,
                        ticketNames: product.ticketNames.map(ticket => ticket.name)
                    }
                    return {
                        form: this.getProductForm(productForForm),
                        image: product.image
                    }
                });
                if (current.brand_logo) {
                    this.image_logo = current.brand_logo;
                }
            }
        } else {
            this.campaign_id = null;
            this.campaign_status = null;
            this.form = this.initialForm(EMPTY_FORM);
            this.image_logo = '';
            this.products = [];
        }
    }


    campaignIsActive = () => {
        return this.campaign_id && this.campaign_status === CampaignStatuses.Active;
    }

    campaignIsDraft = () => {
        return isNil(this.campaign_id) || this.campaign_status === CampaignStatuses.Draft;
    }

    @action
    createNewProduct = async (name: string) => {
        const productForm: FormState<IProductForm> = this.getProductForm({
            name,
            value: '',
            max_value: '',
            ticketNames: [],
            cashback: '',
            image: null,
            currency: Currency.EUR,
        });

        await this.products.push({ form: productForm, image: '' })
    }

    @action
    removeProduct = async (index: number) => {
        await this.products.splice(index, 1)
    }

    @computed
    get getProductsValue(): IProduct[] {
        return this.products.map(product => ({...product.form.value}))
    }

    @action
    validateProducts = () => {
        return Promise.all(this.products.map(async ({ form }) => form.validate()));
    }

    @action
    updateProducts = () => {
        return new Promise<void>((resolve, reject) => {
            const { campaign_id } = this;
            if (campaign_id) {
                this.validateProducts().then((result) => {
                    const productWithError = result.find(i => i.hasError);

                    if (!productWithError) {
                        const data = new FormData();
                        this.getProductsValue.forEach((product, index) => {
                            if (product.id) {
                                data.append(`products[${index}][id]`, `${product.id }`);
                            } else {
                                data.append(`products[${index}][id]`, ``);
                            }

                            if (!isEmpty(product.ticketNames)) {
                                product.ticketNames.forEach((ticket, ticketIndex) => {
                                    data.append(`products[${index}][ticket_names][${ticketIndex}]`, ticket);
                                })
                            }

                            data.append(`products[${index}][name]`, product.name);
                            data.append(`products[${index}][value]`, product.value);
                            if (product.max_value) {
                                data.append(`products[${index}][max_value]`, product.max_value);
                            }
                            data.append(`products[${index}][cashback]`, product.cashback);
                            data.append(`products[${index}][currency]`, `${product.currency}`);
                            data.append(`products[${index}][cashback_type]`, product.currency === Currency.USD ? '2' : '1');
                            if (product.image) {
                                data.append(`products[${index}][image]`, product.image);
                            }
                        });

                        repository.campaign.updateProducts(campaign_id, data).then(response => {
                            if ([200, 201].includes(response.status)) {
                                this.products = response.data.data.map(product => {
                                    const productForForm: IProduct = {
                                        ...product,
                                        ticketNames: product.ticketNames.map(ticket => ticket.name)
                                    }

                                    return {
                                        form: this.getProductForm(productForForm),
                                        image: product.image
                                    }
                                });

                                resolve();
                            } else {
                                reject();
                            }
                        }).catch(reject);
                    }
                })


            } else {
                reject();
            }
        });

    }

    @action
    showModalReview = (id_cash: number, id_check: number, status: number, subtitle: string) => {
        repository.campaign.showCheck(id_check).then((response) => {
            this.modalReview = {
                visible: true,
                id_check,
                id_cash,
                image: response.data.data.check,
                status,
                subtitle,
            };
        })
    }

    @action
    hideModalReview = () => {
        this.modalReview = {
            visible: false,
            image: '',
            id_check: 0,
            id_cash: 0,
            status: 0,
            subtitle: '',
        };
    }

    @action
    showModalApprove = (id: number) => {
        repository.bulk.preview(id).then(response => {
            this.modalApprove = {
                visible: true,
                id,
                budget: response.budget,
                counter: response.cashbacks_count,
            }
        });
    }

    @action
    hideModalApprove = () => {
        this.modalApprove = {
            visible: false,
            id: 0,
            budget: 0,
            counter: 0,
        }
    }

    @action
    fetchBulks = (size: number, page: number) => {
        if (this.campaign_id) {
            repository.bulk.getList(this.campaign_id, size, page).then(response => {
                this.bulks = response.data.data;
                this.bulkMeta = response.data.meta;
            });
        }
    }

    get isCashBack() {
        return this.cashBacksTabs[0].active;
    }
}
