import {FC, useEffect, useState} from "react";
import { isNil } from 'lodash';
import {Icon, Icons} from "../../components/Icon";
import './campaigns.scss';
import {ICashbackInfo, ICounter, IDetail} from "./models";
import {repository} from "../../shared/repositories";

export const TotalInfo: FC<ICashbackInfo> = (props) => {

    const [info, setInfo] = useState<IDetail>({ cashbacks_count: {} } as IDetail);
    const counters: ICounter[] = [
        {
            icon: Icons.cash_await2,
            title: 'Awaiting review',
            filterName: 'review',
            value: info?.cashbacks_count?.review,
        },
        {
            icon: Icons.cash_success2,
            title: 'Accepted',
            filterName: 'accepted',
            value: info?.cashbacks_count?.accepted?.total,
            manual: info?.cashbacks_count?.accepted?.manually,
            auto: info?.cashbacks_count?.accepted?.automatically,
        },
        {
            icon: Icons.cash_reject2,
            title: 'Rejected',
            filterName: 'rejected',
            value: info?.cashbacks_count?.rejected?.total,
            manual: info?.cashbacks_count?.rejected?.manually,
            auto: info?.cashbacks_count?.rejected?.automatically,
        },
        {
            icon: Icons.cash_paid,
            title: 'Paid',
            filterName: 'paid',
            value: info?.cashbacks_count?.paid,
        },
        {
            icon: Icons.cash_process,
            title: 'Total processed',
            filterName: '',
            value: info?.cashbacks_count?.processed?.total,
            manual: info?.cashbacks_count?.processed?.manually,
            auto: info?.cashbacks_count?.processed?.automatically,
        },
    ];
    useEffect(() => {
        console.log(props.campaign);
        repository.campaign.getCampaignDetails(props.campaign).then(response => {
            setInfo(response.data.data);
        })
    }, []);

    const handleFilter = (value?: string) => {
        props.onSearch(value || '')
    }

    const renderDetail = (counter: ICounter) => {
        if (isNil(counter.auto)) {
            return null;
        }

        return (
            <div className="board__detail">
                <div className="board__detail-data">
                    <div className="label">Automatically</div>
                    <div className="value">{counter.auto}</div>
                </div>
                <div className="board__detail-data">
                    <div className="label">Manually</div>
                    <div className="value">{counter.manual}</div>
                </div>
            </div>
        );
    }

    const renderCounters = () => {
        return (
            <div className="board__counters">
                {counters.map(counter => {
                    return (
                        <div className="board__wrapper board__wrapper--20" key={counter.title + counter.value}>
                            <div className="board__counter" onClick={() => handleFilter(counter.filterName)}>

                                <div className="board__counter-icon">
                                    <Icon icon={counter.icon} width={40} height={40} />
                                </div>
                                <div className="board__counter-title">{counter.title}</div>
                                <div className="board__counter-value">{counter.value}</div>
                                {renderDetail(counter)}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div className="board">
            <div className="v-container">
                <div className="board__header">
                    <div>
                        <div className="board__title">{info.title}</div>
                        <a className="board__link" target="_blank" href={`http://value.gives/${props.campaign}`}>
                            {`Landing page url: value.gives/${props.campaign}`}
                        </a>
                    </div>
                    <div className="board__total">
                        <div className="board__total-title">cashback</div>
                        <div className="board__total-value board__total-value--EUR">
                            <div className="board__total-reminder">{info.spent}</div>
                            <div className="board__total-amount">{info.budget}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="v-container">
                {renderCounters()}
            </div>

        </div>
    );
}