import {inject, observer} from "mobx-react";
import {isEmpty} from 'lodash';
import {PureComponent} from "react";
import {CampaignStore, TourStore} from "../../store";
import Header from "../../components/Header/Header";
import {Button} from "../../components/Button";
import {IListProps, List} from "../../components/List";

import {Paginations} from "../../components/Paginations";
import {Meta, User} from "../../shared/models";
import {BulkItem, CashbackItem} from "./CashbackItem";
import {ModalReview} from "../../components/modals/ModalReview";
import {TotalInfo} from "../Campaigns/total-info";
import SearchInput from "../../components/search-input";
import '../Campaigns/campaigns.scss';
import classNames from "classnames";
import {repository} from "../../shared/repositories";
import {ModalApprove} from "../../components/modals/modal-approve";
import moment from "moment-timezone";
import {DATE_TIME_FOR_NAME} from "../../shared/constants";
import FileSaver from "file-saver";
import {CashbackProps, ITab} from "./models";
import ReactTour from "../../components/react-tour";
import {CashbackSteps, CashbackSteps2} from "../../components/react-tour/steps";

@inject('campaignStore', 'tourStore')
@observer
export default class Cashback extends PureComponent<CashbackProps> {
    private readonly campaignStore: CampaignStore;
    private readonly tourStore: TourStore;
    private readonly config: IListProps<User>['config'];
    private readonly configBulk: IListProps<User>['config'];
    private campaignId: number;

    constructor(props: CashbackProps) {
        super(props);
        this.campaignStore = props.campaignStore;
        this.tourStore = props.tourStore;
        this.campaignId = Number(props.match.params.id);
        this.config = {
            sizes: ['10%', '20%', '15%', '15%', '20%', '10%', '10%', '10%'],
            withSort: true,
            headTitles: ['ID', 'NAME', 'EMAIL', 'PRODUCT', 'PAYMENT METHOD', '% OF ACCURACY', 'CASHBACK', 'STATUS'],
            first60: true,
        }
        this.configBulk = {
            sizes: ['5%', '20%', '15%', '15%', '20%', '10%', '35%'],
            withSort: true,
            headTitles: ['ID', 'DATE-TIME CREATE', 'TOTAL BUDGET', 'TOTAL CASHBACK', 'STATUS', 'DATE-TIME UPDATE'],
        }
    }


    componentDidMount() {
        this.campaignStore.getSlug(this.campaignId);
        this.campaignStore.fetchCashbacks(this.campaignId, 10, 1);
        this.campaignStore.fetchBulks(10, 1);
        this.tourStore.toggleNav(true);
    }

    filterCashbacks = (value: string) => {
        const { current_page, per_page } = this.campaignStore.cashbackMeta;
        if (value.length > 1) {
            this.campaignStore.fetchCashbacks(this.campaignId, per_page, current_page, value);
        }
        else {
            this.campaignStore.fetchCashbacks(this.campaignId, per_page, current_page);
        }
    }

    handlePreview = () => {
        this.props.history.push(`/preview/${this.campaignId}`);
    }

    handleApprove = () => {
        this.campaignStore.showModalApprove(this.campaignId);
    }

    handleExport = () => {
        repository.campaign.exportCashback(this.campaignId).then(response => {
            const today = moment().format(DATE_TIME_FOR_NAME)
            const data = new Blob([response.data], {type: 'xls'});
            FileSaver.saveAs(data, `export-cashbacks-${today}.xls`);
        });
    }

    selectTab = (tab: ITab) => {
        if (tab.active) {
            return;
        }

        this.campaignStore.toggleTab();
    }

    publicUrl = () => {

        if (this.campaignStore.slug === null || this.campaignStore.slug === '') {
            return `/${this.campaignId}`;
        }

        return `/${this.campaignId}/${this.campaignStore.slug}`;
    }

    renderCashBackActions() {
        if (!this.campaignStore.isCashBack) {
            return null;
        }

        return (
            <>
                <div className="section__tools-col col">
                    <SearchInput
                        onSearch={this.filterCashbacks}
                    />
                </div>
                <div className="section__tools-col col-12 col-lg-auto buttons-container">
                    <Button isSmall text="Go to report" to={`/campaigns/${this.campaignId}/report`} />
                    <Button isSmall text="Public" targetBlank to={this.publicUrl()} dataTour={'public'} />
                    <Button isSmall text="Approve payout" onClick={this.handleApprove} />
                    <div className="table__actions js-table-actions">
                        <button className="table__actions-btn js-toggle-table-actions">
                            <svg className="icon__more" width="4px" height="20px">
                                <use xlinkHref="#more" />
                            </svg>

                        </button>
                        <div className="table-actions__dropdown">
                            <div className="table-actions__item" onClick={this.handleExport}>Export</div>
                            <div className="table-actions__item" onClick={this.handlePreview}>Preview</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderList() {
        if (this.campaignStore.isCashBack) {
            return (<List config={this.config} dataTour={'cashbacks list'} data={this.campaignStore.cashbacks} component={CashbackItem} />);
        }

        return (
            <List config={this.configBulk} data={this.campaignStore.bulks} component={BulkItem} componentProps={{ campaign_id: this.campaignId }} />
        )
    }

    renderPagination() {
        if (this.campaignStore.isCashBack) {
            if (isEmpty(this.campaignStore.cashbacks)) {
                return null;
            }

            return (
                <Paginations
                    meta={this.campaignStore.cashbackMeta || {} as Meta}
                    onChange={(size: number, page: number) => this.campaignStore.fetchCashbacks(this.campaignId, size, page)}
                />
            );
        }

        if (isEmpty(this.campaignStore.bulks)) {
            return null;
        }

        return (
            <Paginations
                meta={this.campaignStore.bulkMeta || {} as Meta}
                onChange={(size: number, page: number) => this.campaignStore.fetchBulks(size, page)}
            />
        )
    }

    render() {
        const show3 = this.campaignStore.isCashBack && !isEmpty(this.campaignStore.cashbacks);

        return <>
            <ModalReview />
            <ModalApprove />
            <Header/>
            <TotalInfo
                campaign={this.campaignId}
                onSearch={this.filterCashbacks}
            />
            <ReactTour
                steps={show3 ? CashbackSteps2 : CashbackSteps}
                stepName={show3 ? 'cashback2' :'cashback'}
            />
            <main className="page__main page__main--grey page__main--with-head">
                <div className="page__container v-container">
                    <section className="section">
                        <div className="section__tools">
                            <div className="section__tools-row flex-wrap">
                                <div className="section__tools-col col">
                                    {this.campaignStore.cashBacksTabs.map(tab => {
                                        const classTab = classNames({
                                            'section__tab': true,
                                            'disabled': !tab.active,
                                        })
                                        return (
                                            <h2 className={classTab} onClick={() => this.selectTab(tab)} >{tab.name}</h2>
                                        )
                                    })}
                                </div>
                                {this.renderCashBackActions()}
                            </div>
                        </div>
                        {this.renderList()}
                    </section>
                    {this.renderPagination()}
                </div>
            </main>
        </>;
    }
}
