import {AuthStore} from "./AuthStore";
import {ICampaignStore} from "../pages/Campaigns/models";
import {ModalStore} from "./ModalStore";
import {StylePageStore} from "./StylePageStore";
import {NotificationStore} from "./NotificationStore";
import {TicketsStore} from "./TicketsStore";
import {CompanyStore} from "./CompanyStore";
import {AccountStore} from "./AccountStore";
import {FieldState} from "formstate-x";
import {GroupCampaignStore} from "./GroupCampaignsStore";
import {TourStore} from "./tourStore";

export type IStoresWithTypes = ICampaignStore
    & IAuthStore
    & IModalStore
    & IStylePageStore
    & INotificationStore
    & ITicketsStore
    & ICompanyStore
    & IGroupCampaignStore
    & ITourStore;

export interface ITourStore { tourStore: TourStore }

export interface IAuthStore { authStore: AuthStore }

export interface IStylePageStore { stylePageStore: StylePageStore }

export interface IModalStore { modalStore: ModalStore }

export interface INotificationStore { notificationStore: NotificationStore }

export interface ITicketsStore { ticketsStore: TicketsStore }

export interface ICompanyStore { companyStore: CompanyStore }

export interface IAccountStore { accountStore: AccountStore }

export interface IGroupCampaignStore { groupCampaignStore: GroupCampaignStore }

export interface IGroup {
    name: string;
    budget: string;
    id?: number;
}

export type ModalCreateGroupFields = {
    name: FieldState<string>;
    budget: FieldState<string>;
}

export type ModalMoveGroupFields = {
    group: FieldState<null | number>;
}

export enum ModalMode {
    create,
    move,
    update,
}