import axios from "axios";
import {defaultOptions} from "../../shared/constants";

// Create instance
const httpClient = axios.create(defaultOptions);
// Set the AUTH token for any request
httpClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
});

// httpClient.interceptors.response.use(function (response) {
//     return response;
// }, function (response) {
//     if (response.request.status === 401) {
//         window.location.href = '/login';
//     }
//     return response;
// });
export default httpClient;
