import React from "react";
import {Icon, Icons} from "../../../components/Icon";
import {Tabs} from "../../../components/tabs";
import TinyEditorComponent from "../../../components/TinyEditor/TinyEditor";
import {Button} from "../../../components/Button";
import Loading from "../../../components/Loading";
import {ColorPicker, Field, ImageLoad, MultiSelect, Select} from "../../../components/Field";
import {IModalStore, INotificationStore, ITourStore, modalEnhancer} from "../../../store";
import {BUTTON_ACTIONS, BUTTON_TYPES, customPercentage, PAGES, ROW_OPTIONS, WIDTH_LIST} from "./constants";
import {Action, PageTemplateTypes, TypeContent} from "./models";

export const ModalEdit = modalEnhancer((props: IModalStore & INotificationStore & ITourStore) => {
    const tabs = [
        TypeContent.products
    ].includes(props.modalStore.type)
        ? [{title: 'Column styling', content: <ModalStyles/>}]
        : [
            {title: 'Content', content: <ModalContent/>},
            {title: 'Column styling', content: <ModalStyles/>}
        ];

    const handleSave = () => {
        props.modalStore.saveData();
        props.notificationStore.success('Data is saved')
    }

    return (
        <div className={`slidePanel slidePanel--left ${props.modalStore.open ? 'slidePanel--show' : ''}`}>
            <div className="slidePanel-content" data-tour={'content-3'}>
                <span className="close-slide-panel" onClick={() => {
                    props.modalStore.closeModal();
                    if (props.tourStore.isOpen) {
                        props.tourStore.setCurrentStep(8);
                    }
                }}>
                    <Icon icon={Icons.close} width={15} height={15}/>
                </span>
                {props.modalStore.loading ? (
                    <Loading active={true}/>
                ) : (
                    <>
                        <Tabs
                            tabs={tabs}
                        />
                        <div className="footer">
                            <Button text="Save & Apply changes" onClick={handleSave}/>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});

export const ModalContent = modalEnhancer(({modalStore}: IModalStore) => {
    const fieldsWhenValueIsLabel = [
        TypeContent.confirm_phone,
        TypeContent.confirm_email,
        TypeContent.formCheck
    ];

    console.log(modalStore.valParams.value);
    return (
        <div>
            {modalStore.type === TypeContent.text && (
                <TinyEditorComponent
                    onEditorChange={modalStore.changeContent}
                    value={modalStore.content}
                />
            )}
            {modalStore.type === TypeContent.media && (
                <div>
                    <ImageLoad label="Image" image={modalStore.content} getImage={modalStore.changeContent} />
                </div>
            )}
            {[
                TypeContent.popupSubTitle,
                TypeContent.popupTitle,
                ...fieldsWhenValueIsLabel,
            ].includes(modalStore.type) && (
                <Field
                    label={fieldsWhenValueIsLabel.includes(modalStore.type) ? 'label' : 'value'}
                    value={modalStore.content}
                    onChange={(value => modalStore.changeContent(value))}
                />
            )}
            {modalStore.type === TypeContent.checkbox && (
                <>
                    <TinyEditorComponent
                        onEditorChange={modalStore.changeContent}
                        value={modalStore.content}
                    />
                    <Field
                        label="Name"
                        value={modalStore.valParams.$.type._value}
                        onChange={(value => modalStore.valParams.$.type.onChange(value))}
                    />
                </>
            )}
            {modalStore.type === TypeContent.form && (
                <>
                    <TinyEditorComponent
                        onEditorChange={modalStore.changeContent}
                        value={modalStore.content}
                    />
                    <div className="grid">
                        <div className="grid__col">
                            <Field
                                label="label for first name"
                                value={modalStore.valParams.$.firstName._value}
                                onChange={(value => modalStore.valParams.$.firstName.onChange(value))}
                            />

                        </div>
                        <div className="grid__col">
                            <Field
                                label="label for last name"
                                value={modalStore.valParams.$.lastName._value}
                                onChange={(value => modalStore.valParams.$.lastName.onChange(value))}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__col">
                            <Field
                                label="label for email"
                                value={modalStore.valParams.$.email._value}
                                onChange={(value => modalStore.valParams.$.email.onChange(value))}
                            />
                        </div>
                        <div className="grid__col">
                            <Field
                                label="label for IBAN"
                                value={modalStore.valParams.$.IBAN._value}
                                onChange={(value => modalStore.valParams.$.IBAN.onChange(value))}
                            />
                        </div>
                        <div className="grid__col">
                            <Field
                                label="label for bic"
                                value={modalStore.valParams.$.bic._value}
                                onChange={(value => modalStore.valParams.$.bic.onChange(value))}
                            />
                        </div>
                    </div>


                </>
            )}
            {modalStore.type === TypeContent.button && (
                <>
                    <div className="grid">
                        <div className="grid__col">
                            <Field
                                label="Button text"
                                value={modalStore.content}
                                onChange={(value => modalStore.changeContent(value))}
                            />
                        </div>
                        <div className="grid__col">
                            <Select
                                list={BUTTON_TYPES}
                                label="Button type"
                                onChange={(val) => modalStore.valParams.$.type.onChange(val)}
                                value={modalStore.valParams.$.type._value}
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__col">
                            <Select<Action>
                                list={BUTTON_ACTIONS}
                                label="Button Action"
                                onChange={(val) => modalStore.valParams.$.action.onChange(val)}
                                value={modalStore.valParams.$.action._value}
                            />
                        </div>
                        <div className="grid__col">
                            {modalStore.valParams.$.action._value === Action.moveToPage && (
                                <Select<PageTemplateTypes>
                                    list={PAGES}
                                    label="Page"
                                    onChange={(val) => modalStore.valParams.$.page.onChange(val)}
                                    value={modalStore.valParams.$.page._value}
                                />
                            )}{modalStore.valParams.$.action._value === Action.link && (
                            <Field
                                label="Link"
                                value={modalStore.valParams.$.link._value}
                                onChange={(value => modalStore.valParams.$.link.onChange(value))}
                            />
                            )}
                        </div>
                    </div>
                    <div className="grid">
                        <div className="grid__col">
                            <ColorPicker
                                label="Button background"
                                onChange={(color) => modalStore.valParams.$.bgColor.onChange(color?.hex ? color?.hex : '')}
                                value={modalStore.valParams.$.bgColor._value}
                            />
                        </div>
                        <div className="grid__col">
                            <ColorPicker
                                label="Button color"
                                onChange={(color) => modalStore.valParams.$.color.onChange(color?.hex ? color?.hex : '')}
                                value={modalStore.valParams.$.color._value}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});

export const ModalStyles = modalEnhancer(({modalStore}: IModalStore) => {
    const {colParams} = modalStore;

    return (
        <div>
            <MultiSelect
                list={ROW_OPTIONS}
                label="Column options"
                onChange={(value) => colParams.$.colOptions.onChange(value)}
                value={colParams.$.colOptions._value}
            />
            <div className="grid">
                <div className="grid__col">
                    <Select<string>
                        label="Mobile width"
                        placeholder="Select width"
                        list={WIDTH_LIST}
                        onChange={(value) => colParams.$.mobileWidth.onChange(value)}
                        value={colParams.$.mobileWidth._value}
                    />
                    {colParams.$.mobileWidth._value === customPercentage && (
                        <Field
                            label="Custom"
                            placeholder="custom percent"
                            type="number"
                            min={0}
                            prefix="%"
                            onChange={(e) => colParams.$.customMobileWidth.onChange(e)}
                            value={colParams.$.customMobileWidth._value}
                        />
                    )}
                </div>
                <div className="grid__col">
                    <Select<string>
                        label="Small width"
                        placeholder="Select width"
                        list={WIDTH_LIST}
                        onChange={(value) => colParams.$.smallWidth.onChange(value)}
                        value={colParams.$.smallWidth._value}
                    />
                    {colParams.$.smallWidth._value === customPercentage && (
                        <Field
                            label="Custom"
                            placeholder="custom percent"
                            type="number"
                            min={0}
                            prefix="%"
                            onChange={(e) => colParams.$.customSmallWidth.onChange(e)}
                            value={colParams.$.customSmallWidth._value}
                        />
                    )}
                </div>
                <div className="grid__col">
                    <Select<string>
                        label="Medium width"
                        placeholder="Select width"
                        list={WIDTH_LIST}
                        onChange={(value) => colParams.$.mediumWidth.onChange(value)}
                        value={colParams.$.mediumWidth._value}
                    />
                    {colParams.$.mediumWidth._value === customPercentage && (
                        <Field
                            label="Custom"
                            placeholder="custom percent"
                            type="number"
                            min={0}
                            prefix="%"
                            onChange={(e) => colParams.$.customMediumWidth.onChange(e)}
                            value={colParams.$.customMediumWidth._value}
                        />
                    )}
                </div>
                <div className="grid__col">
                    <Select<string>
                        label="Large width"
                        placeholder="Select width"
                        list={WIDTH_LIST}
                        onChange={(value) => colParams.$.largeWidth.onChange(value)}
                        value={colParams.$.largeWidth._value}
                    />
                    {colParams.$.largeWidth._value === customPercentage && (
                        <Field
                            label="Custom"
                            placeholder="custom percent"
                            type="number"
                            min={0}
                            prefix="%"
                            onChange={(e) => colParams.$.customLargeWidth.onChange(e)}
                            value={colParams.$.customLargeWidth._value}
                        />
                    )}
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Field
                        label="Padding"
                        placeholder="top"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.paddingTop.onChange(e)}
                        value={colParams.$.paddingTop._value}
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        placeholder="right"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.paddingRight.onChange(e)}
                        value={colParams.$.paddingRight._value}
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        placeholder="bottom"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.paddingBottom.onChange(e)}
                        value={colParams.$.paddingBottom._value}
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        placeholder="left"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.paddingLeft.onChange(e)}
                        value={colParams.$.paddingLeft._value}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Field
                        label="Margin"
                        placeholder="top"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.marginTop.onChange(e)}
                        value={colParams.$.marginTop._value}
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        placeholder="right"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.marginRight.onChange(e)}
                        value={colParams.$.marginRight._value}
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        placeholder="bottom"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.marginBottom.onChange(e)}
                        value={colParams.$.marginBottom._value}
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="&nbsp;"
                        placeholder="left"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.marginLeft.onChange(e)}
                        value={colParams.$.marginLeft._value}
                    />
                </div>
            </div>
            <div className="grid">
                <div className="grid__col">
                    <Field
                        label="maximum Width"
                        placeholder="max width"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.maxWidth.onChange(e)}
                        value={colParams.$.maxWidth._value}
                    />
                </div>
                <div className="grid__col">
                    <Field
                        label="Maximum height"
                        placeholder="max height"
                        type="number"
                        prefix="px"
                        onChange={(e) => colParams.$.maxHeight.onChange(e)}
                        value={colParams.$.maxHeight._value}
                    />
                </div>
            </div>
        </div>
    );
});