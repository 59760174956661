import {INotificationStore, ITicketsStore, ticketEnhancer} from "../../store";

import './styles.scss';
import {ITicket} from "./models";


export const TicketItem = ticketEnhancer((props: ITicket & ITicketsStore & INotificationStore) => {
    const { ticketsStore, notificationStore, name, id, used, date_format_value } = props;
    const handleDelete = async () => {
        if (used) {
            notificationStore.error('Ticket template is used', 'Error');
        } else if (id) {
            notificationStore.confirm(
                'are you sure?',
                () => {
                    ticketsStore.deleteTicket(id).then(() => {
                        notificationStore.success('Ticket template is deleted');
                        ticketsStore.fetchTickets(10, 1);
                    });
                }
            );
        }
    }

    return (
        <>
            <tr className="table__row">
                <td className="table__td">{id}</td>
                <td className="table__td">
                    <div className="tickets__logo" />
                </td>
                <td className="table__td">{name}</td>
                <td className="table__td">{date_format_value}</td>


                <td className="table__td table__td_actions">
                    <div className="table__actions js-table-actions">
                        <button className="table__actions-btn js-toggle-table-actions">
                            <svg className="icon__more" width="3px" height="15px">
                                <use xlinkHref="#more" />
                            </svg>

                        </button>
                        <div className="table-actions">
                            <div className="table-actions__row">
                                <div className="table-actions__item">
                                    <div className="table-actions__btn" onClick={() => ticketsStore.showForm(props.id)}>
                                        <svg className="icon__settings-v2" width="24px" height="24px">
                                            <use xlinkHref="#settings-v2" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="table-actions__separator" />
                                <div className="table-actions__item">
                                    <button
                                        className="table-actions__btn js-toggle-table-actions button-delete"
                                        onClick={handleDelete}
                                    >
                                        <svg className="icon__icon-delete-red" width="16px" height="18px">
                                            <use xlinkHref="#icon-delete-red" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr className="table__spacer"/>
        </>
    );
});