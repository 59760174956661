import moment from 'moment';
import {useHistory} from "react-router-dom";
import {isEmpty, isNil, omit} from "lodash";
import {useEffect, useState} from "react";
import classNames from "classnames";

import {DATE_VIEW_FORMAT} from "../../shared/constants";
import {statuses} from "../Users/constants";
import {campaignStoreEnhancer} from "../../store";
import {repository} from "../../shared/repositories";
import {List} from "../../components/List";
import {CampaignStatuses, CampaignItemProps, ICampaign} from "./models";
import './campaigns.scss';

export const CampaignItem = campaignStoreEnhancer((props: CampaignItemProps) => {
    const history = useHistory();
    const canDelete = [CampaignStatuses.Archive, CampaignStatuses.Draft].includes(props.status);
    const canEdit = [CampaignStatuses.Active, CampaignStatuses.Draft].includes(props.status);

    const updateCampaignsList = () => {
        const { current_page, per_page } = props.campaignStore.meta;
        props.campaignStore.fetchCampaigns(per_page, current_page, props.campaignStore.statusFilter.value);
    }

    const handleDelete = (event: any) => {
        event.stopPropagation();

        if (props.cashbacks) {
            props.notificationStore.info('Campaign cannot be deleted');
            return;
        }

        props.notificationStore.confirm(
            'Are you sure you want to delete this campaign?',
            () => {
                props.campaignStore.deleteCampaign(props.id).then(() => {
                    props.notificationStore.success('Campaign is deleted');
                    const {meta, statusFilter} = props.campaignStore;
                    const { per_page, current_page} = meta;

                    props.campaignStore.fetchCampaigns(per_page, current_page, statusFilter.value);
                });
            }
        );

    }

    const handleEdit = (event: any) => {
        event.stopPropagation();
        history.push(`/campaigns/${props.id}`)
    }

    const handleView = (event: any) => {
        event.stopPropagation();
        if (props.isReports) {
            history.push(`/campaigns/${props.id}/report`);
        } else {
            history.push(`/campaigns/${props.id}/cashback`);
        }
    }

    const handleArchive = (event: any) => {
        event.stopPropagation();
        props.notificationStore.confirm(
            'Are you sure you want to archive this campaign?',
            () => {
                repository.campaign.changeStatusCampaignAsArchive(props.id).then(() => {
                    props.notificationStore.success('Campaign is Archived');
                    updateCampaignsList();
                });
            }
        );
    }

    const handleDuplicate = (event: any) => {
        event.stopPropagation();
        repository.campaign.createDuplicate(props.id).then(response => {
            if (response.data) {
                props.notificationStore.success('Created duplicate campaign');
                updateCampaignsList();
            }
        });
    }

    const handleMove = (event: any) => {
        event.stopPropagation();
        props.groupCampaignStore.showModalMove(props.id);

    }

    const handleRemove = (event: any) => {
        event.stopPropagation();
        repository.groupCampaign.removeIntoGroup(props.id).then(response => {
            if (response.data) {
                props.notificationStore.success('Сampaign removed from group.');
                updateCampaignsList();

            }
        })

    }

    const getLogo = () => {
        return (<div className="campaign__brand-logo">{props.brand.slice(0,1)}</div>);
    }

    const getDuration = () => {
        const start = moment(props.start_date).format(DATE_VIEW_FORMAT);
        const end = moment(props.end_date).format(DATE_VIEW_FORMAT);

        return (
            <td className="table__td">{`${start} - ${end}`}</td>
        );
    }

    if (isNil(props.brand)) {
        return (
            <GroupCampaignItem {...omit(props, ['campaignStore', 'notificationStore', 'groupCampaignStore'])} />
        );
    }

    if (props.isSubItem) {
        return (
            <tr className="table__row pointer table__row--sub-item" onClick={handleView}>
                <td className="table__td pt 60" colSpan={2}>{props.id}</td>
                <td className="table__td">{props.title}</td>
                <td className="table__td">
                    <div className="campaign__brand">
                        {getLogo()}
                        {props.brand}
                    </div>

                </td>
                {getDuration()}
                <td className="table__td"><div className={`campaigns-block__category campaigns-block__category_${props.status === 1 ? 'blue' : 'gray'}`}>{statuses[props.status]}</div></td>
                <td className="table__td table__td_actions">
                    <div className="table__actions js-table-actions">
                        <button className="table__actions-btn js-toggle-table-actions">
                            <svg className="icon__more" width="3px" height="15px">
                                <use xlinkHref="#more" />
                            </svg>

                        </button>
                        {!props.loading && (<div className="table-actions__dropdown">
                            <div className="table-actions__item" onClick={handleView}>View details</div>
                            {canEdit && (<div className="table-actions__item" onClick={handleEdit}>Edit</div>)}
                            {canEdit && (<div className="table-actions__item" onClick={handleDuplicate}>Duplicate</div>)}
                            {canEdit && (<div className="table-actions__item" onClick={handleRemove}>Remove from group</div>)}
                            {canDelete && (<div className="table-actions__item" onClick={handleDelete}>Delete</div>)}
                            {!canDelete && (<div className="table-actions__item" onClick={handleArchive}>Archive</div>)}
                        </div>)}
                    </div>
                </td>
            </tr>
        );
    }

    return (
        <>
            <tr className="table__row pointer" onClick={handleView} data-tour={props.dataTour ? `${props.dataTour} line` : undefined}>
                <td className="table__td pt-60">{props.id}</td>
                <td className="table__td">{props.title}</td>
                <td className="table__td">
                    <div className="campaign__brand">
                        {getLogo()}
                        {props.brand}
                    </div>

                </td>
                {getDuration()}
                <td className="table__td"><div className={`campaigns-block__category campaigns-block__category_${props.status === 1 ? 'blue' : 'gray'}`}>{statuses[props.status]}</div></td>
                <td className="table__td table__td_actions"  data-tour={props.dataTour}>
                    <div className="table__actions js-table-actions"
                         onMouseEnter={() => {
                             props.dataTour && setTimeout(() => {
                                 props.tourStore.setCurrentStep(2);
                             }, 500);
                         }}
                         onMouseLeave={() => props.dataTour && props.tourStore.setCurrentStep(3)}
                    >
                        <button className="table__actions-btn js-toggle-table-actions">
                            <svg className="icon__more" width="3px" height="15px">
                                <use xlinkHref="#more" />
                            </svg>

                        </button>
                        <div className="table-actions__dropdown" data-tour={`${props.dataTour} menu`}>
                            <div className="table-actions__item" onClick={handleView}>View details</div>
                            {canEdit && (<div className="table-actions__item" onClick={handleEdit}>Edit</div>)}
                            {canEdit && (<div className="table-actions__item" onClick={handleDuplicate}>Duplicate</div>)}
                            {canEdit && !props.isGroupItem && (<div className="table-actions__item" onClick={handleMove}>Move to a group</div>)}
                            {canEdit && props.isGroupItem && (<div className="table-actions__item" onClick={handleRemove}>Remove from group</div>)}
                            {canDelete && (<div className="table-actions__item" onClick={handleDelete}>Delete</div>)}
                            {!canDelete && (<div className="table-actions__item" onClick={handleArchive}>Archive</div>)}
                        </div>
                    </div>
                </td>
            </tr>
            <tr className="table__spacer"/>
        </>
    );
});

export const GroupCampaignItem = campaignStoreEnhancer((props: CampaignItemProps) => {
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<ICampaign[]>([]);
    const history = useHistory();

    useEffect(() => {
        repository.groupCampaign.getListCampaigns(props.id).then(data => {
            setItems(data);
        })

    }, []);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }, [open]);

    const classItem = classNames({
        'table__row': true,
        'pointer': true,
        'table__row--group': true,
        'table__row--open': open,
    });
    const classSubTable = classNames({
        'table__row-sub': true,
        'table__row-sub--open': open,
    });
    const config = {
        sizes: ['7%', '5%', '20%', '20%', '20%', '10%', '10%'],
        withSort: true,
        headTitles: ['', 'Id', 'Campaign', 'Brand', 'Duration', 'Status'],
    }

    const handleToggleOpen = (event: any) => {
        event.stopPropagation();
        setOpen(!open);
    }

    const handleView = () => {
        history.push(`/group/${props.id}`);
    }

    const handleEdit = (event: any) => {
        event.stopPropagation();
        props.groupCampaignStore.showModalUpdate({
            id: props.id,
            name: props.title,
            budget: props.budget
        });
    }

    return (
        <>
            <tr className={classItem} onClick={handleView}>
                <td className="table__td pt-60">{props.id}
                <div className="table__toggle-open" onClick={handleToggleOpen} />
                </td>
                <td className="table__td" >{props.title}</td>
                <td className="table__td" colSpan={3}>
                    {`Budget: ${props.budget}`}
                </td>
                <td className="table__td table__td_actions">
                    <div className="table__actions js-table-actions">
                        <button className="table__actions-btn js-toggle-table-actions">
                            <svg className="icon__more" width="3px" height="15px">
                                <use xlinkHref="#more" />
                            </svg>

                        </button>
                        <div className="table-actions__dropdown">
                            <div className="table-actions__item" onClick={handleEdit}>Edit</div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr className={classSubTable}>
                <td colSpan={6}>
                    <List
                        config={config}
                        data={items}
                        component={CampaignItem}
                        componentProps={{
                            isSubItem: true,
                            loading,
                            isReports: props.isReports,

                        }}
                    />
                </td>
            </tr>

            <tr className="table__spacer"/>
        </>
    );
});