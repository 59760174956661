import React, {FC} from "react";
import classNames from 'classnames';
import {Link} from "react-router-dom";
import {Icon, Icons} from "../Icon";
import {ButtonType, IButtonProps} from "./models";
import './button.scss';

export const Button: FC<IButtonProps> = (props) => {
    const {to, text, disabled, full, type, isWhite, isSuccess, isAlert, isSubmit, popup, isGray, isSmall, isDark, isOrange, targetBlank, isCancel} = props;
    const buttonClass = classNames({
        'button': true,
        'button_full_width': full,
        'button_gray': isGray,
        'button_orange': isOrange,
        'button_dark': isDark,
        'button_small': isSmall,
        'button_disable': disabled,
        'popup__button': popup,
        'button-white': isWhite,
        'button_success': isSuccess,
        'button_alert': isAlert,
        'button_primary': !isWhite,
    });

    const body = (
        <>
            {type === ButtonType.add && <Icon icon={Icons.icon_plus} />}
            {type === ButtonType.edit && (<Icon icon={Icons.icon_edit_button} width={14} height={14} />)}
            {type === ButtonType.save && (<Icon icon={Icons.save} width={14} height={14} />)}
            {type === ButtonType.new && (<Icon icon={Icons.new} width={14} height={14} />)}
            {text}
            {type === ButtonType.next && (<div className="ml-2 icon__wrap"><Icon icon={Icons.next} width={14} height={14} /></div>)}
        </>
    );

    if (to && to.length > 0) {
        const otherProps = targetBlank ? { target: "_blank", to } : { to }

        return (
            <Link {...otherProps} data-tour={props.dataTour} className={buttonClass}>{body}</Link>
        );
    }

    if (isSubmit) {
        return (
            <button type="submit" className={buttonClass} disabled={disabled} onClick={props.onClick}>{body}</button>
        );
    }
    if (isCancel) {
        return (
            <button type="reset" className={buttonClass} disabled={disabled} onClick={props.onClick}>{body}</button>
        );
    }

    return (
        <div data-tour={props.dataTour} className={buttonClass} onClick={props.onClick}>{body}</div>
    );
}
