import React from "react";
import {Icon, Icons} from "../Icon";
import {Image, Library} from "../library";
import {repository} from "../../shared/repositories";

import {FileUpload} from "./FileUpload";
import {IImageLoad} from './models';
import './styles.scss';
import styled from "styled-components";
import moment from "moment-timezone";
import {DATE_TIME_VIEW_FORMAT} from "../../shared/constants";
import {v4 as uuidv4} from "uuid";


export class ImageLoad extends React.PureComponent<IImageLoad, { list: Image[]; openLibrary: boolean }> {
    constructor(props: IImageLoad) {
        super(props);

        this.state = {
            list: [],
            openLibrary: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<IImageLoad>, prevState: Readonly<{ list: Array<unknown>; openLibrary: boolean }>, snapshot?: any) {
        if (prevProps.image !== this.props.image) {
            this.getList()
        }
    }

    getList = () => {
        repository.components.getListLoadedImages().then(response => {
            this.setState({
                list: response.data.data
            })
        });
    }

    load = (file: string) => {
        this.props.getImage(file);
        this.setState({
            openLibrary: false,
        })
    }

    renderItem = (item: Image) => {
        const Image = styled.div`
            background-image: url(${item.file}); 
            background-size: contain;
        `;
        const key = uuidv4();

        return (
            <div className="grid__item js-grid-item col-12 col-lg-4 col-xl-3 col-xxl-2dot4" key={key}>
                <div className="post-card">
                    <div className="post-card__container">
                        <div className="post-card__body">
                            <Image className="post-card__img" />
                        </div>
                        <div className="post-card__footer">
                            <div className="post-card__footer-row">
                                <div className="post-card__footer-col">
                                    <div className="post-card__info">
                                        <div className="post-card__info-icon">
                                            <Icon icon={Icons.icon_calendar} width={18} height={13} />
                                        </div>
                                        <span>{moment(item.updated_at).format(DATE_TIME_VIEW_FORMAT)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="post-card__hover card-hover">
                        <div className="card-hover__main">
                            <span className="button button_primary media-manager-file mb-2" onClick={() => this.load(item.file)}>Select image</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { openLibrary, list } = this.state;
        const { getImage, image, label } = this.props;
        return (
            <div className="field">
                <div className="field__title">{label}</div>
                <div className="upload-images__wrapper">
                    <div className="col upload-images__col">
                        <FileUpload loadToServer={true} image={image} getImage={getImage} />
                    </div>
                    <div className="upload-images__from-library upload-images__col" onClick={() => this.setState({ openLibrary: true })}>
                        <Icon icon={Icons.icon_folder} width={31} height={27} />
                        <span className="upload-images__title" >Select from <br /> your library</span>
                    </div>
                </div>
                <Library
                    title="Media library"
                    open={openLibrary}
                    onClose={() => this.setState({ openLibrary: false })}
                >
                    {list.map(this.renderItem)}
                </Library>
            </div>
        );
    }
}