import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis, YAxis
} from "recharts";
import {IDataLineChart, ILineChartProps} from "./models";

export const LineChartComponent = (props: ILineChartProps<IDataLineChart>) => {
    const { data } = props;
    const renderColorfulLegendText = (value: string) => (<span style={{ color: '#60789D', fontSize: 12, marginRight: 10 }}>{value}</span>);

    return (
        <ResponsiveContainer>
            <LineChart
                data={data}
            >
                <CartesianGrid strokeDashoffset={1} />
                <XAxis
                    dataKey="date"
                    style={{ fontSize: 12, color: '#60789D' }}
                />
                <YAxis style={{ fontSize: 12, color: '#60789D' }} />
                <Tooltip />
                <Legend
                    type="circle"
                    align='left'
                    iconSize={10}
                    iconType="circle"
                    verticalAlign="bottom"
                    layout="horizontal"
                    wrapperStyle={{ bottom: -10 }}
                    formatter={renderColorfulLegendText}
                />
                <Line type="monotone" name="Awaiting review" dataKey="AwaitingReview" stroke="#F6A161" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="Rejected" stroke="#FF5A64" />
                <Line type="monotone" dataKey="Accepted" stroke="#5D5FEF" />
                <Line type="monotone" dataKey="Approved" stroke="#53C351" />
                <Line type="monotone" dataKey="Paid" stroke="#8BA0C0" />
            </LineChart>
        </ResponsiveContainer>
    );
}