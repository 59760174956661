import {FieldState} from "formstate-x/lib";
import {AuthStore} from "../../store";

export type IForgotForm = {
    email: FieldState<string>;
}
export type INewPasswordForm = {
    password: FieldState<string>;
    confirmPassword: FieldState<string>;
    token: FieldState<string | null>;
    email: FieldState<string | null>;
}

export type ILoginForm = {
    email: FieldState<string>;
    password: FieldState<string>
}

export interface IRegisterDTO {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    password: string;
    password_confirmation: string;
    invite_token: string;
}

export type IRegisterForm = {
    email: FieldState<string>;
    password: FieldState<string>;
    confirmPassword: FieldState<string>;
    token: FieldState<string>;
}

export enum LoginFlow {
    init,
    incorrectLogin,
    loading,
    login
}

export interface ILoginProps { authStore: AuthStore }

export interface ILoginState { loginFlow: LoginFlow }