import React from "react";
import {SelectFilterMini} from "../select-filter/SelectFilterMini";
import {LIST_PERIOD} from "../../pages/constants";
import {LineChartComponent} from "./LineChart";
import {BarChartComponent} from "./BarChart";
import {ICharts} from "./models";
import {Icon, Icons} from "../Icon";
import {ICounter} from "../../pages/Campaigns/models";

const Charts = (props: ICharts) => {
    const counters: ICounter[] = [
        {
            icon: Icons.cash_await2,
            title: 'Awaiting review',
            value: props.statistic.review,
        },
        {
            icon: Icons.cash_reject2,
            title: 'Rejected',
            value: props.statistic.rejected,
        },
        {
            icon: Icons.cash_success,
            title: 'Accepted',
            value: props.statistic.accepted,
        },
        {
            icon: Icons.cash_success2,
            title: 'Approved',
            value: props.statistic.reviewed,
        },
        {
            icon: Icons.cash_paid,
            title: 'Paid',
            value: props.statistic.paid,
        },
    ];

    return (
        <div className="v-container">
            <div className="chart__body">
                <div className="chart__header">
                    <div className="chart__title">Line chart</div>
                    <div className="chart__filter">
                        <SelectFilterMini
                            label="Period"
                            value={props.period}
                            list={LIST_PERIOD}
                            onChange={props.onChangePeriod}
                        />
                    </div>
                </div>
                <div className="chart__container">
                    <LineChartComponent data={props.dataLineChart} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className="chart__body">
                        <div className="chart__title">Bar chart 1</div>
                        <div className="chart__container">
                            <BarChartComponent data={props.dataBarChart1} />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="chart__body">
                        <div className="chart__title">Bar chart 2</div>
                        <div className="chart__container">
                            <BarChartComponent data={props.dataBarChart2} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="board__counters">
                {counters.map(counter => {
                    return (
                        <div className="board__wrapper board__wrapper--20" key={counter.title + counter.value}>
                            <div className="board__counter">

                                <div className="board__counter-icon">
                                    <Icon icon={counter.icon} width={40} height={40} />
                                </div>
                                <div className="board__counter-title">{counter.title}</div>
                                <div className="board__counter-value">{counter.value}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Charts;