import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

export const Tests: React.FC<{}> = () => {
    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];
    const lineChartData = [
        {
            name: '1.07',
            AwaitingReview: 0,
            Rejected: 0,
            Accepted: 0,
            Approved: 0,
            Paid: 0,
        },
        {
            name: '2.07',
            AwaitingReview: 0,
            Rejected: 40,
            Accepted: 7,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '3.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '4.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '5.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '6.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '7.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '8.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '9.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '10.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '11.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '12.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '13.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '14.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '15.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '16.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '17.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '18.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '19.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '20.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '21.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '22.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '23.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '24.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '25.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '26.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '27.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '28.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '29.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '30.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
        {
            name: '31.07',
            AwaitingReview: 25,
            Rejected: 40,
            Accepted: 35,
            Approved: 120,
            Paid: 5,
        },
    ];
    const renderColorfulLegendText = (value: string, entry: any) => {

        return <span style={{ color: '#60789D', fontSize: 12, marginRight: 10 }}>{value}</span>;
    };

    return (
        <main className="page__main">
            <div className="page__container v-container">
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <LineChart
                            data={lineChartData}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <Tooltip />
                            <Legend
                                type="circle"
                                align='left'
                                iconSize={10}
                                iconType="circle"
                                formatter={renderColorfulLegendText}
                            />
                            <Line type="monotone" name="Awaiting review" dataKey="AwaitingReview" stroke="#F6A161" activeDot={{ r: 8 }} />
                            <Line yAxisId="right" type="monotone" dataKey="Rejected" stroke="#FF5A64" />
                            <Line yAxisId="right" type="monotone" dataKey="Accepted" stroke="#5D5FEF" />
                            <Line yAxisId="right" type="monotone" dataKey="Approved" stroke="#53C351" />
                            <Line yAxisId="right" type="monotone" dataKey="Paid" stroke="#8BA0C0" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <ResponsiveContainer>
                    <BarChart width={150} height={40} data={data} layout="vertical">
                        <Bar dataKey="uv" fill="#8884d8" />
                        <Bar dataKey="pv" fill="#00dd00" />
                        <XAxis type="number" />
                        <YAxis dataKey="name" type="category" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </main>);
}