// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select2 {
  border-radius: 20px;
  border: 1px solid #A9C2D1;
  padding: 0 16px; }
  .select2 .select2-selection__arrow {
    right: 10px; }
  .select2 .select2-dropdown {
    bottom: 50px;
    transform: scaleY(0);
    top: auto; }
    .select2 .select2-dropdown:before {
      bottom: -6px;
      top: auto; }
  .select2 .select2-results__option {
    cursor: pointer; }
    .select2 .select2-results__option:hover {
      color: #233456;
      background-color: rgba(169, 194, 209, 0.1); }
  .select2.select2-container--open .select2-dropdown {
    bottom: 50px;
    transform: scaleY(1); }
`, "",{"version":3,"sources":["webpack://./src/components/Paginations/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,eAAe,EAAA;EAHjB;IAKI,WAAW,EAAA;EALf;IAQI,YAAY;IACZ,oBAAoB;IACpB,SAAS,EAAA;IAVb;MAaM,YAAY;MACZ,SAAS,EAAA;EAdf;IAkBI,eAAe,EAAA;IAlBnB;MAoBM,cAAc;MACd,0CAAsC,EAAA;EArB5C;IA0BM,YAAY;IACZ,oBAAoB,EAAA","sourcesContent":[".select2 {\r\n  border-radius: 20px;\r\n  border: 1px solid #A9C2D1;\r\n  padding: 0 16px;\r\n  .select2-selection__arrow {\r\n    right: 10px;\r\n  }\r\n  .select2-dropdown {\r\n    bottom: 50px;\r\n    transform: scaleY(0);\r\n    top: auto;\r\n\r\n    &:before {\r\n      bottom: -6px;\r\n      top: auto;\r\n    }\r\n  }\r\n  .select2-results__option {\r\n    cursor: pointer;\r\n    &:hover {\r\n      color: #233456;\r\n      background-color: rgba(169,194,209,.1);\r\n    }\r\n  }\r\n  &.select2-container--open {\r\n    .select2-dropdown {\r\n      bottom: 50px;\r\n      transform: scaleY(1);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
