// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-option {
  background: #F8FAFB;
  border: 1px solid rgba(169, 194, 209, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 13px 16px; }
  .settings-option__info {
    display: flex;
    flex-direction: column; }
    .settings-option__info-title {
      font-size: 12px;
      line-height: 18px;
      color: #60789D;
      text-align: start; }
    .settings-option__info-value {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #233456;
      text-align: start; }
  .settings-option__action {
    display: flex;
    flex-direction: row; }
    .settings-option__action svg {
      fill: #5D5FEF; }
    .settings-option__action-name {
      padding-left: 9px;
      color: #5D5FEF;
      font-size: 12px;
      font-weight: 500;
      -webkit-text-decoration: #5D5FEF underline;
              text-decoration: #5D5FEF underline;
      cursor: pointer; }
    .settings-option__action--first {
      margin-left: auto;
      margin-right: 20px; }
`, "",{"version":3,"sources":["webpack://./src/pages/CompanySettings/CompanySettingsOption.scss"],"names":[],"mappings":"AAIA;EACE,mBAAmB;EACnB,0CAA0C;EAC1C,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB,EAAA;EAElB;IACE,aAAa;IACb,sBAAsB,EAAA;IAEtB;MACE,eAAe;MACf,iBAAiB;MACjB,cAxBkB;MAyBlB,iBAAiB,EAAA;IAGnB;MACE,gBAAgB;MAChB,eAAe;MACf,iBAAiB;MACjB,cA/BkB;MAgClB,iBAAiB,EAAA;EAIrB;IACE,aAAa;IACb,mBAAmB,EAAA;IAFpB;MAKG,aAxCkB,EAAA;IA2CpB;MACE,iBAAiB;MACjB,cA7CkB;MA8ClB,eAAe;MACf,gBAAgB;MAChB,0CAA0C;cAA1C,kCAA0C;MAC1C,eAAe,EAAA;IAGjB;MACE,iBAAiB;MACjB,kBAAkB,EAAA","sourcesContent":["$color-gray-300: #60789D;\n$color-gray-400: #233456;\n$color-iris-100: #5D5FEF;\n\n.settings-option {\n  background: #F8FAFB;\n  border: 1px solid rgba(169, 194, 209, 0.2);\n  box-sizing: border-box;\n  border-radius: 4px;\n  width: 100%;\n  height: 68px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding: 13px 16px;\n\n  &__info {\n    display: flex;\n    flex-direction: column;\n\n    &-title {\n      font-size: 12px;\n      line-height: 18px;\n      color: $color-gray-300;\n      text-align: start;\n    }\n\n    &-value {\n      font-weight: 600;\n      font-size: 18px;\n      line-height: 27px;\n      color: $color-gray-400;\n      text-align: start;\n    }\n  }\n\n  &__action {\n    display: flex;\n    flex-direction: row;\n\n    & svg {\n      fill: $color-iris-100;\n    }\n\n    &-name {\n      padding-left: 9px;\n      color: $color-iris-100;\n      font-size: 12px;\n      font-weight: 500;\n      text-decoration: $color-iris-100 underline;\n      cursor: pointer;\n    }\n\n    &--first {\n      margin-left: auto;\n      margin-right: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
