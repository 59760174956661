import { useState, useEffect } from 'react';
import {LIST_PERIOD} from "pages/constants";
import {formatLineChart, formatStatistic, getFilterPeriod} from "utils/utils";
import {DATE_TIME_WITH_SECONDS_FORMAT} from "shared/constants";
import moment from "moment-timezone";
import {
    IChartRepository,
    IDataBarChart,
    IDataLineChart,
    IStatistic,
    IStatisticDTO,
} from "components/charts/models";

export const useCharts = (id: number, repository: IChartRepository) => {
    const [period, setPeriod] = useState(LIST_PERIOD[0].value);
    const [dataLineChart, setDataLineChart] = useState([] as IDataLineChart[]);
    const [dataBarChart1, setDataBarChart1] = useState([] as IDataBarChart[]);
    const [dataBarChart2, setDataBarChart2] = useState([] as IDataBarChart[]);
    const [statistic, setStatistic] = useState({} as IStatistic);
    const today = moment().format(DATE_TIME_WITH_SECONDS_FORMAT)
    const from = getFilterPeriod(period, DATE_TIME_WITH_SECONDS_FORMAT) || today;

    useEffect(() => {
        repository.getLineChart(id, from, today).then(data => {
            setDataLineChart(formatLineChart(data));
        });
        repository.getBarChartByCampaigns(id, from, today).then(data => {
            setDataBarChart1(data.map((item) => ({
                name: item.title,
                AwaitingReview: item.review.count,
                Rejected: item.rejected.count,
                Accepted: item.accepted.count,
                Approved: item.reviewed.count,
                Paid: item.paid.count,
            })));
        });
        repository.getBarChartByTrek(id, from, today).then(data => {
            setDataBarChart2(data.map((item) => ({
                name: item.name,
                AwaitingReview: item.review,
                Rejected: item.rejected,
                Accepted: item.accepted,
                Approved: item.reviewed,
                Paid: item.paid,
            })));
        });
        repository.getStatistics(id, from, today).then((data: IStatisticDTO) => {

            const validData: IStatistic = formatStatistic(data);

            setStatistic(validData);
        })
    }, [period]);

    return {
        dataLineChart,
        dataBarChart1,
        dataBarChart2,
        period,
        statistic,
        setPeriod,
    }
}
