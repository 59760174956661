import {Component} from 'react';
import {FieldState, FormState} from "formstate-x/lib";
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";

import {Field} from "../../components/Field";
import { Button } from '../../components/Button';
import {repository} from "../../shared/repositories";
import {email} from "../../utils/validators/required";

import {LayoutPage} from "./Layout";
import {IForgotForm} from "./models";
import './styles.scss'
import {RouteComponentProps} from "react-router-dom";
import {INotificationStore} from "../../store";
import {getErrorMessage} from "../../utils/utils";

type Props = RouteComponentProps<{}> & INotificationStore;

@inject('notificationStore')
@observer
export default class ForgotPassword extends Component<Props> {
    @observable form: FormState<IForgotForm>;

    constructor(props: Props) {
        super(props);

        this.form = new FormState({
            email: new FieldState("").validators(email),
        });

        makeObservable(this);
    }

    handleSubmit = async () => {
        const res = await this.form.validate();

        if (res.hasError) {
            return;
        }

        repository.auth.forgotPassword(this.form.$.email.value).then(response => {
            if ([200, 201].includes(response.status)) {
                this.props.notificationStore.success('Password recovery link sent to your email')
                this.props.history.push('/login');
            } else {
                this.props.notificationStore.error(getErrorMessage(response))
            }
        });

    }

    submit = async (e: any) => {
        e.preventDefault();
        this.handleSubmit();
    }


    render() {
        return (
            <>
                <LayoutPage signIn={true} title="Forgot password" >
                    <form onSubmit={this.submit}>
                        <Field
                            label="E-mail address"
                            name="email"
                            placeholder="Enter your email"
                            value={this.form.$.email._value}
                            onChange={(e) => {this.form.$.email.onChange(e)}}
                            errorMessage={this.form.$.email.error}
                        />
                        <div className="login__form-footer">
                            <Button text="SUBMIT" isSubmit full />
                        </div>
                    </form>
                </LayoutPage>
            </>
        )
    }
}
