export interface IIconProps {
    width?: number;
    height?: number;
    icon: Icons;
}

export enum Icons {
    Block = 'Block',
    Bounces = 'Bounces',
    Button = 'Button',
    End_Date = 'End_Date',
    Fan_Reach = 'Fan_Reach',
    Impressions_unique = 'Impressions_unique',
    Mobile = 'Mobile',
    Mobile_Participation = 'Mobile_Participation',
    Mobile_View = 'Mobile_View',
    Organic_Reach = 'Organic_Reach',
    Paid_Reach = 'Paid_Reach',
    Start_Date = 'Start_Date',
    Unsubscribe = 'Unsubscribe',
    Viral_Reach = 'Viral_Reach',
    arrow_down = 'arrow-down',
    arrow_drop_down = 'arrow-drop-down',
    arrow_drop_up = 'arrow-drop-up',
    arrow_left_2 = 'arrow-left-2',
    arrow_left_big = 'arrow-left-big',
    arrow_left = 'arrow-left',
    arrow_left2 = 'arrow-left2',
    arrow_left7 = 'arrow-left7',
    arrow_right_2 = 'arrow-right-2',
    arrow_right_3 = 'arrow-right-3',
    arrow_right_bold = 'arrow-right-bold',
    arrow_right_left = 'arrow-right-left',
    arrow_right = 'arrow-right',
    arrow_right3 = 'arrow-right3',
    arrow_up = 'arrow-up',
    audience = 'audience',
    belgium = 'belgium',
    cBelgium = 'cBelgium',
    cFrance = 'cFrance',
    cUnitedKingdom = 'cUnitedKingdom',
    calendar = 'calendar',
    campaigns_v2 = 'campaigns-v2',
    chart = 'chart',
    chart2 = 'chart2',
    check_2 = 'check-2',
    check_thin = 'check-thin',
    check = 'check',
    check3 = 'check3',
    click = 'click',
    close_circle = 'close-circle',
    close_red = 'close-red',
    close = 'close',
    color_facebook = 'color-facebook',
    color_instagram = 'color-instagram',
    color_linkedin = 'color-linkedin',
    color_pinterest = 'color-pinterest',
    color_telegram = 'color-telegram',
    color_twitter = 'color-twitter',
    color_whatsapp = 'color-whatsapp',
    color_youtube_2 = 'color-youtube-2',
    color_youtube = 'color-youtube',
    comment = 'comment',
    comment2 = 'comment2',
    competition = 'competition',
    confirmation_v2 = 'confirmation-v2',
    confirmation = 'confirmation',
    crown = 'crown',
    dashboard_v2 = 'dashboard-v2',
    download = 'download',
    edit = 'edit',
    email_circle = 'email-circle',
    envelope = 'envelope',
    export = 'export',
    external = 'external',
    facebook_circle = 'facebook-circle',
    facebook_simple = 'facebook-simple',
    facebook = 'facebook',
    format_align_center = 'format_align_center',
    format_align_left = 'format_align_left',
    format_align_right = 'format_align_right',
    format_bold = 'format_bold',
    format_color_fill = 'format_color_fill',
    format_color_text = 'format_color_text',
    format_italic = 'format_italic',
    format_list_bulleted = 'format_list_bulleted',
    format_list_numbered = 'format_list_numbered',
    format_quote = 'format_quote',
    format_strikethrough = 'format_strikethrough',
    format_underlined = 'format_underlined',
    france = 'france',
    fullscreen = 'fullscreen',
    icon_add = 'icon-add',
    icon_advertising_2 = 'icon-advertising-2',
    icon_advertising = 'icon-advertising',
    icon_archive = 'icon-archive',
    icon_attach = 'icon-attach',
    icon_audience_v2 = 'icon-audience-v2',
    icon_audience = 'icon-audience',
    icon_audience2 = 'icon-audience2',
    icon_x_circle = 'icon-x-circle',
    icon_check_circle = 'icon-check-circle',
    icon_info = 'icon-info',
    icon_block_message = 'icon-block-message',
    icon_bubble_circle = 'icon-bubble-circle',
    icon_bubble_circle2 = 'icon-bubble-circle2',
    icon_bubble = 'icon-bubble',
    icon_business = 'icon-business',
    icon_calendar_advent = 'icon-calendar-advent',
    icon_calendar = 'icon-calendar',
    icon_check_blue = 'icon-check-blue',
    icon_check_bold = 'icon-check-bold',
    icon_check_green = 'icon-check-green',
    icon_check_win = 'icon-check-win',
    icon_check = 'icon-check',
    icon_circle = 'icon-circle',
    icon_clock_2 = 'icon-clock-2',
    icon_clock = 'icon-clock',
    icon_close = 'icon-close',
    icon_close2 = 'icon-close2',
    icon_competition_2 = 'icon-competition-2',
    icon_config = 'icon-config',
    icon_connections = 'icon-connections',
    icon_copy = 'icon-copy',
    icon_crack_safe = 'icon-crack-safe',
    icon_crown = 'icon-crown',
    icon_dashboard_v2 = 'icon-dashboard-v2',
    icon_delete_red = 'icon-delete-red',
    icon_delete = 'icon-delete',
    icon_discount_coupon = 'icon-discount-coupon',
    icon_download = 'icon-download',
    icon_drag = 'icon-drag',
    icon_edit = 'icon-edit',
    icon_emails_2 = 'icon-emails-2',
    icon_emails = 'icon-emails',
    icon_emoji_v2 = 'icon-emoji-v2',
    icon_emoji = 'icon-emoji',
    icon_empty = 'icon-empty',
    icon_envelope = 'icon-envelope',
    icon_error = 'icon-error',
    icon_export_v2 = 'icon-export-v2',
    icon_export = 'icon-export',
    icon_feed = 'icon-feed',
    icon_female_2 = 'icon-female-2',
    icon_female = 'icon-female',
    icon_folder = 'icon-folder',
    icon_give = 'icon-give',
    icon_group_deal = 'icon-group-deal',
    icon_hashtag_contest = 'icon-hashtag-contest',
    icon_heart = 'icon-heart',
    icon_help = 'icon-help',
    icon_home = 'icon-home',
    icon_idea = 'icon-idea',
    icon_image = 'icon-image',
    icon_influencers_2 = 'icon-influencers-2',
    icon_influencers = 'icon-influencers',
    icon_info2 = 'icon-info2',
    icon_instagram_bubble = 'icon-instagram-bubble',
    icon_instagram_export = 'icon-instagram-export',
    icon_instagram_heart = 'icon-instagram-heart',
    icon_internet = 'icon-internet',
    icon_join_win = 'icon-join-win',
    icon_landing = 'icon-landing',
    icon_landingpage_2 = 'icon-landingpage-2',
    icon_landingpage = 'icon-landingpage',
    icon_like = 'icon-like',
    icon_link = 'icon-link',
    icon_location = 'icon-location',
    icon_lock = 'icon-lock',
    icon_logout = 'icon-logout',
    icon_mail_info = 'icon-mail-info',
    icon_male_2 = 'icon-male-2',
    icon_male = 'icon-male',
    icon_message_info = 'icon-message-info',
    icon_message_new = 'icon-message-new',
    icon_modules = 'icon-modules',
    icon_monitor = 'icon-monitor',
    icon_monitoring_2 = 'icon-monitoring-2',
    icon_monitoring = 'icon-monitoring',
    icon_monitoring2 = 'icon-monitoring2',
    icon_more = 'icon-more',
    icon_move = 'icon-move',
    icon_neutral = 'icon-neutral',
    icon_notic_v2 = 'icon-notic-v2',
    icon_package = 'icon-package',
    icon_person_assign = 'icon-person-assign',
    icon_person = 'icon-person',
    icon_plane = 'icon-plane',
    icon_plus_small = 'icon-plus-small',
    icon_plus = 'icon-plus',
    icon_poll = 'icon-poll',
    icon_post = 'icon-post',
    icon_price_question = 'icon-price-question',
    icon_promote = 'icon-promote',
    icon_publish = 'icon-publish',
    icon_refresh = 'icon-refresh',
    icon_reply = 'icon-reply',
    icon_reports_2 = 'icon-reports-2',
    icon_reports = 'icon-reports',
    icon_retweets = 'icon-retweets',
    icon_sad = 'icon-sad',
    icon_save = 'icon-save',
    icon_schedule_post_2 = 'icon-schedule-post-2',
    icon_schedule_post = 'icon-schedule-post',
    icon_scratch_win = 'icon-scratch-win',
    icon_search_v2 = 'icon-search-v2',
    icon_search = 'icon-search',
    icon_send_card = 'icon-send-card',
    icon_settings_v2 = 'icon-settings-v2',
    icon_settings_v3 = 'icon-settings-v3',
    icon_share = 'icon-share',
    icon_smail = 'icon-smail',
    icon_smile = 'icon-smile',
    icon_subscription_from = 'icon-subscription-from',
    icon_sweepstake = 'icon-sweepstake',
    icon_target = 'icon-target',
    icon_task = 'icon-task',
    icon_template = 'icon-template',
    icon_text = 'icon-text',
    icon_thumb = 'icon-thumb',
    icon_transfer = 'icon-transfer',
    icon_unlock = 'icon-unlock',
    icon_upload = 'icon-upload',
    icon_video_contest = 'icon-video-contest',
    icon_view_grid = 'icon-view-grid',
    icon_view_list = 'icon-view-list',
    icon_view = 'icon-view',
    icon_web_site = 'icon-web-site',
    icon_web = 'icon-web',
    info = 'info',
    insert_link = 'insert_link',
    instagram_circle = 'instagram-circle',
    instagram_simple = 'instagram-simple',
    instagram = 'instagram',
    language = 'language',
    like = 'like',
    like2 = 'like2',
    linkedin_circle = 'linkedin-circle',
    linkedin_simple = 'linkedin-simple',
    linkedin = 'linkedin',
    location = 'location',
    mail_arrived = 'mail-arrived',
    mail = 'mail',
    marketing_2 = 'marketing-2',
    marketing = 'marketing',
    megaphone = 'megaphone',
    modules = 'modules',
    monitoring = 'monitoring',
    more = 'more',
    next = 'next',
    night = 'night',
    notic = 'notic',
    person_assign = 'person-assign',
    person = 'person',
    pinterest_circle = 'pinterest-circle',
    pinterest_simple = 'pinterest-simple',
    pinterest = 'pinterest',
    play = 'play',
    progress = 'progress',
    publish_v2 = 'publish-v2',
    reports_v2 = 'reports-v2',
    repost = 'repost',
    retweet = 'retweet',
    sad = 'sad',
    settings_v2 = 'settings-v2',
    share2 = 'share2',
    smail = 'smail',
    star_fill = 'star-fill',
    star = 'star',
    startup1 = 'startup1',
    statusLive = 'statusLive',
    telegram_circle = 'telegram-circle',
    telegram_simple = 'telegram-simple',
    telegram = 'telegram',
    thumbs_down = 'thumbs-down',
    trophy = 'trophy',
    twitter_circle = 'twitter-circle',
    twitter = 'twitter',
    united_kingdom = 'united-kingdom',
    upload = 'upload',
    whatsapp_circle = 'whatsapp-circle',
    whatsapp_simple = 'whatsapp-simple',
    whatsapp = 'whatsapp',
    youtube_2 = 'youtube-2',
    youtube_circle_2 = 'youtube-circle-2',
    youtube_circle = 'youtube-circle',
    youtube = 'youtube',
    define_style = 'define-style',
    define_products = 'define-products',
    define_objective = 'define-objective',
    file_image = 'file-image',
    eur = 'eur',
    percent = 'percent',
    icon_button = 'icon-button',
    icon_edit_button = 'icon-edit-button',
    icon_form = 'icon-form',
    icon_logo = 'icon-logo',
    icon_edit_settings = 'icon-edit-settings',
    icon_package_new = 'icon-package-new',
    icon_text_new = 'icon-text-new',
    icon_header = 'icon-header',
    icon_monitor_new = 'icon-monitor-new',
    icon_smartphone = 'icon-smartphone',
    cash_await = 'cash-await',
    cash_await2 = 'cash-await-2',
    cash_process = 'cash-process',
    cash_paid = 'cash-paid',
    cash_manual = 'cash-manual',
    cash_reject = 'cash-reject',
    cash_reject2 = 'cash-reject-red',
    cash_success = 'cash-success',
    cash_success2 = 'cash-success-green',
    statistics = 'statistics',
    total_campaign = 'total-campaign',
    total_reach = 'total-reach',
    save = 'save',
    new = 'new',
}
