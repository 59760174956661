import React, {useEffect, useMemo, useState} from "react";
import {FieldState, FormState} from "formstate-x/lib";

import {ModalForm} from "../../components/modals/modal-form";
import {ITicketEditFormProps} from "./models";
import {Field, FileUpload, Select} from "../../components/Field";
import {repository} from "../../shared/repositories";
import { TList } from "../Campaigns/style-step/models";
import {required} from "../../utils/validators/required";
import {ITicketsStore, INotificationStore, ticketEnhancer} from "../../store";

export const TicketEditForm = ticketEnhancer(({ ticket, onClose, isVisible, notificationStore } : ITicketEditFormProps & INotificationStore & ITicketsStore) => {

    const [formats, setFormats] = useState<TList<string>>([]);
    const [reset, setReset] = useState<boolean>(false);

    const form = useMemo(() => new FormState({
        logo: new FieldState<File | null>(null),
        name: new FieldState<string>(ticket.name).validators(required),
        date_format: new FieldState<string>(`${ticket.date_format}`).validators(required),
    }), [ticket]);

    useEffect(() => {
        repository.ticket.getFormats().then((list) => {
            setFormats(list)
        })
    }, []);

    useEffect(() => {
        if (isVisible) {
            setReset(true);
            setTimeout(() => setReset(false), 0);
        }
    }, [isVisible]);

    const onSubmit = async () => {
        const res = await form.validate();

        if (res.hasError) {
            return;
        }
        const data = new FormData();
        data.append('name', form.$.name._value);
        data.append('date_format', form.$.date_format._value);
        if (form.$.logo._value) {
            data.append('logo', form.$.logo._value);
        }
        repository.ticket.createNewTicketTemplate(data).then((response) => {
            notificationStore.success('ticket template is create');
            onClose();

        });
    }

    return (
        <ModalForm
            isVisible={isVisible}
            title="Add new ticket template"
            subTitle={['Please fill in your ticket template rules and', 'proceed to the next step']}
            onClose={onClose}
            formConfig={{
                cancelText: "Cancel",
                submitText: "Add",
                formSubmitDisabled: form.hasError,
                onSubmit,
            }}
        >
            <div className="sign__form">
                <FileUpload
                    label="Company logo"
                    centered
                    reset={reset}
                    image={ticket.image}
                    onChange={(file: File) => form.$.logo.onChange(file)}
                />
            </div>
            <Field
                label="Template name"
                placeholder="Enter template name"
                value={form.$.name._value}
                onChange={(e) => form.$.name.onChange(e)}
                errorMessage={form.$.name.error}
            />
            <Select
                label="Date format"
                list={formats}
                placeholder="Select date format"
                onChange={(value) => form.$.date_format.onChange(value)}
                value={form.$.date_format._value}
                errorMessage={form.$.date_format.error}
            />
        </ModalForm>

    );
});