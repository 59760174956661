import React, {useRef, useState} from "react";
import classNames from "classnames";
import {v4 as uuidv4} from "uuid";
import {ISelectProps} from "./models";
import './styles.scss';

export const Select = <T extends any>(props: ISelectProps<T>) => {
    const {
        label ,
        list,
        placeholder,
        value,
        onChange,
        errorMessage,
        hidden,
        description,
        updateCurrentTour,
    } = props;
    const selectedOption = value ? list.find(option => option.value === value) : null
    const [open, setOpen] = useState(false);
    const select = useRef<HTMLHeadingElement>(null);

    const onBlur = () => {
        setOpen(false);
        select && select.current && select.current.blur && select?.current.blur();
    }

    const onSelect = (newValue: T) => {
        if (value === newValue) {
            onBlur();
            return;
        }

        onChange(newValue);
        onBlur();

    }

    const handleToggleSelect = () => {
        if (props.disabled) {
            return;
        }

        if (open) {
            updateCurrentTour && updateCurrentTour(false)
            onBlur();
            return;
        }

        setOpen(true);
        updateCurrentTour && updateCurrentTour(true)
    }

    const containerClass = classNames({
        'field': true,
        'sign__form-field input-error': errorMessage,
        'hidden': hidden,
    });
    const selectClass = classNames({
        'select-full': true,
        'select2-container': true,
        'select2-container--default': true,
        'select2-container--below': true,
        'select2-container--focus': true,
        'select2-container--open': open,
    });
    const classSelection = classNames({
        'select2-selection': true,
        'select2-selection--single': true,
        'select2-selection--disabled': props.disabled,
    })

    const renderLabel = () => {
        if (description) {
            return (
                <p className="field__title">{label} <span className="field__description">{description}</span></p>
            );
        }

        if (label) {
            return (
                <p className="field__title">{label}</p>
            );
        }

        return null;

    }

    return (
        <div className={containerClass} data-tour={props.dataTour}>
            {renderLabel()}
            <div
                className={selectClass}
                onBlur={() => setOpen(false)}
                onClick={handleToggleSelect}
                tabIndex={0}
                ref={select}
            >
                <div className="selection">
                    <span className={classSelection}>
                        <span className="select2-selection__rendered">
                            <div className="">{selectedOption ? selectedOption.title : placeholder}</div>
                        </span>
                        <span className="select2-selection__arrow">
                            <b role="presentation" />
                        </span>
                    </span>
                </div>
                <div className="select2-dropdown select2-dropdown--below" data-tour={props.dataTourOpen}>
                        <span className="select2-search select2-search--dropdown select2-search--hide">
                            <input
                                className="select2-search__field"
                                type="search"
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="none"
                            />
                        </span>
                        <span className="select2-results">
                            <ul className="select2-results__options">
                                {list.map(option => {
                                    const selected = option.value === value;
                                    const key = uuidv4();
                                    const classItem = classNames({
                                        'select2-results__option': true,
                                        'select2-results__option--highlighted': selected,
                                    })

                                    return (
                                        <li
                                            key={'option-select-' + key}
                                            className={classItem}
                                            onClick={() => onSelect(option.value)}
                                        >
                                            {option.title}
                                        </li>
                                    );
                                })}
                            </ul>
                        </span>
                    </div>
            </div>
            {errorMessage && (<div className={'field__message color-danger'}>{errorMessage}</div>)}
        </div>
    );
}