import React, {FC, useEffect, useRef, useState} from "react";
import classNames from 'classnames';
import {Icon, Icons} from "../Icon";
import {repository} from "../../shared/repositories";

import {FileEvent, IFileUploadProps} from "./models";
import './styles.scss'

export const FileUpload: FC<IFileUploadProps> = (props) => {
    const inputRef: any = useRef(null);

    const [image, setImage] = useState<any>(null);

    const fileLoad = async (event: FileEvent) => {
        const reader = new FileReader();
        const isLoadFile = event.target.files && event.target.files.length > 0;

        if (!isLoadFile) {
            handleClear();
            return;
        }

        const file = event.target.files && event.target.files.item(0);

        if (props.loadToServer && file !== null) {
            const formData  = new FormData();
            formData.append('file', file);
            const response = await repository.components.loadNewImage(formData);
            setImage(response.data.data.file);
            props.getImage && props.getImage(response.data.data.file);
        } else {
            reader.onload = function (e) {
                setImage(e.target?.result);
                props.getImage && props.getImage(e.target?.result);
            };

            reader.readAsDataURL(inputRef.current?.files ? inputRef.current.files[0] : null);
        }

        props.onChange && props.onChange(file);
    }

    const currentImage = image || props.image;

    useEffect(() => {
        if (props.reset) {
            setImage(null);
        }
    }, [props.reset])

    const fieldClass = classNames({
        'field': true,
        'sign__upload': props.centered,
        'sign__upload--error': props.centered && props.errorMessage,
    });
    const bodyClass =classNames({
        'image-upload__body': true,
        'image-upload__body--error': props.errorMessage,
    })

    const handleClear = () => {
        setImage('');
        props.getImage && props.getImage('');
        props.onChange && props.onChange(null);
        props.onClear && props.onClear();
    }

    return (
        <div className={fieldClass}>
            <p className="field__title">{props.label}</p>
            <div className={currentImage ? "image-upload filled" : "image-upload"}>
                <div className={bodyClass}>
                    {currentImage && !props.hideRemoveBtn && (<div className="image-upload__clear" onClick={handleClear}>
                        <Icon icon={Icons.close_circle} width={17} height={17} />
                    </div>)}
                    <input
                        onChange={fileLoad}
                        className="image-upload__input js-input-image"
                        ref={inputRef}
                        name={props.name}
                        type="file"
                        accept=".png, .jpg, .jpeg, .mp4, .webm, .ogg"
                    />
                    {currentImage ? <div className="image-upload__preview" style={{
                        backgroundImage: "url(" + currentImage + ")",
                    }}>
                        <div className="image-upload__preview-img"/>
                    </div> : <div className="image-upload__preview"/>}
                    <div className="image-upload__add">
                        <div className="image-upload__area-icon">
                            <Icon icon={Icons.file_image} width={31} height={27} />
                        </div>
                        <div className="image-upload__area-text">
                            <b>Drag &amp; drop</b>
                            <br/>to upload
                            <br/>or browse
                        </div>
                    </div>
                    <div className="image-upload__edit">
                        <div className="image-upload__area-icon">
                            <Icon icon={Icons.edit} width={24} height={24} />
                        </div>
                        <div className="image-upload__area-text">Edit Image</div>
                    </div>
                </div>
            </div>
            {props.errorMessage && (<div className={'field__message color-danger'}>{props.errorMessage}</div>)}
        </div>
    );
}