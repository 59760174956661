// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company-settings__container {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.company-settings__header {
  text-align: center; }
  .company-settings__header-title {
    color: #233456;
    font-size: 24px;
    line-height: 36px;
    font-style: normal;
    font-weight: 600; }
  .company-settings__header-subtitle {
    font-size: 14px;
    color: #233456; }

.company-settings__form {
  margin: 24px auto;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(169, 194, 209, 0.04), 0 12px 16px rgba(169, 194, 209, 0.1);
  border-radius: 6px;
  width: 620px; }
  .company-settings__form form {
    padding: 24px 24px 9px 24px; }
  .company-settings__form-field {
    margin-bottom: 10px; }
  .company-settings__form-divider {
    background-color: #E5E5E5;
    height: 1px;
    width: 100%; }

.company-settings__options {
  padding: 24px 24px; }
  .company-settings__options-item {
    margin-bottom: 8px; }

.company-settings__action {
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: space-around; }
  .company-settings__action button {
    width: 100%;
    background-color: #5D5FEF;
    color: #ffffff; }

.hidden {
  display: none; }
`, "",{"version":3,"sources":["webpack://./src/pages/CompanySettings/CompanySettings.scss"],"names":[],"mappings":"AAOE;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAA;;AAGzB;EACE,kBAAkB,EAAA;EAElB;IACE,cAjBe;IAkBf,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB,EAAA;EAGlB;IACE,eAAe;IACf,cAzBkB,EAAA;;AA6BtB;EACE,iBAAiB;EACjB,mBA9BiB;EA+BjB,qFAAqF;EACrF,kBAAkB;EAClB,YAAY,EAAA;EALb;IAQG,2BAA2B,EAAA;EAG7B;IACE,mBAAmB,EAAA;EAGrB;IACE,yBA3CyB;IA4CzB,WAAW;IACX,WAAW,EAAA;;AAIf;EACE,kBAAkB,EAAA;EAElB;IACE,kBAAkB,EAAA;;AAItB;EACE,yBAAyB;EACzB,aAAa;EACb,6BAA6B,EAAA;EAH9B;IAMG,WAAW;IACX,yBA/DkB;IAgElB,cAlEe,EAAA;;AAuErB;EACE,aAAa,EAAA","sourcesContent":["$color-black: #233456;\n$color-gray-400: #233456;\n$color-white: #ffffff;\n$page-background-color: #E5E5E5;\n$color-iris-100: #5D5FEF;\n\n.company-settings {\n  &__container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n\n  &__header {\n    text-align: center;\n\n    &-title {\n      color: $color-black;\n      font-size: 24px;\n      line-height: 36px;\n      font-style: normal;\n      font-weight: 600;\n    }\n\n    &-subtitle {\n      font-size: 14px;\n      color: $color-gray-400;\n    }\n  }\n\n  &__form {\n    margin: 24px auto;\n    background: $color-white;\n    box-shadow: 0 2px 4px rgba(169, 194, 209, 0.04), 0 12px 16px rgba(169, 194, 209, 0.1);\n    border-radius: 6px;\n    width: 620px;\n\n    & form {\n      padding: 24px 24px 9px 24px;\n    }\n\n    &-field {\n      margin-bottom: 10px;\n    }\n\n    &-divider {\n      background-color: $page-background-color;\n      height: 1px;\n      width: 100%;\n    }\n  }\n\n  &__options {\n    padding: 24px 24px;\n\n    &-item {\n      margin-bottom: 8px;\n    }\n  }\n\n  &__action {\n    padding: 0 24px 24px 24px;\n    display: flex;\n    justify-content: space-around;\n\n    & button {\n      width: 100%;\n      background-color: $color-iris-100;\n      color: $color-white;\n    }\n  }\n}\n\n.hidden {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
