import React, {PureComponent} from "react";
import {inject, observer} from "mobx-react";
import {isEmpty} from 'lodash';
import {RouteComponentProps, withRouter} from "react-router-dom";

import {Icon, Icons} from "../../components/Icon";
import {
    CampaignStore,
    IAuthStore,
    IModalStore,
    INotificationStore,
    IStylePageStore,
    ITourStore,
    TourStore
} from "../../store";

import {CampaignOneStep} from "./OneStep";
import {CampaignTwoStep} from "./TwoStep";
import {CampaignStatuses, ICampaignState, ICampaignStore, IStep, StatusStep, TitleStep} from "./models";
import {CampaignPresenter} from "./presenter";
import {StyleStep} from "./style-step";
import './campaigns.scss';
import {StatusNotification} from "../../components/Notifications";
import {repository} from "../../shared/repositories";
import ReactTour from "../../components/react-tour";
import {objectsSteps, productSteps, stylesSteps} from "../../components/react-tour/steps";
import {ReactourStep} from "reactour";

type Props = ICampaignStore & ITourStore & IAuthStore & IStylePageStore & INotificationStore & IModalStore & RouteComponentProps<{ id?: string }>

@inject('campaignStore', 'authStore', 'notificationStore', 'stylePageStore', 'modalStore', 'tourStore')
@observer
class Campaign extends PureComponent<Props, ICampaignState> {
    private readonly campaignStore: CampaignStore;
    private readonly tourStore: TourStore;

    constructor(props: Props) {
        super(props);
        this.campaignStore = props.campaignStore;
        this.tourStore = props.tourStore;
        this.campaignStore.setCompanyId(props.authStore.user.company_id);

        this.state = {
            steps: [
                {
                    title: TitleStep.DEFINE_OBJECTIVE,
                    status: StatusStep.inProgress,
                    active: true,
                    icon: Icons.define_objective,
                },
                {
                    title: TitleStep.DEFINE_PRODUCTS,
                    status: StatusStep.not,
                    icon: Icons.define_products,
                    active: false,
                },
                {
                    title: TitleStep.DEFINE_STYLE,
                    status: StatusStep.not,
                    icon: Icons.define_style,
                    active: false,
                },
            ],
            loadingData: false,
        }
    }

    componentDidMount() {
        const id = this.props.match.params?.id ? Number(this.props.match.params?.id) : undefined;
        this.setState({ loadingData: true });
        this.campaignStore.initialCampaign(id).then(() => {
            this.setState({ loadingData: false });
        });
        this.tourStore.toggleNav(true);
    }

    componentWillUnmount() {
        this.props.notificationStore.closeModal();
        this.props.modalStore.closeModal();
    }

    /** закрытие по крестику */
    handleClose = () => {
        const size = this.campaignStore.meta.per_page || 10;
        const page = this.campaignStore.meta.current_page || 1;
        this.props.history.push(`/?pageNumber=${page}&size=${size}`);
    }

    /** сохранение кампании на 1-м шаге*/
    saveCampaign = async (): Promise<{ type: string; message: string; id?: number; }> => {
        const result = await this.campaignStore.form.validate();

        if (result.hasError) {
            return Promise.resolve({ type: StatusNotification.error, message: 'Form is not valid...' });
        } else {
            return this.campaignStore.saveCampaign();
        }
    }

    /** по нажатию на выбранный шаг **/
    setActiveStep = (index: number) => {
        const activeStep = this.getActiveStep();

        switch (index) {
            case (1): {
                /** если переходим на второй шаг - передаем откуда мы переходим на 2 шаг: с 3-го или с 1-го*/
                this.toSecondStep(activeStep.title === TitleStep.DEFINE_STYLE ? 2 : 0);
                break;
            }
            case (2): {
                /** переход на 3- шаг  */
                this.handleNext();
                break;
            }
            default: {
                /** переход на первый шаг - просто сменяет шаг */
                this.setState((prevState) => ({
                    ...prevState,
                    steps: prevState.steps.map((step, i) => {
                        return {
                            ...step,
                            active: i === index,
                        }
                    }),
                }));
            }
        }
    }

    saveAsDraft = () => {
        const activeStep = this.getActiveStep();

        switch (activeStep.title) {
            case (TitleStep.DEFINE_OBJECTIVE): {
                this.saveCampaign()
                    .then(({ type, message, id }) => {
                        if (type === StatusNotification.success) {
                            this.changeStatusAsDraft();
                        } else {
                            this.props.notificationStore.error(message);
                        }
                    })
                    .catch(() => {
                        this.changeStatusAsDraft();
                    })
                break;
            }
            case (TitleStep.DEFINE_PRODUCTS): {
                if (isEmpty(this.campaignStore.products)) {
                    this.props.notificationStore.error('need to add at least one product');
                    return;
                }

                this.campaignStore.updateProducts().then(() => {
                    this.changeStatusAsDraft();
                })
                break;
            }
            default: {
                this.changeStatusAsDraft();
            }
        }
    }

    changeStatusAsDraft = () => {
        if (this.campaignStore.campaign_id && this.campaignStore.campaign_status === CampaignStatuses.Active) {
            repository.campaign.changeStatusCampaignAsDraft(this.campaignStore.campaign_id).then(() => {
                this.props.history.push('/');
                this.props.notificationStore.success('Campaign saved as draft');
            });
        } else {
            this.props.history.push('/');
            this.props.notificationStore.success('Campaign saved as draft');
        }
    }

    handleNext = () => {
        const activeStep = this.getActiveStep();

        // переход на второй шаг
        if (activeStep.title === TitleStep.DEFINE_OBJECTIVE) {
            this.toSecondStep();
        }

        // переход на третий шаг
        if (activeStep.title === TitleStep.DEFINE_PRODUCTS && this.campaignStore.campaign_id) {
            this.toThreeStep();
        }
    }

    handleSave = () => {
        const campaignIsReady = this.campaignStore.campaign_status === CampaignStatuses.Draft
            && this.props.stylePageStore.pages.length > 3;

        if (this.campaignStore.campaign_id && campaignIsReady) {
            repository.campaign.changeStatusCampaignAsActive(this.campaignStore.campaign_id).then(() => {
                this.props.notificationStore.success('Campaign is active');
                this.props.history.push('/');
            });
        } else {
            this.props.history.push('/');
        }

        if (this.tourStore.isOpen) {
            setTimeout(() => {
                this.tourStore.nextCycle(2);
            }, 1000);
        }

    }

    toSecondStep = (index: number = 0) => {
        if (index === 2) {
            this.setState((prevState) => ({
                ...prevState,
                steps: CampaignPresenter.prevStep(index, prevState.steps),
            }));

            if (this.tourStore.isOpen) {
                this.tourStore.setCurrentStep(0);
            }

            return;
        }

        this.saveCampaign()
            .then(({ type, message, id }) => {
                if (type === StatusNotification.success) {
                    if (id) {
                        this.props.history.push(`/campaigns/${id}`)
                    }
                    if (this.tourStore.isOpen) {
                        this.tourStore.setCurrentStep(0);
                    }
                    this.props.notificationStore.success(message);
                    this.setState((prevState) => ({
                        ...prevState,
                        steps: CampaignPresenter.nextStep(index, prevState.steps),
                    }))
                } else {
                    this.props.notificationStore.error(message);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({
                    ...prevState,
                    steps: CampaignPresenter.nextStep(index, prevState.steps),
                }));
            });
    }

    toThreeStep = () => {

        if (isEmpty(this.campaignStore.products)) {
            this.props.notificationStore.error('need to add at least one product');
            return;
        }

        this.campaignStore.updateProducts().then(() => {
            const id = this.campaignStore.campaign_id;
            const products = this.campaignStore.getProductsValue;

            if (id && !isEmpty(products)) {
                const { page_template } = this.campaignStore.form.$;
                this.props.stylePageStore.initialCampaign(id, products, page_template.value);
                this.setState((prevState) => ({
                    ...prevState,
                    steps: CampaignPresenter.nextStep(1, prevState.steps),
                }));
                this.tourStore.setCurrentStep(0);
            }
        });
    }

    getActiveStep = () => this.state.steps.find(step => step.active) || {} as IStep;

    renderHeaderStep = (step: IStep, index: number) => {
        const dataTour = step.title === TitleStep.DEFINE_PRODUCTS
            ? 'step-2'
            : step.title === TitleStep.DEFINE_STYLE
                ? 'step-3'
                : 'step-1';

        return (
            <div
                key={`header-step-${index}`}
                className={`header__tabs-item header__tabs-item_enabled ${step.active ? 'header__tabs-item_active': ''}`}
                data-tour={dataTour}
            >
                <div className="header__tabs-link" onClick={() => !step.active && this.setActiveStep(index)}>
                    <div className="header__tabs-icon">
                        <Icon icon={step.icon} width={26} height={26} />
                    </div>
                    <div className="header__tabs-content">
                        <p className="header__tabs-title">{step.title}</p>
                        <span className="header__tabs-description">{step.status}</span>
                    </div>
                </div>
            </div>
        );
    }

    renderButtonSaveAsDraft = () => {
        if (this.state.loadingData) {
            return null;
        }

        if (this.campaignStore.campaignIsActive()) {
            return (
                <div className="header__tabs-right">
                    <span className='button button__link' onClick={this.saveAsDraft}>Revert status to draft</span>
                </div>
            );
        }

        if (this.campaignStore.campaignIsDraft()) {
            return (
                <div className="header__tabs-right">
                    <span className='button button__link' onClick={this.saveAsDraft}>Save as draft</span>
                </div>
            );
        }

        return null;

    }

    getTourSteps = (title: TitleStep) => {
        if (title === TitleStep.DEFINE_OBJECTIVE) return {
            steps: objectsSteps,
            stepName: 'objectsSteps'
        };

        if (title === TitleStep.DEFINE_PRODUCTS) return {
            steps: productSteps,
            stepName: 'productSteps'
        };

        if (title === TitleStep.DEFINE_STYLE) return {
            steps: stylesSteps,
            stepName: 'stylesSteps'
        };

        return {
            steps: objectsSteps,
            stepName: 'objectsSteps'
        }
    }

    getCurrentStep = (nextStep: ReactourStep, currentStep: number) => {

        /** отключаем стрелки для шагов внутри селектов */
        if (
            nextStep.selector && [
                '[data-tour="Accuracy"]',
                '[data-tour="Accuracy-open"]',
                '[data-tour="Cashbacks approval"]',
                '[data-tour="Cashbacks approval open"]',
                '[data-tour="Select template"]',
                '[data-tour="template"]',
                '[data-tour="select page template"]',
                '[data-tour="options page template"]',
                '[data-tour="content-1"]',
                '[data-tour="content-2"]',
                '[data-tour="content-3"]'
            ].includes(nextStep.selector)
        ) {
            this.tourStore.setCurrentStep(currentStep);
            this.tourStore.toggleNav(false);
        } else {
            this.tourStore.setCurrentStep(currentStep);
            this.tourStore.toggleNav(true);
        }
    }

    render() {
        const isAllValidAndStepThree = this.state.steps[2].active;
        const activeStep: IStep = this.getActiveStep();

        return (
            <div className="">
                <ReactTour
                    {...this.getTourSteps(activeStep.title)}
                    getCurrentStep={this.getCurrentStep}
                />
                <div className="page__header">
                    <header className="header">
                        <div className="header__container">
                            <div className="header__row">
                                <div className="header__col header__col_first">
                                    <div className="header__close" onClick={this.handleClose}>
                                        <Icon icon={Icons.icon_close}/>
                                    </div>
                                </div>
                                <div className="header__separator header__separator_sm header__separator_right"/>
                                <div className="header__col header__col_left">
                                    <div className="header__tabs">
                                        {this.state.steps.map(this.renderHeaderStep)}
                                        {this.renderButtonSaveAsDraft()}
                                    </div>
                                </div>
                                <div className="header__col header__col_last">
                                    <div
                                        data-tour="next-button"
                                        className={`header__button button ${isAllValidAndStepThree ? 'button_success': 'button_primary'}`}
                                        onClick={isAllValidAndStepThree ? this.handleSave : this.handleNext}
                                    >
                                        <span>{isAllValidAndStepThree ? 'SAVE': 'NEXT'}</span>
                                        <span className="button__icon">
                                            <Icon icon={Icons.arrow_right_bold} width={11} height={14} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
                {activeStep.title === TitleStep.DEFINE_OBJECTIVE && (<CampaignOneStep />)}
                {activeStep.title === TitleStep.DEFINE_PRODUCTS && (<CampaignTwoStep />)}
                {activeStep.title === TitleStep.DEFINE_STYLE && this.campaignStore.campaign_id && (
                    <StyleStep
                        id={this.campaignStore.campaign_id}
                        products={this.campaignStore.getProductsValue}
                        companyId={this.campaignStore.company_id}

                    />
                )}
            </div>
        );
    }
}

export const CampaignPage = withRouter(Campaign);