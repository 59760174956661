import React, {useContext} from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {AuthContext} from "../../store/AuthStore";

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
    const {component: Component, ...rest} = props;
    const authContext = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                authContext.token ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: routeProps.location}
                        }}
                    />
                )
            }
        />
    );
};