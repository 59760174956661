import React, {useMemo, useState} from "react";
import {FieldState, FormState} from "formstate-x/lib";

import {repository} from "../../shared/repositories";
import {email} from "../../utils/validators/required";

import {Button, ButtonType} from '../../components/Button';
import {ModalForm} from "../../components/modals/modal-form";
import {Field} from "../../components/Field";


import './styles.scss'
import {notificationEnhancer, INotificationStore} from "../../store";

const required = (value: string) => !value && 'An email address is required';

export const AddInvite = notificationEnhancer(({ notificationStore }: INotificationStore) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const form = useMemo(() => new FormState({
        email: new FieldState('').validators(required, email),
    }), []);

    const formConfig = useMemo(() => {
        return {
            cancelText: "Close",
            submitText: "Send invite",
            formSubmitDisabled: form.hasError,
            onSubmit: () => onSubmit(),
        }
    }, [form.hasError, form.$.email._value]);

    const onSubmit = async () => {
        setLoading(true);
        const res = await form.validate();

        if (res.hasError) {
            setLoading(false);
            return;
        }

        const response = await repository.user.invite(form.value.email)
        setLoading(false);

        if ([200, 201].includes(response.status)) {
            notificationStore.success('the invitation is sent');
        } else {
            notificationStore.error('something went wrong');
        }

        setVisible(false);
    }

    const add = () => {
        form.reset();
        setVisible(!visible)
    }

    return (
        <>
            <Button type={ButtonType.add} text="Invite new user" onClick={add} />
            <ModalForm
                isVisible={visible}
                loading={loading}
                title="Invite user"
                formConfig={formConfig}
                onClose={() => setVisible(false)}
            >
                <Field
                    needFocus={visible}
                    label='Email user'
                    placeholder='Enter email user...'
                    type='email'
                    name='email'
                    value={form.$.email._value}
                    errorMessage={form.$.email.error}
                    onChange={(value: any) => {form.$.email.onChange(value)}}
                />
            </ModalForm>
        </>
    );
});