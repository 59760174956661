import {action, makeObservable, observable} from "mobx";
import {FieldState, FormState} from "formstate-x/lib";
import {required} from "../utils/validators/required";
import {Company, CompanyDocument, CompanySettingsForm, DocumentIndexes, LoadingState} from "../shared/models";
import {repository} from "../shared/repositories";
import {DOCUMENT_CATEGORY} from "../pages/Documents/constants";

export class CompanyStore {
    @observable company: Company;
    @observable form?: FormState<CompanySettingsForm>;
    @observable loading: LoadingState = LoadingState.success;
    @observable documentIndexes: DocumentIndexes = {};
    @observable document?: CompanyDocument;

    constructor() {
        this.company = {} as Company;
        this.form = new FormState({
            title: new FieldState(""),
            vat: new FieldState(""),
            street: new FieldState(""),
            city: new FieldState(""),
            postal_code: new FieldState(""),
        });
        makeObservable(this)
    }


    @action
    async fetchCompany(id: number) {
        this.loading = LoadingState.loading;
        try {
            const response = await repository.company.getCurrentCompany(id)

            this.company = response.data.data;
            this.handleCompany(this.company);
        } finally {
            this.loading = LoadingState.success
        }
    }

    handleCompany(company?: Company) {
        if (!company) {
            return;
        }

        this.form = new FormState({
            title: new FieldState(company.title).validators(required),
            vat: new FieldState(company.vat).validators(required),
            street: new FieldState(company.street).validators(required),
            city: new FieldState(company.city).validators(required),
            postal_code: new FieldState(company.postal_code).validators(required),
        });
    }

    @action
    async save() {
        this.loading = LoadingState.loading;
        try {
            const response = await repository.company.updateCurrentCompany(this.company?.id, this.form?.value)

            this.handleCompany(response.data.data);
            this.company = response.data.data;
            return true;
        } catch (e: any) {
            alert('Something went wrong');
        } finally {
            this.loading = LoadingState.success;
        }

        return false;
    }


    @action
    async loadDocument(id: number) {
        this.loading = LoadingState.loading
        try {
            this.document = this.company.documents.find(doc => doc.id === id)
            this.loading = LoadingState.success;
        } catch (e) {
            this.loading = LoadingState.failed;
        }
    }

    @action
    async newDocument(category: DOCUMENT_CATEGORY) {
        this.loading = LoadingState.loading
        try {
            this.document = {
                title: '',
                category,
            }
            this.loading = LoadingState.success;
        } catch (e) {
            this.loading = LoadingState.failed;
        }
    }

    async saveDocument() {
        this.loading = LoadingState.loading
        try {
            if (this.document) {
                const formData = new FormData();
                formData.append('company_id', `${this.company.id}`);
                formData.append('category', this.document.category);
                formData.append('title', this.document.title);
                formData.append('order', this.document.order || '0');
                this.document.file && formData.append('file', this.document.file);

                if (this.document.id) {
                    await repository.company.updateDocument(this.document.id, formData);
                } else {
                    await repository.company.createDocument(formData);
                }
            }

            this.loading = LoadingState.success;
        } catch (e) {
            this.loading = LoadingState.failed;
        }
    }
}