import React, {useMemo} from 'react';
import {BrowserRouter as Router, Route, Switch, withRouter} from 'react-router-dom'
import {Provider} from "mobx-react";

import Account from "pages/Account";
import CompanySettings from 'pages/CompanySettings/CompanySettings';
import Login from 'pages/Login/Login';
import {PrivateRoute} from "utils/routes/PrivateRoute";
import Users from "pages/Users/Users";
import {RootStore} from "store";
import 'App.scss';
import {getNotificationComponent} from "components/Notifications";
import TicketSettings from "pages/ticket-templates/TicketSettings";
import {AllIcons} from "components/Icon";
import {CampaignPage} from "pages/Campaigns/Campaign";
import ForgotPassword from "pages/Login/ForgotPassword";
import Register from "pages/Login/SignUp";
import Cashback from "pages/cashback/Cashback";
import {ModalEdit} from "pages/Campaigns/style-step/modal-edit";
import {ModalConfirm} from "components/modals/Confirm";
import {LandingPage} from "pages/preview-page";
import {PreviewPage} from "pages/Campaigns/style-step/preview-page";
import {NewPassword} from "pages/Login/NewPassword";
import {Home} from "pages/super-dashboard";
import {ModalEditDocument} from "components/modals/modal-document";
import {Pricing} from "pages/CompanySettings/pricing";
import {Tests} from "pages/testComponent";
import {DocumentPreview} from "pages/Documents/DocumentPreview";
import {CampaignReport} from "pages/campaign-report";
import GroupDetails from "pages/campaign-groups/group-details";
import {CampaignsList} from "pages/Campaigns/Campaigns";
import FaqPage from "./pages/faq";

const App = () => {
    const rootStore = new RootStore();
    const stores = {
        rootStore,
        accountStore: rootStore.accountStore,
        authStore: rootStore.authContext,
        userStore: rootStore.userStore,
        companyStore: rootStore.companyStore,
        campaignStore: rootStore.campaignStore,
        ticketsStore: rootStore.ticketsStore,
        modalStore: rootStore.modalStore,
        stylePageStore: rootStore.stylePageStore,
        notificationStore: rootStore.notificationStore,
        groupCampaignStore: rootStore.groupCampaignStore,
        tourStore: rootStore.tourStore,
    };
    const Notifications = getNotificationComponent();

    return (
        <Router>
            <Provider {...stores}>
                <Notifications />
                <ModalConfirm />
                <ModalEdit />
                <ModalEditDocument />
                <div className="page">
                    <Switch>
                        <PrivateRoute exact path="/" component={Home} />
                        <PrivateRoute exact path="/users" component={Users} />
                        <PrivateRoute exact path="/profile" component={Account} />
                        <PrivateRoute exact path="/campaigns/new" component={CampaignPage} />
                        <PrivateRoute exact path="/campaigns/:id" component={CampaignPage} />
                        <PrivateRoute exact path="/campaigns/:id/cashback" component={Cashback}/>
                        <PrivateRoute exact path="/campaigns/:id/report" component={withRouter(CampaignReport)} />
                        <PrivateRoute exact path="/group/:id" component={withRouter(GroupDetails)} />
                        <PrivateRoute exact path="/ticket-settings" component={TicketSettings} />
                        <PrivateRoute exact path="/company-settings" component={CompanySettings} />
                        <PrivateRoute exact path="/company-settings/pricing" component={Pricing} />
                        <PrivateRoute exact path="/reports" component={withRouter(CampaignsList)} />
                        <Route exact path="/preview-template/:id/:template" component={PreviewPage} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/faq" component={FaqPage} />
                        <Route exact path="/preview/:id" component={LandingPage} />
                        <Route exact path="/company/:id/:docType" component={withRouter(DocumentPreview)} />
                        <Route exact path="/forgot-password" component={withRouter(ForgotPassword)} />
                        <Route exact path="/new-password" component={NewPassword} />
                        <Route exact path="/register" component={withRouter(Register)} />
                        <Route exact path="/tests" component={Tests} />
                        <Route exact path="/icons" component={AllIcons} />
                        <Route exact path="/:id" component={LandingPage} />
                        <Route exact path="/:id/:slug" component={LandingPage} />
                    </Switch>
                </div>
            </Provider>
        </Router>
    )

}

export default App;
