import {campaignStoreEnhancer, ITourStore, tourEnhancer} from "../../store";
import Tour, {ReactourStep} from "reactour";
import React, {useEffect, useRef, useState} from "react";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {isEmpty} from "lodash";
import {Button} from "../Button";
import classNames from "classnames";
import {ICampaignStore} from "../../pages/Campaigns/models";

interface IReactTourProps {
    steps: ReactourStep[];
    stepName: string;
    withDropDown?: boolean;
    getCurrentStep?(step: ReactourStep, currentStep: number): void;
}

const ReactTour = campaignStoreEnhancer(({ tourStore, campaignStore, steps, withDropDown, getCurrentStep, stepName }: ICampaignStore & ITourStore & IReactTourProps) => {
    const {
        isOpen,
        currentStep,
        showNavigation,
        setCurrentStep,
        toggleOpen,
        nextCycle,
    } = tourStore;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const select = useRef<HTMLHeadingElement>(null);
    const classes = classNames({
        'select3': true,
        'select2-container': true,
        'select2-container--open': open,
    });

    const onBlur = () => {
        setOpen(false);
        select && select.current && select.current.blur && select?.current.blur();
    }

    const onFocus = () => {
        setOpen(true);
    }

    useEffect(() => {
        setCurrentStep(0);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 0);

        const isShowed: string[] = localStorage.getItem('steps-tour-is-done')
            ? JSON.parse(localStorage.getItem('steps-tour-is-done') || '')
            : [];

        if (isShowed.includes(stepName)) {
            return;
        }

        isShowed.push(stepName);
        localStorage.setItem('steps-tour-is-done', JSON.stringify(isShowed));

        if (!isOpen && campaignStore.campaignsFetched && isEmpty(campaignStore.campaigns)) {
            openTour(0);
            return;
        }


        
    }, [steps, campaignStore.campaignsFetched]);

    if (isEmpty(steps)) {
        return null;
    }

    const disableBody = (target: any) => disableBodyScroll(target);
    const enableBody = (target: any) => enableBodyScroll(target);

    const openTour = (cycle: number) => {
        onBlur();
        setLoading(true);
        setCurrentStep(0);
        nextCycle(cycle);
        setTimeout(() => {
            setLoading(false);
            toggleOpen(true);
        }, 0);

    }

    return (
        <>
            <div style={{ position: 'fixed', bottom: 10, left: 10, zIndex: 200 }} data-tour={'help-button'}>
                {withDropDown ? (
                    <div className={classes} onFocus={onFocus} onBlur={onBlur} tabIndex={0} ref={select}>
                        <Button text={'?'} />
                        <div className="select2-dropdown">
                            <ul className="select-dropdown__options">
                                <span className="option" onClick={() => openTour(0)}>Start new tour</span>
                                <span className="option" onClick={() => openTour(2)}>Start tour with existing campaign</span>
                            </ul>
                        </div>
                    </div>
                ) : (
                    <Button text={'?'} onClick={() => openTour(0)} />
                )}

            </div>
            {!loading && (
                <Tour
                    steps={steps}
                    scrollOffset={100}
                    rounded={10}
                    disableFocusLock={true}
                    showNumber={false}
                    isOpen={isOpen}
                    onRequestClose={() => {
                        setCurrentStep(0);
                        toggleOpen(false);
                    }}
                    prevButton={() => null}
                    goToStep={currentStep}
                    onAfterOpen={disableBody}
                    onBeforeClose={enableBody}
                    disableKeyboardNavigation={showNavigation ? ['left'] : ['esc', 'left', 'right']}
                    getCurrentStep={(step: number) => getCurrentStep && getCurrentStep(steps[step], step)}
                    showNavigation={false}
                    showButtons={showNavigation}
                />
            )}
        </>
    );
});


export default ReactTour as React.ComponentType<IReactTourProps>;