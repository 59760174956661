import {CashbackStatuses, ICampaignStore, ICashback} from "pages/Campaigns/models";
import {Button} from "components/Button";
import {campaignStoreEnhancer, INotificationStore} from "store";
import 'pages/Campaigns/campaigns.scss';
import {BULK_STATUS, BulkStatus, IBulk} from "./models";
import moment from "moment-timezone";
import {DATE_VIEW_FORMAT} from "../../shared/constants";
import {repository} from "../../shared/repositories";
import FileSaver from "file-saver";

export const CashbackItem = campaignStoreEnhancer((props: ICashback & ICampaignStore & { hideActionsColumn?: boolean, dataTour?: string }) => {
    const getLogo = () => {
        return (<div className={`campaign__brand-logo`}>{props.name?.slice(0, 2)}</div>);
    }

    const getStatusColor = () => {
        switch (props.status) {
            case 1: {
                return 'green';
            }
            case 2: {
                return 'orange';
            }
            case 3: {
                return 'red';
            }
            default: {
                return 'gray'
            }
        }
    }

    const showModal = () => {
        props.campaignStore.showModalReview(props.id, props.invoice.id, props.status, `${props.name} / ${props.payment_method}`);
    }

    return (
        <>
            <tr className="table__row pointer">
                <td className="table__td">{props.id}</td>
                <td className="table__td">
                    <div className="campaign__brand">
                        {getLogo()}
                        {props.name}
                    </div>
                </td>
                <td className="table__td">{props.email}</td>
                <td className="table__td">
                    {props.product}
                </td>
                <td className="table__td">
                    <div >{props.payment_method}</div>
                </td>
                <td className="table__td">
                    <div >{props.accuracy}</div>
                </td>
                <td className="table__td">
                    <div >{props.sum}</div>
                </td>
                <td className="table__td" data-tour={props.dataTour ? 'status': ''}>
                    <div className={`campaigns-block__category campaigns-block__category_${getStatusColor()}`}>{CashbackStatuses[props.status]}</div>
                </td>
                {!props.hideActionsColumn && (
                    <td className="table__td table__td_actions" data-tour={props.status !== 2 && props.dataTour ? 'view details' : ''}>
                        <Button text={props.status === 2 ? 'Review' : 'View details'} isSmall full isGray={props.status !== 2} onClick={showModal} />
                    </td>
                )}
            </tr>

            <tr className="table__spacer"/>
        </>
    );
});

export const BulkItem = campaignStoreEnhancer((props: IBulk & ICampaignStore & INotificationStore) => {
    const { campaign_id, fetchBulks, bulkMeta: {per_page, current_page} } = props.campaignStore;

    const getStatusColor = () => {
        switch (props.status) {
            case 1:
            case 2: {
                return 'green';
            }
            case 3: {
                return 'red';
            }
            default: {
                return 'gray'
            }
        }
    }

    const handleExport = () => {
        if (campaign_id) {
            repository.bulk.export(campaign_id, props.id).then(response => {
                const data = new Blob([response.data], {type: 'xls'});
                FileSaver.saveAs(data, `export-bulk-${props.id}.xls`);
            })
        }

    }

    const handleDecline = () => {
        if (campaign_id) {
            repository.bulk.decline(campaign_id, props.id).then(response => {
                if (response.data) {
                    props.notificationStore.success('bulk is declined');
                    fetchBulks(per_page, current_page);
                }
            })
        }
    }

    const handleApprove = () => {
        if (campaign_id) {
            repository.bulk.accept(campaign_id, props.id).then(response => {
                if (response.data) {
                    props.notificationStore.success('bulk is approval');
                    fetchBulks(per_page, current_page);
                }
            })
        }
    }

    return (
        <>
            <tr className="table__row">
                <td className="table__td">{props.id}</td>
                <td className="table__td">{props.created_at && moment(props.created_at).format(DATE_VIEW_FORMAT)}</td>
                <td className="table__td">{props.budget} EUR</td>
                <td className="table__td">{props.cashbacks_count}</td>
                <td className="table__td">
                    <div className={`campaigns-block__category campaigns-block__category_${getStatusColor()}`}>{BULK_STATUS[props.status]}</div>
                </td>
                <td className="table__td">{props.updated_at && moment(props.updated_at).format(DATE_VIEW_FORMAT)}</td>
                <td className="table__td table__td-buttons">
                    <Button text='Export' isSmall onClick={handleExport} />
                    <Button disabled={props.status !== BulkStatus.Created} text='Decline' isAlert isSmall onClick={handleDecline} />
                    <Button disabled={props.status !== BulkStatus.Created} text='Approval' isSuccess isSmall onClick={handleApprove} />
                </td>
            </tr>

            <tr className="table__spacer"/>
        </>
    );
});