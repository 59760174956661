import * as React from "react";
import {PureComponent} from "react";
import {HomeProps, IDashboardState, IFilterParams} from "./models";
import Header from "../../components/Header/Header";
import {Button} from "../../components/Button";
import {ModalReview} from "../../components/modals/ModalReview";
import {IListProps, List} from "../../components/List";
import {CashbackItem} from "../cashback/CashbackItem";
import {isEmpty} from "lodash";
import {Paginations} from "../../components/Paginations";
import {Company, Meta, User} from "../../shared/models";
import {getFilterPeriod, getQueryParams} from "../../utils/utils";
import {repository} from "../../shared/repositories";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import FileSaver from "file-saver";
import moment from "moment-timezone";
import {DATE_TIME_FOR_NAME} from "../../shared/constants";
import {SelectFilterMini} from "../../components/select-filter/SelectFilterMini";
import './styles.scss';
import {DEFAULT_LIST, LIST_PERIOD, LIST_STATUS, Period} from "../constants";
import {TList} from "../Campaigns/style-step/models";
import {CashbackStatuses, ICampaign} from "../Campaigns/models";
import Loading from "../../components/Loading";

const reducer = (accumulator: TList<string>, currentValue: Company | ICampaign) => [...accumulator, { title: currentValue.title, value: currentValue.id.toString()}]

@inject('authStore')
@observer
class _SuperDashboard extends PureComponent<HomeProps, IDashboardState> {
    private readonly config: IListProps<User>['config'];

    constructor(props: HomeProps) {
        super(props);
        this.config = {
            sizes: ['5%', '20%', '15%', '15%', '20%', '10%', '10%', '10%'],
            withSort: true,
            headTitles: ['ID', 'CASHBACK NR', 'NAME', 'PRODUCT', 'PAYMENT METHOD', '% OF ACCURACY', 'CASHBACK', 'STATUS'],
        }

        this.state = {
            loading: false,
            cashbacks: [],
            cashbackMeta: {} as Meta,
            companies: DEFAULT_LIST,
            campaigns: DEFAULT_LIST,
            currentCompany: DEFAULT_LIST[0].value,
            currentCampaign: DEFAULT_LIST[0].value,
            currentPeriod: LIST_PERIOD[0].value,
            currentStatus: LIST_STATUS[0].value
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = (page1?: number, size1?: number) => {
        const { search } = this.props.location;
        const { pageNumber, size } = getQueryParams(search);
        const { currentCompany, currentCampaign, currentStatus, currentPeriod } = this.state;
        const filterParams: IFilterParams = {
            company: currentCompany === DEFAULT_LIST[0].value ? null : currentCompany,
            campaign: currentCampaign === DEFAULT_LIST[0].value ? null : currentCampaign,
            period: getFilterPeriod(currentPeriod),
            status: currentStatus === CashbackStatuses.Accepted ? null : currentStatus.toString(),
        }
        this.setState({ loading: true })
        return Promise.all([
            repository.user.getListCashbacks(page1 || pageNumber, size1 || size, filterParams),
            repository.company.getListCompanies(),
        ]).then(([responseCashbacks, responseCompanies]) => {
            this.setState({
                loading: false,
                cashbacks: responseCashbacks.data?.data || [],
                cashbackMeta: responseCashbacks.data?.meta || {},
                companies: responseCompanies.data.data ? responseCompanies.data.data.reduce(reducer, DEFAULT_LIST) : DEFAULT_LIST,
            })
        }).catch(() => {
            this.setState({
                loading: false,
                companies: DEFAULT_LIST,
            })
        })
    }

    export = () => {
        const { currentCompany, currentCampaign, currentStatus, currentPeriod } = this.state;
        const filterParams: IFilterParams = {
            company: currentCompany === DEFAULT_LIST[0].value ? null : currentCompany,
            campaign: currentCampaign === DEFAULT_LIST[0].value ? null : currentCampaign,
            period: getFilterPeriod(currentPeriod),
            status: currentStatus === CashbackStatuses.Accepted ? null : currentStatus.toString(),
        }
        repository.user.export(filterParams).then(response => {
            const today = moment().format(DATE_TIME_FOR_NAME)
            const data = new Blob([response.data], {type: 'xml'});
            FileSaver.saveAs(data, `export-cashbacks-${today}.xml`);
            this.fetchData();
            this.props.history.push('/');
        });
    }

    changeCompany = (value: string) => {
        this.setState({
            currentCompany: value,
            currentCampaign: DEFAULT_LIST[0].value,
        }, this.fetchData);

        if (value !== DEFAULT_LIST[0].value) {
            repository.campaign.getCampaignsList(Number(value), 100, 1).then(response => {
                const campaignsList: ICampaign[] = response.data?.data;

                this.setState({
                    campaigns: campaignsList.reduce(reducer, DEFAULT_LIST),
                })
            });
        }

    }

    changeCampaign = (value: string) => {
        this.setState({
            currentCampaign: value,
        }, this.fetchData)
    }

    changePeriod = (value: Period) => {
        this.setState({
            currentPeriod: value,
        }, this.fetchData)
    }

    changeStatus = (value: string) => {
        this.setState({
            currentStatus: value,
        }, this.fetchData)
    }

    renderFilter = () => {
        return (
            <div className="dashboard-filter">
                <div className="dashboard-filter__row">
                    <div className="dashboard-filter__col col col-2">
                        <SelectFilterMini
                            label="Company"
                            value={this.state.currentCompany}
                            labelOnTop
                            list={this.state.companies}
                            onChange={this.changeCompany}
                        />
                    </div>
                    <div className="dashboard-filter__col col col-2">
                        <SelectFilterMini
                            label="Campaign"
                            value={this.state.currentCampaign}
                            labelOnTop
                            list={this.state.campaigns}
                            onChange={this.changeCampaign}
                        />
                    </div>
                    <div className="dashboard-filter__col col col-2">
                        <SelectFilterMini
                            label="Period"
                            labelOnTop
                            value={this.state.currentPeriod}
                            list={LIST_PERIOD}
                            onChange={this.changePeriod}
                        />
                    </div>
                    <div className="dashboard-filter__col col-2">
                        <SelectFilterMini
                            label="Status"
                            labelOnTop
                            value={this.state.currentStatus}
                            list={LIST_STATUS}
                            onChange={this.changeStatus}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <Loading active={this.state.loading} />
                <Header />
                <main className="page__main page__main--grey page__main--with-head">
                    <div className="page__container v-container">
                        <section className="section">
                            <div className="section__tools">
                                <div className="section__tools-row flex-wrap">
                                    <div className="section__tools-col col">
                                        <h2 className="section__title">Cashbacks</h2>
                                    </div>
                                    <div className="section__tools-col col-12 col-lg-auto">
                                        <Button text="Export" onClick={this.export} />
                                    </div>
                                </div>
                            </div>
                            {this.renderFilter()}
                            <ModalReview />
                            <List config={this.config} data={this.state.cashbacks} component={CashbackItem} componentProps={{ isSuperUser: true }} />
                        </section>
                        {!isEmpty(this.state.cashbacks) && (
                            <Paginations
                                meta={this.state.cashbackMeta || {} as Meta}
                                onChange={(size: number, page: number) => {
                                    this.fetchData(page, size);
                                    this.props.history.push(`/?pageNumber=${page}&size=${size}`);
                                }}
                            />
                        )}
                    </div>
                </main>
            </>
        );
    }
}

export const SuperDashboard = withRouter(_SuperDashboard) as unknown as React.ComponentType<{}>