import React from "react";
import {INotificationStore, notificationEnhancer} from "store";
import {Library} from "components/library";
import {Documents} from "pages/Documents/Documents";

export const ModalEditDocument = notificationEnhancer((props: INotificationStore) => {
    const { document, closeDocument } = props.notificationStore;

    return (
        <Library title={document.title} open={document.open} onClose={closeDocument}>
            {document.id && (<Documents id={document.id} onSave={closeDocument} />)}
        </Library>
    );
})