import React, {FC, useState} from "react";
import {Icon, Icons} from "../Icon";

import {IAccordionProps} from "./models";
import './styles.scss';

export const Accordion: FC<IAccordionProps> = (props) => {
    const { updateDataTour, dataTour, isGrey, title, icon, children, provided, isOpen, onToggle, onCopy, onRemove} = props;
    const [counter, setCount] = useState(0);

    const handleToggle = () => {
        setCount(1);
        onToggle();
        if (updateDataTour) {
            updateDataTour(isOpen);
        }
    }

    const handleCopy = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onCopy && onCopy();

    }
    const handleRemove = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onRemove && onRemove();
    }

    return (
        <div className={`accordion ${isOpen ? 'accordion--open' : counter > 0 ? 'accordion--closed' : 'accordion--initial-closed'}`}
             ref={provided?.innerRef}
             {...(provided?.draggableProps || {})}
             style={provided?.draggableProps.style}
             data-tour={dataTour}
        >
            <div className={`accordion__header ${isGrey ? 'accordion__header--grey' : ''}`} onClick={handleToggle}>
                {provided && (
                    <div className="accordion__draggable"
                         {...provided.dragHandleProps}

                    />
                )}
                <div className="accordion__title">
                    {icon && (<Icon icon={icon}  width={20} height={20} />)}
                    <span className="text">{title}</span>
                </div>
                <div className="accordion__actions">
                    {onCopy && (
                        <div className="accordion__action" onClick={handleCopy}>
                            <Icon icon={Icons.icon_copy} width={16} height={16} />
                        </div>
                    )}
                    {onRemove && (
                        <div className="accordion__action" onClick={handleRemove}>
                            <Icon icon={Icons.close_circle} width={16} height={16} />
                        </div>
                    )}
                </div>
                <div className="accordion__arrow">
                    <Icon icon={Icons.arrow_drop_down} width={12} height={6} />
                </div>
            </div>
            <div className="accordion__content">
                <div className="accordion__body">
                    {children}
                </div>
            </div>
        </div>
    );
}