import React, {FC, useEffect, useRef, useState} from "react";
import InputMask from 'react-input-mask';
import {v4 as uuidv4} from "uuid";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {Currency} from "../../pages/Campaigns/models";
import {ICurrencyFieldProps, IFieldProps, IInputProps} from "./models";
import './styles.scss'
import {Icon, Icons} from "../Icon";

export const Field: FC<IFieldProps> = (props)=> {
    const { label, errorMessage, needFocus, currency, prefix, description, type, min, max } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [focus, setFocus] = useState(false);
    const [passwordType, setPasswordType] = useState('password');

    const inputProps: IInputProps = {
        name: props.name,
        required: props.required,
        type: type === "password" ? passwordType : type ? type : 'text',
        placeholder: props.placeholder
    }


    const handlePress = (key: any) => {
        key.charCode === 13 ? setFocus(false) : setFocus(true);
        props.onKeyPress && props.onKeyPress(key);
    }

    useEffect(() => {
        if (needFocus) {
            inputRef?.current?.focus();
        }
    }, [needFocus])

    const error = errorMessage && !focus;

    const float2 = (value: string) => {
        let result = value;

        if (/[^0-9.,-]/.test(value)) {
            result = value.replace(/[^0-9.,]/g, '')
        }

        if (!result || !/^\d{0,16}[,.]?\d{0,2}$/.test(result)) return result;

        result = result.replace(/[,]/g, '.');
        return result.replace(/^0[\d]/, result[1]);
    };

    const handleChange = (e: any) => {
        let value;

        if (type === "phone") {
            value = e;
        } else
        if (type === "number") {
            value = float2(e.target.value);
        } else {
            value = e.target.value;
        }

        props.onChange && props.onChange(value);
    }

    const handleShowPass = (show: boolean) => {
        setPasswordType(show ? "text" : "password");
    }

    const handleBlur = () => {
        if (min && props.value && Number(min) > Number(props.value)) {
            props.onChange && props.onChange(min);
        } else if (max && props.value && Number(max) < Number(props.value)) {
            props.onChange && props.onChange(max);
        }
        setFocus(false)
    }

    const renderInput = () => {
        const preferredCountries = ["us", "gb", "be", "nl", "de", "fr"];

        if (type === 'phone') {
            return (
                <PhoneInput
                    country={'be'}
                    value={props.value as string}
                    preferredCountries={preferredCountries}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    onChange={handleChange}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    onKeyDown={props.onKeyPress}
                    inputProps={{
                        name: props.name,
                        required: true,
                        autoFocus: true
                    }}
                />
            );
        }
        if (props.mask) {
            return (
                <InputMask
                    {...inputProps}
                    className="field__input-text input-text"
                    onChange={handleChange}
                    onKeyPress={handlePress}
                    value={props.value}
                    mask={props.mask}
                    inputRef={inputRef}
                    alwaysShowMask={false}
                    onKeyDown={props.onKeyPress}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />
            );
        }
        if (type === 'textarea') {
            return (
                <textarea
                    {...inputProps}
                    className="field__input-text field__input-text_area"
                    ref={textareaRef}
                    onChange={handleChange}
                    onKeyDown={handlePress}
                    readOnly={props.noAutocomplete ? !focus : false}
                    value={props.value}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                />
            );
        }
        if (props.children) {
            return props.children;
        }

        return (
            <input
                {...inputProps}
                className={`field__input-text input-text`}
                ref={inputRef}
                onChange={handleChange}
                onKeyDown={handlePress}
                autoComplete={props.noAutocomplete ? 'off' : undefined}
                readOnly={props.noAutocomplete ? !focus : false}
                value={props.value}
                onFocus={() => setFocus(true)}
                onBlur={handleBlur}
                min={props.min}
                max={props.max}
            />
        );

    }

    const renderLabel = () => {
        if (!label) {
            return null;
        }

        if (description) {
            return (
                <p className="field__title">
                    { label }
                    <span className="field__description">
                        { description }
                    </span>
                </p>
            );
        }

        return (
            <p className="field__title">{label}</p>
        );
    }

    return (
        <div data-tour={props.dataTour} className={`field  ${error ? 'sign__form-field input-error': ''}`}>
            {renderLabel()}
            <div className="field__input">
                {renderInput()}
                {currency && (<CurrencyField value={currency} onChange={props.onChangeCurrency} />)}
                {type === 'password' && (
                    <div
                        className="field__eye"
                        onMouseDown={() => handleShowPass(true)}
                        onMouseUp={() => handleShowPass(false)}

                    />
                )}
                {prefix && (<div className="input-sub__domain">{prefix}</div>)}
            </div>
            {error && (<div className={'field__message color-danger'}>{errorMessage}</div>)}
        </div>
    );
}

const CurrencyField: FC<ICurrencyFieldProps> = ({ value, onChange }) => {
    const [open, setOpen] = useState<boolean>(false);
    const select = useRef<HTMLHeadingElement>(null);
    const list = [
        {
            icon: Icons.eur,
            value: Currency.EUR,
        },
        {
            icon: Icons.percent,
            value: Currency.USD,
        },
    ];

    const onBlur = () => {
        setOpen(false);
        select && select.current && select.current.blur && select?.current.blur();
    }

    const onSelect = (newValue: Currency) => {
        if (value === newValue) {
            onBlur();
            return;
        }

        onChange && onChange(newValue);
        onBlur();

    }
    const getIcon = (value: Currency): Icons => {
        const currencyIcons = {
            [Currency.EUR]: Icons.eur,
            [Currency.USD]: Icons.percent,
        }

        return currencyIcons[value];
    }

    return (
        <div
            className={`currency-field ${open ? 'currency-field--open' : 'currency-field--close'}`}
            onBlur={() => setOpen(false)}
            tabIndex={0}
            ref={select}
        >
            <div
                className={`currency-field__select`}
                onClick={() => { open ? onBlur() : setOpen(true)}}
            >
                <Icon icon={getIcon(value)} />
            </div>
            <div className="currency-field__dropdown">
                <ul className="currency-field__list">
                    {list.map(option => {
                        const key = uuidv4();

                        return(
                            <li className="currency-field__item" onClick={() => onSelect(option.value)} key={key}>
                                <Icon icon={option.icon} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}