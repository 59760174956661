import {INotificationStore, notificationEnhancer} from "../../store";
import {Button} from "../Button";

export const ModalConfirm = notificationEnhancer((props: INotificationStore) => {
    const { visible, closeModal, messageConfirm } = props.notificationStore;
    const visibleClass = visible ? 'fadeIn' : 'fadeOut';

    return (
        <div className={`popup ${visibleClass}`}>
            <div className="popup__overlay" onClick={closeModal} />
            <div className="popup__body">
                <div className="popup__close" onClick={closeModal}>
                    <svg className="icon__close" width="11" height="11">
                        <use xlinkHref="#close" />
                    </svg>
                </div>
                <div className="popup__title">Confirm</div>
                <h2 className="popup__text mt-32 mb-24">{messageConfirm}</h2>
                <div className="popup__footer">
                    <Button text="Cancel" isWhite popup onClick={closeModal}  />
                    <Button text={props.notificationStore.buttonConfirm} popup onClick={props.notificationStore.onConfirm} />
                </div>
            </div>
        </div>
    );
})