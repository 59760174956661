import {FC, useState} from "react";
import {ISelectFilterProps, IStatus, StatusesCampaign} from "./models";
import './styles.scss';

export const SelectFilter: FC<ISelectFilterProps> = ({status, onChange}) => {
    const [open, setOpen] = useState(false);

    const handleChange = (newStatus: IStatus) => {
        if (newStatus.value !== status.value) {
            onChange(newStatus);
        }

        setOpen(false);
    }

    return (
        <div className={`select-filter ${open ? 'open': ''}`} tabIndex={0} onBlur={() => setOpen(false)}>
            <div className="select-filter__value" onClick={() => { setOpen(!open)}}>
                <span className="text">
                    {`${status.title} campaign`}
                </span>
            </div>
            <div className="select-filter__dropdown">
                <div className="select-filter__list">
                    {StatusesCampaign.map((statusCampaign) => {
                        return (
                            <div
                                className={`select-filter__item ${status.value === statusCampaign.value ? 'select-filter__item--active': ''}`}
                                key={`${statusCampaign.title}-${statusCampaign.value}`}
                                onClick={() => handleChange(statusCampaign)}
                            >
                                {`${statusCampaign.title} campaign`}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}