import React from "react";
import {isEmpty} from 'lodash';
import {DOC_TYPES, DOCUMENT_CATEGORIES, DOCUMENT_LINKS} from "./constants";
import {repository} from "../../shared/repositories";
import {CompanyDocument} from "../../shared/models";
import {getEnumKeyByEnumValue} from "../../utils/utils";
import Loading from "../../components/Loading";
import {IState, Props} from "./models";

export class DocumentPreview extends React.PureComponent<Props, IState> {
    private title: string;

    constructor(props: Props) {
        super(props);

        this.state = {
            document: {} as CompanyDocument,
            loading: true,
        }
        const { docType } = props.match.params;
        const docKey: string = getEnumKeyByEnumValue(DOCUMENT_LINKS, docType);
        this.title = DOCUMENT_CATEGORIES[docKey] || "";
    }
    componentDidMount() {
        const { id, docType } = this.props.match.params;

        if (id && docType && DOC_TYPES.includes(docType)) {
            repository.company.getCurrentCompany(Number(id)).then(response => {
                const document = response.data.data.documents.find(doc => doc.category === this.title) || {} as CompanyDocument;
                this.setState({
                    document,
                    loading: false,
                })
            })
        } else {
            this.setState({
                loading: false,
            })
        }
    }

    renderDocument = () => {
        const { document, loading } = this.state;
        if (!loading && isEmpty(document)) {
            return (<h2 className="not-page">
                404 page
            </h2>)
        }

        return (
            <p dangerouslySetInnerHTML={{ __html: document.title }} />
        )
    }

    render() {
        const { loading } = this.state;
        return (
            <div className="container">
                <h1>{this.title}</h1>
                <Loading active={loading} />
                {this.renderDocument()}
            </div>
        );
    }
}