import React, {useState} from "react";
import './styles.scss';

interface ISearchInputProps {
    onSearch(value: string): void;
}

const SearchInput = (props: ISearchInputProps) => {
    const [value, setValue] = useState('');

    const handleChange = (event: any) => {
        setValue(event.target.value);
        props.onSearch(value);
    }

    const handlePress = (key: any) => {
        if (key.charCode === 13) {
            props.onSearch(value);
        }
    }

    return (
        <div className="search">
            <input
                className='search__input-text'
                type="search"
                placeholder='Search...'
                value={value}
                onChange={handleChange}
                onKeyPress={handlePress}
            />
        </div>
    );
}

export default SearchInput;