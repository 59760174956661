import React, {useRef, useState} from "react";
import {IMultiSelectProps, ISelectOption} from "./models";
import {SelectMap} from "../../utils/utils";
import './styles.scss';
import {v4 as uuidv4} from "uuid";

export const MultiSelect = <T extends any>(props: IMultiSelectProps<T>) => {
    const { label , list, placeholder, value = [], onChange } = props;
    const select = useRef<HTMLHeadingElement>(null);
    const search = useRef<HTMLInputElement>(null);

    const [open, setOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(SelectMap(value, list));
    const [searchValue, setSearchValue] = useState('');

    const selectIsEmpty = value.length === 0;
    const filteredList = (): ISelectOption<T>[]  => {
        const fList = Array.from(list);

        return fList.filter(i => {
            if (searchValue.length < 3) {
                return true;
            }

            return i.title.toLowerCase().match(searchValue.toLowerCase());
        })
    }

    const onSelect = (event: any, selectOption: ISelectOption<T>) => {
        event.preventDefault();
        event.stopPropagation();

        let newValue = Array.from(value);
        const index = value.indexOf(selectOption.value);

        if (index !== -1) { // need remove
            newValue.splice(index, 1);
        }

        else {
            newValue.push(selectOption.value);
        }

        onChange(newValue);
        setSelectedOptions(SelectMap(newValue, list));
    }

    const onSelectAndClose = (event: any, selectOption: ISelectOption<T>) => {
        onSelect(event, selectOption);
        setSearchValue('');
        onBlur();
    }

    const onBlur = () => {
        setOpen(false);
        select?.current?.blur();
    }

    const handleClickSelect = () => {
        setOpen(true);
    }

    const clear = () => {
        setOpen(false);
        setSearchValue('');
        setSelectedOptions([]);
        onChange([]);
    }

    return (
        <div className="field">
            {label && (<p className="field__title">{label}</p>)}
            <div
                className={`select-full select2-container select2-container--default select2-container--below select2-container--focus ${open ? 'select2-container--open': ''}`}
            >
                <div className="selection">
                    <span className={`${selectIsEmpty ? '' : 'empty'} select2-selection select2-selection--multiple`}>
                        <div className="select2-selection__rendered"
                            onBlur={onBlur}
                            onClick={handleClickSelect}
                            tabIndex={1}
                            ref={select}
                        >
                            <span className={`select2-selection__clear`} onMouseDown={clear}>×</span>
                            {selectedOptions.map((option: ISelectOption<T>, index) => (
                                <li className="select2-selection__choice" key={index + option.title}>
                                    <span className="select2-selection__choice__remove" onMouseDown={(e) => onSelect(e, option)}>×</span>
                                    <div className="">{option?.title}</div>
                                </li>
                            ))}
                            <li className="select2-search select2-search--inline" style={{ maxWidth: 5 + (searchValue.length*10) }}>
                                <input
                                    className="select2-search__field"
                                    type="search"
                                    tabIndex={0}
                                    autoComplete="off"
                                    ref={search}
                                    autoCorrect="off"
                                    autoCapitalize="none"
                                    placeholder={selectIsEmpty ? placeholder : ''}
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        setOpen(true);
                                    }}
                                />
                            </li>
                        </div>
                    </span>
                </div>
                <div className="select2-dropdown select2-dropdown--multi select2-dropdown--below">
                    <span className="select2-results">
                            <ul className="select2-results__options">
                                {filteredList().map(option => {
                                    const selected = option.value === value;
                                    const key = uuidv4();

                                    return (
                                        <li
                                            key={'option-multi-select-' + key}
                                            className={`select2-results__option ${selected ? 'select2-results__option--highlighted' : ''}`}
                                            onMouseDown={(e) => onSelectAndClose(e, option)}
                                        >{option.title}</li>
                                    );
                                })}
                            </ul>
                        </span>
                </div>
            </div>
        </div>
    );
}