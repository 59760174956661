import {Icons} from "../../../components/Icon";
import {FieldState} from "formstate-x/lib";


export enum Droppables {
    blocks = 'blocks',
    page = 'page'
}

export interface IBlock {
    id: string;
    title: string;
    icon: Icons;
    items: ISubBlock[];
    templates: (PageTemplateTypes | string)[],
}

export enum PageTemplateTypes {
    welcome = 'welcome',
    popup_confirm = 'popup_confirm',
    form = 'form',
    upload = 'upload',
    thank_you = 'thank_you',
    all = 'all',
    none = 'none',
}

export interface ISubBlock {
    id: string;
    title: string;
    icon: Icons;
    content: IContent[];
    rowParams: IRowParams;
    templates: (PageTemplateTypes)[],
}

export interface IValParams {
    type?: string;
    bgColor?: string;
    color?: string;
    action?: Action;
    link?: string;
    page?: PageTemplateTypes;
    firstName?: string;
    lastName?: string;
    email?: string;
    IBAN?: string;
    bic?: string;
}
export enum Action {
    moveToPage = 'move',
    link = 'link',
    submit = 'submit',
    cancel = 'cancel',
}

export type ValParams = {
    type: FieldState<string | undefined>;
    action: FieldState<Action | undefined>;
    link: FieldState<string | undefined>;
    page: FieldState<PageTemplateTypes | undefined>;
    bgColor: FieldState<string | undefined>;
    color: FieldState<string | undefined>;
    firstName: FieldState<string | undefined>;
    lastName: FieldState<string | undefined>;
    email: FieldState<string | undefined>;
    IBAN: FieldState<string | undefined>;
    bic: FieldState<string | undefined>;
}

export type IColParams = {
    mobileWidth: string;
    smallWidth: string;
    mediumWidth: string;
    largeWidth: string;
    customMobileWidth?: number;
    customSmallWidth?: number;
    customMediumWidth?: number;
    customLargeWidth?: number;
    maxWidth?: number;
    maxHeight?: number;
    paddingTop?: number;
    paddingLeft?: number;
    paddingRight?: number;
    paddingBottom?: number;
    marginTop?: number;
    marginLeft?: number;
    marginRight?: number;
    marginBottom?: number;
    colOptions: string[];
}


export interface IContent {
    id: string;
    type: TypeContent;
    value: string;
    valueParams?: IValParams;
    colParams: IColParams;
}

export interface ISaveColData {
    content: string;
    colParams: IColParams;
}

export enum TypeContent {
    text = 'text',
    media = 'media',
    button = 'button',
    confirm_phone = 'confirm_phone',
    confirm_email = 'confirm_email',
    popupTitle = 'popupTitle',
    popupSubTitle = 'popupSubTitle',
    formCheck = 'formCheck',
    form = 'form',
    checkbox = 'checkbox',
    products = 'products',
}

export interface IColumnsProps {
    items: IContent[];
    parent_id: string;
}

export interface IRowParams {
    container: string;
    width?: number;
    maxWidth?: number;
    minHeight?: number;
    options: string[]
    marginTop?:     number;
    marginRight?:   number;
    marginLeft?:    number;
    marginBottom?:  number;
    paddingTop?:    number;
    paddingRight?:  number;
    paddingLeft?:   number;
    paddingBottom?: number;
    color?: string;
    backgroundColor?: string;
    backgroundImage?: string;
    backgroundRepeat?: string;
    backgroundPosition?: string;
    backgroundAttachment?: string;
    backgroundSize?: string;
}

export interface ISettingsProps extends IRowParams {
    name: string;
    parent_id: string;
}

export interface IStyleList {
    title: string;
    items: ISubBlock[]
}

export interface IAccordionBlock extends ISubBlock {
    isOpen: boolean;
}

export type TList<T> = IItemList<T>[];

export interface IItemList<T> {
    title: string, value: T
}

export type ColParams = {
    mobileWidth: FieldState<string>;
    smallWidth: FieldState<string>;
    mediumWidth: FieldState<string>;
    largeWidth: FieldState<string>;
    customMobileWidth:  FieldState<number | undefined>;
    customSmallWidth:   FieldState<number | undefined>;
    customMediumWidth:  FieldState<number | undefined>;
    customLargeWidth:   FieldState<number | undefined>;
    maxWidth: FieldState<number | undefined>;
    maxHeight: FieldState<number | undefined>;
    paddingTop: FieldState<number | undefined>;
    paddingLeft: FieldState<number | undefined>;
    paddingRight: FieldState<number | undefined>;
    paddingBottom: FieldState<number | undefined>;
    marginTop: FieldState<number | undefined>;
    marginLeft: FieldState<number | undefined>;
    marginRight: FieldState<number | undefined>;
    marginBottom: FieldState<number | undefined>;
    colOptions: FieldState<string[]>;
}

export interface IModalProps {
    id: string;
    parent_id: string;
    type: TypeContent;
    content: string;
    colParams: IColParams;
    valParams: IValParams;
}

export interface IGeneralSettings {
    maxWidth?: number;
    container?: string;
    bgColor?: string;
    bgImage?: string;
    imageSize?: string;
    imageAttachment?: string;
    imagePosition?: string;
    imageRepeat?: string;
    paddingTop?: number;
    paddingLeft?: number;
    paddingRight?: number;
    paddingBottom?: number;
    marginTop?: number;
    marginLeft?: number;
    marginRight?: number;
    marginBottom?: number;
}

export interface IAllSettings {
    general: IGeneralSettings;
    text: {
        main: ITextSettings;
        h1: ITextSettings;
        h2: ITextSettings;
    }
}

export interface ITextSettings {
    fontFamily?:    string;
    color?:         string;
    size?:          string;
    weight?:        string;
    style?:         string;
    lineHeight?:    number | string;
    letterSpacing?: number | string;
}

export type FormGeneralSettings = {
    maxWidth: FieldState<IGeneralSettings['maxWidth']>;
    container: FieldState<IGeneralSettings['container']>;
    bgColor: FieldState<IGeneralSettings['bgColor']>;
    bgImage: FieldState<IGeneralSettings['bgImage']>;
    imageSize: FieldState<IGeneralSettings['imageSize']>;
    imageAttachment: FieldState<IGeneralSettings['imageAttachment']>;
    imagePosition: FieldState<IGeneralSettings['imagePosition']>;
    imageRepeat: FieldState<IGeneralSettings['imageRepeat']>;
    paddingTop: FieldState<IGeneralSettings['paddingTop']>;
    paddingLeft: FieldState<IGeneralSettings['paddingLeft']>;
    paddingRight: FieldState<IGeneralSettings['paddingRight']>;
    paddingBottom: FieldState<IGeneralSettings['paddingBottom']>;
    marginTop: FieldState<IGeneralSettings['marginTop']>;
    marginLeft: FieldState<IGeneralSettings['marginLeft']>;
    marginRight: FieldState<IGeneralSettings['marginRight']>;
    marginBottom: FieldState<IGeneralSettings['marginBottom']>;
}
export type FormText = {
    fontFamily:  FieldState<ITextSettings['fontFamily']>;
    color:       FieldState<ITextSettings['color']>;
    size:        FieldState<ITextSettings['size']>;
    weight:      FieldState<ITextSettings['weight']>;
    style:       FieldState<ITextSettings['style']>;
    lineHeight:  FieldState<ITextSettings['lineHeight']>;
    letterSpacing:  FieldState<ITextSettings['letterSpacing']>;
}

export interface IDataAll {
    phone?: string;
    email?: string;
    name?: string;
    lastName?: string;
    payment_method?: string;
    check?: File;
    product?: string;
    bic?: string;
}

export interface IDataForm extends IDataAll {
    acceptLabel: string;
    labels: Pick<IValParams, 'firstName'|'lastName'|'email'|'IBAN'|'bic'>;
}

export interface IFieldForm extends IDataAll {
    label: string;
}