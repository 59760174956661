import {Component} from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {FieldState, FormState} from "formstate-x/lib";
import {inject, observer} from "mobx-react";
import {makeObservable, observable} from "mobx";
import {isEmpty} from 'lodash';

import {Field} from "../../components/Field";
import { Button } from '../../components/Button';
import {repository} from "../../shared/repositories";
import {confirmPassword, password, required} from "../../utils/validators/required";
import {LayoutPage} from "./Layout";
import {INewPasswordForm} from "./models";
import './styles.scss'
import {getErrorMessage, getQueryParams} from "../../utils/utils";
import Loading from "../../components/Loading";
import {INotificationStore} from "../../store";

interface IState {
    loading: boolean;
}
type Props = RouteComponentProps<{}> & INotificationStore;

@inject('notificationStore')
@observer
export class _NewPassword extends Component<Props, IState> {
    @observable form: FormState<INewPasswordForm>;

    constructor(props: Props) {
        super(props);

        this.form = new FormState({
            password: new FieldState("").validators(required, password),
            confirmPassword: new FieldState("").validators(required, password),
            token: new FieldState(null).validators(required),
            email:  new FieldState(null).validators(required),
        });

        this.state = {
            loading: false,
        }

        makeObservable(this);
    }

    componentDidMount() {
        const { search } = this.props.location;
        const { token, email } = getQueryParams(search);
        this.setState({ loading: true });

        repository.auth.checkEmail(token, email).then(response => {
            this.setState({ loading: false });

            if ([200, 201].includes(response.status)) {
                this.form.$.token.onChange(token);
                this.form.$.email.onChange(email);
            } else {
                this.props.notificationStore.error(getErrorMessage(response))
                this.form.$.token.onChange(null);
                this.form.$.email.onChange(null);
            }
        })
    }

    handleSubmit = async () => {
        const res = await this.form.validate();

        if (res.hasError) {
            console.log(res.hasError, res.error)
            return;
        }

        const formData = new FormData();
        formData.append('token', this.form.$.token.value ? this.form.$.token.value : '');
        formData.append('email', this.form.$.email.value ? this.form.$.email.value : '');
        formData.append('password', this.form.$.password.value);
        formData.append('password_confirmation', this.form.$.confirmPassword.value);

        repository.auth.resetPassword(formData).then(response => {
            if ([200, 201].includes(response.status)) {
                this.props.notificationStore.success('Password is changed!');
                this.props.history.push('/login');
            }
        });
    }

    submit = async (e: any) => {
        e.preventDefault();
        this.handleSubmit();
    }

    renderForm = () => {
        const disabled = confirmPassword(this.form.$.password._value, this.form.$.confirmPassword._value);

        return (
            <form onSubmit={this.submit}>
                <Loading active={this.state.loading} />
                <Field
                    label="New password"
                    name="email"
                    type="password"
                    placeholder="Enter new password"
                    value={this.form.$.password._value}
                    onChange={(e) => {this.form.$.password.onChange(e)}}
                    errorMessage={this.form.$.password.error}
                />
                <Field
                    label="Confirm password"
                    name="email"
                    type="password"
                    placeholder="Confirm password"
                    value={this.form.$.confirmPassword._value}
                    onChange={(e) => {this.form.$.confirmPassword.onChange(e)}}
                    errorMessage={this.form.$.confirmPassword.error || disabled}
                />
                <div className="login__form-footer">
                    <Button text="Update password" disabled={!!this.form.error || !isEmpty(disabled)} isSubmit full />
                </div>
            </form>
        );
    }

    render() {
        return (
            <>
                <LayoutPage signIn={true} title="Change password" >
                    {this.renderForm()}
                </LayoutPage>
            </>
        )
    }
}

export const NewPassword = withRouter(_NewPassword);