export enum StatusNotification {
    success = 'success',
    error = 'error',
    info = 'info',
    closed = 'notification__item--closed'
}

export interface INotificationsProps {
    status: StatusNotification;
    title: string;
    id: string;
    description?: string;
}

export interface IDocumentStore {
    id?: string;
    open: boolean;
    title: string;
}